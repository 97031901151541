import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, message } from "antd";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import { LOG_BASE_URL } from "constants/Settings";
import { LOG_CONTENT_TYPE, DATE_TIME_FORMAT_LISTING } from "constants/CollegeDekho";
import { getLogDetail } from "../../../services/Logs/index";
import { getErrors } from "../../../util/helpers";
import moment from 'moment';


const LogDetail = () => {
  const { templateId, objectId, logId } = useParams();
  let url = LOG_BASE_URL + "/api/logs/" + logId;
  const [logDetail, setLogDetail] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    getLogDetail(url)
      .then((data) => {
        if (mounted) {
          if (data) {
            console.log("data", data);
            setLogDetail(data?.data);
          }
        }
      })
      .catch(function (response) {
        const err = getErrors(response);
        setError(err.allError);
        console.log(err);
      });
    return () => (mounted = false);
  }, []);

  return (
    <>
      {error ? (
        message.error(error)
      ) : (
        <Row>
          <Col span={24}>
            <NavBreadCrumb
              page={LOG_CONTENT_TYPE[templateId]}
              page2={logDetail?.content_name}
            />
            <div className="gx-pt-4">
              <div className="ant-card ant-card-bordered gx-card-widget">
                <div className="ant-card-body">
                  <h2 className="gx-mb-0">Log Detail</h2>
                </div>
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className="ant-card ant-card-bordered gx-card">
              <div className="ant-card-body">
                <div className="log-detail">
                  <table>
                    <tr>
                      <td>Content type</td>
                      <td>{logDetail?.content_type}</td>
                    </tr>
                    <tr>
                      <td>Field</td>
                      <td>{logDetail?.field}</td>
                    </tr>
                    <tr>
                      <td>Object ID</td>
                      <td>{logDetail?.object_id}</td>
                    </tr>
                    <tr>
                      <td>Content Name</td>
                      <td>{logDetail?.content_name}</td>
                    </tr>
                    <tr>
                      <td>Entity Content Type</td>
                      <td>{logDetail?.entity_content_type}</td>
                    </tr>
                    <tr>
                      <td>Entity Object ID</td>
                      <td>{logDetail?.entity_object_id}</td>
                    </tr>
                    <tr>
                      <td>Short Name</td>
                      <td>{logDetail?.short_name}</td>
                    </tr>
                    <tr>
                      <td>Page Content Type</td>
                      <td>{logDetail?.page_content_type}</td>
                    </tr>
                    <tr>
                      <td>Page Object ID</td>
                      <td>{logDetail?.page_object_id}</td>
                    </tr>
                    <tr>
                      <td>Page Name</td>
                      <td>{logDetail?.page_name}</td>
                    </tr>
                    <tr>
                      <td>Added On</td>
                      <td>{moment(logDetail?.added_on).format(DATE_TIME_FORMAT_LISTING)}</td>
                    </tr>
                    <tr>
                      <td>Modified By ID</td>
                      <td>{logDetail?.modified_by_id}</td>
                    </tr>
                    <tr>
                      <td>Modified By</td>
                      <td>{logDetail?.modified_by}</td>
                    </tr>
                    <tr>
                      <td>Added Words</td>
                      <td>{logDetail?.added_words}</td>
                    </tr>
                    <tr>
                      <td>Deleted Words</td>
                      <td>{logDetail?.deleted_words}</td>
                    </tr>
                    <tr>
                      <td>Added Words Count</td>
                      <td>{logDetail?.added_words_count}</td>
                    </tr>
                    <tr>
                      <td>Deleted Words Count</td>
                      <td>{logDetail?.deleted_words_count}</td>
                    </tr>
                    <tr>
                      <td>Total Words Count</td>
                      <td>{logDetail?.total_words_count}</td>
                    </tr>
                    <tr>
                      <td>Content</td>
                      <td>{logDetail?.content}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
export default LogDetail;
