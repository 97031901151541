import React from 'react';
import {Row, Col,Form} from "antd";
import DebounceSelect from '../../../components/DebounceSelect/index'
import { getAccreditationList } from '../../../services/Accreditation/Accreditation';
import { getUniversityList } from "../../../services/University/University";
import { getInstituteAutoSuggestion } from "../../../services/Institute/Institute";
import { getApprovedByList } from "../../../services/ApprovedBy/ApprovedBy";





const Collaborations=()=>{

	return(
		  <Row>
			<Col span={12}>
				<Form.Item label="Affiliated To" name="affiliation_with_institute" className="labelInput"
				>

				<DebounceSelect
                     mode="multiple"


                     placeholder="Affiliated To"
                     fetchOptions={getInstituteAutoSuggestion}
					 showSearch = {true}
                  />

				</Form.Item>
                </Col>

			<Col span={12}>
				<Form.Item label="Affiliated by university" name="affiliation_with_university" className="labelInput">

				<DebounceSelect
                     mode="multiple"


                     placeholder="Affilation by university"
                     fetchOptions={getUniversityList}
					 showSearch = {true}
                  />

				</Form.Item>


			</Col>

			<Col span={12}>
			<Form.Item label="Accreditation" name="accreditation_to" className="labelInput">
                   <DebounceSelect
                     mode="multiple"
                     placeholder="Accreditation"
                     fetchOptions={getAccreditationList}
					 showSearch = {true}
                  />
				</Form.Item>
			</Col>

			<Col span={12}>
			<Form.Item label="Approved By" name="approved_by" className="labelInput">

				<DebounceSelect
                     mode="multiple"
                     placeholder="Approved By"
                     fetchOptions={getApprovedByList}
					 showSearch = {true}
                  />
				</Form.Item>
			</Col>
		</Row>
	)
}
export default Collaborations
