import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Table, Row, Col, Divider } from "antd";
import { getLatestUpdate } from "../../../services/InstituteLatestUpdate/InstituteLatestUpdate";
import { Link, useParams } from 'react-router-dom';
import { FormOutlined } from "@ant-design/icons";
import { getErrors } from '../../../util/helpers';
import { DATE_TIME_FORMAT_LISTING } from '../../../constants/CollegeDekho'
import { CONTENT_TYPE_ID } from "../../../constants/Settings";
import moment from 'moment';
const columns = [{
  title: 'ID',
  dataIndex: 'id',

},
{
  title: 'Heading',
  dataIndex: 'heading',
},

{
  title: 'Date',
  dataIndex: 'date',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span> {record.date ? moment(record.date).format(DATE_TIME_FORMAT_LISTING) : null} </span>
    </span>
  ),
},

{
  title: 'End Date',
  dataIndex: 'end_date',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span> {record.end_date ? moment(record.end_date).format(DATE_TIME_FORMAT_LISTING) : null} </span>
    </span>
  ),

},
{
  title: 'Last Modified On',
  dataIndex: 'updated_on',
  render: (text, record) => (
    <span>
      {moment(record.modified_on).format(DATE_TIME_FORMAT_LISTING)}
    </span>
  ),
},
{
  title: 'Status',
  dataIndex: 'object_status',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span>{record.object_status ? 'Active' : 'InActive'}</span>


    </span>
  ),
},

{
  title: 'Action',
  key: 'action',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span className="gx-link"><Link to={`/course/${record?.content_object?.parent?.id}/vernacular/${record?.content_object?.vernacular_parent?.id}/latest-update/${record.id}/update/`}> <FormOutlined /></Link></span>


    </span>
  ),
}
];



const LatestUpdateListComponent = ({ setExtraDetails, setError }) => {

  const pathname = useSelector(({ common }) => common.pathname);
  const dashboardType = pathname.includes("institute") ? "institute" : "course";
  const contentTypeId = CONTENT_TYPE_ID[dashboardType]

  const [latestUpdate, setLatestUpdate] = useState([])
  const [loading, setLoading] = useState(true);
  const { ID, vernacularID } = useParams();


  useEffect(() => {
    let mounted = true;
    //setLoading(true);
    console.log("API CALL - LatestUpdate ")

    getLatestUpdate(vernacularID, contentTypeId)
      .then(data => {
        if (mounted) {
          setLatestUpdate(data.data.results);
          //setTotal(data.data.count);
          setLoading(false);
          setExtraDetails(data.data.extra_details)

        }
      }
      )
      .catch(function (response) {
        const err = getErrors(response)
        setError(err.allError);
        console.log(err)

      });

  }, [ID])



  return (
    <Row>
      <Col span={24}>
        <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={latestUpdate} pagination={false} />
      </Col>
    </Row>

  );
};

export default LatestUpdateListComponent;
