import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Button, Form, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { useFetchData } from "../../../hooks/";
import { patch, post } from '../../../services/';
import { isInt, getErrors } from '../../../util/helpers';
import { CoursePageDetailForm } from "./CoursePageDetailForm";
import { COMMON_SUCCESS_MESSAGE, COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho';
import CircularProgress from "../../../components/CircularProgress";
import { Spin } from 'antd';



const CoursePageDetail = () => {
    const [loader, setLoader] = useState(false);
    const [form] = Form.useForm();
    const { courseID, courseVernacularID } = useParams();
    const { courseMasterPageID } = useParams();
    const [disabledSave, setDisabledSave] = useState(false)
    const history = useHistory()
    const isAddMode = !isInt(courseMasterPageID)

    const dashboardTypeDetailURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", courseID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(dashboardTypeDetailURL)
    let url = null;
    let saveURL = COURSE_CONTENT_API_LIST["COURSE_PAGE_LISTING"]
    if (!isAddMode) {
        url = COURSE_CONTENT_API_LIST["COURSE_PAGE_DETAIL"].replace("#id#", courseVernacularID)
        url = url.replace("#id1#", courseMasterPageID)
        saveURL = url
        console.log(url)
    }
    const [data, loading, error] = useFetchData(url)
    const setInstitutePageObj = (data) => {
        const obj = { ...data }

        obj.object_status = obj.object_status?.id
        form.setFieldsValue(obj)
    }


    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (data) {
                setInstitutePageObj(data)

            }

        }


        return () => mounted = false;
    }, [data])


    const getCleanData = (data) => {
        const cleanData = { ...data }
        delete cleanData.course_content
        delete cleanData.course_content_vernacular
        delete cleanData.master_page
        return cleanData
    }

    const crudErrorHandler = (error) => {
        showError(error)

    }

    const patchData = (data) => {
        setLoader(true);
        patch(saveURL, data).then(function (response) {
            message.success(COMMON_SUCCESS_MESSAGE);
            history.push(`/course/${courseID}/vernacular/${courseVernacularID}/page`)

        })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const postData = (data) => {
        setLoader(true);
        post(saveURL, data)
            .then(function (response) {
                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/course/${courseID}/vernacular/${courseVernacularID}/page`)

            })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const showError = (errors) => {

        const error = getErrors(errors)
        if (error.allError.length > 0) {
            message.error(error.allError.join());
        }
        console.log("server error", error.serverError)
        form.setFields(error.serverError)


    }
    const onFinish = (e) => {
        const data = form.getFieldsValue(true);
        const cleanData = getCleanData(data)
        setDisabledSave(true)
        if (isAddMode) {
            postData(cleanData)
        }
        else {
            patchData(cleanData)
        }
    }
    return (
        <>

            {loading ?
                <div className="gx-loader-view">
                    <CircularProgress />
                </div> : null}



            {error ? message.error(error) : loader === true ? <center><Spin /></center> :

                <Row>
                    <Col span={24}>
                        <NavBreadCrumb 
                        page={data ? data.master_page.name : null} 
                        breadCrumb={breadCrumb}
                        courseVernacularID={data?.course_content_vernacular} 
                        />
                        <div className="gx-pt-4">
                            <div className="ant-card ant-card-bordered gx-card-widget">
                                <div className="ant-card-body">
                                    <h2 className="gx-mb-0">
                                        {data ? `${data.course_content_vernacular?.name} - (${data.master_page.name})` : null}
                                    </h2>

                                </div>
                            </div>
                        </div>
                    </Col>


                    <Col span={24}>
                        <div className="ant-card ant-card-bordered gx-card">
                            <div className="ant-card-body">
                                <Form onFinish={onFinish} form={form}>
                                    <div className="gx-p-2">
                                        <CoursePageDetailForm />
                                    </div>
                                    <div className="d-flex" style={{ justifyContent: 'left' }}>
                                        <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>

                </Row>
            }
        </>
    );
};
export default CoursePageDetail;
