import { getAutoSuggestionList } from "../index";


export async function getInstituteStreamList(text,instituteID) {
    const url =   `/api/cms/institute/${instituteID}/stream/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
  }    

export async function getStreamList(text) {
    const url =  `/api/cms/course/stream/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
  }    


