import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { Collapse, Row, Col, Form, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import BasicDetails from './BasicDetails'
import ContactDetails from './ContactDetails'
import Collaborations from './Collaborations'
import ExtraDetails from './ExtraDetails'
import Description from './Description'
import Overview from './Overview'
import ReviewedBy from './reviewed_by';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getObject, getMultiObject } from '../../../util/helpers';

import { isInt, getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patchImage, postImage, post } from '../../../services/';
import {
	COMMON_SUCCESS_MESSAGE,
	COMMON_ERROR_MESSAGE_WRONG_DATA, API_URL_LIST
}
	from '../../../constants/CollegeDekho'


const Panel = Collapse.Panel;


const InstituteDetail = () => {
	const callback = (key) => { };

	const [form] = Form.useForm();

	const history = useHistory()
	const [loader, setLoader] = useState(false);
	const { instituteID } = useParams();
	const isAddMode = !isInt(instituteID)
	let url = null;
	let saveURL = API_URL_LIST["INSTITUTE_DETAIL_POST_URL"]
	let liveStatusUrl = API_URL_LIST["INSTITUTE_LIVE_STATUS_CHANGE"]
	if (!isAddMode) {
		url = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
		saveURL = url
	}

	const [data,, error] = useFetchData(url)
	const [institute,setInstitute] = useState(null)
	const [disabledSave, setDisabledSave] = useState(false)
	const [description, setDescription] = useState("");
	const [overview, setOverview] = useState("");
	const [adminUser, setAdminUser] = useState(false);
	const [isLive, setIsLive] = useState(false);
	const [partnerStatus, setPartnerStatus] = useState(null)
	const [sizeTypeRequired, setSizeTypeRequired] = useState(false)
  
	const setAaffiliation = (obj, affiliation) => {

		if (!affiliation) {
			return;
		}
		let tempInstitute = []
		let tempUniversity = []

		for (var temp of affiliation) {
			if (temp.content_type === 22) {
				tempInstitute.push({ "value": temp.id, "label": temp.name })
			}
			else {
				tempUniversity.push({ "value": temp.id, "label": temp.name })
			}
		}
		obj["affiliation_with_institute"] = tempInstitute
		obj["affiliation_with_university"] = tempUniversity

	}

	const getLogo = (data) => {
		if (!data.logo) {
			return [];
		}
		return [
			{
				//uid: '1',
				//name: 'xxx.png',
				//status: 'done',

				url: data.logo,
			},
		]

	}

	const getEtpLogo = (data) => {
		if (!data.etp_logo) {
			return [];
		}
		return [
			{
				url: data.etp_logo,
			},
		]

	}

	const getBanner = (data) => {
		if (!data.banner) {
			return [];
		}

		return [
			{
				url: data.banner,
			},
		]

	}
	const getProspectus = (data) => {
		if (!data.prospectus) {
			return [];
		}
		return [
			{
				//uid: '1',
				//name: 'xxx.png',
				//status: 'done',

				url: data.prospectus,
			},
		]

	}
	const getETPBrochure = (data) => {
		if (!data.etp_brochure) {
			return [];
		}
		return [
			{
				//uid: '1',
				//name: 'xxx.png',
				//status: 'done',

				url: data.etp_brochure,
			},
		]

	}


	const setInstituteObj = (data) => {
		const instituteObj = {
			name: data.name,
			short_name: data.short_name,
			address: data.address,
			latitude: data.latitude,
			longitude: data.longitude,
			website: data.website,
			phone_no: data.phone_no,
			pin_code: data.pin_code,
			email_address: data.email_address,
			gender: data.gender?.id,
			object_status: data.object_status?.id,
			service: data.service,
			synonym: data.synonym,
			description: data.description,
			overview: data.overview,
			coordinator_name: data.coordinator_name,
			coordinator_designation: data.coordinator_designation,
			coordinator_linkedin: data.coordinator_linkedin,
			number_of_seats_filled: data.number_of_seats_filled,
			size: data?.size,
			size_type: data?.size_type

		}
		instituteObj["other_campuses"] = getMultiObject(data.other_campuses)
		instituteObj["university"] = getObject(data.university)
		instituteObj["state"] = getObject(data.state)
		instituteObj["city"] = getObject(data.city)
		if (data.estb_date) {
			instituteObj["estb_date"] = moment(data.estb_date, 'YYYY-MM-DD')
		}
		else {
			instituteObj["estb_date"] = null;
		}
		if (data.year_of_accredition) {
			instituteObj["year_of_accredition"] = moment(`${data.year_of_accredition}-11-11`, 'YYYY-MM-DD')
		}


		if (data.institute_type) {
			instituteObj["institute_type"] = data.institute_type.id
		}
		if (data.partner_status) {
			instituteObj["partner_status"] = data.partner_status.id
			instituteObj["admission_status"] = data.admission_status && data.admission_status.id// By default Live (1)
		}
		if (data.type) {
			instituteObj["type"] = data.type.id
		}
		if (data.fax_numbers) {
			instituteObj["fax_numbers"] = data.fax_numbers.toString()
		}
		//instituteObj["other_campuses"] = [{"id":30,"label":"himmat",key:30}]
		setAaffiliation(instituteObj, data.affiliation)
		instituteObj["accreditation_to"] = getMultiObject(data.accreditation_to)
		instituteObj["approved_by"] = getMultiObject(data.approved_by)
		instituteObj["acceptance_rate"] = data.acceptance_rate
		instituteObj["student_faculty_ratio"] = data.student_faculty_ratio
		instituteObj["minority_college"] = getMultiObject(data.minority_college)
		instituteObj["gender_ratio"] = data.gender_ratio
		instituteObj["description"] = data.description
		instituteObj["overview"] = data.overview
		instituteObj["number_of_teaching_faculty"] = data.number_of_teaching_faculty
		if (data.foreign_exchange_program) {
			instituteObj["foreign_exchange_program"] = true;
		}
		if (data.is_autonomous) {
			instituteObj["is_autonomous"] = true
		}
		if (data.minority_non_minority == 1) {
			instituteObj["minority_non_minority"] = true
		} else {
			instituteObj["minority_non_minority"] = false
		}
		//instituteObj.logo = {}
		instituteObj.logo = getLogo(data)
		instituteObj.etp_logo = getEtpLogo(data)
		instituteObj.prospectus = getProspectus(data)
		instituteObj.etp_brochure = getETPBrochure(data)
		instituteObj.banner = getBanner(data)
		setDescription(data.description)
		setOverview(data.overview)
		data.partner_status.id && setPartnerStatus(data.partner_status.id)
		//setInstitute(instituteObj)
		form.setFieldsValue(instituteObj)

	}
	const isAdmin = useSelector(state => state.auth.isAdminUser)
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (data) {
				setInstitute(data)
				setInstituteObj(data)
				setIsLive(data.is_live)
				setAdminUser(isAdmin)


			}

		}


		return () => mounted = false;
	}, [data])




	const buildFormData = (formData, data, parentKey) => {
		if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {

			Object.keys(data).forEach(key => {
				buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
			});
		} else {
			const value = data == null ? '' : data;

			formData.append(parentKey, value);
		}
	}

	const getFormData = (data) => {
		const formData = new FormData();

		buildFormData(formData, data);

		return formData;
	}

	const getCleanData = (data) => {
		const formData = new FormData();
		const logo = data.logo
		const prospectus = data.prospectus;
		const etp_brochure = data.etp_brochure;
		const etp_logo = data.etp_logo;
		const banner = data.banner;
		formData.append("name", data.name)


		formData.append("partner_status", data.partner_status ? data.partner_status: '');

		formData.append("admission_status", data.admission_status ? data.admission_status : '')

		formData.append("number_of_seats_filled", data.number_of_seats_filled ? data.number_of_seats_filled : '');

		formData.append("short_name", data.short_name ? data.short_name : '')
		formData.append("university", data.university ? data.university.value : '');
		formData.append("city", data.city.value)
		formData.append("state", data.state.value)
		formData.append("institute_type", data.institute_type ? data.institute_type : '');
		formData.append("type", data.type ? data.type : '');
		formData.append("estb_date", data.estb_date ? data.estb_date.format('YYYY-MM-DD').toString() : '')
		formData.append("year_of_accredition", data.year_of_accredition ? data.year_of_accredition.format('YYYY') : '')



		let other_campuses = []
		if (data.other_campuses) {
			other_campuses = data.other_campuses.map((item) => { return item.value })
		}
		formData.append("other_campuses", JSON.stringify(other_campuses))
		formData.append("object_status", data.object_status)
		formData.append("synonym", data.synonym ? data.synonym : '')
		formData.append("address", data.address ? data.address : '');
		formData.append("latitude", data.latitude ? data.latitude : '');
		formData.append("longitude", data.longitude ? data.longitude : '');
		formData.append("website", data.website ? data.website : '');
		formData.append("phone_no", data.phone_no ? data.phone_no : '')
		formData.append("pin_code", data.pin_code ? data.pin_code : '');
		formData.append("fax_numbers", data.fax_numbers ? data.fax_numbers : '');
		formData.append("email_address", data.email_address ? data.email_address : '');
		formData.append("description", description ? description : '');
		formData.append("overview", overview ? overview : '');
		formData.append("coordinator_name", data.coordinator_name ? data.coordinator_name : '');
		formData.append("coordinator_designation", data.coordinator_designation ? data.coordinator_designation : '');
		formData.append("coordinator_linkedin", data.coordinator_linkedin ? data.coordinator_linkedin : '');

		formData.append("size", data.size ? data.size : '');
		formData.append("size_type", data.size_type ? data.size_type : '');

		const affiliated_to = { "with_institute": [], "with_university": [] }
		if (typeof data.affiliation_with_institute !== 'undefined') {
			affiliated_to.with_institute = data.affiliation_with_institute.map((item) => { return item.value })
		}
		if (typeof data.affiliation_with_university !== 'undefined') {
			affiliated_to.with_university = data.affiliation_with_university.map((item) => { return item.value })
		}

		formData.append("affiliated_to", JSON.stringify(affiliated_to))

		let accreditation_to = []
		if (typeof data.accreditation_to !== 'undefined') {
			accreditation_to = data.accreditation_to.map((item) => { return item.value })
		}
		formData.append("accreditation_to", JSON.stringify(accreditation_to))

		let approved = []
		if (typeof data.approved_by !== 'undefined') {
			approved = data.approved_by.map((item) => { return item.value })

		}
		formData.append("approved", JSON.stringify(approved))
		formData.append("acceptance_rate", data.acceptance_rate ? data.acceptance_rate : '');
		formData.append("student_faculty_ratio", data.student_faculty_ratio ? data.student_faculty_ratio : '');

		let minority_college = []
		if (data.minority_college) {
			minority_college = data.minority_college.map((item) => { return item.value })
		}
		formData.append("minority_college", JSON.stringify(minority_college))
		formData.append("gender", data.gender ? data.gender : '');
		formData.append("gender_ratio", data.gender_ratio ? data.gender_ratio : '');
		formData.append("number_of_teaching_faculty", data.number_of_teaching_faculty ? data.number_of_teaching_faculty : '')
		formData.append("foreign_exchange_program", data.foreign_exchange_program ? data.foreign_exchange_program : 0);
		formData.append("is_autonomous", data.is_autonomous ? data.is_autonomous : 0);
		if (data.minority_non_minority) {
			formData.append("minority_non_minority", 1)
		} else {
			formData.append("minority_non_minority", 2)
		}
		if (logo && logo.length > 0) {
			for (const element of logo) {
				if (typeof element.originFileObj !== 'undefined') {
					formData.append("logo", element.originFileObj)
				}
			}

		}
		else {
			formData.append("logo", '')
		}
		if (prospectus && prospectus.length > 0) {
			for (const element of prospectus) {
				if (typeof element.originFileObj !== 'undefined') {
					formData.append("prospectus", element.originFileObj)
				}
			}
		}
		else {
			formData.append("prospectus", '')
		}


		if (etp_brochure && etp_brochure.length > 0) {
			for (const element of etp_brochure) {
				if (typeof element.originFileObj !== 'undefined') {
					formData.append("etp_brochure", element.originFileObj)
				}
			}
		}
		else {
			formData.append("etp_brochure", '')
		}

		if (etp_logo && etp_logo.length > 0) {
			for (const element of etp_logo) {
				if (typeof element.originFileObj !== 'undefined') {
					formData.append("etp_logo", element.originFileObj)
				}
			}
		}
		else {
			formData.append("etp_logo", '')
		}

		if (banner && banner.length > 0) {
			for (const element of banner) {
				if (typeof element.originFileObj !== 'undefined') {
					formData.append("banner", element.originFileObj)
				}
			}
		}
		else {
			formData.append("banner", '')
		}

		return formData
	}

	const crudErrorHandler = (error) => {
		showError(error)


	}

	const patchData = (data) => {
		setLoader(true);
		patchImage(saveURL, data).then(function (response) {
			message.success(COMMON_SUCCESS_MESSAGE);

		})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
				setLoader(false);
			});

	}
	const postData = (data) => {
		setLoader(true);
		postImage(saveURL, data)
			.then(function (response) {

				message.success(COMMON_SUCCESS_MESSAGE);
				history.push(`/institute/${response.data.id}/update/`)

			})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
				setLoader(false);
			});

	}
	const showError = (errors) => {

		const error = getErrors(errors)
		if (error.allError.length > 0) {
			message.error(error.allError.join(''));
		}
		else {
			message.error(COMMON_ERROR_MESSAGE_WRONG_DATA);
		}

		form.setFields(error.serverError)


	}
	const onFinish = (e) => {
		const data = form.getFieldsValue(true);
		const formData = getCleanData(data)

		setDisabledSave(true)
		if (isAddMode) {
			postData(formData)
		}
		else {
			patchData(formData)
		}
	}


	const onLive = (e) => {
		let liveStatus = isLive ? "Remove Live" : "Make Live"
		let confirmBox = window.confirm("Are you sure to " + liveStatus)
		if (confirmBox === true) {
			const newStatus = !isLive

			let newData = { "id": instituteID, "is_live": newStatus }
			setLoader(true);
			post(liveStatusUrl, newData)
				.then(function (response) {
					setIsLive(newStatus)

					message.success(response.data.message);
					history.push(`/institute/${instituteID}/update`)
				})
				.catch(crudErrorHandler)
				.finally(() => {
					setLoader(false);
				});
		}
	}

	const onValuesChange = (changedValues, allValues) => {
		const isSizeTypeRequired = allValues.size
			? Object.values(allValues.size).filter(Boolean).length > 0
			: false;
		setSizeTypeRequired(isSizeTypeRequired);
	};



	return (
		<>
			{error ? message.error(error) : loader === true ? <center><Spin /></center> :
				<Row>
					<Col span={24}>

						<NavBreadCrumb breadCrumb={data} />
						<div className="gx-pt-4">
							<div className="ant-card ant-card-bordered gx-card-widget">
								<div className="ant-card-body">
									<h2 className="gx-mb-0"> {institute?.name ? `${institute.name} Overview` : `New Institute`} </h2>
								</div>
							</div>
						</div>
					</Col>


					<Col span={24}>
						<Form onFinish={onFinish} form={form} onValuesChange={onValuesChange}>
							<Collapse defaultActiveKey={['1']} onChange={callback} className="collegeEditCollapse">
								<Panel header="Basic Details" key="1">
									<div className="gx-p-2">
										<BasicDetails
											partnerStatus={partnerStatus} setPartnerStatus={setPartnerStatus}
											form={form}
										/>
									</div>
								</Panel>
								<Panel header="Contact Details" key="2">
									<div className="gx-p-2">
										<ContactDetails />
									</div>
								</Panel>
								<Panel header="Collaborations" key="3">
									<div className="gx-p-2">
										<Collaborations />
									</div>
								</Panel>
								<Panel header="Extra Details" key="4">
									<div className="gx-p-2">
										<ExtraDetails form={form} sizeTypeRequired={sizeTypeRequired} />
									</div>
								</Panel>
								<Panel header="Description" key="5">
									<div className="gx-p-2">
										<Description content={description} setContent={setDescription} isAddMode={isAddMode} instituteID={instituteID} />
									</div>
								</Panel>
								<Panel header="Overview" key="6">
									<div className="gx-p-2">
										<Overview content={overview} setContent={setOverview} isAddMode={isAddMode} instituteID={instituteID} />
									</div>
								</Panel>
								<Panel header="ReviewedBy" key="7">
									<div className="gx-p-2">
										<ReviewedBy />
									</div>
								</Panel>
							</Collapse>
							<div className="d-flex">
								<Button type="primary" htmlType="submit" disabled={disabledSave}> Save</Button>
								{instituteID && adminUser && (<Button type="danger" onClick={onLive} > {isLive === true ? "Remove Live" : "Make Live"}</Button>)}
							</div>
						</Form>
					</Col>

				</Row>
			}






		</>
	)
}

export default InstituteDetail
