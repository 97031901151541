import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../notFound/index";
import CourseListing from "./courseListing/CourseListing";
import CourseDetails from "./courseDetail/CourseDetail";
import CourseVernacularListing from "./courseVernacularListing/CourseVernacularListing";
import CourseVernacularDetails from "./courseVernacularDetail/CourseVernacularDetail";
import MasterPageListing from "./masterPageListing/MasterPageListing";
import MasterPageDetail from "./masterPageDetail/MasterPageDetail";
import ComponentListing from "./componentListing/ComponentListing";
import ComponentDetail from "./componentDetail/ComponentDetail";
import CoursePageListing from "./coursePageListing/CoursePageListing";
import CoursePageDetail from "./coursePageDetail/CoursePageDetail";
import CoursePageComponent from "./coursePageComponent/CoursePageComponent";
import CoursePageComponentListing from "./coursePageComponentListing/CoursePageComponentListing";
import LatestUpdateListing from "../common/latestUpdateListing/LatestUpdateListing";
import LatestUpdateDetail from "../common/latestUpdateDetail/latestUpdateDetail";
import SlugRedirectionListing from "../common/slugRedirectionListing/SlugRedirectionListing";
import SlugRedirectionDetail from "../common/slugRedirectionDetail/SlugRedirectionDetail";
import FaqsListing from "../../components/Faqs/listing";
import FaqsDetail from "../../components/Faqs/details";
import FaqLogs from "../common/faqLogs";

const CourseDetail = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/faq-logs`} component={FaqLogs} />
    <Route path={`${match.url}/slug-redirection/create/`} component={SlugRedirectionDetail} />
    <Route path={`${match.url}/slug-redirection`} component={SlugRedirectionListing} />
    <Route exact path={`${match.url}/`} component={CourseListing} />
    <Route exact path={`${match.url}/create/`} component={CourseDetails} />
    <Route exact path={`${match.url}/:courseID(\\d+)/update/`} component={CourseDetails} />
    
    <Route exact path={`${match.url}/:courseID(\\d+)/vernacular/`} component={CourseVernacularListing} />
    <Route exact path={`${match.url}/:courseID(\\d+)/vernacular/create/`} component={CourseVernacularDetails} />
    <Route exact path={`${match.url}/:courseID(\\d+)/vernacular/:courseVernacularID(\\d+)/update/`} component={CourseVernacularDetails} />
    
    <Route exact path={`${match.url}/master-page`} component={MasterPageListing} />
    <Route exact path={`${match.url}/master-page/create/`} component={MasterPageDetail} />
    <Route exact path={`${match.url}/master-page/:masterPageID(\\d+)/update/`} component={MasterPageDetail} />
    <Route exact path={`${match.url}/component`} component={ComponentListing} />
    <Route exact path={`${match.url}/component/create/`} component={ComponentDetail} />
    <Route exact path={`${match.url}/component/:componentID(\\d+)/update/`} component={ComponentDetail} />

    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/page`} component={CoursePageListing} />
    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/page/:courseMasterPageID(\\d+)/update/`} component={CoursePageDetail} />

    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/page/:courseMasterPageID(\\d+)/component`} component={CoursePageComponentListing} />
    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/page/:courseMasterPageID(\\d+)/component/create/`} component={CoursePageComponent} />
    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/page/:courseMasterPageID/component/:componentCourseMasterPageID(\\d+)/update/`} component={CoursePageComponent} />

    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/faqs/:templateContentTypeID/`} component={FaqsListing} />
    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/faqs/:templateContentTypeID/create/`} component={FaqsDetail} />
    <Route exact path={`${match.url}/:courseID/vernacular/:courseVernacularID(\\d+)/faqs/:templateContentTypeID/:faqObjectID/update/`} component={FaqsDetail} />
    <Route exact path={`${match.url}/:ID/vernacular/:vernacularID(\\d+)/latest-update`} component={LatestUpdateListing} />
    <Route exact path={`${match.url}/:ID/vernacular/:vernacularID(\\d+)/latest-update/:latestUpdateID(\\d+)/update/`} component={LatestUpdateDetail} />
    <Route exact path={`${match.url}/:ID/vernacular/:vernacularID(\\d+)/latest-update/create/`} component={LatestUpdateDetail} />
    <Route component={NotFound} />
  </Switch>
);

export default CourseDetail;
