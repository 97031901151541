import { Button, Col, Form, Row, message } from "antd"
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb"
import { useFetchData } from "../../../hooks"
import { getErrors, isInt } from "../../../util/helpers"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import ScholarshipPageComponentDetailForm from "./ScholarshipPageComponentDetailForm"
import { CustomEditor } from "../../../components/CustomEditor"
import { useEffect, useState } from "react"
import { COMMON_SUCCESS_MESSAGE, SCHOLARSHIP_API_LIST } from "../../../constants/CollegeDekho"
import { patch, post } from "../../../services"


const ScholarshipPageComponentDetail = (props) => {

    const { scholarshipID, componentID ,ID } = useParams()
    const history = useHistory()
    const locationData = props.location?.locationData
    const dashboardTypeID = scholarshipID ? scholarshipID : ID
    const scholarshipUrl = SCHOLARSHIP_API_LIST["SCHOLARSHIP_DETAIL_PAGE_URL"].replace("#id#", dashboardTypeID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(scholarshipUrl)

    let url = null
    const isAddMode = !isInt(componentID)
    let saveUrl = SCHOLARSHIP_API_LIST["SCHOLARSHIP_PAGE_COMPONENT_LISTING"].replace("#id#", scholarshipID)
    if (!isAddMode) {
        url = SCHOLARSHIP_API_LIST["SCHOLARSHIP_PAGE_COMPONENT_DETAIL"].replace("#id#", scholarshipID).replace("#id1#", componentID)
        saveUrl = url
        
    }

    const [data, loading, error] = useFetchData(url)
    const [content, setContent] = useState("")
    const [form] = Form.useForm()

    const setSholarshipComponentObj = (data) => {
        const scholarshipComponentObj = {
            h2: data.h2,
            object_status: data?.object_status?.id,
            component: data?.component.id
        }
        form.setFieldsValue(scholarshipComponentObj)

    }

    const getCleanData = (data) => {
        const formData = new FormData()
        formData.append("component", data.component)
        formData.append("h2", data.h2)
        formData.append("scholarship", scholarshipID)
        formData.append("content", content)
        formData.append("object_status", data?.object_status)
        
        return formData

    }


    useEffect(() => {
        if (data) {

            setSholarshipComponentObj(data)
            setContent(data?.content || "")
        }
    }, [data])

    const showError = (errors) => {

        const error = getErrors(errors)
        if (error.allError.length > 0) {
            message.error(error.allError.join());
        }

        form.setFields(error.serverError)

    }

    const crudErrorHandler = (error) => {
        showError(error)


    }


    const postData = (data) => {
        // setLoader(true);
        post(saveUrl, data)
            .then(function (response) {

                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/scholarship/${scholarshipID}/component/`)

            })
            .catch(crudErrorHandler)
            .finally(() => {

            });

    }

    const patchData = (data) => {
        
        patch(saveUrl, data).then(function (response) {
            message.success(COMMON_SUCCESS_MESSAGE);
            history.push(`/scholarship/${scholarshipID}/component/`)

        })
            .catch(crudErrorHandler)
            .finally(() => {

            });

    }






    const submitHandler = () => {
        const data = form.getFieldsValue()

        
        const formData = getCleanData(data)

        if (isAddMode) {
            postData(formData)
        }
        else {
            patchData(formData)
        }

    }
    return (
        <>
            {error ? message.error(error) :

                <Row>
                    <Col span={24}>
                        <NavBreadCrumb breadCrumb={breadCrumb}  />
                        <div className="gx-pt-4">
                            <div className="ant-card ant-card-bordered gx-card-widget">
                                <div className="ant-card-body">
                                    <h2 className="gx-mb-0">
                                        {
                                            isAddMode && locationData?.scholarship?.name ?
                                                `${locationData?.scholarship?.name}'s Component`
                                                :
                                                componentID ? 
                                                    `${data?.scholarship?.name}'s Component`
                                                    : 'New Component'
                                        }
                                    </h2>

                                </div>
                            </div>
                        </div>
                    </Col>


                    <Col span={24}>
                        <div className="ant-card ant-card-bordered gx-card">
                            <div className="ant-card-body">
                                <Form onFinish={submitHandler} form={form}>
                                    <div className="gx-p-2">
                                        <ScholarshipPageComponentDetailForm />
                                    </div>
                                    <div className="gx-p-2">
                                        <CustomEditor content={content} setContent={setContent} />
                                    </div>
                                    <div className="d-flex" style={{ justifyContent: 'left' }}>
                                        <Button type="primary" htmlType="submit"
                                        //    disabled={disabledSave} 
                                        > Save</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>



                </Row>
            }
        </>
    )
}

export default ScholarshipPageComponentDetail