import { useEffect, useState } from "react"
import { useFetchData } from "../../../hooks"
import { SCHOLARSHIP_API_LIST } from "../../../constants/CollegeDekho"
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { Button, Col, Row, message } from "antd"
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb"
import DragnDrop from "./DragNDrop"

const ScholarshiPageComponentListing = () => {
    const { scholarshipID ,ID} = useParams()
    const dashboardTypeID = scholarshipID ? scholarshipID : ID
    const scholarshipUrl = SCHOLARSHIP_API_LIST["SCHOLARSHIP_DETAIL_PAGE_URL"].replace("#id#", dashboardTypeID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(scholarshipUrl)

    const url = SCHOLARSHIP_API_LIST["SCHOLARSHIP_PAGE_COMPONENT_LISTING"].replace("#id#", scholarshipID)
    const [data, loading, error] = useFetchData(url)
    const [dataSource, setDataSource] = useState()
    const [extraDetails, setExtraDetails] = useState()

    useEffect(()=>{
        
        if(data){
            setDataSource(data.results)
            setExtraDetails(data?.extra_details)
        }
    }, [data])



    return (
        <>
        <Row>
            {error ? message.error(error) : null}
            
        <Col span={24}>
            <NavBreadCrumb 
            page={extraDetails?.scholarship?.name}
            breadCrumb={breadCrumb}
            />
            <div className="gx-pt-4">
                       <div className="ant-card ant-card-bordered gx-card-widget">
                             <div className="ant-card-body">
                             <h2 className="gx-mb-0">
                     {extraDetails?.scholarship?.name}
                   </h2>
                           </div>
                       </div>
                   </div>
           <Row>
               <Col span={24} style={{ textAlign: 'right' }}>
               <Link to={{pathname: `/scholarship/${scholarshipID}/component/create/`, locationData: extraDetails }} 
               
               >
   
                     <Button>
                        <span>Add New Component</span>
                     </Button>
                 </Link>
   
   
               </Col>
           </Row>
        </Col>
   
        <Col span={24}>
           <DragnDrop dataSource={dataSource} setDataSource={setDataSource} />
        </Col>
       </Row>
        </>

    )
}
export default ScholarshiPageComponentListing