import React from "react";
import { Route, Switch } from "react-router-dom";

import Institute from "./institute/index";
import CourseDetail from "./courseDetail/index";
import Logs from "./logs/index"
import NotFound from "./notFound/index";
import Scholarship from "./scholarship/index"



const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}institute`} component={Institute} />
      <Route path={`${match.url}course`} component={CourseDetail} />
      <Route path={`${match.url}logs`} component={Logs} />
      <Route path={`${match.url}scholarship`} component={Scholarship} />
      <Route path="*" component={NotFound} />
    </Switch>
  </div>
);

export default App;
