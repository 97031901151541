import { get } from "../index";

export async function getInstituteLatestUpdate(id)
{
    const url = `/api/cms/institute/${id}/latestupdate/`;

    // const params = {
    //   page_size: data.pageSize,
    //   page:data.page,
    //   text:data.text,
    // }
    return await get(url)
}

export async function getLatestUpdate(id, contentTypeId)
{
    const url = `/api/cms/common/latest-update/${contentTypeId}/${id}`;

    // const params = {
    //   page_size: data.pageSize,
    //   page:data.page,
    //   text:data.text,
    // }
    return await get(url)
}