import { getAutoSuggestionList, getExamSuggestionList, getNewsSuggestionList } from "../index";
import { COURSE_STUDY_MODE } from "../../constants/CollegeDekho";

export async function getAuthorList(text) {
    const url = `/api/cms/autosuggestion/author/?text=${text}`;
    return await getAutoSuggestionList(url)
}

export async function getNewsList(text) {
    const url = `/api/cms/autosuggestion/news/?q=${text}`;
    return await getNewsSuggestionList(url)
}

export async function getExamList(text) {
    const url = `/api/cms/autosuggestion/exam/?q=${text}`;
    return await getExamSuggestionList(url)
}

export async function getCareerList(text) {
    const url = `/api/cms/autosuggestion/career/?text=${text}`;
    return await getAutoSuggestionList(url)
}

export async function getStreamList(text) {
    const url = `/api/cms/course/stream/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
}

export async function getCourseList(text) {
    const url = `/api/cms/course/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
}
export async function getStudyeMode(text) {
    return COURSE_STUDY_MODE.map((item) => ({ label: item[1] + "-" + (item[0]), value: item[0] }))
}

export async function getSpecializationList(text) {
    const url = `/api/cms/course/specialization/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
}

export async function getDegreeList(text) {
    const url = `/api/cms/course/degree/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
}

export async function getAllCoursesList(text) {
    const url = `/api/cms/course/course-content/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
}

