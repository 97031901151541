import { Col, Form, Input, Row } from "antd"
import TextArea from "antd/lib/input/TextArea"

const ScholarshipSubCategorySeoDetails = () => {

    return (
        <Row>
            <Col span={12}>
            <Form.Item
                        label="H1"
                        name="h1"
                        className="labelInput"
                        rules={[
                            
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="h1" maxLength={255}/>
                    </Form.Item>
                    
            </Col>
            <Col span={12}>
            <Form.Item
                        label="Page Title"
                        name="title"
                        className="labelInput"
                        rules={[
                            {
                                required:true,
                                message: "title required"
                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="page_title" maxLength={255}/>
                    </Form.Item>
                    
            </Col>
            <Col span={12}>
            <Form.Item
                        label="Meta Keywords"
                        name="keyword"
                        className="labelInput"
                        >
                        <TextArea className="gx-mb-3" name="keyword"/>
                    </Form.Item>
                    
            </Col>

            <Col span={12}>
            <Form.Item
                        label="Meta Description"
                        name="description"
                        className="labelInput"
                        rules={
                            [{
                                required:true,
                                message: "title required"
                            },
                        ]
                        }
                        >
                        <TextArea className="gx-mb-3" name="description" />
                    </Form.Item>
                    
            </Col>

        </Row>
    )

}

export default ScholarshipSubCategorySeoDetails