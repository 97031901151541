import React, { useEffect, useState } from 'react';
import StreamDescription from '../../../components/StreamDescription';
import { useFetchData } from "../../../hooks/";
import { deleteDescription, getDescription, saveDescription } from '../../../services/';
import { Button, message } from "antd";



const DescriptionPopUp = (instituteID) => {
    console.log("INNNS", instituteID.instituteID)
    
    const url = `/api/cms/institute/${instituteID.instituteID}/stream-description/`
    const [description, setDescription] = useState([])
    const [data, loading, error] = useFetchData(url)
    const stream_list_api_url = `/api/cms/institute/${instituteID.instituteID}/stream/`
    const [streams, streamsLoading, streamError] = useFetchData(stream_list_api_url)
    const [options, setOptions] = useState(streams)

    useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (data) {
            
			setDescription(data.results)
			}
            if (streams){
                setOptions(streams.results.map((item)=> {return {"label":item.name, "value": item.id}}));
            }
		}


		return () => mounted = false;
	}, [data, streams])

    
    const handleRemove = (e, id, index) =>{
        console.log("Remove clicked on id", id);
        if(id!=undefined){
        let url = `/api/cms/institute/${instituteID.instituteID}/stream-description/${id}/`
        deleteDescription(url).then(function (response) {
			message.success("Deleted Description from db");
            

		});
        }
        const array = [...description];
        setDescription(array.filter((item, i)=> i!=index))
        

    }
    
    const handleAddMore = () => {
        const newDesc = [...description, {"stream": "", "description": ""}];
        setDescription(newDesc);
    }
    const handleSave = () =>
    {
        let description_data = JSON.stringify(description);
        console.log(description_data);
        console.log("SAVE THIS DATA INTO DB", description)
        const saveURL = `/api/cms/institute/${instituteID.instituteID}/stream-description/`
        saveDescription(saveURL, description_data).then(function (response) {
            message.success("Data Saved");
            getDescription(saveURL).then(
                function(response){
                    if (response.data.results){
                        setDescription(response.data.results);
                    }
                }
            )
            

        }).catch(function(error){
            if(error.response.data){
            console.log(error.response.data.errors)
            message.error(error.response.data.errors);
            }
        });
    }

    console.log("DESCRIPTION", description);
    return ( 
        <>
        {description?.map((item, index)=>{
            return <StreamDescription item={item} index={index} key={index} handleRemove={handleRemove} options={options} description={description}/>
        })}
        <Button type='primary' onClick={handleAddMore}>Add More</Button>
        {description.length>0?
        <Button type='primary' htmlType='submit' onClick={handleSave}>Save</Button>
        : null}
        </>
     );
}

export default DescriptionPopUp