import {useState, useEffect} from 'react';
import { get } from '../services/';
import { getErrors } from '../util/helpers';
export function useFetchData(url,params)
{

  const [ data, setData] = useState(null);
  const [ loading, setLoading] = useState(false);
  const [ error, setError] = useState(null);
  

  useEffect(() =>
  {
    console.log("called.....",url)  
    const getData = async () => {
      setLoading(true);
      await get(url,params)
            .then(function (data) 
            {
               setData(data.data);
               setLoading(false);
               setError(null);
            })
            .catch(function (response) 
            {
                    
                    const err =  getErrors(response)
                    setError(err.allError);
                    

            }); 
    }
    
      if(url)
      {
         getData(); 
      }
  },[url]);

  return [data, loading, error]
}