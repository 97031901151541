import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../constants/Settings'
import { API_URL_LIST, PAGE_SIZE } from '../../../constants/CollegeDekho'
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb'
import { Checkbox, Col, Pagination, Row, Table, message } from 'antd'
import { columns } from './columns'
import { get } from '../../../services'
import { getUser } from '../../../util/helpers'

export default function FaqLogs() {

    const [faqLogs, setFaqLogs] = useState([])
    const [loading, setLoading] = useState([])
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(faqLogs?.count);
    const [searchParams, setSearchParams] = useState({
        user_id: null
    });

    useEffect(() => {
        const getFaqLogs = async () => {
            setLoading(true)
            const url = API_URL_LIST.FAQ_LOGS
            const params = {
                page,
                page_size: pageSize,
                user_id: searchParams?.user_id
            }
            const response = await get(url, params)
                .catch(err => {
                    message.error("Something went wrong")
                    setLoading(false)
                })
            if(response?.status === 200) {
                setLoading(false)
                setFaqLogs(response?.data?.results)
                setTotal(response?.data?.count)
            }
        }
        getFaqLogs()
    }, [page, pageSize, searchParams])


    const onShowSizeChange = (current, PageSize) => setPageSize(PageSize)
    const onPageChange = (current, PageSize) => setPage(current)


    return (
        <>
            <NavBreadCrumb />
            <br />
            <div className="ant-card ant-card-bordered gx-card-widget gx-mt-4">
                <div className="ant-card-body">
                    <h2 className="gx-mb-0">FAQ Logs </h2>
                </div>
            </div>
            <Row>
                <Col className='gx-ml-4 gx-mb-4'  >
                    <Checkbox onChange={(e) => setSearchParams(prev => ({ ...prev, user_id: e.target.checked && getUser()?.user?.id || null }))}>Only Me</Checkbox>
                </Col>
                <Col span={24}>
                    <Table
                        className="gx-table-responsive"
                        loading={loading}
                        rowKey="id"
                        columns={columns({ setSearchParams })}
                        dataSource={faqLogs}
                        pagination={false}
                    />
                </Col>
                <Col span={24} className="gx-mt-3 gx-text-right">
                    <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onPageChange}
                        pageSize={pageSize}
                        total={total}
                        current={page}
                    />
                </Col>
            </Row>
        </>
    )
}
