import React from 'react';
import { Row, Col, Form, Input, Upload, message, Button } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';


const ScholarshipLandingPageForm = () => {
    


    const normFile = (e) =>
	{
     console.log('Upload event:', e);
      if (Array.isArray(e)) {
             return e;
      }
     return e && e.fileList;
  };

  const beforeUpload =(file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng)
        {
         message.error("You can only upload JPG/PNG file!");
    }
    return false
}

    return (
        <>
            <Row>

                <Col span={12}>

                    <Form.Item
                        label="Slug"
                        name="slug"
                        className="labelInput"
                    >
                        <Input className="gx-mb-3" name="slug" readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label="Snippet"
                        name="snippet"
                        className="labelInput"
                        rules={[

                            { type: 'string', max: 2084 }

                        ]}>
                        <Input className="gx-mb-3" name="snippet" maxLength={2084}/>
                    </Form.Item>

                </Col>

                <Col span={24}>
                    <Form.Item
                        name="image"
                        label="Image"
                        getValueFromEvent={normFile}
                        valuePropName="fileList"
                        rules={[
                            {
                                required: true,
                                message: 'Image is required',
                            },
                        ]}>
                        <Upload name="image" maxCount={1} beforeUpload={beforeUpload} listType="picture">
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Col>



                <Col span={12}>
                    <Form.Item
                        label="H1"
                        name="h1"
                        className="labelInput"
                        rules={[

                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="h1" maxLength={255} />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Page Title"
                        name="title"
                        className="labelInput"
                        rules={[

                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="page_title" maxLength={255} />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Meta Keywords"
                        name="keyword"
                        className="labelInput"
                    >
                        <TextArea className="gx-mb-3" name="keyword" />
                    </Form.Item>

                </Col>

                <Col span={12}>
                    <Form.Item
                        label="Meta Description"
                        name="description"
                        className="labelInput"
                    >
                        <TextArea className="gx-mb-3" name="description" />
                    </Form.Item>

                </Col>



            </Row>



        </>
    )
}
export default ScholarshipLandingPageForm;