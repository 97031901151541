import React from "react";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { get } from '../../services';
import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
import { getTemplateContentTypeID, isInt, sentenceCase } from "../../util/helpers"
import { SIDE_BAR_API_LIST } from "../../constants/CollegeDekho";
import { useEffect } from "react";
import { useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import SubMenu from "antd/lib/menu/SubMenu";
const MenuItemGroup = Menu.ItemGroup;

const getTemplateContentTypeIDfromName = (name) => {
  const contentTypeIDs =  {
    institute :22 ,
    course :533,
    scholarship :1019
  }
  return contentTypeIDs[name]
}


const menuData = [
  {
    key: "Institute",
    to: "/institute",
    icon: "icon-table",
    label: "Institute"
  },
  {
    key: "Course",
    to: "/course",
    icon: "icon-table",
    label: "Course"
  },
  {
    key: "Logs",
    to: "/logs",
    icon: "icon-table",
    label: "Logs"
  },
  {
    key: "Scholarship",
    to: "/scholarship",
    icon: "icon-table",
    label: "Scholarship"
  }
];

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const {  themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const [templateTypes, setTemplateTypes] = useState([])

  const getDashboardType = () => {
    if(pathname.includes("institute")) return "institute"
    if(pathname.includes("course")) return "course"
    if(pathname.includes("scholarship")) return "scholarship"
    if(pathname.includes("logs")) return "logs"
    return "course"
  }
  const templateContentTypeID = getTemplateContentTypeID(getDashboardType());

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = pathname.split('/')[1];
  const dashboardName = pathname.split("/")
  let templateObjectID = null;
  let vernacularObjectID = null;

  if (templateContentTypeID == 513){
     templateObjectID = isInt(dashboardName[2]) ? dashboardName[2] : null ;
     vernacularObjectID = isInt(dashboardName[4]) ? dashboardName[4] : null ;
  }
  else{
     templateObjectID = isInt(dashboardName[2]) ? dashboardName[2] : null ;
     vernacularObjectID = 1
  }

  useEffect(() => {
    const getSideMenus = async () => {
      const url = SIDE_BAR_API_LIST.MAIN_API;
      const params = {
        template_content_type_id: templateContentTypeID
      }
      const response = await get(url, params).catch(err => console.log(err))
      if(response?.data.length) setTemplateTypes(response?.data)
    }
    getSideMenus()
  }, [templateContentTypeID, templateObjectID])

  const replaceKeysFromUrl = (url) => {
    const keys = ["<template_content_type_id>", "<template_object_id>", "<vernacular_object_id>"]
    if(url.includes(keys[0])) url = url.replaceAll(keys[0], templateContentTypeID)
    if(url.includes(keys[1])) url = url.replaceAll(keys[1], templateObjectID)
    if(url.includes(keys[2])) url = url.replaceAll(keys[2], vernacularObjectID) 
    return url
  }

  const NavigationDropdown = () => {
    const linkStyle = {
      display:"flex",
      alignItems:'center'
    }
    const menu = (
      <Menu>
        {menuData.map(item => (
          <Menu.Item key={item.key}>
            <Link to={item.to} style={linkStyle}>
              <div>
                <i className={"icon icon-table gx-mr-2"} />
              </div>
              <div className="gx-mb-1">
                {item.label}
              </div>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    );
    const dropdownStyle = {
      fontSize: '16px',
      marginLeft: '40px',
      background:"#fff",
      padding:'12px 20px',
      borderRadius:'4px',
      display:"flex",
      width:"70%",
      justifyContent:"space-between",
      alignItems:'center'
    };
  
    return (
      <div style={{ marginTop: "15px", height: "30px", marginBottom:"15px" }}>
        <Dropdown overlay={menu} placement="bottomRight" arrow>
          <a style={dropdownStyle} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {sentenceCase(getDashboardType())} <DownOutlined />
          </a>
        </Dropdown>
      </div>
    );
  };

  return <>
    <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
    <div className="gx-sidebar-content">
      <CustomScrollbars className="gx-layout-sider-scrollbar gx-mt-3">
        <Menu
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={[selectedKeys]}
          theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
          mode="inline">
          
          <NavigationDropdown />
          
          {templateTypes?.map(template =>
            <>
              <Menu.Item key={template.url.slice(1)}>
                <Link to={template.url}><i className={template?.icon} />
                  <span>{template.title}</span>
                </Link>
              </Menu.Item>
            </>
          )}

          {templateTypes?.map(template =>
            pathname.includes(template?.url) && template["sub-menu"] && templateObjectID && vernacularObjectID &&
            <MenuItemGroup key="main" className="gx-menu-group" title={template.title}>
              {template["sub-menu"]?.map(subMenu => <>
                <Menu.Item key={SubMenu.title}>
                  <Link to={replaceKeysFromUrl(subMenu?.url)}><i className={subMenu?.icon || "icon icon-editor"} />
                    <span>{subMenu.title}</span>
                  </Link>
                </Menu.Item>
              </>)
              }
            </MenuItemGroup>
          )}
        </Menu>
      </CustomScrollbars>
    </div>
  </>
};

export default React.memo(SidebarContent);

