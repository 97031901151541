import React from "react";
import { Breadcrumb } from 'antd';
import { useLocation, Link, useParams } from 'react-router-dom';
import { sentenceCase, isInt } from "../../util/helpers.js"

const getPathNames = (pathname) => pathname.split('/').filter((item) => {
   if(item !== 'update' && item !== "") { return true }
   return false
});

const NavBreadCrumb = ({ page, page2,breadCrumb:dashboardType ,courseVernacularID}) => {
   const location = useLocation();   
   let { pathname } = location;
   const { instituteID, instituteMasterPageID, instituteCourseID, placementID, popularDegreeID, componentInstituteMasterPageID, faqId } = useParams();
   const { courseID, courseMasterPageID, ID, templateId, objectId, scholarshipID } = useParams();
   let faqRouteTo = null
   if(faqId || pathname.includes('faqs')) {
      faqRouteTo = getPathNames(pathname)
      faqRouteTo.splice(-1)
      pathname = "/" + faqRouteTo.join("/")
   }
   const pathnames = getPathNames(pathname)

   var dashboardTypeID = null
   var dashboardTypeMasterPageID = null

   if(instituteID) {
      dashboardTypeID = instituteID
      dashboardTypeMasterPageID = instituteMasterPageID

   }else if(courseID || ID) {
      dashboardTypeID = courseID ? courseID : ID
      dashboardTypeMasterPageID = courseMasterPageID

   }
   else if(scholarshipID || ID) {
      dashboardTypeID = scholarshipID ? scholarshipID : ID

   }
   

   return (
      <>
         <Breadcrumb separator=">">
            {pathnames.length > 0 ? (
               <Breadcrumb.Item>
                  <Link to="">Home</Link>
               </Breadcrumb.Item>
            ) : (
               <Breadcrumb.Item>Home</Breadcrumb.Item>
            )}
            {pathnames.map((name, index) => {
               const routeTo = name === 'faqs' && faqRouteTo ? `/${faqRouteTo.join("/")}`: !dashboardTypeID || index % 2 === 0 ? `/${pathnames.slice(0, index + 1).join('/')}/` : `/${pathnames.slice(0, index + 1).join('/')}/update/`;
               const isDashboardType = dashboardTypeID && name === dashboardTypeID
               const isLast = (index === pathnames.length - 1) || (pathnames.includes('logs') && index === 1);
               var lastID = false
               if(isLast) {
                  if(isInt(name)) { lastID = true }
               }
               const isNew = name === 'create';
               const isPage = (dashboardTypeMasterPageID && name === dashboardTypeMasterPageID)
                  || (instituteCourseID && name === instituteCourseID)
                  || (popularDegreeID && name === popularDegreeID)
                  || (placementID && name === placementID)
                  || (templateId && name === templateId)
                  || (scholarshipID && name === scholarshipID)
               const isComponent = (componentInstituteMasterPageID && name === componentInstituteMasterPageID) || (objectId && name === objectId)
               return isLast ?
                  (<Breadcrumb.Item key={name}>
                     {
                        isDashboardType ? `${dashboardType && sentenceCase(dashboardType.name)}` :
                           isPage ? `${page}` : isComponent ? `${page2}` :
                              isNew ? `Add ${sentenceCase(pathnames[index - 1])}` : lastID ? `Edit ${sentenceCase(pathnames[index - 1])}` : `${sentenceCase(name)}`}
                  </Breadcrumb.Item>)
                  : (<Breadcrumb.Item key={name}>
                     <Link to={`${routeTo}`}>
                        {isDashboardType
                           ? `${dashboardType && sentenceCase(dashboardType.name)}`
                           : isPage
                              ? `${page}`
                              : isComponent
                                 ? `${page2}`
                                 : courseVernacularID?.id == name
                                 ? `${courseVernacularID?.name +"(" +courseVernacularID?.language?.name + ")" }`
                                 : `${sentenceCase(name)}`}                     </Link>
                  </Breadcrumb.Item>)
            })}
         </Breadcrumb>
      </>
   )
}

export default NavBreadCrumb;
