import React,{useState,useEffect} from "react";
import {  useParams } from 'react-router-dom';
import {Row, Col,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import DragnDrop from './DragnDrop';
import { API_URL_LIST } from '../../../constants/CollegeDekho'
import { useFetchData } from "../../../hooks/";

const InstituteDegreeListing = (props) =>
{
  const { instituteID } = useParams();
  let url = API_URL_LIST["INSTITUTE_POPULAR_DEGREE_LISTING"].replace("#id#",instituteID)

  const [dataSource, setDataSource] = useState([]);
  const [extraDetails, setExtraDetails] = useState(null);
  const [data,loading,error] = useFetchData(url,{"page_size":2000})
  const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
  const [breadCrumb] = useFetchData(dashboardTypeDetailURL)

  useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {

        setDataSource(data.results)
        setExtraDetails(data.extra_details)

		  }
		}


		return () => mounted = false;
	}, [data])





  return (
    <>
    {error ? message.error(error) :

    <Row>
     <Col span={24}>
        <NavBreadCrumb breadCrumb={breadCrumb}/>
        <div className="gx-pt-4">
         <div className="ant-card ant-card-bordered gx-card-widget">
               <div className="ant-card-body">
               <h2 className="gx-mb-0">{extraDetails?.institute?.name}, Degree</h2>
           </div>
         </div>
       </div>
       <Col span={24}>
        <DragnDrop dataSource={dataSource} setDataSource={setDataSource} />
     </Col>
     </Col>
    </Row>
  }
  </>

    );
   }
  export default InstituteDegreeListing;
