import React from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { COMMON_API_LIST } from "../../../constants/CollegeDekho";
import { useFetchData } from "../../../hooks/";
import { Row, Col, Table, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { CONTENT_TYPE_ID } from "../../../constants/Settings";


const SlugRedirectionListing = () => {

  const pathname = useSelector(({ common }) => common.pathname);
  const dashboardType = pathname.includes("institute") ? "institute" : "course";
  const contentTypeId = CONTENT_TYPE_ID[dashboardType]

  const columns = [{
    title: 'ID',
    dataIndex: 'id',

  },
  {
    title: 'Old Template',
    key: 'old_template',
    render: (text, record) => (
      <span>
        {record.old_template?.name}
      </span>
    ),
  },
  {
    title: 'Old Slug',
    dataIndex: 'old_slug',
  },
  {
    title: 'New Template',
    key: 'new_template',
    render: (text, record) => (
      <span>
        {record.new_template?.name}
      </span>
    ),
  },
  {
    title: 'New Slug',
    dataIndex: 'new_slug',
  },
  ];


  const [data, loading, error] = useFetchData(COMMON_API_LIST['SLUG_REDIRECTION_POST_URL'].replace("#id#", contentTypeId))


  return (
    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered gx-card-widget">
            <div className="ant-card-body">
              <h2 className="gx-mb-0">Slug Redirection</h2>
            </div>
          </div>
        </div>

      </Col>

      <Col span={24}>
        <Row>
          <Col span={18}>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Link to={{ pathname: `/${dashboardType}/slug-redirection/create/` }}>
              <Button type="primary">
                <span>Add Slug Redirection</span>
              </Button>
            </Link>
          </Col>
        </Row>

      </Col>

      <Col span={24} className="gx-mb-3">
        <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={data} pagination={false} />
      </Col>

    </Row>
  );
};

export default SlugRedirectionListing;
