import React, { useState, useEffect } from 'react';
import { useParams, useHistory, } from 'react-router-dom';
import { Row, Col, Form, Button, message, Select } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { API_URL_LIST, COMMON_SUCCESS_MESSAGE, COURSE_CONTENT_API_LIST, SCHOLARSHIP_API_LIST } from '../../../constants/CollegeDekho'
import { getErrors, getTemplateContentTypeName } from '../../../util/helpers';
import { get, getAutoSuggestionList, patch, post } from '../../../services';
import { CustomEditor } from '../../../components/CustomEditor';
import FaqsDetailForm from './FaqsDetailForm';
import { useFetchData } from '../../../hooks';
import { getFaqList } from '../../../services/FaqList';

const isLoadingInitialState = {
	save1: false,
	save2: false,
	save3: false,
}

const FaqsDetail = () => {
	const [form] = Form.useForm()
	const [formFilters] = Form.useForm()
	const { templateContentTypeID, instituteID, courseID, courseVernacularID, scholarshipID,
		scholarshipLandingPageID, scholarshipCategoryID, scholarshipSubCategoryID,
		faqObjectID,ID } = useParams();
	const templateObjectID = instituteID || courseVernacularID || scholarshipID
		|| scholarshipLandingPageID || scholarshipCategoryID || scholarshipSubCategoryID
		;
	const searchParams = new URLSearchParams(document.location.search)
	const history = useHistory()
	const [disabledSave, setDisabledSave] = useState(false)
	const [content, setContent] = useState("");
	const [isLoading, setIsLoading] = useState(isLoadingInitialState)
	const isAddMode = !Boolean(faqObjectID)

	const [pageTypeList, setPageTypeList] = useState([])
	const [pageObjectList, setPageObjectList] = useState([])
	const [pageContentTypeID, setPageContentTypeID] = useState(searchParams.get('page_content_type_id'))
	const [pageObjectID, setPageObjectID] = useState(null)
	const [isLoadingSuggestions, setIsLoadingSuggestions] = useState({
		pageType: false,
		pageObject: false
	})
	const [loading, setLoading] = useState(true);
	const [extraDetails, setExtraDetails] = useState(null)

	let url=extraDetails?.breadcrumb[1]?.url
	let newData={
		id:url?.includes(courseVernacularID)&&courseVernacularID,
		name:extraDetails?.breadcrumb[1]?.title,
		language:{name:extraDetails?.breadcrumb[1]?.language?.name}
	}	
	var detailUrl=null
	if(instituteID){
 		detailUrl = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)

	}else if(courseID || ID){
		detailUrl = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", courseID ?courseID: ID)

	}else if(scholarshipID || ID){
		detailUrl = SCHOLARSHIP_API_LIST["SCHOLARSHIP_DETAIL_PAGE_URL"].replace("#id#", scholarshipID?scholarshipID: ID)

	}

		const [instituteData] = useFetchData(detailUrl)
	

	useEffect(() => {
		const getPageTypeList = async () => {
			setIsLoadingSuggestions(prev => ({ ...prev, pageType: true }))
			const url = API_URL_LIST.FAQ_CONTENT_TYPES.replace('#templateContentTypeID#', templateContentTypeID)
			const response = await getAutoSuggestionList(url).finally(() => setIsLoadingSuggestions(prev => ({ ...prev, pageType: false })))
			if (response) {
				setIsLoadingSuggestions(prev => ({ ...prev, pageType: false }))
				setPageTypeList(response)
			}
		}
		getPageTypeList()
	}, [templateContentTypeID]);

	useEffect(() => {
		const getPageTypeList = async () => {
			setIsLoadingSuggestions(prev => ({ ...prev, pageObject: true }))
			const url = API_URL_LIST.FAQ_OBJECT_LIST.replace('#pageContentTypeID#', pageContentTypeID).replace('#templateObjectID#', templateObjectID)
			const response = await getAutoSuggestionList(url)
			if (response) {
				setIsLoadingSuggestions(prev => ({ ...prev, pageObject: false }))
				setPageObjectList(response)
				setPageObjectID(searchParams.get('page_object_id'))
				formFilters.setFieldsValue({ page: +searchParams.get('page_object_id') })
			}
		}
		pageContentTypeID && getPageTypeList()
	}, [pageContentTypeID, templateObjectID]);


	useEffect(() => {
		const getFaqData = async () => {
			const url = API_URL_LIST
				.FAQ_GET_PATCH
				.replace('#pageContentTypeID#', pageContentTypeID)
				.replace('#pageObjectID#', pageObjectID || searchParams.get('page_object_id'))
				.replace('#faqObjectID#', faqObjectID)
			const response = await get(url).then(res => res).catch(crudErrorHandler)
			if (response?.status === 200) {
				response?.data?.answer && setContent(response?.data?.answer)
				form.setFieldsValue({
					object_id: response?.data?.content_type === 990 ? response?.data?.object_id : undefined,
					object_status: response?.data?.object_status,
					question: response?.data?.question,
				})
				setPageObjectID(searchParams.get('page_object_id'))
				formFilters.setFieldsValue({ page: +searchParams.get('page_object_id') })
			}
		}
		!isAddMode && getFaqData()
	}, [isAddMode, pageContentTypeID])


	useEffect(() => {
		let mounted = true;
		setLoading(true);
	
		  getFaqList({ templateContentTypeID, templateObjectID, pageContentTypeID, pageObjectID ,})
		  .then(data => {
			if(mounted) {
			  console.log({data})
			  setLoading(false);
			  setExtraDetails(data.data.extra_details)
			}
		  })
		  .catch(err=> {
			console.log({err})
			message.error("Some error occured")
		  });
	
		
	  }, [ pageObjectID,])

	const getCleanData = (data) => {
		const cleanData = { ...data }
		console.log({ data })
		if (content) cleanData.answer = content;
		if (data?.object_status?.value) cleanData.object_status = data.object_status.value;
		if (!isAddMode) {
			//edit
			cleanData.content_type = pageContentTypeID;
			cleanData.object_id = pageObjectID;

		} else {
			if (cleanData.master_page) delete cleanData.master_page
			if (cleanData.object_id) delete cleanData.object_id
		}
		return cleanData
	}
	const crudErrorHandler = (error) => showError(error)
	const patchData = (data, saveType) => {
		setIsLoading(loaders => ({ ...loaders, [`save${saveType}`]: true }))
		const saveURL = API_URL_LIST
			.FAQ_GET_PATCH
			.replace('#pageContentTypeID#', pageContentTypeID)
			.replace('#pageObjectID#', pageObjectID)
			.replace('#faqObjectID#', faqObjectID)
		patch(saveURL, data).then(function (response) {
			if (saveType === 1) {
				if (courseID) {
					history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${courseID}/vernacular/${templateObjectID}/faqs/${templateContentTypeID}/`)
				}
				else {
					history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${templateObjectID}/faqs/${templateContentTypeID}/`)
				}
			} else if (saveType === 2) {
				form.resetFields()
				setContent('')
				if (courseID) {
					history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${courseID}/vernacular/${templateObjectID}/faqs/${templateContentTypeID}/create`)
				}
				else {
					history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${templateObjectID}/faqs/${templateContentTypeID}/create`)
				}
			}
			message.success(COMMON_SUCCESS_MESSAGE);
		})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
				setIsLoading(isLoadingInitialState)
			});
	}

	const postData = (data, saveType) => {
		setIsLoading(loaders => ({ ...loaders, [`save${saveType}`]: true }))
		const saveURL = API_URL_LIST
			.FAQ_POST
			.replace('#pageContentTypeID#', pageContentTypeID)
			.replace('#pageObjectID#', pageObjectID)

		post(saveURL, data)
			.then(function (response) {
				if (saveType === 1) {
					if (courseID) {
						history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${courseID}/vernacular/${templateObjectID}/faqs/${templateContentTypeID}/`)
					}
					else {
						history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${templateObjectID}/faqs/${templateContentTypeID}/`)
					}
				} else if (saveType === 2) {
					form.resetFields()
					setContent('')
					window.location.reload();
				} else {
					if (courseID) {
						history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${courseID}/vernacular/${templateObjectID}/faqs/${templateContentTypeID}/${response?.data?.id}/update?page_content_type_id=${pageContentTypeID}&page_object_id=${pageObjectID}`)
					}
					else {
						history.push(`/${getTemplateContentTypeName(templateContentTypeID)}/${templateObjectID}/faqs/${templateContentTypeID}/${response?.data?.id}/update?page_content_type_id=${pageContentTypeID}&page_object_id=${pageObjectID}`)
					}
				}
				message.success(COMMON_SUCCESS_MESSAGE);
			})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
				setIsLoading(isLoadingInitialState)
			});
	}

	const showError = (errors) => {
		const error = getErrors(errors)
		if (error.allError.length > 0) {
			message.error(error.allError.join());
		}
		form.setFields(error.serverError)
	}

	const onFinish = (saveType) => {
		const data = form.getFieldsValue(true);
		const filterData = formFilters.getFieldsValue(true);
		if (!filterData?.page) {
			message.error("Page is not selected")
			return
		}
		if (data?.object_status === undefined) return
		const cleanData = getCleanData(data)
		setDisabledSave(true)
		if (isAddMode) postData(cleanData, saveType)
		else patchData(cleanData, saveType)
	}
	return (
		<>
			<Row>
				<Col span={24}>
					<NavBreadCrumb 
					breadCrumb={instituteData}					
					courseVernacularID={newData}
					/>
					<div className="gx-pt-4">
						<div className="ant-card ant-card-bordered gx-card-widget">
							<div className="ant-card-body">
								<h2 className="gx-mb-0"> Faqs </h2>
							</div>
						</div>
					</div>
				</Col>

				<Col span={24}>
					<div className="ant-card ant-card-bordered gx-card">
						<div className="ant-card-body">
							<Form form={formFilters}>
								<Row gutter={[40]}>
									<Col span={6} >
										<Form.Item label="Page Type" name="category" className="labelInput"
											rules={[
												{
													required: true,
													message: 'Select Category',
												},
											]}>
											<Select
												loading={isLoadingSuggestions?.pageType}
												allowClear={false}
												style={{ width: '100%' }}
												placeholder="Select Page"
												options={pageTypeList}
												value={+pageObjectID}
												defaultValue={+pageContentTypeID || null}
												onChange={(opt) => {
													setPageContentTypeID(opt)
													setPageObjectID(null)
												}}
											>
											</Select>
										</Form.Item>
									</Col>

									<Col span={6}>
										<Form.Item label="Page" name="page" className="labelInput"
											rules={[
												{
													required: true,
													message: 'Select page',
												},
											]}>
											<Select
												loading={isLoadingSuggestions?.pageObject}
												allowClear={false}
												style={{ width: '100%' }}
												placeholder="Select Page"
												options={pageObjectList}
												onChange={opt => setPageObjectID(opt)}
											>
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
				</Col>

				<Col span={24}>
					<div className="ant-card ant-card-bordered gx-card">
						<div className="ant-card-body">
							<Form form={form}>
								<div className="gx-p-2">
									<FaqsDetailForm />
								</div>
								<div className="gx-pt-0">
									<CustomEditor content={content} setContent={setContent} />
								</div>
								<div className="d-flex gx-mt-4" style={{ justifyContent: 'left' }}>
									<Row gutter={[20]} >
										<Col>
											<Button
												loading={isLoading?.save1}
												onClick={() => onFinish(1)}
												htmlType="submit"
												disabled={disabledSave}
											>
												Save
											</Button>
										</Col>
										<Col>
											<Button
												loading={isLoading?.save2}
												onClick={() => onFinish(2)}
												htmlType="submit"
												disabled={disabledSave}
											>
												Save and Add New
											</Button>
										</Col>
										<Col>
											<Button
												loading={isLoading?.save3}
												onClick={() => onFinish(3)}
												type="default"
												htmlType="submit"
												disabled={disabledSave}
											>
												Save and Continue Editing
											</Button>
										</Col>
									</Row>
								</div>
							</Form>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};
export default FaqsDetail;
