import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import ComponentDetailForm from './ComponentDetailForm'
import { useFetchData } from "../../../hooks";
import { patch,post } from '../../../services';
import { isInt,getErrors } from '../../../util/helpers';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'

const ComponentDetail = () => {
    const [form] = Form.useForm();
	const { componentID } = useParams();
	const  isAddMode = !isInt(componentID)
	let url = null;
	let saveURL = API_URL_LIST["COMPONENT_LISTING"] 
	if(!isAddMode)
	{
	  url=API_URL_LIST["COMPONENT_DETAIL"].replace("#id#",componentID)
	  saveURL = url
	  console.log(url)
	}  
	const [data,loading,error] = useFetchData(url)
	const history = useHistory()
	const [disabledSave,setDisabledSave] = useState(false)

	const getDefaultAvailability = () =>
	{
		const availability = [{master_page:null,is_mandatory:false},{master_page:null,is_mandatory:false}]
       
		return availability
        
	
	}
	const getComponentAvailability = (obj) =>
	{
      if(!obj || !obj.component_availability)
	  { 
		  return getDefaultAvailability()
	  }
	  return obj.component_availability.map ( (item) => {

		  return item
	  })


	}
	const setComponentObj = (data) => {
        const obj = {...data}
		obj.component = obj.component.id
		obj.object_status = data.object_status.id
		obj.component_availability = getComponentAvailability(obj)
		
		console.log("obj..",obj)
		form.setFieldsValue(obj)
	}

	useEffect(() => {
		let mounted = true;
        		
		if(mounted) 
		{
		  	
		  if(data)
		  {
			  setComponentObj(data)
		  }  
		  else
		  {
			const availability = {}
			availability.component_availability = getDefaultAvailability()   
			form.setFieldsValue(availability)
		  }
		  
		}
		
		
		return () => mounted = false;
	}, [data])
  

	const getCleanData = (data) => 
	{
		const temp = JSON.stringify(data)
        const cleanData = JSON.parse(temp);

		if(typeof cleanData.component_availability != 'undefined' )
		{
		  cleanData.component_availability = cleanData.component_availability.map ( (item) => {
			  if(typeof item.is_mandatory === 'undefined')
			  {
				item.is_mandatory = false
			  }
			  return item
		  })
		}  
        return cleanData 
	}
	
	const crudErrorHandler = (error) => 
	{
		showError(error)
	}

	const patchData = (data) => 
	{
	  patch(saveURL,data).then(function (response) 
      {
        
		message.success(COMMON_SUCCESS_MESSAGE);
		history.push(`/institute/component`)
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
      });

	}
	const postData = (data) => 
	{
	  post(saveURL,data)
	  .then(function (response) 
      {
		 message.success(COMMON_SUCCESS_MESSAGE);
		 history.push(`/institute/component`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
      });

	}	
  const showError = (errors) => {

	const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
    
    console.log("server error",error.serverError)
    form.setFields(error.serverError)
	


  } 	

  const onFinish = (values) => 
  {
    console.log(values);
	const cleanData = getCleanData(values)
	setDisabledSave(true)
	if(isAddMode)
	{
		
		postData(cleanData)
	}
	else
	{
		patchData(cleanData)
	}

  };


    
  return (
	<>
	{error ? message.error(error) :

     <Row>
			<Col span={24}>
				<NavBreadCrumb/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">Component</h2>
						
						</div>
					</div>
				</div>
			</Col>	


			<Col span={24}>
					<div className="ant-card ant-card-bordered gx-card">
					<div className="ant-card-body">
					<Form onFinish={onFinish} form={form}>
						<div className="gx-p-2">
						<ComponentDetailForm form={form} isAddMode={isAddMode}/>  
						</div>
						<div className="d-flex" style={{ justifyContent: 'left' }}>
						<Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
						</div>
					</Form>
					</div>
					</div>
			</Col>


			
		</Row>
      }
	  </>
  );
};

export default ComponentDetail;
