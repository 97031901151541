import React from 'react';
import {  Row, Col, Form,Input, Select} from "antd";
import {  getObjectStatus } from '../../../util/helpers';

const objectStatusOptions =  getObjectStatus()


const InstituteCoursePageForm=()=>{

	return(
		   <Row>

                <Col span={8}>
					<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'Object Status Required',
			              },
			       ]}>
						<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select object status"
                        options={objectStatusOptions}
					    >
                        </Select>

					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item label="H1" name="h1" className="labelInput">
						<Input className="gx-mb-3" />

					</Form.Item>
				</Col>
                <Col span={8}>
					<Form.Item label="Title" name="title" className="labelInput">
						<Input className="gx-mb-3" />

					</Form.Item>
				</Col>
                <Col span={8}>
					<Form.Item label="Meta Description" name="meta_description" className="labelInput">
						<Input className="gx-mb-3" />

					</Form.Item>
				</Col>
                <Col span={8}>
					<Form.Item label="Meta Keywords" name="meta_keywords" className="labelInput">
						<Input className="gx-mb-3" />

					</Form.Item>
				</Col>




				

			</Row>
		)
}

export default InstituteCoursePageForm
