import { Button, Col, Collapse, Form, Row, message } from "antd"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"

import { useEffect, useState } from "react"
import ScholarshipCategoryBasicDetails from "./ScholarshipCategoryBasicDetails"
import ScholarshipCategorySeoDetails from "./ScholarshipCategorySeoDetails"
import { CustomEditor } from "../../../../components/CustomEditor"
import NavBreadCrumb from "../../../../components/CollegeDekho/NavBreadCrumb"
import { patch, post } from "../../../../services"
import { COMMON_SUCCESS_MESSAGE } from "../../../../constants/CollegeDekho"
import { getErrors, getObject, isInt } from "../../../../util/helpers"
import { useFetchData } from "../../../../hooks"



const ScholarshipCategoryDetail = () => {

    const [content, setContent] = useState("")
    const [landingPageContent, setLandingPageContent] = useState("")
    const { scholarshipCategoryID } = useParams()
    const isAddMode = !isInt(scholarshipCategoryID)
    let url = null
    let saveURL = '/api/cms/scholarship/category/'
    if (!isAddMode) {
        url = `/api/cms/scholarship/category/${scholarshipCategoryID}/`
        saveURL = url
    }
    const history = useHistory()
    const [data, loading, err] = useFetchData(url)
    const Panel = Collapse.Panel

    const handleCollapse = (key) => { }


    const [form] = Form.useForm()
    const setScholarshipCategoryObj = (data) => {
        
        const scholarshipCategoryData = {
            name: data.name,
            slug: data.slug,
            h1: data?.h1 || "",
            title: data?.title,
            description: data?.description,
            keyword: data?.keyword || "",
            object_status: data?.object_status?.id,
            filter_name: data?.filter_name
            

        }

        form.setFieldsValue(scholarshipCategoryData)
        setContent(data?.content || "")
        setLandingPageContent(data?.landing_page_content || "")
        


    }

    useEffect(() => {
        if (data) { setScholarshipCategoryObj(data); }

    }, [data])


    const getCleanData = (data) => {
        
        const formData = new FormData();
        formData.append("name", data.name)
        formData.append("slug", data.slug)
        formData.append("object_status", data.object_status || 0)
        formData.append("filter_name", data?.filter_name)
        data?.title && formData.append("title", data?.title)
        data?.description && formData.append("description", data?.description)
        data?.h1 && formData.append("h1",  data?.h1)
        data?.keyword  && formData.append("keyword", data?.keyword)
        
        formData.append("content", content)
        formData.append("landing_page_content", landingPageContent)
        return formData

    }

    const showError = (errors) => {

        const error = getErrors(errors)
        if (error.allError.length > 0) {
            message.error(error.allError.join());
        }

        form.setFields(error.serverError)

    }

    const crudErrorHandler = (error) => {
        showError(error)


    }
    const postData = (data) => {
        // setLoader(true);
        post(saveURL, data)
            .then(function (response) {

                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/scholarship/category/`)

            })
            .catch(crudErrorHandler)
            .finally(() => {

            });

    }

    const patchData = (data) => {
        
        patch(saveURL, data).then(function (response) {
            message.success(COMMON_SUCCESS_MESSAGE);
            history.push(`/scholarship/category/`)

        })
            .catch(crudErrorHandler)
            .finally(() => {

            });

    }

    const submitHandler = () => {
        
        const data = form.getFieldsValue(true);
        
        const formData = getCleanData(data)
        
        if (isAddMode) {
            postData(formData)
        }
        else {
            patchData(formData)
        }
    }


    return (
        <Row>
            <Col span={24}>
                <NavBreadCrumb />
                <div className="gx-pt-4">
                    <div className="ant-card ant-card-bordered gx-card-widget">
                        <div className="ant-card-body">
                            <h2 className="gx-mb-0">
                               { isAddMode ? 'New Scholarship' : data?.name}
                            </h2>

                        </div>
                    </div>
                </div>
            </Col>
            <Col span={24}>

                <Form form={form} onFinish={submitHandler}>
                    <Collapse defaultActiveKey={['1', '2', '3', '4']} onChange={handleCollapse} className="collegeEditCollapse">
                        <Panel header="Basic Details" key={1}>
                            <div className="gx-p-2">
                                <ScholarshipCategoryBasicDetails isAddMode={isAddMode}  />
                            </div>
                        </Panel>
                        <Panel header={"Seo Details"} key={2}>
                        <div className="gx-p-2">
                                <ScholarshipCategorySeoDetails/>
                            </div>
                        </Panel>
                        <Panel header="Landing Page Content" key={3}>
                            <div className="gx-p-2">
                                <CustomEditor content={landingPageContent} setContent={setLandingPageContent}/>
                            </div>
                        </Panel>
                        <Panel header="Content" key={4}>
                            <div className="gx-p-2">
                            <CustomEditor content={content} setContent={setContent}/>
                            </div>
                        </Panel>


                    </Collapse>
                    <div className="d-flex">
                        <Button type="primary" htmlType="submit" > Save</Button>
                    </div>
                </Form>
            </Col>

        </Row>
    )

}
export default ScholarshipCategoryDetail