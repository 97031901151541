import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import RankingDetailForm from './RankingDetailForm'
import { getObject } from '../../../util/helpers';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import { isInt,getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patch,post } from '../../../services/';
import { Spin } from 'antd';


const InstituteRankingDetail = (props) => {
  const locationData = props.location?.locationData
  const [form] = Form.useForm();
	const { instituteID } = useParams();
	const  [loader, setLoader] = useState(false);
	const { rankingID } = useParams();
	const history = useHistory()
	const isAddMode = !isInt(rankingID)
	console.log("isAddMode",isAddMode)
	let url = null;
	let saveURL = API_URL_LIST["INSTITUTE_RANKING_DETAIL_POST_URL"].replace("#id#",instituteID)

	if(!isAddMode)
	{
	  url=API_URL_LIST["INSTITUTE_RANKING_DETAIL_URL"].replace("#id#",instituteID)
	  url = url.replace("#id1#",rankingID)
	  saveURL = url
	  console.log(url)
	}
	const [data,loading,error] = useFetchData(url)
	const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
	const [breadCrumb] = useFetchData(dashboardTypeDetailURL)
 
  const [instituteRanking,setInstituteRanking] = useState(null)
	const [disabledSave,setDisabledSave] = useState(false)

	const setInstituteRankingObj = (data) => {

        const  instituteRankingObj = {}
        instituteRankingObj.add_ranking = [{
      ranking_authority:getObject(data.ranking_authority),
		  year:data.year,
			rank:data.rank,
			rank_max:data.rank_max,
			institute:data.institute,
			object_status:data.object_status.id,
			stream:getObject(data.stream)

		}]
		setInstituteRanking(instituteRankingObj)
		form.setFieldsValue({"add_ranking":instituteRankingObj.add_ranking})
	}
	useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {
			  console.log("data",data)
			  setInstituteRankingObj(data)
		  }
		  else {
		      const ranking_data = [{rank_max:null}]
		      form.setFieldsValue({"add_ranking":ranking_data})
		  }
		}


		return () => mounted = false;
	}, [data])


	const getCleanData = (data) =>
	{
        let temp = JSON.stringify(data['add_ranking'])
        let cleanData = JSON.parse(temp);
        for (const data_obj in cleanData) {
         for (let attr in cleanData[data_obj]) {
           if (cleanData[data_obj][attr] === "") {
                cleanData[data_obj][attr] = null
           }
         }
        if(!cleanData[data_obj].stream) {
            cleanData[data_obj].stream = null }
        else {
            cleanData[data_obj].stream = cleanData[data_obj].stream.value }
        if (cleanData[data_obj].ranking_authority) {
            cleanData[data_obj].ranking_authority = cleanData[data_obj].ranking_authority.value
           }
        if (cleanData[data_obj].institute) {
            cleanData[data_obj].institute = cleanData[data_obj].institute.id
           }
        }

        if(!isAddMode) {
        cleanData = cleanData[0]
        }
        return cleanData
	}

	const crudErrorHandler = (error) =>
	{
		showError(error)
	}

	const patchData = (data) =>
	{
		setLoader(true);
	  patch(saveURL,data).then(function (response)
      {
        console.log(response);
		    message.success(COMMON_SUCCESS_MESSAGE);
		    history.push(`/institute/${instituteID}/ranking/`)
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);

      });

	}
	const postData = (data) =>
	{
		setLoader(true);
	  post(saveURL,data)
	  .then(function (response)
      {
		message.success(COMMON_SUCCESS_MESSAGE);
		 history.push(`/institute/${instituteID}/ranking/`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);

      });

	}
  const showError = (errors) =>
  {
  const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
    console.log("server error",error.serverError)
    form.setFields(error.serverError)

  }
  const onFinish=(e)=>
  {
	  const data = form.getFieldsValue(true);
	  const cleanData = getCleanData(data)
	  setDisabledSave(true)
	  if(isAddMode)
	  {
		postData(cleanData)
	  }
	  else
	  {
        patchData(cleanData)
	  }
  }

  return (
  <>
	{error ? message.error(error) : loader === true ? <center><Spin /></center> :
    <Row>
			<Col span={24}>
				<NavBreadCrumb breadCrumb={breadCrumb}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0"> { instituteRanking ? `${instituteRanking.add_ranking[0].institute.name} Ranking Template` : locationData ? `${locationData?.name}, Ranking Template` : `Ranking Template` } </h2>
						</div>
					</div>
				</div>
			</Col>


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
              <RankingDetailForm form={form} instituteID={instituteID} isAddMode={isAddMode}/>
            </div>
             <div className="d-flex" style={{ justifyContent: 'left' }}>
            <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
          </div>
        </div>
			</Col>

		</Row>
		}
		</>
  );
};
export default InstituteRankingDetail;
