import React, { useState } from "react";
import DebounceSelect from "../../../components/DebounceSelect";
import { getStreamList } from "../../../services/Stream/Stream";
import { getAuthorAutoSuggestion } from "../../../services/Institute/Institute";
import { Button, Form, Select, DatePicker } from "antd";


const Option = Select.Option;
const LogsListingFilter = ({ onApplyFilter, onClearFilter }) => {
  const [stream, setStream] = useState([]);
  const [author, setAuthor] = useState(null);
  const [templateType, setTemplateType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const { RangePicker } = DatePicker;

  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (dates) {
      setStartDate(dates[0].format("YYYY-MM-DD HH:mm:ss"));
      setEndDate(dates[1].format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const onTemplateTypeChange = (value) => {
    console.log(value);
    setTemplateType(value);
  };

  const applyFilter = (e) => {
    onApplyFilter({ stream, author, templateType, startDate, endDate });
    console.log("apply filter");
  };

  const clearFilter = (e) => {
    setStream([]);
    setAuthor(null);
    setStartDate(null);
    setEndDate(null);
    setDateRange(null);
    setTemplateType(null);
    onClearFilter();
  };

  return (
    <div className="ant-card ant-card-bordered gx-card">
      <div className="ant-card-body">
        <Form name="register" layout="ant-form-horizontal">
          <ul className="collgeForm">
            <li className="gx-mb-3">
              <DebounceSelect
                mode="multiple"
                value={stream}
                placeholder="Select Stream"
                fetchOptions={getStreamList}
                onChange={(newValue) => {
                  setStream(newValue);
                  console.log(newValue);
                }}
                style={{
                  width: "100%",
                }}
              />
            </li>
            <li className="gx-mb-3">
              <DebounceSelect
                mode="single"
                value={author}
                placeholder="Select Author"
                fetchOptions={getAuthorAutoSuggestion}
                onChange={(newValue) => {
                  setAuthor(newValue);
                  console.log(newValue);
                }}
                showSearch={true}
                allowClear={true}
                style={{
                  width: "100%",
                }}
              />
            </li>
            <li className="gx-mb-3">
              <Select
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="Template Type"
                value={templateType}
                onChange={onTemplateTypeChange}
              >
                <Option value="">Select Template</Option>
                <Option value="1">News</Option>
                <Option value="5">Article</Option>
                <Option value="6">Board</Option>
                <Option value="7">Blog</Option>
                <Option value="2">Exam Widget</Option>
                <Option value="4">College Component</Option>
                <Option value="3">CLD_INSTITUTE_COURSE</Option>
                <Option value="8">Course Component</Option>
              </Select>
            </li>
            <li>
              <RangePicker
                showTime
                onChange={handleDateChange}
                value={dateRange}
              />
            </li>
            <li>
              <Button type="primary" htmlType="submit" onClick={applyFilter}>
                Apply Filter
              </Button>
              <Button
                type="danger"
                htmlType="submit"
                ghost
                onClick={clearFilter}
              >
                Clear
              </Button>
            </li>
          </ul>
        </Form>
      </div>
    </div>
  );
};
export default LogsListingFilter;
