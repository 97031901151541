import { BASE_URL } from '../../../constants/Settings';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { getCourseContent } from "../../../services/Course/Course";
import { Row, Col, Table, Pagination, Button, Input, message } from "antd";
import { FormOutlined, EyeOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "../../../constants/CollegeDekho";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import CourseListingFilter from './CourseListingFilter';
import { SearchOutlined } from '@ant-design/icons';
import { getErrors } from '../../../util/helpers';
import { DATE_TIME_FORMAT_LISTING } from '../../../constants/CollegeDekho'
import moment from 'moment';


const CourseListing = () => {
  const [courseList, setCourseList] = useState([])
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [text, setText] = useState("");
  const [courseContentId, setCourseContentId] = useState([]);
  const [stream, setStream] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [course, setCourse] = useState([]);
  const [addedBy, setAddedBy] = useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);
  const [isActive, setIsActive] = useState(null);


  const columns = [{
    title: 'Course Id',
    dataIndex: 'id',
  },
  {
    title: 'Course Name',
    dataIndex: 'name',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    render: (text, record) => (
      <span>
        {record.min_duration} {record.min_duration_type?.name} - {record.max_duration} {record.max_duration_type?.name}
      </span>
    ),
  },
  {
    title: 'Added On',
    dataIndex: 'added_on',
    render: (text, record) => (
      <span>
        {moment(record.added_on).format(DATE_TIME_FORMAT_LISTING)}
      </span>
    ),
  },
  {
    title: 'Last Modified On',
    dataIndex: 'updated_on',
    render: (text, record) => (
      <span>
        {moment(record.modified_on).format(DATE_TIME_FORMAT_LISTING)}
      </span>
    ),
  },
  {
    title: 'Last Modified By',
    dataIndex: 'updated_by',
    render: (text, record) => (
      <span>{record.updated_by?.name}</span>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
        <span className="gx-link"><Link to={'/course/' + record.id + '/update/'}> <FormOutlined /></Link></span>

      </span>

    ),
  },
  {
    title: 'View',
    key: 'view',
    render: (text, record) => (
      (record.object_status === 1) ?
        <span>
          <a href={`${BASE_URL}/courses/${record.slug}`} target="_blank"> <EyeOutlined /></a>      </span>
        : <span></span>

    ),
  },
  ];





  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getCourseContent({ page, pageSize, text, stream, startDate, endDate, course, addedBy, modifiedBy, isActive, courseContentId })
      .then(data => {
        if (mounted) {
          setCourseList(data.data.results);
          setTotal(data.data.count);
          setLoading(false);
          setError(null);
        }
      })
      .catch(function (response) {
        const err = getErrors(response)
        setError(err.allError);
        console.log(err)

      });

    return () => mounted = false;
  }, [page, pageSize, text, stream, startDate, endDate, course, addedBy, modifiedBy, isActive, courseContentId])

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);
    console.log(current, PageSize);
  }
  function onChange(page, PageSize) {
    setPage(page);
    console.log(page, PageSize);
  }

  const onPressEnterSearchInput = (e) => {
    console.log(e.target.value);
    if (e.target.value.length > 2) {
      setText(e.target.value);
    }
  }
  const onApplyFilter = (data) => {
    setStream(data.stream)
    setStartDate(data.startDate)
    setEndDate(data.endDate)
    setCourse(data.course)
    setAddedBy(data.addedBy)
    setModifiedBy(data.modifiedBy)
    setIsActive(data.isActive)
    setCourseContentId(data.courseContentId)
    setPage(1)
  }
  const onClearFilter = () => {
    setStream([])
    setCourseContentId([])
    setStartDate("")
    setEndDate("")
    setCourse([])
    setAddedBy("")
    setModifiedBy("")
    setIsActive(null)
    setText("")
    window.location.reload(false);
  }
  return (



    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered searchBox gx-card">
            <div className="ant-card-body">
              <Input placeholder="Search by Course Name or Course Short Name or by Course ID	" prefix={<SearchOutlined />} allowClear onPressEnter={onPressEnterSearchInput} />
            </div>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <CourseListingFilter onApplyFilter={onApplyFilter} onClearFilter={onClearFilter} />
      </Col>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Link to="/course/create/">
          <Button type="primary">
            <span>Add New Course</span>
          </Button>
        </Link>

      </Col>

      <Col span={24}>
        <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={courseList} pagination={false} />
      </Col>
      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          pageSize={pageSize}
          total={total}
          current={page}
        />
      </Col>
    </Row>


  );
};

export default CourseListing;
