import { Row, Col, Form, Input, Select } from "antd"
import { getObjectStatus, getScholarshipComponent } from "../../../util/helpers"

const objectStatus = getObjectStatus()
const ScholarshipPageComponentDetailForm = () => {

    const componentOptions = getScholarshipComponent()


    return (
        <Row>

            <Col span={12}>
                <Form.Item label="Component" name="component" className="labelInput" rules={[
                    {
                        required: true,
                        message: 'Select component',
                    }

                ]}>
                    <Select
                        allowClear={true}
                        style={{ width: '100%' }}
                        placeholder="Select Component"
                        options={componentOptions}
                    >
                    </Select>

                </Form.Item>
            </Col>


            <Col span={8}>
                <Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
                    {
                        required: true,
                        message: 'Object Status Required',
                    },
                ]}>
                    <Select
                        allowClear={true}
                        name="object_status"
                        style={{ width: '100%' }}
                        placeholder="Select object status"
                        options={objectStatus}
                    >
                    </Select>

                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item label="H2" name="h2" className="labelInput" rules={[
                    {
                        required: true,
                        message: 'H2 required',
                    },
                ]}>
                    <Input className="gx-mb-3" />

                </Form.Item>
            </Col>



        </Row>
    )
}
export default ScholarshipPageComponentDetailForm