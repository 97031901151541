import { Col, Form, Input, Row } from "antd"
import TextArea from "antd/lib/input/TextArea";

const ScholarshipSEODetails = () => {
    return (
        <>
        <Row>
        <Col span={24}>
                

                <Form.Item label="H1" name="h1" className="labelInput">
                        <Input className="gx-mb-3" name="h1" />
                    </Form.Item>

                    
            
            </Col>
            <Col span={24}>
                

                <Form.Item label="Page Title" name="page_title" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Page title required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="page_title" />
                    </Form.Item>

                    
            
            </Col>

            <Col span={24}>
            <Form.Item label="Meta description" name="description" className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Meta Description required',

                            }
                            

                        ]}>
                        <TextArea className="gx-mb-3" name="description" />
                    </Form.Item>
            </Col>

            <Col span={24}>
            <Form.Item label="Meta Keywords" name="keyword" className="labelInput">
                        <TextArea className="gx-mb-3" name="keyword" />
                    </Form.Item>
            </Col>
        </Row>
        
        </>
    )
}

export default ScholarshipSEODetails;