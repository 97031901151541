import { get } from "../index";

export async function getInstitutePlacementList(data) {
  console.log("data", data);
  const url = `/api/cms/institute/${data?.instituteID}/placement-detail/?page=${data.page}&page_size=${data.pageSize}`;
  return await get(url);
}
export async function getStudentPlacementList(data) {
  console.log("data", data);
  const url = `/api/cms/institute/${data?.instituteID}/placement-type/${data?.placementID}/student-placement/?page=${data.page}&page_size=${data.pageSize}`;
  return await get(url);
}
export async function getSectorPlacementList(data) {
  const url = `/api/cms/institute/${data?.instituteID}/placement-type/${data?.placementID}/sector-placement/?page=${data.page}&page_size=${data.pageSize}`;
  return await get(url);
}
export async function getCompanyPlacementList(data) {
  const url = `/api/cms/institute/${data?.instituteID}/placement-type/${data?.placementID}/company-placement/?page=${data.page}&page_size=${data.pageSize}`;
  return await get(url);
}