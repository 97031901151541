import moment from "moment";
import { DATE_TIME_FORMAT_LISTING, OBJECT_STATUS } from "../../../constants/CollegeDekho";
import { Divider } from "antd";
import { Link } from 'react-router-dom';
import { getTemplateContentTypeName } from "../../../util/helpers";
import { FormOutlined } from "@ant-design/icons";

function findValueInObjectStatus(valueToFind) {
    for (const status of OBJECT_STATUS) {
        if (status[0] === valueToFind) {
            return status[1]; // Return the 1st index value when a match is found.
        }
    }
    return null; // Return null if the value is not found.
}

export const columns = ({ templateContentTypeID, templateObjectID, courseID }) => [{
    title: 'ID',
    dataIndex: 'id',

},
{
    title: 'Question',
    dataIndex: 'question',
},
{
    title: 'Added By',
    dataIndex: 'added_by',
    width: 210,
    render: (_, record) => record?.added_by?.name,
},
{
    title: 'Updated On',
    dataIndex: 'updated_on',
    width: 210,
    render: (text, record) => (
        <span>
            <Divider type="vertical" />
            <span> {record.updated_on ? moment(record.updated_on).format(DATE_TIME_FORMAT_LISTING) : null} </span>
        </span>
    ),
},
{
    title: 'Added On ',
    dataIndex: 'added_on',
    width: 210,
    render: (text, record) => (
        <span>
            <Divider type="vertical" />
            <span> {record.added_on ? moment(record.added_on).format(DATE_TIME_FORMAT_LISTING) : null} </span>
        </span>
    ),
},
{
    title: 'Status',
    dataIndex: 'object_status',
    width: 120,
    render: (text, record) => (
        <span>
            <Divider type="vertical" />
            <span>{findValueInObjectStatus(record?.object_status)}</span>
        </span>
    ),
},
{
    title: 'Action',
    key: 'action',
    render: (text, record) => <span>

        <Divider type="vertical" />
        <span className="gx-link">
            <Link
                to={
                    courseID ?
                        `/${getTemplateContentTypeName(templateContentTypeID)}/${courseID}/vernacular/${templateObjectID}/faqs/${templateContentTypeID}/${record?.id}/update/?page_content_type_id=${record?.content_type}&page_object_id=${record?.object_id}`
                        :
                        `/${getTemplateContentTypeName(templateContentTypeID)}/${templateObjectID}/faqs/${templateContentTypeID}/${record?.id}/update/?page_content_type_id=${record?.content_type}&page_object_id=${record?.object_id}`
                }
            >
                <FormOutlined />
            </Link>
        </span>
    </span>,
}
];