import React from 'react';
import { Row, Col, Input, Form } from "antd";


const ReviewedBy = () => {
    const clearError = (rule, value, callback) => {
        return Promise.resolve()
    }

    return (
        <Row>
            <Col span={8}>
                <Form.Item label="Coordinator name" name="coordinator_name" className="labelInput" rules={[{ validator: clearError }]}>
                    <Input className="gx-mb-3" name="coordinator_name" />
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item label="Coordinator Designation" name="coordinator_designation" className="labelInput" rules={[{ validator: clearError }]}>
                    <Input className="gx-mb-3" name="coordinator_designation" />
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item label="Coordinator Linkedin ID" name="coordinator_linkedin" className="labelInput" rules={[{ validator: clearError }]}>
                    <Input className="gx-mb-3" name="coordinator_linkedin" />
                </Form.Item>
            </Col>
        </Row>
    )
}
export default ReviewedBy