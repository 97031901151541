import React, { useState } from 'react';
import {Card, Row, Col, Form, message, Button, Input, Select, Space, Upload, Modal} from "antd";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import DebounceSelect from '../../../components/DebounceSelect';
import { getInstituteCourseList } from "../../../services/Course/Course";
import { getCompanyList } from "../../../services/Company/Company";
import { getYearChoices,getObjectStatus } from "../../../util/helpers";
import Cropper from 'react-easy-crop';
import getCroppedImg from '../instituteDetail/cropUtils';

const YearOptions =  getYearChoices(2000)
const objectStatusOptions = getObjectStatus()

const AlumniDetail=({form,instituteID,isAddMode, cropImage})=>{
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const[imgSrc, setImgSrc]= useState(null);
	const [croppedArea, setCroppedArea] = useState(null);
  const [cropperVisible, setCropperVisible] = useState(true);
  const normFile = (e) =>
	{
     console.log('Upload event:', e);
      if (Array.isArray(e)) {
             return e;
      }
	  setCropperVisible(true);
     return e && e.fileList;
  };

  const beforeUpload =(file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng)
		    {
             message.error("You can only upload JPG/PNG file!");
        }
		setImgSrc(URL.createObjectURL(file));

        return false
  }

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCancelCrop = (e) => {
    //e.preventDefault();
    setCropperVisible(false);
  };
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onCrop = async (e) => {
    if (!croppedArea || !imgSrc) {
      return;
    }

    
    const croppedImageCanvas = await getCroppedImg(imgSrc, croppedArea);
    const croppedImageData = croppedImageCanvas.toDataURL("image/jpeg");
    const fileList = base64ToFile(croppedImageData);
	console.log("Our file list is ", fileList);

	cropImage(fileList);

    //form.setFieldsValue({ banner: fileList });
    setCropperVisible(false, () =>{

    });
  };

  function base64ToFile(
    base64Data,
    fileName = "image.jpg",
    fileType = "image/jpeg",
    uid = "rc-upload-1713348873181-6"
  ) {
    // Convert base64 data URL to Blob
    const byteString = atob(base64Data.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: fileType });

    // Create File object
    const file = new File([blob], fileName, { type: fileType });

    // Prepare the structure to match the format provided
    return [
      {
        uid,
        originFileObj: file,
        percent: 0,
        size: file.size,
        type: file.type,
      },
    ];
  }

	return (
  <Row>
    <Col span={24}>
      <div>
        <Form.List name="add_alumni">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
              <Card>
              <Space key={field.key} style={{ display: "flex", marginBottom: 8 }}  align="baseline">
              <Row>
                <Col span={6}>
                  <Form.Item
                    {...field}
                    className="labelInput"
                    name={[field.name, "name"]}
                    label = {[field.label, "Name"]}
                    fieldKey={[field.fieldKey, "name"]}
                    rules = {[{ required: true,
                                message: 'Enter Name',}]}>
                    <Input className="gx-mb-3"/>
                  </Form.Item>
                </Col>
                <Col span={6}>
				          <Form.Item {...field}
				            className="labelInput"
				            name={[field.name, "batch"]}
				            label={[field.label, "Batch"]}
				            fieldKey={[field.fieldKey, "batch"]}
					 	        rules={[{
			                required: true,
			                message: 'Enter Batch',
			              }]}>
					            <Select
						            allowClear={true}
						            style={{width: '100%'}}
						            placeholder="Select Batch"
						            options={YearOptions}>
                                </Select>
					          </Form.Item>
					        </Col>
					        <Col span={6}>
				            <Form.Item {...field}
				              className="labelInput"
				              label={[field.label, "Course"]}
				              name={[field.name, "course"]}
				              fieldKey={[field.fieldKey, "course"]}>
					            <DebounceSelect
                         mode="single"
					               name="course"
                         placeholder="Select Course"
                         cldID={instituteID}
                         fetchOptions={getInstituteCourseList}
					               showSearch = {true}
					               allowClear = {true}
                      />
					          </Form.Item>
				          </Col>
				          <Col span={6}>
				            <Form.Item {...field}
				               className="labelInput"
				               label={[field.label, "Company"]}
				               name={[field.name, "company"]}
				               fieldKey={[field.fieldKey, "company"]}
				               rules={[{
			                    required: true,
			                    message: 'Enter Company',
			                 },
			              ]}>
					            <DebounceSelect
                        mode="single"
					              name="company"
                        placeholder="Select Company"
                        cldID={instituteID}
                        fetchOptions={getCompanyList}
					              showSearch = {true}
                      />
					          </Form.Item>
				          </Col>
				          <Col span={6}>
					          <Form.Item {...field}
				               className="labelInput"
				               label={[field.label, "Designation"]}
				               name={[field.name, "designation"]}
				               fieldKey={[field.fieldKey, "designation"]}>
						         <Input className="gx-mb-3" />
					          </Form.Item>
				          </Col>
				          <Col span={6}>
					           <Form.Item {...field}
				                className="labelInput"
				                label={[field.label, "Email"]}
				                name={[field.name, "email"]}
				                fieldKey={[field.fieldKey, "email"]}>
						          <Input className="gx-mb-3" />
					           </Form.Item>
				          </Col>
				          <Col span={6}>
					           <Form.Item {...field}
				                className="labelInput"
				                label={[field.label, "Linkedin"]}
				                name={[field.name, "linkedin_profile"]}
				                fieldKey={[field.fieldKey, "linkedin_profile"]}>
						           <Input className="gx-mb-3" />
					           </Form.Item>
				          </Col>
				          <Col span={6}>
				             <Form.Item
				                className="labelInput"
                        name={[field.name, "image"]}
                        label={[field.label, "Upload Image"]}
                        fieldKey={[field.fieldKey, "image"]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
						           >
                     <Upload name="image" maxCount={1} beforeUpload={beforeUpload} listType="picture" className="upload-list-inline">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                     </Upload>
                     </Form.Item>
					 { imgSrc && cropperVisible && (
          <Modal
            title="Crop Image"
            visible={cropperVisible}
            onCancel={onCancelCrop}
            footer={[
             
              <Button key="crop" type="primary" 
			  onClick={onCrop}
			  >
                Crop
              </Button>,
            ]}>
              <Cropper
                image={imgSrc}
                crop={crop}
                aspect={1}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
              />
          </Modal>
        )}
				          </Col>
				          <Col span={6}>
					           <Form.Item {...field}
				                className="labelInput"
				                label={[field.label, "Object Status"]}
				                name={[field.name, "object_status"]}
					              fieldKey={[field.fieldKey, "object_status"]}
					              >
						           <Select
						              allowClear={true}
						              style={{width: '100%'}}
						              placeholder="Select Object Status"
						              options={objectStatusOptions}
					             >
                       </Select>
					           </Form.Item>
				          </Col>
				          {isAddMode &&
				          <Col span={6}>
				             <Form.Item className="labelInput">
                       <Button type="primary" onClick={() => remove(field.name)} block icon={<MinusCircleOutlined/>}> Remove</Button>
                     </Form.Item>
                  </Col>
                  }
                </Row>
                </Space>
                </Card>
                ))}
              {isAddMode &&
              <Col span={8}>
                <Form.Item>
                   <Button type="primary" onClick={() => add()} block icon={<PlusOutlined/>}> Add More</Button>
                </Form.Item>
              </Col>
              }
            </>
          )}
        </Form.List>
      </div>
    </Col>
  </Row>
  )
}

export default AlumniDetail
