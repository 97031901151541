import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../../components/CollegeDekho/NavBreadCrumb';
import { useFetchData } from '../../../../hooks';
import { patch, post } from '../../../../services';
import { getErrors, getObject, isInt } from '../../../../util/helpers';
import { API_URL_LIST, COMMON_SUCCESS_MESSAGE } from '../../../../constants/CollegeDekho';

import { CustomEditor } from '../../../../components/CustomEditor';

import InstituteCoursePageComponentForm from './InstituteCoursePageComponentForm';


const InstituteCoursePageComponent = () => {
    const [form] = Form.useForm();
	const { instituteID, instituteCourseID, componentID } = useParams();
	const  isAddMode = !isInt(componentID)
	let url = null;
	let saveURL = API_URL_LIST["INSITUTE_COURSE_COMPONENTS_LISING"].replace("#instituteID#",instituteID).replace("#courseID#", instituteCourseID)
    
	if(!isAddMode)
	{
	  url=API_URL_LIST["INSITUTE_COURSE_PAGE_COMPONENT_DETAIL"].replace("#instituteID#",instituteID).replace("#courseID#", instituteCourseID).replace("#componentID#", componentID)
	  saveURL = url
	  
	}  
	const [data,loading,error] = useFetchData(url)
	const [breadCrumbData,breadCrumbLoading,breadCrumbError] = useFetchData(API_URL_LIST["INSITUTE_COURSE_COMPONENTS_LISING"].replace("#instituteID#",instituteID).replace("#courseID#", instituteCourseID))
	const history = useHistory()
	const [disabledSave,setDisabledSave] = useState(false)
    const [content, setContent] = useState(null)
	

	
    
    const setComponentObj = (data) => {
    const obj = {...data}
      obj.object_status = obj.object_status?.id
      obj.component = data.component.id
      if(obj.has_read_more)
		  {
		  	 obj.has_read_more = "checked"
		  }
      obj.component = getObject(data.component)
	  if(data.content)
      {
        setContent(data.content)
      }
		form.setFieldsValue(obj)
	}

	useEffect(() => {
		let mounted = true;
        		
		if(mounted) 
		{
		  	
		  if(data)
		  {
			  setComponentObj(data)
		  }  
		
		  
		}
		
		
		return () => mounted = false;
	}, [data])
  

	const getCleanData = (data) => 
	{
		const temp = JSON.stringify(data)
        const cleanData = JSON.parse(temp);
	
		
		if(cleanData?.has_read_more)
		{
			cleanData.has_read_more = true
		}
		else
		{
		cleanData.has_read_more = false
		}
		
        cleanData.content = content
		cleanData.component = cleanData.component.value
		
        return cleanData 
	}
	
	const crudErrorHandler = (error) => 
	{
		showError(error)
	}

	const patchData = (data) => 
	{
	  patch(saveURL,data).then(function (response) 
      {
        
		message.success(COMMON_SUCCESS_MESSAGE);
		history.push(`/institute/${instituteID}/course/${instituteCourseID}/page/components`)
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
      });

	}
	const postData = (data) => 
	{
       
	  post(saveURL,data)
	  .then(function (response) 
      {
		 message.success(COMMON_SUCCESS_MESSAGE);
		 history.push(`/institute/${instituteID}/course/${instituteCourseID}/page/components`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
      });

	}

  const showError = (errors) => {

	const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
    
    
    form.setFields(error.serverError)
	


  } 	

  const onFinish = (e) => 
  {
    
	const data = form.getFieldsValue(true);
	const cleanData = getCleanData(data)

	setDisabledSave(true)
	if(isAddMode)
	{
		
		postData(cleanData)
	}
	else
	{
		patchData(cleanData)
	}

  };


    
  return (
	<>
	{error ? message.error(error) :

     <Row>
			<Col span={24}>
			<NavBreadCrumb page={breadCrumbData?.extra_details?.institute_course?.name} breadCrumb={breadCrumbData?.extra_details?.institute}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">Component</h2>
						
						</div>
					</div>
				</div>
			</Col>	


			<Col span={24}>
					<div className="ant-card ant-card-bordered gx-card">
					<div className="ant-card-body">
					<Form onFinish={onFinish} form={form}>
						<div className="gx-p-2">
						<InstituteCoursePageComponentForm form={form} isAddMode={isAddMode}/>  
						</div>
                        <div className="gx-p-2">
                            <CustomEditor content={content} setContent={setContent}/>
				        </div>
						<div className="d-flex" style={{ justifyContent: 'left' }}>
						<Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
						</div>
					</Form>
					</div>
					</div>
			</Col>


			
		</Row>
      }
	  </>
  );
};

export default InstituteCoursePageComponent;
