import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getSectorPlacementList } from "../../../services/InstitutePlacement/InstitutePlacement";
import { Divider, Row, Col, Table, Pagination, Button, message } from "antd";
import { getErrors } from "../../../util/helpers";
import { FormOutlined } from "@ant-design/icons";
import { API_URL_LIST, PAGE_SIZE } from "../../../constants/CollegeDekho";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import { useFetchData } from "../../../hooks";

const SectorPlacementListing = (props) => {
  const { placementID, instituteID } = useParams();

  // const { object_id } = state;
  let instituteData = API_URL_LIST["INSTITUTE_PLACEMENT_DETAIL_URL"]
    .replace("#id#", instituteID)
    .replace("#id1#", placementID);

  const [instituteDetailData] = useFetchData(instituteData);

  const [institutePlacementList, setInstitutePlacementList] = useState([]);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const instituteName =
    instituteDetailData?.placement_type_data?.object_id?.name;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Sector Percent",
      render: (text, record) => (
        <span>
          {record.sector_percent ? record.sector_percent + " %" : "-"}
        </span>
      ),
    },
    {
      title: "Sector",
      render: (text, record) => <span>{record?.sector?.name}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            <Divider type="vertical" />
            <span className="gx-link">
              <Link
                to={{
                  pathname: `/institute/${instituteID}/placement/${placementID}/sector-placement/${record?.id}/update`,
                }}
              >
                <FormOutlined />
              </Link>
            </span>
          </span>
        );
      },
    },
  ];
  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getSectorPlacementList({ placementID, page, pageSize, text, instituteID })
      .then((data) => {
        if (mounted) {
          console.log("daatd", data.data);
          setInstitutePlacementList(data.data.results);
          // setTotal(data.data.count);
          // setInstitute(data.data.extra_details.institute);
          setLoading(false);
          setError(null);
        }
      })
      .catch(function (response) {
        console.log("response", response);
        const err = getErrors(response);
        setError(err.allError);
        console.log(err);
      });

    return () => (mounted = false);
  }, [page, pageSize, text, placementID]);

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);
    console.log(current, PageSize);
  }
  function onChange(page, PageSize) {
    setPage(page);
    console.log(page, PageSize);
  }

  return (
    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb page={instituteName} breadCrumb={instituteDetailData?.extra_details?.institute}/>
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered gx-card-widget">
            <div className="ant-card-body">
              <h2 className="gx-mb-0">{instituteName}, Placements</h2>
            </div>
          </div>
        </div>
        <Row>
          <Col span={18}></Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Link
              to={{
                pathname: `/institute/${instituteID}/placement/${placementID}/sector-placement/create/`,
              }}
            >
              <Button type="primary">
                <span>Add Sector Placement Data</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Table
          className="gx-table-responsive"
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={institutePlacementList}
          pagination={false}
        />
      </Col>
      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          pageSize={pageSize}
          total={total}
          current={page}
        />
      </Col>
    </Row>
  );
};

export default SectorPlacementListing;
