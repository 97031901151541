import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import FacilityDetailForm from './FacilityDetailForm';
import { getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks";
import {COMMON_SUCCESS_MESSAGE,COMMON_ERROR_MESSAGE_WRONG_DATA,API_URL_LIST} from '../../../constants/CollegeDekho'
import { post } from '../../../services/';
import { Spin } from 'antd';

const InstituteFacilityDetail = () => {
  const [form] = Form.useForm();  
  const { instituteID } = useParams();
  const history = useHistory()
  const  [loader, setLoader] = useState(false);

	let saveURL = API_URL_LIST["INSTITUTE_FACILITY_DETAIL_POST_URL"].replace("#id#",instituteID)
  
	const [data,loading,error] = useFetchData(saveURL)
	const [institute,setInstitute] = useState(null)
	const [disabledSave,setDisabledSave] = useState(false)
  
  const setFacility = (data) => 
  {
        
	const items = data.results.map( (item) => {
	     return {"value":item.facility.id,"label":item.facility.name}	
	} )
	form.setFieldsValue({"facility":items})
    
 }
 const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
 const [breadCrumb] = useFetchData(dashboardTypeDetailURL)

  useEffect(() => {
      let mounted = true;
              
      if(mounted) 
      {
         if(data)
         {
            setFacility(data)
            setInstitute(data.extra_details.institute)
         }  
      
      }
      
      
      return () => mounted = false;
  }, [data])


  const getCleanData = (data) =>
	{
       let cleanData = {}
	   cleanData["facility"] =  data.facility.map( (item) => {
		   return item.value
	   } ) 
	   return cleanData
         
	}

	const crudErrorHandler = (error) => 
	{
		showError(error)
	}


	const postData = (data) =>
	{
    setLoader(true);
	  post(saveURL,data)
	  .then(function (response)
      {
		       message.success(COMMON_SUCCESS_MESSAGE);
		       history.push(`/institute/${instituteID}/facility/`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
    setLoader(false);
      });

	}

  const showError = (errors) => {

	const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
    else
    {
          message.error(COMMON_ERROR_MESSAGE_WRONG_DATA);
	}
    form.setFields(error.serverError)

	
  }

  const onFinish=(e)=>
  {
	   const data = form.getFieldsValue(true);
	   const cleanData = getCleanData(data)
	   setDisabledSave(true)	  
	   postData(cleanData)
 }

  return (
    <>
    {loader === true ? <center><Spin /></center> :
    <Row>
			<Col span={24}>
				<NavBreadCrumb breadCrumb={breadCrumb}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">{ institute ? `${institute.name}, Facilities` : `Facilities`} </h2>
						</div>
					</div>
				</div>
			</Col>	 


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
              <FacilityDetailForm form={form} instituteID={instituteID}/>
            </div>
            <div className="d-flex" style={{ justifyContent: 'right' }}>
            <Button type="primary" htmlType="submit" > Save</Button>
            </div>
          </Form>
          </div>
        </div>
			</Col>
			
		</Row>
  }
    </>
  );
};
export default InstituteFacilityDetail;
