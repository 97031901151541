import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import DebounceSelect from "../../../components/DebounceSelect";
import { getInstituteDegreeList } from "../../../services/Degree/Degree";
import { getCompanyList } from "../../../services/Company/Company";
import {
  getCurrency,
  getYearChoices,
  getObjectStatus,
  getLevel,
} from "../../../util/helpers";
import { API_URL_LIST } from "../../../constants/CollegeDekho";
import { getAutoSuggestionList } from "../../../services";
import { getContentTypeObjectList } from "../../../services/ContentType/ObjectList";
import { CONTENT_TYPE_ID } from "../../../constants/Settings";

const YearOptions = getYearChoices(2010);
const currencyOptions = getCurrency();
const objectStatusOptions = getObjectStatus();
const levelOptions = getLevel();

const PlacementDetailForm = ({
  form,
  CurrReqValidations,
  instituteID,
  contentTypeID,
}) => {
  console.log("formform", form);
  const [contentType, setContentType] = useState([]);
  const numberValidator = (rule, value, callback) => {
    // Always return a callback, otherwise validateFields cannot respond
    if (value > 40000) {
      return Promise.reject(
        "Number too big"
        //When i delete that row its fix warning, but i need have that error message.
      );
    }

    //callback();
    return Promise.resolve();
  };

  useEffect(() => {
    const getPageTypeList = async () => {
      const content_type_url = API_URL_LIST.FAQ_CONTENT_TYPES.replace(
        "#templateContentTypeID#",
        CONTENT_TYPE_ID.placementType
      );
      const response = await getAutoSuggestionList(content_type_url);
      if (response) {
        setContentType(response);
        // setIsLoadingSuggestions((prev) => ({ ...prev, pageType: false }));
        // setPageTypeList(response);
      }
    };

    getPageTypeList();
  }, []);
  // const getContentTypeObjectList = (response) => {
  // console.log("response",response);
  // getContentTypeObjectList()
  // };
  return (
    <Row>
      <div></div>
      <Col span={12}>
        <Form.Item
          label="Placement Year"
          name="placement_year"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Enter Placement Year",
            },
          ]}
        >
          <Select
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="Select Placement Year"
            options={YearOptions}
          ></Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="No of Students Placed"
          name="no_of_students_placed"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="% of Students Placed"
          name="percent_of_students_placed"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="No of  Companies Visited"
          name="no_of_companies_visited"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Total Offers"
          name="total_offers"
          className="labelInput"
          rules={[
            {
              validator: numberValidator,
            },
          ]}
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="Course Duration"
          name="course_duration"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="Student opted for higher Education "
          name="students_opted_for_higher_education"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="Overseas Offer"
          name="overseas_offers"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="Highest Package"
          name="highest_package"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Median Package"
          name="median_package"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Avg Package"
          name="average_package"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="Currency Type"
          name="package_currency"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Package Currency Required",
            },
          ]}
        >
          <Select
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="Select Package Currency"
            options={currencyOptions}
          ></Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Content Type"
          name="content_type"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Content Type Required",
            },
          ]}
        >
          <Select
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="Select Content Type"
            optionLabelProp="label"
            options={contentType}
          ></Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Total Pre-placement offers"
          name="total_pre_placement_offers"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Object Type"
          name="object_id"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Object Type Required",
            },
          ]}
        >
          {contentTypeID == CONTENT_TYPE_ID?.level ? (
            <Select
              mode="single"
              allowClear={true}
              style={{ width: "100%" }}
              placeholder="Level"
              options={levelOptions}
              onChange={(newValue) => {
                // setLevel(newValue);
              }}
            ></Select>
          ) : contentTypeID == CONTENT_TYPE_ID?.institute ? (
            <DebounceSelect
              mode="single"
              name="institute"
              // placeholder="Select Degree"
              cldID={instituteID}
              contentTypeId={contentTypeID}
              fetchOptions={getContentTypeObjectList}
              showSearch={false}
            />
          ) : (
            <DebounceSelect
              mode="single"
              name="degree"
              placeholder="Select Degree"
              cldID={instituteID}
              contentTypeId={contentTypeID}
              fetchOptions={getContentTypeObjectList}
              showSearch={true}
            />
          )}
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Object Status"
          name="object_status"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Object Status is required",
            },
          ]}
        >
          <Select
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="Select Object Status"
            options={objectStatusOptions}
          ></Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PlacementDetailForm;
