import axios from "axios";
import { COMMON_API_LIST, CONTENT_LOG_API_LIST } from "../../constants/CollegeDekho";
import { BASE_URL, LOG_BASE_URL } from "../../constants/Settings";

export async function getLogVersionsList(data) {
  console.log("data", data);
  const baseURL = LOG_BASE_URL;
  const url = CONTENT_LOG_API_LIST["LOG_LISTING_URL"]
    .replace("#id#", data.templateId)
    .replace("#id1#", data.text || data.objectId);
  console.log("API LOGSS -------------- ", url);
  return await axios.get(baseURL + url);
}

export async function getPageWidgetList(template, widgetId) {
  const url = COMMON_API_LIST["PAGES_AND_WIDGETS_LIST_URL"]
    .replace("#id#", template)
    .replace("#id1#", widgetId);
  return await axios.get(BASE_URL + url);
}

export async function getLogsList(data) {
  const url = `${LOG_BASE_URL}/api/logs`;
  console.log("data", data, url);
  let streams = [];
  if (data.stream) {
    streams = data.stream.map((item) => {
      return item.value;
    });
  }
  delete data.stream;
  data.streams = streams.join();

  data.page_size = data.pageSize;
  delete data.pageSize;

  data.search_text = data.text;
  delete data.text;

  data.template = data.templateType;
  delete data.templateType;

  data.modified_by = data.author?.value;
  delete data.author;

  data.start_date = data.startDate;
  delete data.startDate;

  data.end_date = data.endDate;
  delete data.endDate;

  return await axios.get(url, { params: data });
}
