import { get } from "../index";
export async function getInstituteAlumniList(data)
{
    console.log("data",data);
    const url = `/api/cms/institute/${data.instituteID}/alumni/`;

    const params = {
      page_size: data.pageSize,
      page:data.page,
      text:data.text

    }
    console.log(params)
    return await get(url,params)
}

