import { BASE_URL } from '../../../constants/Settings';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { getInstituteList, getAuthorAutoSuggestion } from "../../../services/Institute/Institute";
import { Divider, Row, Col, Table, Pagination, Button, Input, message, Alert, Modal, Upload } from "antd";
import { FormOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "../../../constants/CollegeDekho";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import InstituteListingFilter from './InstituteListingFilter';
import { SearchOutlined } from '@ant-design/icons';
import { getErrors } from '../../../util/helpers';
import { DATE_TIME_FORMAT_LISTING, COMMON_SUCCESS_MESSAGE, API_URL_LIST } from '../../../constants/CollegeDekho'
import { post } from '../../../services/';
import DebounceSelect from '../../../components/DebounceSelect'
import moment from 'moment';
import { CONTENT_TYPE_ID } from '../../../constants/Settings';



const InstituteListing = () => {
  const [instituteList, setInstituteList] = useState([])
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false)
  const [newTemplateVisible, setNewTemplateVisible] = useState(false)
  const [messageText, setMessageText] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [text, setText] = useState("");
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [college, setCollege] = useState(null)
  const [author, setAuthor] = useState(null)
  const [partnerStatus, setPartnerStatus] = useState("");


  const authorPermission = (record) => {
    if (record.permissions.includes("change_author")) {
      newTemplateModel(record?.id)
    }
    else {
      alert("You are not allow to change author")
    }
  }

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },

  {
    title: 'Location',
    key: 'location',
    render: (text, record) => (
      <span>
        {record.city?.name} - {record.state?.name}
      </span>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'partner_status',
    render: (text, record) => (
      <span>
        {record.partner_status?.name}
      </span>
    ),
  },
  {
    title: 'Last Modified By',
    dataIndex: 'updated_by',
    render: (text, record) => (
      <>
        <span>
          {record.updated_by?.name}
        </span>
        <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => authorPermission(record)}>
          {record.author?.name ? record.author?.name : "change author"}
        </p>
      </>
    ),
  },
  {
    title: 'Last Modified On',
    dataIndex: 'updated_on',
    render: (text, record) => (
      <span>
        {moment(record.updated_on).format(DATE_TIME_FORMAT_LISTING)}
      </span>
    ),
  },

  {
    title: 'FAQ',
    key: 'faq',
    render: (text, record) => (
      <span className="gx-link">
        <Link to={`/institute/${record.id}/faqs/${CONTENT_TYPE_ID.institute}/`}>
          <FormOutlined />
        </Link>
      </span>
    ),
  },

  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span className="gx-link">
        <Link to={'/institute/' + record.id + '/update/'}>
          <FormOutlined />
        </Link>
      </span>
    ),
  },
  {
    title: 'View',
    key: 'view',
    render: (text, record) => (
      (record.is_live && record.object_status.id === 1) ?
        <span>
          <a href={BASE_URL + record.url} target="_blank"> <EyeOutlined /></a>
        </span>
        : <span></span>

    ),
  },
  ];

  const newTemplateModel = (collegeId) => {
    setCollege(collegeId)
    setNewTemplateVisible(true);
    setIsError(false)
  }
  const crudErrorHandler = (error) => {
    showError(error);
  }
  const postData = () => {
    const url = API_URL_LIST["COMMON_AUTHOR_URL"].replace("#id2#", college).replace("#id1#", 910)
    post(url, { author_id: author?.value })
      .then(function (response) {
        setNewTemplateVisible(false);
        message.success(COMMON_SUCCESS_MESSAGE);
        setRefresh(true)
      })
      .catch(crudErrorHandler)
      .finally(() => {
        //setDisabledSave(false)
      });

  }
  const handleOk = (e) => {
    postData()
  }

  const handleCancel = (e) => {
    setNewTemplateVisible(false);
  }


  const showError = (err) => {
    const errors = getErrors(err)
    setIsError(true)
    setMessageText(errors.allError.join())
  }


  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getInstituteList({ page, pageSize, text, partnerStatus, city, state })
      .then(data => {
        if (mounted) {
          setInstituteList(data.data.results);
          setTotal(data.data.count);
          setLoading(false);
          setError(null);
        }
      })
      .catch(function (response) {
        const err = getErrors(response)
        setError(err.allError);
        console.log(err)

      });

    return () => mounted = false;
  }, [page, pageSize, text, city, state, partnerStatus, refresh])

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);
    console.log(current, PageSize);
  }
  function onChange(page, PageSize) {
    setPage(page);
    console.log(page, PageSize);
  }

  const onPressEnterSearchInput = (e) => {
    console.log(e.target.value);
    if (e.target.value.length > 2) {
      setText(e.target.value);
    }
  }
  const onApplyFilter = (data) => {
    console.log(data);
    setCity(data.city)
    setState(data.state)
    setPartnerStatus(data.partnerStatus)
    setPage(1)
  }
  const onClearFilter = () => {
    setCity([])
    setState([])
    setPartnerStatus("")
    setText("")
  }



  return (

    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered searchBox gx-card">
            <div className="ant-card-body">
              <Input placeholder="Search by College Name or College Short Name or by College ID	" prefix={<SearchOutlined />} allowClear onPressEnter={onPressEnterSearchInput} />
            </div>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <InstituteListingFilter onApplyFilter={onApplyFilter} onClearFilter={onClearFilter} />
      </Col>
      <Col span={24} style={{ textAlign: 'right' }}>

        <Link to="/institute/create/">
          <Button type="primary">
            <span>Add New Institute</span>
          </Button>
        </Link>

      </Col>

      <Modal
        title="Change Author"
        visible={newTemplateVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {isError &&
          <Alert type="error" message={messageText} banner />
        }

        <DebounceSelect
          mode="single"
          name="author"
          placeholder="Select Author"
          fetchOptions={getAuthorAutoSuggestion}
          showSearch={true}
          style={{ width: '100%' }}
          onChange={(newValue) => {
            setAuthor(newValue);
            console.log(newValue);
          }}
        />
      </Modal>

      <Col span={24}>
        <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={instituteList} pagination={false} />
      </Col>
      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          pageSize={pageSize}
          total={total}
          current={page}
        />
      </Col>
    </Row>

  );
};

export default InstituteListing;
