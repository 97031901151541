import React, { useState } from 'react';
import { Row, Col, Input, Select, Checkbox, Form, Upload, Button, message, Modal } from "antd";
import { CustomEditor } from '../../../components/CustomEditor/';
import DescriptionPopUp from './DescriptionPopUp';


const CollegeListing = ({content, setContent, isAddMode, instituteID}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
		setIsModalOpen(true);
	  };
      
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <Row>
      <Col span={24}>
        <div className="gx-p-2">
          <CustomEditor content={content} setContent={setContent} />
        </div>
      </Col>
      {!isAddMode &&
					<Col>
          <div className="gx-p-4">
					<Button type="primary" onClick={showModal}> Add Stream Description</Button>
          </div>
						<Modal title="Add Stream Wise Description" visible={isModalOpen} footer={null} width={1000} onCancel={handleCancel} zIndex={1}>
							<DescriptionPopUp instituteID={instituteID}/>
						</Modal>
            
					</Col>}
    </Row>
  )
}
export default CollegeListing
