import React from 'react';
import { Row, Col, Form,Checkbox, Input, Select} from "antd";
import DebounceSelect from '../../../components/DebounceSelect'
import { getCourseList} from '../../../services/Course/Course';
import { getCourseMode,getDurationType,getCourseType,getCourseAttribute, getFeeType, getCurrency, getProgram, getShift, getObjectStatus, getOptions } from "../../../util/helpers";
import { COURSE_ADMISSION_STATUS } from '../../../constants/CollegeDekho';
import { getExamList } from '../../../services/CourseDetailApi/AutoSuggestion';
const courseModeOptions =  getCourseMode()
const durationTypeOptions =  getDurationType()
const courseTypeOptions =  getCourseType()
const courseAttributeOptions =  getCourseAttribute()
const feeTypeOptions =  getFeeType()
const currenyOptions =  getCurrency()
const programOption =  getProgram()
const shiftOptions =  getShift()
const objectStatusOptions =  getObjectStatus()
const courseAdmissionStatusOptions = getOptions(COURSE_ADMISSION_STATUS)



const CourseDetail=({institutePartnerStatus, form,feeCurrRequired})=>{

const numberValidator = (rule, value, callback) => {
	// Always return a callback, otherwise validateFields cannot respond
	if (value > 400000) {
		return Promise.reject(
			"Number too big"
			//When i delete that row its fix warning, but i need have that error message.
		  );
	}


	//callback();
	return Promise.resolve()
  }

 	return(
		   <Row>
			   <Col span={12}>
				    <Form.Item label="Course Name" name="name" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'Enter course name',
			              },
						  { type: 'string', max: 1000 },
			       ]}>
					   <Input className="gx-mb-3" />
					   </Form.Item>
				</Col>
				<Col span={12}>
				    <Form.Item label="Course" name="course" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'Course Required',
			              },
			       ]}>
					 <DebounceSelect
					 allowClear = {true}
                     mode="single"
					 name="course"
                     placeholder="Select Course"
                     fetchOptions={getCourseList}
					 showSearch = {true}
                  />

					   </Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Mode" name="mode" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'Select mode',
			              },
			       ]}>

					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Mode Required"
						options={courseModeOptions}
					>
                    </Select>

					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Duration" name="duration" className="labelInput"
							rules={[
							{
								required: true,
								message: 'Enter Duration',
							},


					]}>
						<Input className="gx-mb-3" name="duration"/>
					</Form.Item>
				</Col>
				<Col span={8}>
				<Form.Item label="Duration Type" name="duration_type" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'Select Duration Type',
			              },
			       ]}>

					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Duration Type"
						options={durationTypeOptions}
					>
                    </Select>


					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Fee" name="fee" className="labelInput" >
						<Input className="gx-mb-3"/>
					</Form.Item>
				</Col>
				<Col span={8}>
				<Form.Item label="Fee Type" name="fee_type" className="labelInput" rules={[
			              {
			                required: feeCurrRequired,
			                message: 'Fee Type Required',
			              },
			      ]}>

					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Fee Type"
						options={feeTypeOptions}
					>
                    </Select>

					</Form.Item>
				</Col>
				<Col span={8}>
				<Form.Item label="Fee Currency" name="fee_currency" className="labelInput" rules={[
			              {
			                required: feeCurrRequired,
			                message: 'Fee Currency Required',
			              },
			      ]}>

					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Fee Currency"
						options={currenyOptions}
					>
                    </Select>

					</Form.Item>
				</Col>

				<Col span={8}>
         <Form.Item label="Total Course Fee" name="total_course_fee" className="labelInput" >
             <Input className="gx-mb-3"/>
         </Form.Item>
       </Col>
	   <Col span={8}>
          <Form.Item label="Course Fee Currency" name="total_course_fee_currency" className="labelInput" >


          <Select
              allowClear={true}
              style={{width: '100%'}}
              placeholder="Select Fee Currency"
              options={currenyOptions}
          >
          </Select>

          </Form.Item>
       </Col>

	   <Col span={8}>
          <Form.Item label="Program Type" name="program_type" className="labelInput" >


          <Select
              allowClear={true}
              style={{width: '100%'}}
              placeholder="Select Program"
              options={programOption}
          >
          </Select>

          </Form.Item>
       </Col>

	   {
			institutePartnerStatus && <Col span={8}>
			<Form.Item label="Course Admission status" name="admission_status" className="labelInput" >


			<Select
				allowClear={true}
				style={{width: '100%'}}
				placeholder="Select Status"
				options={courseAdmissionStatusOptions}
			>
			</Select>

			</Form.Item>
			</Col>
		}

	   <Col span={8}>
					<Form.Item 
					label="Course Seat Filled" 
					name="seats_filled" 
					className="labelInput" 
					>
					<Input className="gx-mb-3" type='number' name='seats_filled' />

					</Form.Item>
				</Col>
	   <Col span={8}></Col>

       <Col span={8}>
         <Form.Item label="Tuition Fee" name="tuition_fees_on_campus" className="labelInput" >
             <Input className="gx-mb-3"/>
         </Form.Item>
       </Col>
       <Col span={8}>
          <Form.Item label="Tuition Fee Type" name="tuition_fee_type" className="labelInput" >

          <Select
             allowClear={true}
             style={{width: '100%'}}
             placeholder="Select Fee Type"
             options={feeTypeOptions}
           >
          </Select>

          </Form.Item>
       </Col>
       <Col span={8}>
          <Form.Item label="Tuition Fee Currency" name="tuition_fee_currency" className="labelInput" >

          <Select
              allowClear={true}
              style={{width: '100%'}}
              placeholder="Select Fee Currency"
              options={currenyOptions}
          >
          </Select>

          </Form.Item>
       </Col>

				<Col span={8}>
					<Form.Item label="Total Students" name="total_student" className="labelInput" rules={[
						  {
							  validator:numberValidator
						  }
			       ]}>
						<Input className="gx-mb-3" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Actual Intake" name="actual_intake" className="labelInput" rules={[
						  {
							  validator:numberValidator
						  }
			       ]}>
						<Input className="gx-mb-3" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Gender Ratio" name="gender_ratio" className="labelInput">
						<Input className="gx-mb-3" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Course Attribute" name="course_attribute" className="labelInput">
					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Course Attribute"
						options={courseAttributeOptions}
					>
                    </Select>

					</Form.Item>
				</Col>
				<Col span={8}>
				<Form.Item label="Course Type" name="course_type" className="labelInput">
					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Course Attribute"
						options={courseTypeOptions}
					>
                    </Select>

					</Form.Item>
				</Col>
				<Col span={8}>
				<Form.Item label="Shift" name="shift" className="labelInput">
					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Shift Type"
						options={shiftOptions}
					>
                    </Select>
					</Form.Item>
				</Col>
				<Col span={8}>
				<Form.Item label="Exams Accepted" name="exam_accepted" className="labelInput"
				>

				<DebounceSelect
                     mode="multiple"
                     placeholder="Exams Accepted"
                     fetchOptions={getExamList}
					 showSearch = {true}
                  />
				</Form.Item>
                </Col>
				<Col span={8}>
				<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'Object Status',
			              },
			       ]} >
					<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Object Status"
						options={objectStatusOptions}
					>
                    </Select>
					</Form.Item>
				</Col>

				<Col span={8}>
				<Form.Item label=" " colon={false} name="exchange_program" className="labelInput" valuePropName="checked">

					<Checkbox className="gx-mb-3" >Foreign Exchange Program</Checkbox>
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item label="Commission Amount (₹)" name="commission_amount" className="labelInput" >
						<Input className="gx-mb-6"/>
					</Form.Item>
				</Col>

			</Row>
		)
}

export default CourseDetail
