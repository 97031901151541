import React from 'react';
import {Card, Row, Col, Form, Button, Input, Select, Space} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getInstituteSpecializationList } from "../../../services/Specialization/Specialization";
import { getObjectStatus } from "../../../util/helpers";
import DebounceSelect from '../../../components/DebounceSelect'

const objectStatusOptions = getObjectStatus()

const FacultyDetailForm=({form,instituteID,isAddMode})=>{

  const phoneNoValidator1 = (rule, value, callback) => {
	  if(value) {
	     if (!(['6','7','8','9'].includes(value[0]))) {
	        return Promise.reject("Phone No should start with 6,7,8,9");
	     }
	  }
	  return Promise.resolve()
	}
	const phoneNoValidator2 = (rule, value, callback) => {
	  if(value) {
     	let regex = "^[0-9]{10}$"
      let result = value.match(regex)
	    if (!result) {
	       return Promise.reject("Enter a valid 10 digit mobile number");
	    }
    }
    return Promise.resolve()
  }

	return (
		<Row>
			<Col span={24}>
				<div>
					<Form.List name="add_faculty">
						{(fields, { add, remove }) => (
							<>
							{fields.map((field) => (
							<Card>
							<Space key={field.key} style={{ display: "flex", marginBottom: 8 }}  align="baseline">
							<Row>
								<Col span={6}>
									<Form.Item
										{...field}
										className="labelInput"
										name={[field.name, "name"]}
										label = {[field.label, "Name"]}
										fieldKey={[field.fieldKey, "name"]}
										rules = {[{
											required: true,
											message: 'Enter name',
										}]}>
										<Input className="gx-mb-3"/>
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item {...field}
										className="labelInput"
										name={[field.name, "phone"]}
										label={[field.label, "Phone"]}
										fieldKey={[field.fieldKey, "phone"]}
										rules={[
										{
										     validator:phoneNoValidator1,
										     validateTrigger: 'onChange'
										},
										{
							           validator:phoneNoValidator2,
							           validateTrigger: 'onSubmit'
						        }
						        ]}
						        >
										<Input className="gx-mb-3"/>
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item {...field}
										className="labelInput"
										label={[field.label, "Department"]}
										name={[field.name, "department"]}
										fieldKey={[field.fieldKey, "department"]}
										>

										<Input className="gx-mb-3"/>
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item {...field}
										className="labelInput"
										label={[field.label, "Designation"]}
										name={[field.name, "designation"]}
										fieldKey={[field.fieldKey, "designation"]}
										rules={[{
											required: true,
											message: 'Enter designation',
										},
										]}>

										<Input className="gx-mb-3"/>
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item {...field}
										className="labelInput"
										label={[field.label, "Specialization"]}
										name={[field.name, "specialization"]}
										fieldKey={[field.fieldKey, "specialization"]}>

										<DebounceSelect
											mode="single"
										  name="specialization"
											placeholder="Select Specialization"
											cldID={instituteID}
											fetchOptions={getInstituteSpecializationList}
											showSearch = {true}
											allowClear = {true}
										 />
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item {...field}
										className="labelInput"
										label={[field.label, "Education Qualification"]}
										name={[field.name, "education_qualification"]}
										fieldKey={[field.fieldKey, "education_qualification"]}>
										<Input className="gx-mb-3" />
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item {...field}
										className="labelInput"
										label={[field.label, "Years of Work Experience"]}
										name={[field.name, "years_of_work_experience"]}
										fieldKey={[field.fieldKey, "years_of_work_experience"]}>
										<Input className="gx-mb-3" />
									</Form.Item>
								</Col>

								<Col span={6}>
									<Form.Item {...field}
										label={[field.label, "Object Status"]}
										name={[field.name, "object_status"]}
										fieldKey={[field.fieldKey, "object_status"]}
										className="labelInput">
										<Select
										allowClear={true}
										style={{width: '100%'}}
										placeholder="Select object status"
										options={objectStatusOptions}
										>
										</Select>

									</Form.Item>
								</Col>


								{isAddMode &&
								<Col span={6}>
									<Form.Item className="labelInput">
										<Button type="primary" onClick={() => remove(field.name)} block icon={<MinusCircleOutlined/>}> Remove</Button>
									</Form.Item>
								</Col>
								}
								</Row>
								</Space>
								</Card>
								))}
								{isAddMode &&
									<Col span={8}>
										<Form.Item>
											<Button type="primary" onClick={() => add()} block icon={<PlusOutlined/>}> Add More</Button>
										</Form.Item>
									</Col>
								}
							</>
						)}
					</Form.List>
				</div>
			</Col>
		</Row>
  	)
};

export default FacultyDetailForm;
