import { getAutoSuggestionList,get } from "../index";

export  function getInstituteList(data) 
{
    console.log("data",data);
    const url =  `/api/cms/institute/`;
    console.log(url)
    let city = []
    let state = []
    if(data.city)
    {
       city=data.city.map((item) => { return item.value })
    }
    if(data.state)
    {
       state=data.state.map((item) => {return item.value })
    }
    delete data.city
    data.city_id = city.join()
    delete data.state
    data.state_id = state.join()
    data.page_size = data.pageSize
    delete data.pageSize
    data.partner_status = data.partnerStatus
    delete data.partnerStatus
    return get(url,data)

}

export async function getInstituteAutoSuggestion(text) {
    console.log('fetching user', text);
    const url =  `/api/cms/autosuggestion/institute/?q=${text}`;
    return await getAutoSuggestionList(url)
  } 
  

  export async function getAuthorAutoSuggestion(text) {
   console.log('fetching user', text);
   const url =  `/api/cms/autosuggestion/author/?text=${text}`;
   return await getAutoSuggestionList(url)
 } 
