import React from 'react';
import {Input, Col, Row, Form} from "antd";

const ContactDetails=()=>{
	
	return(
		<Row>
			<Col span={24}>
				<Form.Item label="Address" name="address" className="labelInput"
				rules={[{ type: 'string', max: 1000 }]}
				>
				<Input className="gx-mb-3"/>
				</Form.Item>
			</Col>
			<Col span={8}> 
			    <Form.Item label="Latitude" name="latitude" className="labelInput">
				<Input
				 className="gx-mb-3"
				 name="latitude" />
				 </Form.Item>
			</Col>
			<Col span={8}> 
			 	<Form.Item label="Longitude" name="longitude" className="labelInput">
				<Input
				className="gx-mb-3"
				name="longitude"/>
				</Form.Item>
			</Col>
			<Col span={8}> 
				<Form.Item label="Website" name="website" className="labelInput">
				<Input className="gx-mb-3" placeholder="Url" />
				</Form.Item>
			</Col>
			<Col span={8}> 
				<Form.Item label="Phone No" name="phone_no" className="labelInput">
				<Input
				className="gx-mb-3"
				name="phone_no"/>
				</Form.Item>
			</Col>
			<Col span={8}> 
				<Form.Item label="Pin Code" name="pin_code" className="labelInput">
				<Input
				className="gx-mb-3"
				name="pin_code"/>
				</Form.Item>
			</Col>
			<Col span={8}> 
				<Form.Item label="Fax" name="fax_numbers" className="labelInput">
				<Input  
				className="gx-mb-3"
				name="fax_numbers"/>
				</Form.Item>
			</Col>
			<Col span={8}> 
				<Form.Item label="Email" name="email_address" className="labelInput" 
				rules={[{ 
					type: 'email' 
				}]}
				>
				<Input className="gx-mb-3" placeholder="Email" 
				 name="email_address"/>
				 </Form.Item>
			</Col>
		</Row>
	)
}

export default ContactDetails