import axios from "axios"
import moment from "moment";

export async function getLogSuggestionList(url) {
    // url = BASE_URL + url
    return axios.get(url,
      // { headers: getHeaders() }
      )
      .then((response) =>
        {
            // console.log(response);
            return response.data.result.map(({id, added_on}) => ({
                label: `${moment(added_on).format("DD/MM/YYYY hh:mm:ss A")}`,
                value: id,
              }))
        }
      ).catch(err => console.log(err));
  }

export async function getLogDetail(url) {
   return await axios.get(url)
}
