import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Form,
  Upload,
  Button,
  message,
  Modal,
} from "antd";
import DebounceSelect from "../../../components/DebounceSelect/index";
import { UploadOutlined } from "@ant-design/icons";
import { getGender, getSizeType } from "../../../util/helpers";
import { getUserCategoryList } from "../../../services/UserCategory/UserCategory";
import {
  ALLOW_IMAGE_TYPE,
  ALLOW_DOCUMENT_TYPE,
} from "../../../constants/CollegeDekho";
import Cropper from "react-easy-crop";

import getCroppedImg from "./cropUtils";

const gender = getGender();
const sizeType = getSizeType()



const ExtraDetails = ({ form, sizeTypeRequired }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [ImgSrc, setImgSrc] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [cropperVisible, setCropperVisible] = useState(true);

  useEffect(() => {
    console.log("image source is " + ImgSrc);
    console.log("Is Cropper visible" , cropperVisible);
  }, [ImgSrc]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setCropperVisible(true);
    return e && e.fileList;
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCancelCrop = (e) => {
    //e.preventDefault();
    setCropperVisible(false);
  };
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onCrop = async (e) => {
    if (!croppedArea || !ImgSrc) {
      return;
    }


    const croppedImageCanvas = await getCroppedImg(ImgSrc, croppedArea);
    const croppedImageData = croppedImageCanvas.toDataURL("image/jpeg");
    const fileList = base64ToFile(croppedImageData);

    form.setFieldsValue({ banner: fileList });
    setCropperVisible(false, () =>{

    });
  };

  function base64ToFile(
    base64Data,
    fileName = "image.jpg",
    fileType = "image/jpeg",
    uid = "rc-upload-1713348873181-6"
  ) {
    // Convert base64 data URL to Blob
    const byteString = atob(base64Data.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: fileType });

    // Create File object
    const file = new File([blob], fileName, { type: fileType });

    // Prepare the structure to match the format provided
    return [
      {
        uid,
        originFileObj: file,
        percent: 0,
        size: file.size,
        type: file.type,
      },
    ];
  }

  const beforeLogoUpload = (file) => {
    const isJpgOrPng = ALLOW_IMAGE_TYPE.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }

    return false;
  };
  const beforeProspectusUpload = (file) => {
    const isJpgOrPng = ALLOW_DOCUMENT_TYPE.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    return false;
  };
  const beforeETPBrochureUpload = (file) => {
    const isJpgOrPng = ALLOW_DOCUMENT_TYPE.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const beforeBannerUpload = async (file) => {
    const isJpgOrPng = ALLOW_DOCUMENT_TYPE.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }


    setImgSrc(URL.createObjectURL(file));
    // console.log("Image source is ", ImgSrc);
    return false; // Returning false to prevent default Upload behavior
  };



  const clearError = (rule, value, callback) => {
    return Promise.resolve();
  };

  return (
    <>
      <Row>
      <Col span={8}>
          <Form.Item
            label="Campus Size"
            name="size"
            className="labelInput"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input className="gx-mb-3" name="size" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Campus Size Type"
            name="size_type"
            className="labelInput"
            rules={[
              {
                required: sizeTypeRequired,
                message: 'Size Type Required',
              },
            ]}
          >
            <Select
              placeholder="Select Size Type"
              style={{ width: "100%" }}
              className="gx-mb-4"
              options={sizeType}
            ></Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Acceptance Rate"
            name="acceptance_rate"
            className="labelInput"
            rules={[
              {
                validator: clearError,
              },
            ]}
          >
            <Input className="gx-mb-3" name="acceptance_rate" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Faculty Ratio"
            name="student_faculty_ratio"
            className="labelInput"
            rules={[
              {
                message: "Faculty Ratio",
              },
            ]}
          >
            <Input className="gx-mb-3" name="student_faculty_ratio" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Minority"
            name="minority_college"
            className="labelInput"
            rules={[
              {
                required: false,
                message: "Minority",
              },
            ]}
          >
            <DebounceSelect
              mode="multiple"
              name="minority_college"
              placeholder="Select category"
              fetchOptions={getUserCategoryList}
              showSearch={true}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Gender"
            name="gender"
            className="labelInput"
            rules={[
              {
                required: false,
                message: "Gender",
              },
            ]}
          >
            <Select
              placeholder="Select Gender"
              style={{ width: "100%" }}
              className="gx-mb-4"
              options={gender}
            ></Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Gender Ratio"
            name="gender_ratio"
            className="labelInput"
            rules={[
              {
                required: false,
                message: "Gender Ratio",
              },
            ]}
          >
            <Input className="gx-mb-3" name="gender_ratio" />
          </Form.Item>
        </Col>
        <Col span={8}>
					<Form.Item
            label="Total Faculty"
            name="number_of_teaching_faculty"
            className="labelInput"
            rules={[
              {
                required: false,
              },
            ]}
					>
					<Input className="gx-mb-3" type='number' min="0" name='number_of_teaching_faculty' />

					</Form.Item>
				</Col>
				<Col span={8}>
          <Form.Item
            label=" "
            colon={false}
            name="is_autonomous"
            className="labelInput"
            rules={[
              {
                required: false,
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox className="gx-mb-3" name="is_autonomous">
              Autonomous
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label=" "
            colon={false}
            name="minority_non_minority"
            className="labelInput"
            rules={[
              {
                required: false,
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox className="gx-mb-3" name="minority_non_minority">
              Minority
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label=" "
            colon={false}
            name="foreign_exchange_program"
            className="labelInput"
            rules={[
              {
                required: false,
                message: "Foreign Exchange Program",
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox className="gx-mb-3" name="foreign_exchange_program">
              Foreign Exchange Program
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            className="labelInput"
            name="logo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="logo"
              maxCount={1}
              beforeUpload={beforeLogoUpload}
              listType="picture"
              className="upload-list-inline"
            >
              <Button icon={<UploadOutlined />}>Logo</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="labelInput"
            name="prospectus"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="prospectus"
              maxCount={1}
              beforeUpload={beforeProspectusUpload}
              listType="picture"
              className="upload-list-inline"
            >
              <Button icon={<UploadOutlined />}>Prospectus</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="labelInput"
            name="etp_brochure"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="etp_brochure"
              maxCount={1}
              beforeUpload={beforeETPBrochureUpload}
              listType="picture"
              className="upload-list-inline"
            >
              <Button icon={<UploadOutlined />}>ETP Brochure</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            className="labelInput"
            name="etp_logo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            initialValue={[]}
          >
            <Upload
              name="etp_logo"
              maxCount={1}
              beforeUpload={beforeLogoUpload}
              listType="picture"
              className="upload-list-inline"
            >
              <Button icon={<UploadOutlined />}>ETP Logo</Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            className="labelInput"
            name="banner"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
<Upload
            name="banner"
            maxCount={1}
            beforeUpload={beforeBannerUpload}
            listType="picture"
            className="upload-list-inline"

          >
            <Button icon={<UploadOutlined />} >Banner</Button>
          </Upload>
        </Form.Item>

        { ImgSrc && cropperVisible && (
          <Modal
            title="Crop Image"
            visible={cropperVisible}
            onCancel={onCancelCrop}
            footer={[
              // <Button key="cancel" onClick={onCancelCrop}>
              //   Cancel
              // </Button>,
              <Button key="crop" type="primary" onClick={onCrop}>
                Crop
              </Button>,
            ]}
             //style={{ top: "40vh" }}


        >
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}

          > */}

              <Cropper
                image={ImgSrc}
                crop={crop}
                aspect={4 / 3}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                // style={{
                //   position: "absolute",
                //   top: 0,
                //   left: 0,
                //   width: "100%",
                //   height: "100%",
                // }}
              />
            {/* </div> */}

          </Modal>
        )}
      </Col>
    </Row>
    </>
  );
};
export default ExtraDetails;
