import {  Row, Col, Form,Input, Select, DatePicker } from "antd";
import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useFetchData } from "../../../hooks/";
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {API_URL_LIST} from '../../../constants/CollegeDekho'
import { getObjectStatus } from "../../../util/helpers";


let objectStatusOptions =  getObjectStatus(true)




const LatestUpdateDetailForm = ({form}) => {

    const { instituteID } = useParams();
	let url = API_URL_LIST["INSTITUTE_PAGE_LISTING"]
	url = url.replace("#id#",instituteID)
	const [data,loading,error] = useFetchData(url)
	const [institutePage,setInstitutePage] = useState([])
	console.log("type of", typeof setContent)

	useEffect(() => {
		let mounted = true;
		if(mounted)
		{
		   if(data)
		   {
			   let items = data.results.map((item) => { return {value:item.id,label:item.master_page.name} } )
               setInstitutePage(items)
		   }

		}


		return () => mounted = false;
	}, [data])





	return (
		<Row>

			<Col span={6}>
				<Form.Item label="College Tab" name="object_id" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Select page',
						},
					]}>
                  <Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Page"
                        options={institutePage}
						labelInValue = {true}
					    >
                    </Select>


				</Form.Item>
			</Col>
			<Col span={6}>
				<Form.Item label="Start Date" name="date" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Enter Date',
						},
					]}>
					<DatePicker showTime />
				</Form.Item>
			</Col>
			<Col span={6}>
				<Form.Item label="End Date" name="end_date" className="labelInput"
					rules={[
						{
							required: false,
							message: 'Enter End Date',
						},
					]}>

					<DatePicker showTime />
				</Form.Item>
			</Col>
		    <Col span={6}>
				<Form.Item label="Schedule Date" name="schedule_date" className="labelInput"
					rules={[
						{
							required: false,
							message: 'Enter schedule Date',
						},
					]}>

					<DatePicker showTime />
				</Form.Item>
			</Col>
			<Col span={6}>
				<Form.Item label="Object Status" name="object_status" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Select Object Status',
						},
					]}>

					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Object status"
					    options={objectStatusOptions}
					>
					</Select>
				</Form.Item>
			</Col>
			<Col span={24} className="gx-mt-4 gx-mb-4">
				<Form.Item label="Heading" name="heading" className="labelInput" rules={[{ required: false }]}>
					<Input placeholder="Heading" />
				</Form.Item>
			</Col>


		</Row>
	)
}

export default LatestUpdateDetailForm
