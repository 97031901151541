import React from 'react';
import { Col, Form } from "antd";
import DebounceSelect from '../../../components/DebounceSelect'
import { getFacilityList } from "../../../services/InstituteFacility/InstituteFacility";



const FacilityDetailForm= ({form,instituteID}) => {
   return(
          <Col span={24}>
            <Form.Item label="Facilities" name="facility" className="labelInput">
               <DebounceSelect
              mode="multiple"
              name="facility"
              placeholder="Select facility"
              cldID={instituteID}
              fetchOptions={getFacilityList}
              showSearch = {true}
          />
            </Form.Item>
          </Col>
        )
    }

export default FacilityDetailForm