import React from 'react';
import { Row, Col, Form, Input, Select } from "antd";
import { getObjectStatus } from '../../../../util/helpers';
import DebounceSelect from '../../../../components/DebounceSelect';
import { getCategory } from '../../../../services/Scholarships/Category';

export const objectStatus = getObjectStatus()



const ScholarshipSubCategoryBasicDetails = ({ isAddMode }) => {


    return (
        <>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label="Sub Category Name"
                        name="name"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Category name required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="name" maxLength={255} />
                    </Form.Item>
                </Col>
                <Col span={12}>

                    <Form.Item
                        label="Sub Category Slug"
                        name="slug"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Category slug required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="slug" readOnly={!isAddMode} maxLength={255}/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                <Form.Item label="Category" name={"category"} className="labelInput">
                    <DebounceSelect
                        mode="single"
                        name={"category"}
                        placeholder="Select Category"
                        fetchOptions={getCategory}
                        showSearch={true}
                        allowClear={true}
                    />
                </Form.Item>
                </Col>

                <Col span={12}>

                    <Form.Item
                        label="Sub Category Filter Name"
                        name="filter_name"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Filter name required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="filter_name" maxLength={255}/>
                    </Form.Item>
                </Col>


                <Col span={8}>
                    <Form.Item
                        label="Object Status"
                        name="object_status"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Object status',
                            },
                        ]}>

                        <Select
                            placeholder="Object Status"
                            name="object_status"
                            style={{ width: '100%' }}
                            className="gx-mb-3"
                            options={objectStatus}>

                        </Select>

                    </Form.Item>
                </Col>


            </Row>



        </>
    )
}

export default ScholarshipSubCategoryBasicDetails;