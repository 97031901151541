import { getAutoSuggestionList } from "../index";


export async function getInstituteDegreeList(text,instituteID) {
    const url =  `/api/cms/institute/${instituteID}/degree/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
  }    


  export async function getDegreeList(text) {
    const url =  `/api/cms/course/degree/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
  }    



