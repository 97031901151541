import React from 'react';
import { Row, Col, Form,Input } from "antd";
const { TextArea } = Input;

const DegreeDetail=({form,instituteID})=>{
	return(
    <Row>
        <Col span={8}>
             <Form.Item label="Degree" name="degree_name" className="labelInput"
                   rules={[
                   {
                     required: false,
                     message: 'Degree',
                   },
                   { type: 'string', max: 254 },
            ]}>
                <Input className="gx-mb-3" disabled={true} />
             </Form.Item> 
         </Col>
         <Col span={8}>
             <Form.Item label="New Degree Name" name="name" className="labelInput" rules={[
                   
                   { type: 'string', max: 254 },
            ]}>
             <Input className="gx-mb-3" />
             </Form.Item> 
         </Col>
         
         <Col span={20}> 
         <Form.Item label="H1" name="heading" className="labelInput"
                   rules={[
                   
                   { type: 'string', max: 254 },
            ]}>
                <Input className="gx-mb-3"/>
             </Form.Item>
         </Col>
         <Col span={20}> 
             <Form.Item label="Title" name="title" className="labelInput" rules={[

                   { type: 'string', max: 254 },
            ]}>
                 <Input className="gx-mb-3" />
             </Form.Item> 
         </Col>
         <Col span={24}> 
             <Form.Item label="Meta Description" name="meta_description" className="labelInput" rules={[
                
                   
                   { type: 'string', max: 1000 },
            ]}>
                 
                 <TextArea className="gx-mb-3" rows={4}/>
             </Form.Item> 
         </Col>
         
     </Row>
 )
}


export default DegreeDetail;