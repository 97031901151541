import { Col, Row, Table, message } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { FormOutlined } from "@ant-design/icons"
import { getErrors } from "../../../util/helpers"
import { get } from "../../../services"
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb"
import { CONTENT_TYPE_ID } from "../../../constants/Settings"


const ScholarshipLanding = () => {

  const [scholarshipLandingPage, setScholarshipLandingPage] = useState([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)


  const columns = [
    {
      title: "id",
      dataIndex: "id",
    },
    {
      title: "title",
      dataIndex: "title",
    },
    {
      title: "slug",
      dataIndex: "slug",
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <span className="gx-link"><Link to={'/scholarship/landing/' + record.id + '/update/'}> <FormOutlined /></Link>
          </span>
        </span>

      ),
    },
    {
      title: 'FAQ',
      key: 'faq',
      render: (text, record) => (
        <span className="gx-link">
          <Link to={`/scholarship/landing/${record.id}/faqs/${CONTENT_TYPE_ID.scholarshipLandingPage}`}>
            <FormOutlined />
          </Link>
        </span>
      ),
    },
  ];



  useEffect(() => {
    setLoading(true)
    const url = "/api/cms/scholarship/landing"
    get(url).then((res) => {

      setScholarshipLandingPage(res.data.results)
      setLoading(false)
    }
    ).catch((res) => {
      const err = getErrors(res)
      setError(err.allError)

    })

  }, [])



  return (
    <Row>
      {error && error.length > 0 ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
      </Col>


      <Col span={24}>
        {scholarshipLandingPage?.length > 0 &&
          <Table
            className="gx-table-responsive"
            rowKey={"id"}
            dataSource={scholarshipLandingPage}
            columns={columns}
            loading={loading}
            pagination={false}
          />}
      </Col>
    </Row>
  )
}
export default ScholarshipLanding