import React from "react";
import { Route, Switch } from "react-router-dom";
import CompareLogs from "./compareLogs/CompareLogs";
import LogVersionListing from "./listingLogs/LogVersionListing";
import LogsListing from "./listingLogs/LogsListing";
import LogDetail from "./logDetail/LogDetail";
import FaqLogs from "../common/faqLogs";

const Logs = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={LogsListing} />
    <Route exact path={`${match.url}/faq-logs`} component={FaqLogs} />
    <Route exact path={`${match.url}/:templateId/:objectId`} component={LogVersionListing} />
    <Route exact path={`${match.url}/:templateId/:objectId/compare`} component={CompareLogs} />
    <Route exact path={`${match.url}/:templateId/:objectId/compare/:version1/:version2`} component={CompareLogs} />
    <Route exact path={`${match.url}/:templateId/:objectId/:logId`} component={LogDetail} />
  </Switch>
)

export default Logs
