import { getAutoSuggestionList } from "../index";


export async function getInstituteSpecializationList(text,instituteID) {
    const url =  `/api/cms/institute/${instituteID}/specialization/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
  }    



  export async function getSpecializationList(text) {
    const url =  `/api/cms/course/specialization/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
  }    
