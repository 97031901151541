import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../../components/CollegeDekho/NavBreadCrumb';
import InstituteDegreePageForm from './InstituteDegreePageForm';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../../constants/CollegeDekho'
import { getObject,getMultiObject } from '../../../../util/helpers';
import { isInt,getErrors } from '../../../../util/helpers';
import { useFetchData } from '../../../../hooks';
import { patch,post } from '../../../../services';
import { CustomEditor } from '../../../../components/CustomEditor';
import CircularProgress from "../../../../components/CircularProgress";
import { Spin } from 'antd';


const InstituteDegreePage = (props) => {
  const  [loader, setLoader] = useState(false);
    const locationData = props.location?.locationData
    const [form] = Form.useForm();
	  const { instituteID, degreeID, pageID } = useParams();
    

    const [content,setContent] = useState("")
    const [extraDetails, setExtraDetails] = useState(locationData !== 'undefined' ? locationData : null);

    let url = null;
    let saveURL = API_URL_LIST["INSTITUTE_DEGREE_PAGE"].replace("#instituteID#",instituteID).replace("#degreeID#", degreeID)


    const [data,loading,error] = useFetchData(saveURL)
    const [degreePageData, setDegreePageData] = useState()
    const history = useHistory()
    const [disabledSave,setDisabledSave] = useState(false)



    const setObject = (data) => {
      const obj = {
        "h1": data?.h1,
        "title": data?.title,
        "meta_description": data?.meta_description,
        "meta_keywords": data?.meta_keywords,
        "object_status": data?.object_status?.id
      }
      
      form.setFieldsValue(obj)

    }


    useEffect(() => {
      let mounted = true;
      if(mounted)
      {
         if(data)
         {
            setObject(data?.results[0])
            setDegreePageData(data.results[0])
            setExtraDetails(data.extra_details)
         }

      }


      return () => mounted = false;
  }, [data])


    const getCleanData = (data) =>
    {
      
          const temp = JSON.stringify(data)
          const cleanData = JSON.parse(temp);
          return cleanData
    }

    const crudErrorHandler = (error) =>
    {
        showError(error)

    }

    const patchData = (data) =>
    {
      setLoader(true);
      let degreePageUpdateUrl = API_URL_LIST["INSTITUTE_DEGREE_PAGE_UPDATE"].replace("#instituteID#", instituteID).replace("#degreeID#", degreeID).replace("#pageID#", degreePageData?.id)
      patch(degreePageUpdateUrl,data).then(function (response)
        {

          message.success(COMMON_SUCCESS_MESSAGE);
          history.push(`/institute/${instituteID}/degree`)

        })
        .catch(crudErrorHandler)
      .finally(() => {
      setDisabledSave(false)
      setLoader(false);
        });

    }
    
    const showError = (errors) =>
    {

      const error = getErrors(errors)
      if(error.allError.length>0)
        {
              message.error(error.allError.join());
        }
        form.setFields(error.serverError)


    }
    const onFinish=(e)=>
    {
      const data = form.getFieldsValue(true);
      console.log(data)
      const cleanData = getCleanData(data)
      setDisabledSave(true)
      patchData(cleanData)
    
    }



  return (
    <>
    {console.log(extraDetails, "EXTRAA ")}

          {loading ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}

	  {error ? message.error(error) : loader === true ? <center><Spin /></center> :
    
    <Row>
			<Col span={24}>
				<NavBreadCrumb page={extraDetails && extraDetails?.master_page?.name} page2={extraDetails && extraDetails?.component_name} breadCrumb={extraDetails?.institute}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">
                { extraDetails && extraDetails?.institute ?  `Degree Page of ${extraDetails?.institute?.name}, (${degreePageData?.institute_popular_degree?.name})`
                  : `Component Detail`
                }

                </h2>
						</div>
					</div>
				</div>
			</Col>


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
               <InstituteDegreePageForm form={form}/>
            </div>


            <div className="d-flex" style={{ justifyContent: 'left' }}>
            <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
          </div>
        </div>
			</Col>

		</Row>
   }
   </>
  );
};
export default InstituteDegreePage;
