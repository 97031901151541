import { Form, Input, Button, Space, Select,Checkbox, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React,{useState,useEffect} from "react";
import { useFetchData } from "../../../hooks";
import {API_URL_LIST} from '../../../constants/CollegeDekho'


const DynamicForm = () => {

    const url=API_URL_LIST["MASTER_PAGE_API_URL"]
    const [data,loading,error] = useFetchData(url)
    const [masterPages,setMasterPages] = useState([])


    useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {
			  const tempOptions=data.results.map((item) => {
                          return {"value":item.id,"label":item.name}
              })

              setMasterPages(tempOptions)


		  }

		}

		return () => mounted = false;
	}, [data])




  return (
    <div>


        <Form.List name="component_availability" >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (

                <Space
                  key={field.key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Col span={24}>
                  <Form.Item
                    {...field}
                    name={[field.name, "master_page"]}
                    fieldKey={[field.fieldKey, "master_page"]}
                    label={[field.label, "Master Page"]}
                    rules = {[{ required: true, message: 'Master Page required' }]}
                  >
                    <Select
                      allowClear={true}
                      style={{width: '100%'}}
                      placeholder="Select Master Page"
                                  options = {masterPages}
                        >

                        </Select>
                  </Form.Item>
                  </Col>
                  <Col span={24}>

                  <Form.Item
                    {...field}
                    name={[field.name, "order"]}
                    fieldKey={[field.fieldKey, "order"]}
                    label={[field.label, "Order"]}
                    rules = {[{ required: true, message: 'Order required' }]}
                  >
                    <Input placeholder="Order" />
                  </Form.Item>
                  </Col>
                  <Col span={24}>

                  <Form.Item
                    {...field}
                    name={[field.name, "is_mandatory"]}
                    fieldKey={[field.fieldKey, "is_mandatory"]}
                    label={[field.label, " "]}
                    colon={false}
                    valuePropName="checked"
                  >

                    <Checkbox className="gx-mb-3" >is mandatory</Checkbox>
                  </Form.Item>


                  </Col>


                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>

              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add More
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>


    </div>
  );
};

export default DynamicForm;
