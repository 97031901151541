import { Button, Col, Pagination, Row, Table, message } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { FormOutlined } from "@ant-design/icons"
import NavBreadCrumb from "../../../../components/CollegeDekho/NavBreadCrumb"
import { get } from "../../../../services"
import { getErrors } from "../../../../util/helpers"
import { PAGE_SIZE } from "../../../../constants/CollegeDekho"
import { CONTENT_TYPE_ID } from "../../../../constants/Settings"

const ScholarshipSubCategoryListing = () => {

  const [scholarshipCategories, setScholarshipCategories] = useState([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE)


  const columns = [
    {
      title: "name",
      dataIndex: "name",
    },
    {
      title: "slug",
      dataIndex: "slug",
    },
    {
      title: 'Status',
      key: 'object_status',
      render: (text, record) => (

        <span>{record?.object_status?.name}</span>

      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <span className="gx-link"><Link to={'/scholarship/subcategory/' + record.id + '/update/'}> <FormOutlined /></Link>
          </span>
        </span>

      ),
    },
    {
      title: 'FAQ',
      key: 'faq',
      render: (text, record) => (
        <span className="gx-link">
          <Link to={`/scholarship/subcategory/${record.id}/faqs/${CONTENT_TYPE_ID.scholarshipSubCategory}`}>
            <FormOutlined />
          </Link>
        </span>
      ),
    },
  ];



  useEffect(() => {
    setLoading(true)
    const url = "/api/cms/scholarship/subcategory?page=1"
    get(url).then((res) => {
      setScholarshipCategories(res.data.results)
      setLoading(false)
    }
    ).catch((res) => {
      const err = getErrors(res)
      setError(err.allError)

    })

  }, [])

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);

  }

  function onChange(page, PageSize) {
    setPage(page);
  }


  return (
    <Row>
      {error && error.length > 0 ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
      </Col>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Link to="/scholarship/subcategory/create/">
          <Button type="primary">
            <span>Add New Sub Category</span>
          </Button>
        </Link>

      </Col>


      <Col span={24}>
        {scholarshipCategories.length > 0 &&
          <Table
            className="gx-table-responsive"
            rowKey={"id"}
            dataSource={scholarshipCategories}
            columns={columns}
            loading={loading}
            pagination={false}
          />}
      </Col>

      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          pageSize={pageSize}
          // total={total}
          current={page}
        />
      </Col>
    </Row>
  )
}
export default ScholarshipSubCategoryListing