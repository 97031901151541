import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import DegreeDetail from './DegreeDetail'
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import { getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patch } from '../../../services/';
import { CustomEditor } from '../../../components/CustomEditor/';
import { Spin } from 'antd';


const InstituteDegreeDetail = () => {
    const [form] = Form.useForm();
    const { instituteID } = useParams();
    const { popularDegreeID } = useParams();
    const history = useHistory()
	const  [loader, setLoader] = useState(false);
	const [description, setDescription] = useState("");
	const [eligibilityCriteria, setEligibilityCriteria] = useState("");

	let saveURL = API_URL_LIST["INSTITUTE_POPULAR_DEGREE_DETAIL"].replace("#id#",instituteID)
    saveURL = saveURL.replace("#id1#",popularDegreeID)

	const [data,loading,error] = useFetchData(saveURL)
    const [instituteDegree,setInstituteDegree] = useState(null)
	const [institute,setInstitute] = useState(null)
	const [disabledSave,setDisabledSave] = useState(false)

	const setInstituteDegreeObj = (data) => {
        const instituteDegreeObj = {
			name: data.name,
			heading: data.heading,
			title: data.title,
			meta_description: data.meta_description,
			degree_name:data.degree?.name

		}
	   if(data.description)
	   {
		   setDescription(data.description)
	   }
	   data?.eligibility_criteria && setEligibilityCriteria(data.eligibility_criteria)
	   setInstituteDegree(instituteDegreeObj)
	   form.setFieldsValue(instituteDegreeObj)
	}

    useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {

			setInstituteDegreeObj(data)
			setInstitute(data.institute)

		  }
		}


		return () => mounted = false;
	}, [data])

	const getCleanData = (data) =>
	{
		const temp = JSON.stringify(data)
        const cleanData = JSON.parse(temp);
        for (let attr in cleanData) {
           if (cleanData[attr] === "") {
                console.log(attr)
                cleanData[attr] = null
           }
		}

		cleanData.description = description;
		cleanData.eligibility_criteria = eligibilityCriteria
		return cleanData

	}
	const crudErrorHandler = (error) =>
	{
			showError(error)
	}


	const patchData = (data) =>
	{
		setLoader(true);
	  patch(saveURL,data).then(function (response)
      {
		message.success(COMMON_SUCCESS_MESSAGE);
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);
      });

	}

	const showError = (errors) =>
	{
	    const error = getErrors(errors)
	    if(error.allError.length>0)
        {
            message.error(error.allError.join());
        }
        form.setFields(error.serverError)
	}

	const onFinish=(e)=>
	{
		const data = form.getFieldsValue(true);
		const cleanData = getCleanData(data)
		setDisabledSave(true)
		patchData(cleanData)

	}

  return (
	<>
	{error ? message.error(error) : loader === true ? <center><Spin /></center> :
	<Row>
	<Col span={24}>
		<NavBreadCrumb page={instituteDegree?.name}/>
		<div className="gx-pt-4">
			<div className="ant-card ant-card-bordered gx-card-widget">
				  <div className="ant-card-body">
				  <h2 className="gx-mb-0"> { institute ? `${institute?.name} (${instituteDegree?.name})` : `Degree Template` } </h2>
				</div>
			</div>
		</div>
	</Col>


	<Col span={24}>
<div className="ant-card ant-card-bordered gx-card">
<div className="ant-card-body">
  <Form onFinish={onFinish} form={form}>
	<div className="gx-p-2">
	  <DegreeDetail form={form} instituteID={instituteID}/>
	</div>

    <div className="gx-p-2">Description
		    <CustomEditor content={description} setContent={setDescription} />
    </div>

	<div className="gx-p-2">Eligibility Criteria
		    <CustomEditor content={eligibilityCriteria} setContent={setEligibilityCriteria} />
    </div>

	<div className="d-flex" style={{ justifyContent: 'left' }}>
	   <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
	</div>
  </Form>
  </div>
</div>
	</Col>

</Row>
 }
 </>

);
};
export default InstituteDegreeDetail;
