import React from "react";
import { Link } from 'react-router-dom';
import { COURSE_CONTENT_API_LIST } from "../../../constants/CollegeDekho";
import { useFetchData } from "../../../hooks/";
import { Divider, Row, Col, Table, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { FormOutlined } from "@ant-design/icons";

const columns = [{
  title: 'ID',
  dataIndex: 'id',

},
{
  title: 'Name',
  dataIndex: 'name',
},
{
  title: 'Component',
  dataIndex: 'component',
  render: (text, record) => (
    <span>
      {record.component?.name}
    </span>
  ),
},
{
  title: 'Status',
  dataIndex: 'object_status',
  render: (text, record) => (
    <span>
      {record.object_status?.name}
    </span>
  ),
},
{
  title: 'Action',
  key: 'action',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span className="gx-link"><Link to={`/course/component/${record.id}/update/`}> <FormOutlined /> </Link></span>
      <Divider type="vertical" />

    </span>
  ),
}
];



const ComponentListing = () => {


  const [data, loading, error] = useFetchData(COURSE_CONTENT_API_LIST['COMPONENT_LISTING'])


  return (
    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered gx-card-widget">
            <div className="ant-card-body">
              <h2 className="gx-mb-0">Component</h2>
            </div>
          </div>
        </div>
        <Row>
        </Row>
      </Col>

      <Col span={24}>


      </Col>

      <Col span={24} className="gx-mb-3">
        <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={data?.results} pagination={false} />
      </Col>

    </Row>
  );
};

export default ComponentListing;
