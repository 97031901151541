import {  getAutoSuggestionList } from "../index";

export async function getMasterPageList(text) {
    console.log('fetching', text);
    const url =  `/api/cms/institute/master-page/?text=${text}&page_size=100&page=1`;
    return await getAutoSuggestionList(url)
  }    

  export async function getCourseMasterPageList(text) {
    console.log('fetching', text);
    const url =  `/api/cms/course-details/course-master-page/?text=${text}&page_size=100&page=1`;
    return await getAutoSuggestionList(url)
  }    