import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, message, Button, Checkbox, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import { getImageType,getObjectStatus,getErrors} from "../../../util/helpers";
import { patch,postImage } from '../../../services/';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import { useFetchData } from "../../../hooks/";



const objectStatusOptions = getObjectStatus()
const imageTypeOptions = getImageType()

const ImageUploadForm=({isAddMode,imageID,instituteID,refreshPage})=>
{
    const [form] = Form.useForm();
    const history = useHistory()
	const [uploadedFileList,setUploadedFileList] = useState([])
	const [disabledSave,setDisabledSave] = useState(false)
	
	let url = null;
	let saveURL = API_URL_LIST["INSTITUTE_IMAGE_DETAIL_POST_URL"].replace("#id#",instituteID)
	

	if(!isAddMode)
	{
	  url=API_URL_LIST["INSTITUTE_IMAGE_DETAIL_URL"].replace("#id#",instituteID)
	  url = url.replace("#id1#",imageID)
	  saveURL = url
	  console.log(url)
	}
	const [data,loading,error] = useFetchData(url)
  const setInstituteImageObj = (data) => {
    console.log(data)
    const instituteImageObj = {
       object_status:data.object_status.id,
			 institute:data.institute
		}
		if(data.is_approved)
	    {
	         instituteImageObj["is_approved"] = "checked"
	     }
	  form.resetFields()
		form.setFieldsValue(instituteImageObj)
	}

  	useEffect(() => {
		let mounted = true;
		if(mounted)
		{
		  if(data)
		  {
			   console.log("data",data)
			   setInstituteImageObj(data)
		  }
		}
		return () => mounted = false;
	}, [data])

	const normFile = (e) =>
	{
     console.log('Upload event:', e);
      if (Array.isArray(e)) {
             return e;
      }
     return e && e.fileList;
  };

  const beforeUpload =(file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng)
		    {
             message.error("You can only upload JPG/PNG file!");
        }
		const tempFileList = [...uploadedFileList, file]
		    setUploadedFileList(tempFileList)
        return false
  }
		

	const getCleanData = (data) =>
	{
        const cleanData = {...data}
        if(data.institute) {
        cleanData.institute = data.institute.id}
        if(data.is_approved === "checked")
		    {
			      cleanData.is_approved = true;
		    }
        return cleanData
	}

	const crudErrorHandler = (error) =>
    {
        showError(error)

    }

  const showError = (errors) => {
     const error = getErrors(errors)
        if(error.allError.length>0)
        {
              message.error(error.allError.join());
        }
     console.log("server error",error.serverError)
     form.setFields(error.serverError)
  }

	const onFinish=(e)=>
	{
		const data = form.getFieldsValue(true);
		const cleanData = getCleanData(data)
		setDisabledSave(true)
		if(isAddMode)
	  {
		    postData(cleanData)
	  }
	  else
	  {
        patchData(cleanData)
	  }
	}
  const patchData = (data) =>
	{
	  console.log(data)
	  patch(saveURL,data).then(function (response)
    {
        refreshPage()
		    message.success(COMMON_SUCCESS_MESSAGE);
    })
      .catch(crudErrorHandler)
	       .finally(() => {
		         setDisabledSave(false)
         });
	}
	const postData = (data) =>
	{
		const formData = new FormData();
        uploadedFileList.forEach(file => {
             formData.append('image', file);
        });
		formData.append("image_type",data.image_type)
		if (data.object_status!=null) {
		formData.append("object_status",data.object_status) }
		if (data.is_approved === true) {
		formData.append("is_approved", data.is_approved)
		}
	  postImage(saveURL,formData)
	  .then(function (response)
      {
		     refreshPage()
		     message.success(COMMON_SUCCESS_MESSAGE);
         form.resetFields()
      })
      .catch(crudErrorHandler)
	     .finally(() => {
		      setDisabledSave(false)
       });
	}

	return(
	 <>
	{error ? message.error(error) :
	<>
		<Form onFinish={onFinish} form={form}>
		  <Row>
		   { isAddMode ? <>
			   <Col span={24}>
				    <Form.Item label="Tagging" name="image_type" className="labelInput"
					 	    rules={[
			              {
			                required: true,
			                message: 'Enter Tag',
			              },
			          ]}>
					          <Select
						            allowClear={true}
						            style={{width: '100%'}}
						            placeholder="Select Tag"
						            options={imageTypeOptions}>
                    </Select>
					  </Form.Item>
				</Col>
				<Col span={24}>
			   <Form.Item label=" " colon={false} name="is_approved" className="labelInput" valuePropName="checked">

				  <Checkbox className="gx-mb-3" name="is_approved">Approved</Checkbox>
				</Form.Item>
			</Col>
			  <Col span={24}>
				  <Form.Item
                name="image"
                label="Image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
						    rules={[
									{
										required: true,
										message: 'Image is required',
									},
							 ]}>
              <Upload name="logo" maxCount={1} beforeUpload={beforeUpload} listType="picture" >
                 <Button icon={<UploadOutlined />}>Click to upload</Button>
			</Upload>
			 

          </Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item label="Object Status" name="object_status" className="labelInput" >
						   <Select
						      allowClear={true}
						      style={{width: '100%'}}
						      placeholder="Select Object Status"
						      options={objectStatusOptions}>
             	 </Select>
					</Form.Item>
				</Col>
				</>
				:
				<>
				<Col span={24}>

					<Form.Item label="Object Status" name="object_status" className="labelInput" >
						   <Select
						      allowClear={true}
						      style={{width: '100%'}}
						      placeholder="Select Object Status"
						      options={objectStatusOptions}
						    >
             			  </Select>
					</Form.Item>
				</Col>
				<Col span={24}>
			   <Form.Item label="Approved" name="is_approved" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'Approved is required',
			              },
			       ]} valuePropName="checked">

				  <Checkbox className="gx-mb-3" name="is_approved">Approved</Checkbox>
				</Form.Item>
			</Col>
				 </> }
			</Row>
			<div className="d-flex" style={{ justifyContent: 'left' }}>

            <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
		</Form>
		</>
		}
		</>
		)
}

export default ImageUploadForm
