import React from 'react';
import { Row, Col, Form, Input, Checkbox, Select} from "antd";
import { getVideoType, getObjectStatus} from "../../../util/helpers";
import { validYoutubeId } from "../../../services/InstituteVideo/InstituteVideo";

const videoTypeOptions =  getVideoType()
const objectStatusOptions = getObjectStatus()


const VideoUploadForm=({form,isAddMode,instituteID})=>{

  const youtubeIdValidator = async (rule, value, callback) => {
	if(value) {
  let result = await validYoutubeId(value)
	if (result) {
	  return Promise.resolve()
	}
	return Promise.reject(
			"Invalid Youtube ID"
		  );
  }
  }

	return(
		   <Row>
		   { isAddMode ? <>
			   <Col span={24}>
				    <Form.Item label="Tagging" name="videotags" className="labelInput"
					 	    rules={[
			              {
			                required: true,
			                message: 'Enter Tag',
			              },
			      ]}>
					          <Select
						            allowClear={true}
						            style={{width: '100%'}}
						            placeholder="Select Tag"
						            options={videoTypeOptions}
					          >
                    </Select>
					  </Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item label="YouTube ID" name="youtube_id" className="labelInput"
					   rules={[
			              {
			                required: true,
			                message: 'Enter YouTube ID',
			              },
			              {
							        validator:youtubeIdValidator,
						        }
			      ]}>
						   <Input className="gx-mb-3" />
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item label="Object Status" name="object_status" className="labelInput">
						   <Select
						      allowClear={true}
						      style={{width: '100%'}}
						      placeholder="Select Object Status"
						      options={objectStatusOptions}
					     >
               </Select>
					</Form.Item>
				</Col>
				<Col span={24}>
			   <Form.Item label=" " colon={false} name="approved" className="labelInput" valuePropName="checked">

				  <Checkbox className="gx-mb-3" name="approved">Approved</Checkbox>
				</Form.Item>
			</Col>
				</>
				:
				<>
				<Col span={24}>
					<Form.Item label="Object Status" name="object_status" className="labelInput">
						   <Select
						      allowClear={true}
						      style={{width: '100%'}}
						      placeholder="Select Object Status"
						      options={objectStatusOptions}
					     >
               </Select>
					</Form.Item>
				</Col>
				<Col span={24}>
			   <Form.Item label="Approved" name="approved" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'Approved is required',
			              },
			       ]} valuePropName="checked">

				  <Checkbox className="gx-mb-3" name="approved">Approved</Checkbox>
				</Form.Item>
			</Col>
				</> }
			</Row>
		)
}

export default VideoUploadForm
