import { useEffect, useState } from "react";
import { Button, Select, Row, Col } from 'antd';
import { CustomEditor } from "../CustomEditor";


function StreamDescription({item, index , options, handleRemove, description}) {
    const [stream, setStream] = useState(item.stream);
    const [content, setContent] = useState(item.description);


    useEffect(()=>{

        description[index].stream = stream;
        description[index].description = content;   

    }, [stream, content])

    useEffect(()=>{
        setStream(description[index].stream);
        setContent(description[index].description);
    }, [description])
    
    return ( 
        <Row>
                <Col span={6}>
                    
                        <Select   style={{ width: 200 }} options={options} value={stream} onChange={(e)=> setStream(e)}></Select>
                    </Col>
                <Col span={15}>
                <div  className="gx-p-2">
                    <CustomEditor content={content} setContent={setContent}></CustomEditor>
                    </div></Col>
                    <Col span={3}>
                        <div className="gx-p-2">
                            <Button type="primary" onClick={(e)=>handleRemove(e, item.id, index)}>delete</Button>
                        </div>
                    </Col>

            </Row>
     );
}

export default StreamDescription;