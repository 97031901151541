import React from 'react';
import { Col, Form, Row, Input } from "antd";


const SlugRedirectionDetailForm= ({form}) => {
   return(
          <Row>
				<Col span={24}>
				<Form.Item label="Old URI Slug" name="old_slug" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'Old Slug Required',

			              },
						  { type: 'string', max: 255 }

			       ]}>
					   <Input className="gx-mb-3" name="name"/>
				</Form.Item>
				</Col>
				<Col span={24}>
					 <Form.Item label="New URI Slug" name="new_slug" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message:'New Slug Required',
			              },
						  { type: 'string', max: 255 }
			       ]}>
					<Input className="gx-mb-3"/>
					 </Form.Item>
				</Col>
				</Row>
        )
    }

export default SlugRedirectionDetailForm
