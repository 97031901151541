import { Button, Col, Collapse, Form, Row, message } from "antd"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import ScholarshipBasicDetails from "./ScholarshipBasicDetails"
import ScholarshipSEODetails from "./ScholarshipSEODetails"
import { getErrors, getMultiObject, getObject, isInt } from "../../../util/helpers"
import { useFetchData } from "../../../hooks"
import { useEffect, useState } from "react"
import ScholarshipCategoriesForm from "./ScholarshipCategoriesForm"
import { get, patch, post } from "../../../services"
import { COMMON_SUCCESS_MESSAGE, DATE_FORMAT, DATE_TIME_FORMAT, SCHOLARSHIP_API_LIST } from "../../../constants/CollegeDekho"
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb"
import moment from "moment"
import { CustomEditor } from "../../../components/CustomEditor"


const ScholarshipDetail = () => {

    const [listingPageContent, setListingPageContent] = useState("")
    const [breadcrumbCategoryOptions, setBreadcrumbCategoryOptions] = useState([])
    const [breadcrumbSubCategoryOptions, setBreadcrumbSubCategoryOptions] = useState([])

    const { scholarshipID,ID } = useParams()
    const dashboardTypeID = scholarshipID ? scholarshipID : ID
    const scholarshipUrl = SCHOLARSHIP_API_LIST["SCHOLARSHIP_DETAIL_PAGE_URL"].replace("#id#", dashboardTypeID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(scholarshipUrl)

    const isAddMode = !isInt(scholarshipID)
    let url = null
    let saveURL = '/api/cms/scholarship/'
    if (!isAddMode) {
        url = `/api/cms/scholarship/${scholarshipID}/`
        saveURL = url
    }
    const [data, loading, error] = useFetchData(url)
    const history = useHistory()
    // const [data, loading, error] = useFetchData(url)
    const Panel = Collapse.Panel

    const handleCollapse = (key) => { }


    const [form] = Form.useForm()
 

    const setScholarshipObj = (data) => {
        const scholarshipData = {
            name: data.name,
            slug: data.slug,
            awarding_body: data.awarding_body,
            scholarship_min_amount: data.scholarship_min_amount,
            scholarship_max_amount: data.scholarship_max_amount,
            is_popular: data.is_popular,
            is_related: data.is_related,
            is_visible_on_landing_page: data.is_visible_on_landing_page,
            states: getMultiObject(data?.states),
            streams: getMultiObject(data?.streams),
            degrees: getMultiObject(data?.degrees),
            h1: data?.h1 || "",
            page_title: data?.title,
            description: data?.description,
            keyword: data?.keyword || "",
            object_status: data?.object_status?.id,
            categories: getMultiObject(data?.categories),
            subcategories: getMultiObject(data.subcategories),
            breadcrumb_category: data.breadcrumb_category ? data.breadcrumb_category.id : null, 
            breadcrumb_subcategory: data.breadcrumb_subcategory ? data.breadcrumb_subcategory.id : null ,
            snippet: data?.snippet,
            application_start_date: data?.application_start_date ? moment(data?.application_start_date, DATE_FORMAT) : null,
            application_end_date: data?.application_end_date ? moment(data?.application_end_date, DATE_FORMAT) : null,
            show_start_month_only: data?.show_start_month_only,
            show_end_month_only: data?.show_end_month_only,
            listing_page_heading: data?.listing_page_heading,
            landing_page_heading: data?.landing_page_heading,
            is_tentative: data?.is_tentative,



        }

        form.setFieldsValue(scholarshipData)
        setListingPageContent(data?.listing_page_content)
        setBreadcrumbCategoryOptions(getMultiObject(data?.categories))
        setBreadcrumbSubCategoryOptions(getMultiObject(data?.subcategories))


    }

    useEffect(() => {
        
        if (data) { setScholarshipObj(data); }

    }, [data])


    const getCleanData = (data) => {
        const formData = new FormData();
        formData.append("name", data.name)
        formData.append("slug", data.slug)
        formData.append("is_popular", data?.is_popular || false)
        formData.append("is_related", data?.is_related || false)
        formData.append("is_visible_on_landing_page", data?.is_visible_on_landing_page || false)
        formData.append("object_status", data.object_status || 0)
        formData.append("landing_page_heading", data?.landing_page_heading || "")
        formData.append("listing_page_heading", data?.listing_page_heading || "")
        formData.append("listing_page_content", listingPageContent)
        formData.append("is_tentative", data?.is_tentative || false)
        formData.append("show_start_month_only", data?.show_start_month_only || false)
        formData.append("show_end_month_only", data?.show_end_month_only || false)
        
       
        
        data?.scholarship_min_amount && formData.append("scholarship_min_amount", data.scholarship_min_amount)
        data?.scholarship_max_amount && formData.append("scholarship_max_amount", data.scholarship_max_amount)
        data.awarding_body && formData.append("awarding_body", data.awarding_body)
        data?.snippet && formData.append("snippet", data?.snippet )
        data?.page_title && formData.append("title", data?.page_title)
        data?.description && formData.append("description", data?.description)
        data?.h1 && formData.append("h1",  data?.h1)
        data?.keyword  && formData.append("keyword", data?.keyword)

        
        
        let categories = []
        
        if (typeof data?.categories !== 'undefined') {
            categories = data?.categories.map((category) => { if (category.value) return category.value })

        }
        let subcategories = data?.subcategories || []
        if (data?.subcategories!=='undefined' && typeof data?.subcategories?.[0] == 'object') {
            subcategories = data?.subcategories.map((subcategory) => {
                if (subcategory.value) return subcategory.value
            })
        }
        
        formData.append("subcategories", JSON.stringify(subcategories))

        formData.append("categories", JSON.stringify(categories))

        let states = []
        if (data?.states?.length > 0) {
            states = data?.states.map((state) => { if (state.value) return state.value })
            formData.append("states", JSON.stringify(states))
        }
        let streams = []
        if (data?.streams?.length > 0) {
            streams = data?.streams.map((stream) => { if (stream.value) return stream.value })
            formData.append("streams", JSON.stringify(streams))
        }
        let degrees = []
        if (data?.degrees?.length > 0) {
            degrees = data?.degrees.map((degree) => { if (degree.value) return degree.value })
            formData.append("degrees", JSON.stringify(degrees))
        }
        if(data?.application_start_date){
            formData.append("application_start_date", data?.application_start_date.format(DATE_FORMAT))
        }
        if(data?.application_end_date){
            formData.append("application_end_date", data?.application_end_date.format(DATE_FORMAT))
        }

        

        if(data?.breadcrumb_category){
            
            if(typeof data?.breadcrumb_category == "object"){
                formData.append("breadcrumb_category", data?.breadcrumb_category?.value)
            }
            else{
                formData.append("breadcrumb_category", data?.breadcrumb_category)
            }
            
        }
        if(data?.breadcrumb_subcategory){
            if(typeof data?.breadcrumb_subcategory == "object"){
                formData.append("breadcrumb_subcategory", data?.breadcrumb_subcategory?.value)
            }
            else{
                formData.append("breadcrumb_subcategory", data?.breadcrumb_subcategory)
            }
            
        }
        
        
        return formData

    }

    const showError = (errors) => {

        const error = getErrors(errors)
        if (error.allError.length > 0) {
            message.error(error.allError.join());
        }

        form.setFields(error.serverError)

    }

    const crudErrorHandler = (error) => {
        showError(error)


    }
    const postData = (data) => {
        // setLoader(true);
        post(saveURL, data)
            .then(function (response) {

                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/scholarship/`)

            })
            .catch(crudErrorHandler)
            .finally(() => {

            });

    }

    const patchData = (data) => {
    
        patch(saveURL, data).then(function (response) {
            message.success(COMMON_SUCCESS_MESSAGE);
            history.push(`/scholarship/`)

        })
            .catch(crudErrorHandler)
            .finally(() => {

            });

    }

    const submitHandler = () => {
        const data = form.getFieldsValue(true);
        const formData = getCleanData(data)
        if (isAddMode) {
            postData(formData)
        }
        else {
            patchData(formData)
        }
        // const formdata = 
    }


    return (
        <>
        
        <Row>
            <Col span={24}>
                <NavBreadCrumb breadCrumb={breadCrumb} />
                <div className="gx-pt-4">
                    <div className="ant-card ant-card-bordered gx-card-widget">
                        <div className="ant-card-body">
                            <h2 className="gx-mb-0">
                               { isAddMode ? 'New Scholarship' : data?.name}
                            </h2>

                        </div>
                    </div>
                </div>
            </Col>
            <Col span={24}>

                <Form form={form} onFinish={submitHandler}>
                    <Collapse defaultActiveKey={['1', '2', '3', '4']} onChange={handleCollapse} className="collegeEditCollapse">
                        <Panel header="Basic Details" key={1}>
                            <div className="gx-p-2">
                                <ScholarshipBasicDetails isAddMode={isAddMode} form={form} />
                            </div>
                        </Panel>

                        <Panel header="SEO Details" key={2}>
                            <div className="gx-p-2">
                                <ScholarshipSEODetails />
                            </div>
                        </Panel>

                        <Panel header="Scholarship Category Details" key={3}>
                            <div className="gx-p-2">
                                <ScholarshipCategoriesForm 
                                breadcrumbCategoryOptions={breadcrumbCategoryOptions}
                                setBreadcrumbCategoryOptions={setBreadcrumbCategoryOptions} 
                                breadcrumbSubCategoryOptions={breadcrumbSubCategoryOptions}
                                setBreadcrumbSubCategoryOptions={setBreadcrumbSubCategoryOptions}
                                form={form} />

                            </div>
                        </Panel>

                        <Panel header="Listing Page Content" key={4}>
                            <div className="gx-p-2">
                                <CustomEditor content={listingPageContent} setContent={setListingPageContent} /> 

                            </div>
                        </Panel>

                    </Collapse>
                    <div className="d-flex">
                        <Button type="primary" htmlType="submit" > Save</Button>
                    </div>
                </Form>
            </Col>

        </Row>
        </>
    )

}
export default ScholarshipDetail