import React,{useState,useEffect} from "react";
import {  useParams,useHistory } from 'react-router-dom';
import {Row, Col,Button,Form,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { useFetchData } from "../../../hooks/";
import { patch,post } from '../../../services/';
import { isInt,getErrors } from '../../../util/helpers';
import { InstitutePageDetailForm } from "./InstitutePageDetailForm"
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import CircularProgress from "../../../components/CircularProgress";
import { Spin } from 'antd';



const InstitutePageDetail = () => {
    const  [loader, setLoader] = useState(false);
    const [form] = Form.useForm();
    const { instituteID } = useParams();
    const { instituteMasterPageID } = useParams();
    const [disabledSave,setDisabledSave] = useState(false)
    const history = useHistory()
    const  isAddMode = !isInt(instituteMasterPageID)
    let url = null;
    let saveURL = API_URL_LIST["INSTITUTE_PAGE_LISTING"]
    if(!isAddMode)
    {
        url = API_URL_LIST["INSTITUTE_PAGE_DETAIL"].replace("#id#",instituteID)
        url = url.replace("#id1#",instituteMasterPageID)
        saveURL = url
        console.log(url)
    }
    const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
    const [breadCrumb] = useFetchData(dashboardTypeDetailURL)
    const [data,loading,error] = useFetchData(url)
    const setInstitutePageObj = (data) => {
        const obj = {...data}

		obj.object_status = obj.object_status?.id
		form.setFieldsValue(obj)
	}


    useEffect(() => {
        let mounted = true;

        if(mounted)
        {
           if(data)
           {
              console.log("data",data)
              setInstitutePageObj(data)

           }

        }


        return () => mounted = false;
    }, [data])


    const getCleanData = (data) =>
    {
        const cleanData = {...data}
        delete cleanData.institute
        delete cleanData.master_page
        return cleanData
    }

    const crudErrorHandler = (error) =>
    {
        showError(error)

    }

    const patchData = (data) =>
    {
        setLoader(true);
            patch(saveURL,data).then(function (response)
            {
                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/institute/${instituteID}/page`)

            })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const postData = (data) =>
    {
        setLoader(true);
            post(saveURL,data)
            .then(function (response)
            {
                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/institute/${instituteID}/page`)

            })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const showError = (errors) => {

        const error = getErrors(errors)
        if(error.allError.length>0)
        {
              message.error(error.allError.join());
        }
        console.log("server error",error.serverError)
        form.setFields(error.serverError)


    }
    const onFinish=(e)=>
    {
        const data = form.getFieldsValue(true);
        const cleanData = getCleanData(data)
        setDisabledSave(true)
        if(isAddMode)
        {
            postData(cleanData)
        }
        else
        {
            patchData(cleanData)
        }
    }
    return (
        <>

           {loading ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}



        {error ? message.error(error) : loader === true ? <center><Spin /></center> :

        <Row>
                <Col span={24}>
                    <NavBreadCrumb page={data ? data.master_page.name : null} breadCrumb={breadCrumb}/>
                    <div className="gx-pt-4">
                        <div className="ant-card ant-card-bordered gx-card-widget">
                              <div className="ant-card-body">
                              <h2 className="gx-mb-0">
                                  {data ? `${data.institute.name} - (${data.master_page.name})` : null}
                              </h2>

                            </div>
                        </div>
                    </div>
                </Col>


                <Col span={24}>
            <div className="ant-card ant-card-bordered gx-card">
            <div className="ant-card-body">
              <Form onFinish={onFinish} form={form}>
                <div className="gx-p-2">
                   <InstitutePageDetailForm form={form} isAddMode={isAddMode}/>
                </div>
                <div className="d-flex" style={{ justifyContent: 'left' }}>
                <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
                </div>
              </Form>
              </div>
            </div>
                </Col>

            </Row>
         }
        </>
      );
    };
export default InstitutePageDetail;
