import React from 'react';
import { Row, Col, Form, Input, Select, DatePicker, Radio, Checkbox, message, Upload, Button } from "antd";
import DebounceSelect from '../../../components/DebounceSelect'
import { getStateList } from "../../../services/State/State";
import { getObjectStatus } from '../../../util/helpers';
import { getStreamList } from '../../../services/Stream/Stream';
import { getDegreeList } from '../../../services/Degree/Degree';

export const objectStatus = getObjectStatus()

const ScholarshipBasicDetails = ({ isAddMode }) => {




    return (
        <>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label="Scholarship Name"
                        name="name"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Scholarship name required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="name" maxLength={255}/>
                    </Form.Item>
                </Col>
                <Col span={12}>


                    <Form.Item
                        label="Scholarship Slug"
                        name="slug"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Scholarship slug required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="slug" readOnly={!isAddMode} maxLength={255} />
                    </Form.Item>
                </Col>

            </Row>
            <Row>

                <Col span={12}>

                    <Form.Item
                        label="Scholarship Awarding Body"
                        name="awarding_body"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Scholarship Awarding body required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="awarding_body" maxLength={255} />
                    </Form.Item>
                </Col>
                <Col span={12}>


                    <Form.Item
                        label="Scholarship min Amount"
                        name="scholarship_min_amount"
                        className="labelInput"
                        >
                        <Input className="gx-mb-3" name="scholarship_min_amount"/>
                    </Form.Item>
                </Col>
                <Col span={12}>


                    <Form.Item
                        label="Scholarship max Amount"
                        name="scholarship_max_amount"
                        className="labelInput"
                        >
                        <Input className="gx-mb-3" name="scholarship_max_amount"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>

                <Col span={7}>

                    <Form.Item
                        label="Is Popular Scholarship"
                        name="is_popular"
                        className="labelInput"
                        valuePropName='checked' >
                        <Checkbox name="isPopular" />
                    </Form.Item>
                </Col>
                <Col span={7}>

                    <Form.Item
                        label="Is Related Scholarship"
                        name="is_related"
                        className="labelInput"
                        valuePropName='checked'>
                        <Checkbox />
                    </Form.Item>
                </Col>

                <Col span={7}>

                    <Form.Item
                        label="Show on Landing Page"
                        name="is_visible_on_landing_page"
                        className="labelInput"
                        valuePropName='checked'
                    >
                        <Checkbox />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="State" name="states" className="labelInput">

                        <DebounceSelect
                            mode="multiple"
                            name="states"
                            placeholder="Select State"
                            fetchOptions={getStateList}
                            showSearch={true}
                            allowClear={true}
                        />



                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Stream" name="streams" className="labelInput">

                        <DebounceSelect
                            mode="multiple"
                            name="streams"
                            placeholder="Select Stream"
                            fetchOptions={getStreamList}
                            showSearch={true}
                            allowClear={true}
                        />



                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Degree" name="degrees" className="labelInput">

                        <DebounceSelect
                            mode="multiple"
                            name="degrees"
                            placeholder="Select Degree"
                            fetchOptions={getDegreeList}
                            showSearch={true}
                            allowClear={true}
                        />



                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Scholarship Snippet"
                        name="snippet"
                        className="labelInput"
                        rules={[
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="snippet" maxLength={255}/>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Listing Page Heading"
                        name="listing_page_heading"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Listing Page heading required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="listing_page_heading" maxLength={255} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Landing Page Heading"
                        name="landing_page_heading"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Landing Page heading required',

                            },
                            { type: 'string', max: 255 }

                        ]}>
                        <Input className="gx-mb-3" name="landing_page_heading" maxLength={255}/>
                    </Form.Item>
                </Col>



                <Col span={8}>
                    <Form.Item
                        label="Object Status"
                        name="object_status"
                        className="labelInput"
                        rules={[
                            {
                                required: true,
                                message: 'Object status',
                            },
                        ]}>

                        <Select
                            placeholder="Object Status"
                            name="object_status"
                            style={{ width: '100%' }}
                            className="gx-mb-3"
                            options={objectStatus}>

                        </Select>

                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item name={"application_start_date"} label={"Application Start date"}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={12}>

                    <Form.Item
                        label="show month only"
                        name="show_start_month_only"
                        className="labelInput"
                        valuePropName='checked'
                    >
                        <Checkbox />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={"application_end_date"} label={"Application End date"}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={12}>

                    <Form.Item
                        label="show month only"
                        name="show_end_month_only"
                        className="labelInput"
                        valuePropName='checked'
                    >
                        <Checkbox />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={12}>

                    <Form.Item
                        label="Tentative Date or Month"
                        name="is_tentative"
                        className="labelInput"
                        valuePropName='checked'
                    >
                        <Checkbox />
                    </Form.Item>
                </Col>
                
            </Row>



        </>
    )
}

export default ScholarshipBasicDetails