import React from 'react';
import {  Row, Col, Form,Input, Select, Checkbox} from "antd";

import { API_URL_LIST } from '../../../../constants/CollegeDekho';
import { useFetchData } from '../../../../hooks';
import { getObjectStatus } from '../../../../util/helpers';

const objectStatusOptions =  getObjectStatus()


const InstituteCoursePageComponentForm=({form,isAddMode})=>{

	
	const component = [{value: 41, label: "Static"}]

	return(
		   <Row>
			   

				<Col span={24}>
                    
				    <Form.Item label="Component" name="component" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'Select component',
			              }

			       ]}>
                    <Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Component"
						options={component}
						labelInValue = {true}
                        
					    >
                        </Select>

					</Form.Item>
				</Col>


                <Col span={24}>
					<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'Object Status Required',
			              },
			       ]}>
						<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select object status"
                        options={objectStatusOptions}
					    >
                        </Select>

					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item label="H2" name="h2" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'H2 is required',
			              }

			       ]}>
						<Input className="gx-mb-3" /> 

					</Form.Item>
				</Col>

                <Col span={24}>
				<Form.Item label=" " colon={false} name="has_read_more" className="labelInput" valuePropName="checked">

					<Checkbox className="gx-mb-3" name="has_read_more" >Has Read More</Checkbox>
				</Form.Item>
			</Col>




			</Row>
		)
}

export default InstituteCoursePageComponentForm
