import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import InstitutePageComponentForm from './InstitutePageComponentForm'
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import { getObject,getMultiObject } from '../../../util/helpers';
import { isInt,getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patch,post } from '../../../services/';
import { CustomEditor } from '../../../components/CustomEditor/';
import CircularProgress from "../../../components/CircularProgress";
import { Spin } from 'antd';


const InstitutePageComponent = (props) => {
  const  [loader, setLoader] = useState(false);
    // const locationData = props.location?.locationData
    const [form] = Form.useForm();
	  const { instituteID } = useParams();
    const { instituteMasterPageID } = useParams();
    const { componentInstituteMasterPageID } = useParams();
  	const isAddMode = !isInt(componentInstituteMasterPageID)
    const [content,setContent] = useState("")
    const [extraDetails, setExtraDetails] = useState(null);
    console.log("isAddMode",isAddMode)
    let url = null;
    let saveURL = API_URL_LIST["INSTITUTE_PAGE_COMPONENT_LISTING"].replace("#id#",instituteID).replace("#id1#",instituteMasterPageID)
    let componentUrl = API_URL_LIST["INSTITUTE_PAGE_COMPONENT_LISTING"].replace("#id#",instituteID)
    componentUrl = componentUrl.replace("#id1#",instituteMasterPageID)
    

    if(!isAddMode)
    {
      url = API_URL_LIST["INSTITUTE_PAGE_COMPONENT_DETAIL"].replace("#id#",instituteID)
      url = url.replace("#id1#",instituteMasterPageID)
      url = url.replace("#id2#",componentInstituteMasterPageID)
      saveURL = url
      console.log(url)
    }


    const [data,loading,error] = useFetchData(url)
    const [componentUrlData,componentUrLoading,componentUrlError] = useFetchData(componentUrl)

    const history = useHistory()
    const [disabledSave,setDisabledSave] = useState(false)
    const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
    const [breadCrumb] = useFetchData(dashboardTypeDetailURL)
 

console.log("componentUrlDatacomponentUrlData",componentUrlData?.extra_details?.master_page);

    const setObject = (data) => {
      const obj = {...data}
      obj.object_status = obj.object_status?.id
      obj.component = data.component.id
      if(obj.has_read_more)
		  {
		  	 obj.has_read_more = "checked"
		  }
      obj.component = getObject(data.component)
      console.log(obj.component)
      obj.degree = getMultiObject(data?.degree)
      obj.stream = getMultiObject(data?.stream)
      obj.specialization = getMultiObject(data?.specialization)
      if(data.content)
      {
        setContent(data.content)
      }
      form.setFieldsValue(obj)

      const extra = {"institute": data?.institute_master_page?.institute,"master_page":data?.institute_master_page?.master_page, "component_name":data?.component?.name}
      setExtraDetails(extra)

    }


    useEffect(() => {
      let mounted = true;
      if(mounted)
      {
         if(data)
         {
            setObject(data)
         }
        if(componentUrlData){
          setExtraDetails(componentUrlData?.extra_details)
        }
      }


      return () => mounted = false;
  }, [data,componentUrlData])

console.log("extraDetails",extraDetails);

    const getCleanData = (data) =>
    {
      
          const temp = JSON.stringify(data)
          const cleanData = JSON.parse(temp);
          
          if(data.degree)
          {
            cleanData.degree = data.degree.map( (item) => { return item.value} )
          }
          else
          {
            cleanData.degree = []
          }
          if(data.stream)
          {
            cleanData.stream = data.stream.map( (item) => { return item.value} )
          }
          else
          {
            cleanData.stream = []
          }
          if(data.specialization)
          {
            cleanData.specialization = data.specialization.map( (item) => { return item.value} )
          }
          else
          {
            cleanData.specialization = []
          }

          for (let attr in cleanData)
          {
             if (cleanData[attr] === "") {
                  console.log(attr)
                  cleanData[attr] = null
             }
          }
          if(cleanData.institute_master_page?.id )
          {
            delete cleanData.institute_master_page
          }
          if(cleanData?.has_read_more)
		      {
			          cleanData.has_read_more = true
		      }
          else
          {
            cleanData.has_read_more = false
          }
          cleanData.component = cleanData.component.value
          cleanData.content = content
          return cleanData
    }

    const crudErrorHandler = (error) =>
    {
        showError(error)

    }

    const patchData = (data) =>
    {
      setLoader(true);
      patch(saveURL,data).then(function (response)
        {

          message.success(COMMON_SUCCESS_MESSAGE);
          history.push(`/institute/${instituteID}/page/${instituteMasterPageID}/component/`)

        })
        .catch(crudErrorHandler)
      .finally(() => {
      setDisabledSave(false)
      setLoader(false);
        });

    }
    const postData = (data) =>
    {
      setLoader(true);
      post(saveURL,data)
      .then(function (response)
        {
           message.success(COMMON_SUCCESS_MESSAGE);
           history.push(`/institute/${instituteID}/page/${instituteMasterPageID}/component/`)

        })
        .catch(crudErrorHandler)
      .finally(() => {
      setDisabledSave(false)
      setLoader(false);
        });

    }
    const showError = (errors) =>
    {

      const error = getErrors(errors)
      if(error.allError.length>0)
        {
              message.error(error.allError.join());
        }
        form.setFields(error.serverError)


    }
    const onFinish=(e)=>
    {
      const data = form.getFieldsValue(true);
      const cleanData = getCleanData(data)
      setDisabledSave(true)
      if(isAddMode)
      {
      postData(cleanData)
      }
      else
      {
          patchData(cleanData)
      }
    }



  return (
    <>

          {loading ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}

	  {error ? message.error(error) : loader === true ? <center><Spin /></center> :

    <Row>
			<Col span={24}>
				<NavBreadCrumb page={extraDetails && extraDetails?.master_page?.name} page2={extraDetails && extraDetails?.component_name} breadCrumb={breadCrumb}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">
                { extraDetails && extraDetails?.institute ?  `Component of ${extraDetails?.institute?.name}, (${extraDetails?.master_page?.name})`
                  : `Component Detail`
                }

                </h2>
						</div>
					</div>
				</div>
			</Col>


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
               <InstitutePageComponentForm form={form} isAddMode={isAddMode}/>
            </div>

            <div className="gx-p-2">
               <CustomEditor content={content} setContent={setContent}/>
				    </div>

            <div className="d-flex" style={{ justifyContent: 'left' }}>
            <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
          </div>
        </div>
			</Col>

		</Row>
   }
   </>
  );
};
export default InstitutePageComponent;
