import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { Row, Col, Button, message } from "antd";
import { Link, useParams } from 'react-router-dom';
import LatestUpdateListComponent from "./LatestUpdateListComponent";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho';
import { useFetchData } from '../../../hooks';


const LatestUpdateListing = () => {

	const pathname = useSelector(({ common }) => common.pathname);
	const dashboardType = pathname.includes("institute") ? "institute" : "course";

	const { ID, vernacularID } = useParams();
	const [extraDetails, setExtraDetails] = useState(null);
	const [error, setError] = useState(null)
	const dashboardTypeDetailURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", ID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(dashboardTypeDetailURL)

	return (

		<Row>
			{error ? message.error(error) : null}
			<Col span={24}>
				<NavBreadCrumb breadCrumb={breadCrumb} courseVernacularID={extraDetails} />
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
						<div className="ant-card-body">
							<h2 className="gx-mb-0">{extraDetails?.name}, Latest Updates </h2>
						</div>
					</div>
				</div>

				<Row>
					<Col span={18}>
					</Col>
					<Col span={6} style={{ textAlign: 'right' }}>
						<Link to={{ pathname: `/${dashboardType}/${ID}/vernacular/${vernacularID}/latest-update/create/`, locationData: { parent: { "name": extraDetails?.name } } }}>
							<Button type="primary">
								<span>Add New Latest Update</span>
							</Button>
						</Link>
					</Col>
				</Row>

			</Col>
			<Col span={24}>
				<LatestUpdateListComponent setExtraDetails={setExtraDetails} setError={setError} />

			</Col>
		</Row>
	)
};
export default LatestUpdateListing;
