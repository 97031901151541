import React,{useState,useEffect} from "react";
import {  Link,useParams } from 'react-router-dom';
import { getInstituteRankingList } from "../../../services/InstituteRanking/InstituteRanking";
import { getCollegeorDegree } from "../../../util/helpers";

import {Divider,Row, Col, Table, Pagination, Button, message} from "antd";
import { getErrors } from '../../../util/helpers';
import {FormOutlined} from "@ant-design/icons";
import {API_URL_LIST, PAGE_SIZE} from "../../../constants/CollegeDekho";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { useFetchData } from "../../../hooks";

const columns = [{
  title: 'ID',
  dataIndex: 'id',

},
{
  title: 'Ranking Body',
  key: 'ranking_authority',
  render: (text, record) => (
    <span>
     {record.ranking_authority?.name}
  </span>
  ),
},
{
  title: 'Ranking Type',
  key: 'stream',
  render: (text, record) => (
    <span>
     {getCollegeorDegree(record.stream?.name)}
  </span>
  ),
},
{
  title: 'Year',
  dataIndex: 'year',
},
{
  title: 'Rank',
  dataIndex: 'rank',
},

  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
      <Divider type="vertical"/>
      <span className="gx-link"><Link to={`/institute/${record.institute.id}/ranking/${record.id}/update/`}><FormOutlined /></Link></span>
      <Divider type="vertical"/>

    </span>
    ),
  }
];



const InstituteRankingListing = () => {
  const  [instituteRankingList,setInstituteRankingList] = useState([])
  const  [error,setError] = useState(null)
  const  [pageSize,setPageSize] = useState(PAGE_SIZE)
  const  [page,setPage] = useState(1);
  const  [total,setTotal] = useState(0);
  const  [loading,setLoading] = useState(true);
  const  [text, setText] = useState("");
  const  [institute, setInstitute] = useState({});
  const { instituteID } = useParams();

  const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
  const [breadCrumb] = useFetchData(dashboardTypeDetailURL)

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getInstituteRankingList({instituteID,page,pageSize,text})
    .then(data => {
    if(mounted)
    {
      setInstituteRankingList(data.data.results);
      setTotal(data.data.count);
      setInstitute(data.data.extra_details.institute)
      setLoading(false);
      setError(null);
    }
    })
    .catch(function (response)
    {
        const err =  getErrors(response)
        setError(err.allError);
        console.log(err)

     });

    return () => mounted = false;
   }, [page,pageSize,text])

   function onShowSizeChange(current, PageSize)
   {
       setPageSize(PageSize);
       console.log(current, PageSize);
   }
   function onChange(page, PageSize)
   {
        setPage(page);
        console.log(page, PageSize);
   }

  return (
    <Row>
     <Col span={24}>
     {error ? message.error(error) : null}
         <NavBreadCrumb breadCrumb={breadCrumb}/>
         <div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">{institute?.name}, Rankings</h2>
						</div>
					</div>
				</div>
        <Row>
           <Col span={18}>
           </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
            <Link to={{pathname: `/institute/${instituteID}/ranking/create/`, locationData: {name: institute?.name } }}>
                  <Button type="primary">
                   <span>Add Ranking</span>
               </Button>
              </Link>
            </Col>
          </Row>
        </Col>


      <Col span={24}>
         <Table className="gx-table-responsive" loading={loading} rowKey="id"  columns={columns}  dataSource={instituteRankingList} pagination={false} />
      </Col>
       <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        onChange={onChange}
        pageSize = {pageSize}
        total = {total}
        current = {page}
        />
      </Col>
    </Row>
  );
};

export default InstituteRankingListing;
