import React, { useState, useEffect } from "react";
import { Table, Row, Col, Pagination, message } from "antd";
import { PAGE_SIZE } from '../../../constants/CollegeDekho'
import { getFaqList } from "../../../services/FaqList";
import { columns } from "./columns";

const FaqListingComponent = ({
  setExtraDetails,
  faqObjectID,
  instituteID,
  contentTypeID,
  courseID,
  page,
  setPage,
  templateContentTypeID,
  templateObjectID,
  pageContentTypeID,
  pageObjectID 
}) => {

  const [listingData, setListingData] = useState([])
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [total, setTotal] = useState(0);

  const onShowSizeChange = (current, PageSize) => setPageSize(PageSize)
  const onPageChange = (current, PageSize) => setPage(current)

  useEffect(() => {
    let mounted = true;
    setLoading(true);

      getFaqList({ templateContentTypeID, templateObjectID, pageContentTypeID, pageObjectID , pageSize, page})
      .then(data => {
        if(mounted) {
          console.log({data})
          setTotal(data?.data?.count)
          setListingData(data?.data?.results);
          setLoading(false);
          setExtraDetails(data.data.extra_details)
        }
      })
      .catch(err=> {
        console.log({err})
        message.error("Some error occured")
      });

    
  }, [ pageObjectID, page, pageSize])
  return (
    <Row>
      <Col span={24}>
        <Table
          className="gx-table-responsive"
          loading={loading}
          rowKey="id"
          columns={columns({ contentTypeID, instituteID, courseID, faqObjectID, templateContentTypeID, templateObjectID, pageContentTypeID, pageObjectID  })}
          dataSource={listingData}
          pagination={false}
          
        />
      </Col>
      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onPageChange}
          pageSize={pageSize}
          total={total}
          current={page}
        />
      </Col>
    </Row>
  );
};

export default FaqListingComponent;
