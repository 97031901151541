import { Col, Form, Select } from "antd"
import DebounceSelect from "../../../components/DebounceSelect"
import { getCategory, getSubCategory } from "../../../services/Scholarships/Category"
import { useEffect, useState } from "react"
import { getObjectStatus } from "../../../util/helpers"
import { element } from "prop-types"


const ScholarshipCategoriesForm = (
    {
        form,
        breadcrumbCategoryOptions,
        setBreadcrumbCategoryOptions,
        breadcrumbSubCategoryOptions,
        setBreadcrumbSubCategoryOptions 
    }
) => {

    
    const [subcategoryOptions, setSubCategoryOptions] = useState([])
    

    const data = form.getFieldsValue(true)
    const [categories, setCategories] = useState(data?.categories)



    useEffect(() => {
        

        if (data?.categories) {
            setCategories(data?.categories)

            let categoryValues = []
            data?.categories.forEach(element => {

                if (element.value) {
                    categoryValues.push(element.value)
                }

            });
            getSubCategory(categoryValues.toString()).then((subcategories) => {
                setSubCategoryOptions(subcategories)
                setBreadcrumbCategoryOptions(data?.categories)
            }
            )
        }

    }, [data?.categories])


    const onCategoryChange = (e) => {
        if (e) {
            let categoryValues = e.map(element => element.value)
            getSubCategory(categoryValues.toString()).then((subcategories)=>{
                setSubCategoryOptions(subcategories)
            });
            setBreadcrumbCategoryOptions(e)
            form.setFieldsValue({breadcrumb_category: null})


        }
    }
    

    const onSubCategoryChange = (e) => {
        setBreadcrumbSubCategoryOptions(e);
        form.setFieldsValue({breadcrumb_subcategory: null})
    }

    const fetchSubcategoryOptions = async() => {
        let categoryValues = []
        categoryValues =categories.map((category) => category.value)
        categoryValues = categoryValues.toString()
        return await getSubCategory(categoryValues)
    }


    return (
        <>
    
            <Col span={10}>
                <Form.Item label="Scholarship Category" name="categories" className="labelInput"
                    rules={[
                        {
                            required: true,
                            message: 'Category required',
                        },
                    ]}

                >
                    <DebounceSelect
                        mode="multiple"
                        name="categories"
                        placeholder="Select Category"
                        fetchOptions={getCategory}
                        showSearch={true}
                        allowClear={false}
                        onChange={onCategoryChange}

                    />
                </Form.Item>

                <Form.Item label="Scholarship Sub Category" name="subcategories" className="labelInput">
                    <DebounceSelect
                        mode="multiple"
                        name="subcategories"
                        placeholder="Select Sub Category"
                        fetchOptions={fetchSubcategoryOptions}
                        onChange={onSubCategoryChange}
                    />
                </Form.Item>
                
            </Col>

            <Col span={10}>
                <Form.Item label="Breadcrumb Category" name="breadcrumb_category" className="labelInput"
                    rules={[
                        {
                            required: true,
                            message: 'Breadcrumb category required',
                        },
                    ]}

                >
                    <Select
                        mode="single"
                        name="breadcrumb_category"
                        options={breadcrumbCategoryOptions}
                        placeholder="Select Breadcrumb Category"


                    />
                </Form.Item>

                <Form.Item label="Scholarship BreadCrumb Sub Category" name="breadcrumb_subcategory" className="labelInput">
                    <Select
                        mode="single"
                        name="breadcrumb_subcategory"
                        placeholder="Select Breadcrumb Sub Category"
                        options={breadcrumbSubCategoryOptions}
                    />
                </Form.Item>
            </Col>

        </>

    );
}


export default ScholarshipCategoriesForm