import React from 'react';
import { Row, Col, Form,Input, Select, } from "antd";
import { getObjectStatus } from "../../../util/helpers";
const objectStatusOptions =  getObjectStatus()

const { TextArea } = Input;



export const InstitutePageDetailForm=({form,isAddMode})=>{
	
	return(
		   <Row>
				<Col span={24}> 
				<Form.Item label="H1" name="h1" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'H1 Required',
			              },
                          
                          { type: 'string', max: 254 },
			       ]}>
                       <Input className="gx-mb-3"/>
					</Form.Item>
				</Col>
				<Col span={24}> 
					<Form.Item label="Title" name="title" className="labelInput" rules={[
			              {
			                required: false,
			                message: 'Title Required',
			              },
                          { type: 'string', max: 254 },
			       ]}>
						<Input className="gx-mb-3" />
					</Form.Item> 
				</Col>
                <Col span={24}> 
					<Form.Item label="Description" name="meta_description" className="labelInput" rules={[
			              {
			                required: false,
			                message: 'Description Required',
			              },
                          
                          { type: 'string', max: 1000 },
			       ]}>
						<TextArea className="gx-mb-3" rows={4}/>
					</Form.Item> 
				</Col>
                <Col span={24}> 
					<Form.Item label="Keywords" name="meta_keywords" className="labelInput" rules={[
			              {
			                required: false,
			                message: 'Keywords Required',
			              },
                          
                          { type: 'string', max: 254 },
			       ]}>
						<TextArea className="gx-mb-3" rows={4}/>
					</Form.Item> 
				</Col>

                <Col span={8}> 
					<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'Object Status Required',
			              },
			       ]}>
						<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select object status"
                        options={objectStatusOptions}
					    >
                        </Select>

					</Form.Item> 
				</Col>
				
			</Row>
		)
};

