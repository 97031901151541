import React,{useState,useEffect} from 'react';
import {Card, Modal, Row, Col, Image, Pagination, Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import { getInstituteImageList } from "../../../services/InstituteImage/InstituteImage";
import ImageUploadForm from './ImageUploadForm'
import {NO_IMAGE_AVAILABLE} from '../../../constants/CollegeDekho'

const UploadPicture=({instituteID,institute,setInstitute})=>{

  const  [page,setPage] = useState(1);
  const  [pageSize,setPageSize] = useState(8)
  const  [total,setTotal] = useState(0);
  const  [loading,setLoading] = useState(true);
  const  [text, setText] = useState("");
  const [refresh,setRefresh] = useState(false)

  const [previewVisible, setPreviewVisible]=useState(false);
  const [previewImage, setPreviewImage]=useState('');
  const [instituteImageList, setInstituteImageList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewTitle, setPreviewTitle]=useState('');
  const [isAddMode, setIsAddMode] = useState(true)
  const [imageID,setImageID] = useState(0)

  console.log("isAddMode", isAddMode)
    useEffect(() => {
    let mounted = true;

    setLoading(true);

    getInstituteImageList({instituteID,page,pageSize,text})
    .then(data => {
    if(mounted)
    {
      console.log(data.data)
      setInstituteImageList(data.data.results);
      setTotal(data.data.count);
      setInstitute(data.data.extra_details.institute)
      setLoading(false);
      setRefresh(false);
    }
    })
    return () => mounted = false;
   }, [page,pageSize,text,refresh])

   function onShowSizeChange(current, PageSize)
   {
       setPageSize(PageSize);
   }
   function onChange(page, PageSize)
   {
        setPage(page);
   }


    const refreshPage = () =>
    {
      setRefresh(true)
      setIsModalVisible(false);
      setPreviewVisible(false)
    }
    const showModal = () =>
    {
    setIsAddMode(true);
    setIsModalVisible(true);
  };

    const handleCancel =()=>
    {
       setPreviewVisible(false)
    }
    const hideModel =(e)=>{
      setIsModalVisible(false);
      }

    const handlePreview = (file) => {
      setPreviewImage(file.url || file.thumbUrl)
      setPreviewTitle(file.image_type ? file.image_type.name : "No Tag")
      setIsAddMode(false)
      setImageID(file.id)
      setPreviewVisible(true)
    };

    const handleChange = ({ fileList: newFileList }) => {
      setInstituteImageList(newFileList);
    };



    const uploadButton = (
      <Button type="primary" onClick = {showModal}>
        <PlusOutlined />
        <span> Upload</span>
      </Button>
    );

    const title = <div> Upload Image for {institute?.name} </div>
  return(
  <>
    <Card title="Image" className="gx-card clearfix">
      {page === 1 ? uploadButton : null }
       <Modal title={title} visible={isModalVisible}
            footer={null}
            onCancel={hideModel}>
            <div className="gx-p-2">
              <ImageUploadForm isAddMode={isAddMode} instituteID={instituteID} refreshPage={refreshPage}/>
            </div>
        </Modal>

     <Row>
          {instituteImageList.map((item) =>
            <Col span={3} key={item.id} className={`${item.is_approved === true ? 'upload-list-inline' : ''}`}>
               <div>
                  <figure className="gallery-uploaded-image">
                     <Image className="ant-upload-list-picture-card-container"
                        alt={item.image_type ? item.image_type.name : "No Tag"}
                        width={150}
                        src={item.url}
                        style={{'cursor': 'pointer'}}
                        fallback={NO_IMAGE_AVAILABLE}
                        preview={false} onClick={() => handlePreview(item)}>
                     </Image>
                     <p style={{textAlign: "center"}}>{item.image_type ? item.image_type.name : "No Tag"}</p>
                  </figure>
               </div>
            </Col>
          )}
       </Row>

        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
           <div className="gx-p-2">
              <img alt="example" style={{width: '75%'}} src={previewImage}
              onError={(e) => {
                      e.target.src = `${NO_IMAGE_AVAILABLE}`}
              }/>
              <ImageUploadForm  isAddMode={isAddMode} imageID={imageID} instituteID={instituteID} refreshPage={refreshPage}/>
            </div>
        </Modal>


    </Card>
    <Row>
      <Col span={24}>
        <Pagination
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        onChange={onChange}
        pageSize = {pageSize}
        total = {total}
        current = {page}
        />
      </Col>
    </Row>
    </>

  )
}
export default UploadPicture
