import { get } from "../index";

export async function getInstituteFacultyList(data)
{
    const url = `/api/cms/institute/${data.instituteID}/faculty/`;

    const params = {
      page_size: data.pageSize,
      page:data.page,
      text:data.text,
    }
    return await get(url, params)
}