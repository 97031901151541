import React,{useState,useEffect} from 'react';
import {Card, Modal, message, Row, Col, Pagination, Image, Button, Form} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import { getInstituteVideoList } from "../../../services/InstituteVideo/InstituteVideo";
import VideoUploadForm from './VideoUploadForm'
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import { patch,post } from '../../../services/';
import { getErrors } from '../../../util/helpers';
import { useHistory } from 'react-router-dom';
import { useFetchData } from "../../../hooks/";

const UploadVideos=({instituteID})=>{
  const [form] = Form.useForm();
  const history = useHistory()
  const [disabledSave,setDisabledSave] = useState(false)
  const  [page,setPage] = useState(1);
  const  [pageSize,setPageSize] = useState(8)
  const  [total,setTotal] = useState(0);
  const  [loading,setLoading] = useState(true);
  const  [text, setText] = useState("");
  const  [institute, setInstitute] = useState({});
  const [refresh,setRefresh] = useState(false)

  const [previewVisible, setPreviewVisible]=useState(false);
  const [previewVideo, setPreviewVideo]=useState('');
  const [instituteVideoList, setInstituteVideoList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewTitle, setPreviewTitle]=useState('');
  const [isAddMode, setIsAddMode] = useState(true)
  const [videoID,setVideoID] = useState(0)

  console.log("isAddMode",isAddMode)
	let url = null;
	let saveURL = API_URL_LIST["INSTITUTE_VIDEO_DETAIL_POST_URL"].replace("#id#",instituteID)

	if(!isAddMode)
	{
	  url=API_URL_LIST["INSTITUTE_VIDEO_DETAIL_URL"].replace("#id#",instituteID)
	  url = url.replace("#id1#",videoID)
	  saveURL = url
	  console.log(url)
	}

	const [data,dataloading,error] = useFetchData(url)
  const setInstituteVideoObj = (data) => {
        const  instituteVideoObj = {
          object_status:data.object_status.id,
			    institute:data.institute
		}
		if(data.approved)
	    {
	         instituteVideoObj["approved"] = "checked"
	     }
    form.resetFields()
		form.setFieldsValue(instituteVideoObj)
	}
  	useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {
			  console.log("data",data)
			  setInstituteVideoObj(data)
		  }
		}
		return () => mounted = false;
	}, [data])

  const getCleanData = (data) =>
	{
        const cleanData = {...data}
        if(data.institute) {
        cleanData.institute = data.institute.id}
        if(data.approved === "checked")
		    {
			      cleanData.approved = true;
		    }
        console.log(cleanData)
        return cleanData
	}
	const crudErrorHandler = (error) =>
    {
        showError(error)

    }

    const postData = (data) =>
    {
            post(saveURL,data)
            .then(function (response)
            {
                refreshPage()
                message.success(COMMON_SUCCESS_MESSAGE);
                form.resetFields()
            })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
            });
    }

    const patchData = (data) =>
	{
	  console.log(data)
	  patch(saveURL,data).then(function (response)
      {
        refreshPage()
		    message.success(COMMON_SUCCESS_MESSAGE);
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
      });
	}

  const showError = (errors) => {
     const error = getErrors(errors)
        if(error.allError.length>0)
        {
              message.error(error.allError.join());
        }
     console.log("server error",error.serverError)
     form.setFields(error.serverError)
  }

  const onFinish=(e)=>
    {
        const data = form.getFieldsValue(true);
        const cleanData = getCleanData(data)
        setDisabledSave(true)
        if(isAddMode)
	  {
		postData(cleanData)
	  }
	  else
	  {
        patchData(cleanData)
	  }
	}

    useEffect(() => {
    let mounted = true;

    setLoading(true);

    getInstituteVideoList({instituteID,page,pageSize,text})
    .then(data => {
    if(mounted)
    {
      console.log(data.data)
      setInstituteVideoList(data.data.results);
      setTotal(data.data.count);
      if(data.data.count > 0)
      {
        let ins = data.data.results[0].institute
        setInstitute({"id":ins.id, "name":ins.name})
      }
      setLoading(false);
      setRefresh(false);
    }
    })

    return () => mounted = false;
   }, [page,pageSize,text,refresh])

   function onShowSizeChange(current, PageSize)
   {
       setPageSize(PageSize);
   }

   function onChange(page, PageSize)
   {
        setPage(page);
   }

    const refreshPage = () =>
    {
      setRefresh(true)
      setIsModalVisible(false);
      setPreviewVisible(false)
    }
    const showModal = () =>
    {
    setIsAddMode(true);
    setIsModalVisible(true);
  };

    const handleCancel =()=>
    {
       setPreviewVisible(false)
    }
    const hideModel =(e)=>{
      setIsModalVisible(false);
      }

    const handlePreview = (file) => {
      setPreviewVideo("https://www.youtube.com/embed/"+file.youtube_id)
      setPreviewTitle(file.videotags ? file.videotags.name : "No Tag")
      setPreviewVisible(true)
      setIsAddMode(false)
      setVideoID(file.id)
    };

    const uploadButton = (
      <Button type="primary" onClick = {showModal}>
        <PlusOutlined />
        <span> Upload</span>
      </Button>
    );

    const title = <div> Upload Videos for {institute?.name} </div>
  return(
  <>
	{error ? message.error(error) :
	 <>
    <Card title="Videos" className="gx-card clearfix">

       {page === 1 ? uploadButton : null }

       <Modal title={title} visible={isModalVisible}
            footer={null}
            onCancel={hideModel}>
            <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
              <VideoUploadForm form={form} isAddMode={isAddMode} instituteID={instituteID} />
            </div>
            <div className="d-flex" style={{ justifyContent: 'left' }}>
                 <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
        </Modal>

        <Row>
          {instituteVideoList.map((item) =>
            <Col span={3} key={item.id} className={item.approved === true ? 'upload-list-inline' : ''}>
               <div>
                  <figure className="gallery-uploaded-image">
                     <Image className="ant-upload-list-picture-card-container"
                         alt={item.videotags ? item.videotags.name : "No Tag"}
                         width={150}
                         src={item.url}
                         preview={false}
                         style={{'cursor': 'pointer'}}
                         onClick={() => handlePreview(item)}>
                     </Image>
                     <p style={{textAlign: "center"}}>{item.videotags ? item.videotags.name : "No Tag"}</p>
                  </figure>
               </div>
            </Col>
          )}
       </Row>

        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
          <Form onFinish={onFinish} form={form}>
         <div className="gx-p-2">
             <iframe alt="example" width="420" height="345" src={previewVideo} title={previewTitle}></iframe>
              <VideoUploadForm form={form} isAddMode={isAddMode} instituteID={instituteID} />
            </div>
            <div className="d-flex" style={{ justifyContent: 'left' }}>
                 <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
        </Modal>

    </Card>
    <Row>
      <Col span={24}>
        <Pagination
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        onChange={onChange}
        pageSize = {pageSize}
        total = {total}
        current = {page}
        />
      </Col>
    </Row>
    </>
   }
    </>
  )
}
export default UploadVideos
