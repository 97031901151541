import moment from "moment";
import { DATE_TIME_FORMAT_LISTING } from "../../../constants/CollegeDekho";
import { DownloadOutlined } from '@ant-design/icons';

export const columns = () => [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 200,

    },
    {
        title: 'Upload',
        dataIndex: 'upload',
        width: 200,
        align: 'center',
        render: (_, record) => <a href={record?.upload} ><DownloadOutlined style={{ fontSize: "22px" }} /></a>
    },
    {
        width: 200,
        align: 'center',
        title: 'Upload Remark',
        dataIndex: 'upload_remark',
        render: (_, record) => <a href={record?.upload_remark}><DownloadOutlined style={{ fontSize: "22px" }} /></a>
    },
    {
        title: 'Added By',
        dataIndex: 'added_by',
        render: (_, record) => record?.added_by?.name
    },
    {
        title: 'Added By',
        dataIndex: 'added_by',
        width: 190,
        render: (text, record) => (
            <span> {record.added_on ? moment(record.added_on).format(DATE_TIME_FORMAT_LISTING) : null} </span>
        ),
    },
];