import React from 'react';
import { Divider, Table, message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { post } from '../../../services';
import { useParams } from 'react-router-dom';
import { API_URL_LIST, COMMON_ERROR_MESSAGE } from '../../../constants/CollegeDekho'
import { CONTENT_TYPE_ID } from '../../../constants/Settings';
import { FormOutlined } from "@ant-design/icons";
import moment from 'moment';
import { Spin } from 'antd';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState } from "react";

const DragnDrop = ({ dataSource, setDataSource }) => {
  const [loader, setLoader] = useState(false);
  const { instituteID } = useParams();
  let saveURL = API_URL_LIST["INSTITUTE_POPULAR_DEGREE_UPDATE_ORDER"].replace("#id#", instituteID)
  const columns = [
    {
      key: "dragHandle",
      dataIndex: "dragHandle",
      title: "Drag",
      width: 30,
      render: () => <MenuOutlined />,
    },
    {
      key: "key",
      dataIndex: "key",
      title: "ID",
    },
    {
      dataIndex: "name",
      title: "Name",
    },
    {
      dataIndex: "faq",
      title: "FAQ",
      render: (text, record) => (
        < span >
          {record?.page ?
            <Link to={
              {
                pathname: `/institute/${instituteID}/faqs/${CONTENT_TYPE_ID.institute}/`,
                search: `?page_content_type_id=${CONTENT_TYPE_ID.institutePopularDegreePage}&page_object_id=${record.page.id}`
              }}>
              <FormOutlined />
            </Link>
            : <FormOutlined onClick={() => message.error("Degree page doesn't exist")} />
          }
        </span >
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          {/* <Divider type="vertical"/>
        <span className="gx-link">
          <Link to={`/institute/${instituteID}/degree/${record.id}/update/`}>  <FormOutlined/>  </Link></span> */}
          {console.log({ record })}
          {
            record?.page &&
            <>
              <Divider type="vertical" />
              <span className="gx-link">
                <Link to={{ pathname: `/institute/${instituteID}/degree/${record.id}/page`, locationData: { "institute": { "name": record.institute?.name }, "master_page": { "name": record.master_page?.name } } }}>
                  Edit/SEO
                </Link>
              </span>
              <Divider type="vertical" />
              <span className="gx-link">
                <Link to={{ pathname: `/institute/${instituteID}/degree/${record.id}/page/components`, locationData: { "institute": { "name": record.institute?.name }, "master_page": { "name": record.master_page?.name } } }}>
                  Components
                </Link>
              </span>
            </>
          }

        </span>
      ),
    },

  ];


  const [activeId, setActiveId] = useState(null);


  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setDataSource((items) => {
        // In this example, find an item, where `item.key` === `useSortable.id`.
        const oldIndex = items.findIndex((item) => item.key === active.id);
        const newIndex = items.findIndex((item) => item.key === over.id);
        console.log(active.id, over.id)
        updateOrder(active.id, over.id)
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    // Stop overlay.
    setActiveId(null);
  }

  const crudErrorHandler = (error) => {
    message.error(COMMON_ERROR_MESSAGE);
  }

  function updateOrder(from, after) {
    setLoader(true);
    post(saveURL, { "from": from, "after": after })
      .then(function (response) {

      })
      .catch(crudErrorHandler)
      .finally(() => {
        //setDisabledSave(false)
        setLoader(false);
      });

  }

  return (
    <>
      {loader === true ? <center><Spin /></center> :
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <Table
            columns={columns}
            dataSource={dataSource}
            components={{
              body: {
                wrapper: DraggableWrapper,
                row: DraggableRow,
              },
            }}
            pagination={false}
          />
          {/* Render overlay component. */}
          <DragOverlay>{activeId ? activeId : null}</DragOverlay>
        </DndContext>
      }
    </>
  );

  function DraggableWrapper(props) {
    const { children, ...restProps } = props;
    /**
     * 'children[1]` is `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    return (
      <SortableContext
        items={children[1] instanceof Array ? children[1].map((child) => child.key) : []}
        strategy={verticalListSortingStrategy}
        {...restProps}
      >
        <tbody {...restProps}>
          {
            // This invokes `Table.components.body.row` for each element of `children`.
            children
          }
        </tbody>
      </SortableContext>
    );
  }

  function DraggableRow(props) {
    const { attributes, listeners, setNodeRef, isDragging, overIndex, index } = useSortable({
      id: props["data-row-key"],
    });
    const isOver = overIndex === index
    const { children, ...restProps } = props;
    const isData = children instanceof Array;
    const style = {
      ...restProps?.style,
      ...(isData && isDragging ? { background: "#80808038" } : {}),
      ...(isData && isOver ? { borderTop: "5px solid #ec161638" } : {})
    }
    /**
     * 'children[1]` is a row of `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    return (
      <tr
        ref={setNodeRef}
        {...attributes}
        {...restProps}
        style={style}
      >
        {
          children instanceof Array ? (
            children.map((child) => {
              const { children, key, ...restProps } = child;
              return key === "dragHandle" ? (
                <td {...listeners} {...restProps}>
                  {child}
                </td>
              ) : (
                <td {...restProps}>{child}</td>
              );
            })
          ) : (
            children
          )
        }
      </tr>
    );
  }
}

export default DragnDrop;
