import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getLogsList } from "../../../services/Logs/logsList";
import { Row, Col, Table, Pagination, Input, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "../../../constants/CollegeDekho";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import LogsListingFilter from "./LogsListingFilter";
import { SearchOutlined } from "@ant-design/icons";
import { getErrors } from "../../../util/helpers";
import { DATE_TIME_FORMAT_LISTING } from "../../../constants/CollegeDekho";
import moment from "moment";


const LogsListing = () => {
  const [logsList, setLogsList] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [text, setText] = useState("");
  const [searchtext, setSearchText] = useState("");
  const [stream, setStream] = useState([]);
  const [author, setAuthor] = useState(undefined);
  const [templateType, setTemplateType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const columns = [
    {
      title: "Template Type",
      key: "content_type",
      render: (text, record) => <span>{record.content_type?.name}</span>,
    },
    {
      title: "Object ID",
      dataIndex: "object_id",
    },
    {
      title: "Content Name",
      dataIndex: "content_name",
    },

    {
      title: "Short Name",
      dataIndex: "short_name",
    },
    {
      title: "Page Name",
      dataIndex: "page_name",
    },
    {
      title: "Last Modified By",
      dataIndex: "modified_by",
    },
    {
      title: "Last Modified On",
      dataIndex: "added_on",
      render: (text, record) => (
        <span>{moment(record.added_on).format(DATE_TIME_FORMAT_LISTING)}</span>
      ),
    },

    {
      title: "View",
      key: "view",
      render: (text, record) => (
        <span>
          <span className="gx-link">
            <Link
              to={"/logs/" + record.content_type?.id + "/" + record.object_id}
            >
              {" "}
              <EyeOutlined />
            </Link>
          </span>
        </span>
      ),
    },
  ];

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getLogsList({
      page,
      pageSize,
      text,
      templateType,
      stream,
      author,
      startDate,
      endDate,
    })
      .then((data) => {
        if (mounted) {
          setLogsList(data.data.results);
          setTotal(data.data.count);
          setLoading(false);
          setError(null);
          console.log(data.data.results);
        }
      })
      .catch(function (response) {
        const err = getErrors(response);
        setError(err.allError);
        console.log(err);
      });

    return () => (mounted = false);
  }, [page, pageSize, text, author, stream, templateType, startDate, endDate]);

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);
    console.log(current, PageSize);
  }
  function onChange(page, PageSize) {
    setPage(page);
    console.log(page, PageSize);
  }

  const onPressEnterSearchInput = (e) => {
    console.log(e.target.value);
    if (e.target.value.length > 2) {
      setText(e.target.value);
    }
  };
  const onApplyFilter = (data) => {
    console.log(data);
    setStream(data.stream);
    setAuthor(data.author);
    setTemplateType(data.templateType);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setPage(1);
  };
  const onClearFilter = () => {
    setStream([]);
    setAuthor(undefined);
    setStartDate(null);
    setEndDate(null);
    setTemplateType(null);
    setText("");
    setSearchText("")
  };
  return (
    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered searchBox gx-card">
            <div className="ant-card-body">
              <Input
                placeholder="Search by Title or URL or Short Name	"
                prefix={<SearchOutlined />}
                allowClear
                onPressEnter={onPressEnterSearchInput}
                value={searchtext}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <LogsListingFilter onApplyFilter={onApplyFilter} onClearFilter={onClearFilter}
        />
      </Col>

      <Col span={24}>
        <Table
          className="gx-table-responsive"
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={logsList}
          pagination={false}
        />
      </Col>
      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          pageSize={pageSize}
          total={total}
          current={page}
        />
      </Col>
    </Row>
  );
};

export default LogsListing;
