import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import MasterPageForm from './MasterPageForm'
import { useFetchData } from "../../../hooks/";
import { patch, post } from '../../../services/';
import { isInt, getErrors } from '../../../util/helpers';
import { COMMON_SUCCESS_MESSAGE, COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho'

const MasterPageDetail = () => {
	const [form] = Form.useForm();
	const { masterPageID } = useParams();
	const isAddMode = !isInt(masterPageID)
	let url = null;
	let saveURL = COURSE_CONTENT_API_LIST["MASTER_PAGE_API_URL"];

	if (!isAddMode) {
		url = COURSE_CONTENT_API_LIST["MASTER_PAGE_DETAIL_URL"].replace("#id#", masterPageID)
		saveURL = url
	}
	const [data, loading, error] = useFetchData(url)
	const history = useHistory()
	const [disabledSave, setDisabledSave] = useState(false)
	const [masterPageName, setMasterPageName] = useState("");

	const setMasterPageObj = (data) => {
		const obj = { ...data }
		obj.object_status = obj.object_status.id
		obj.page_type = obj.page_type.id
		form.setFieldsValue(obj)
	}

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (data) {
				let name = data.name ? data.name : "Master Page";
				setMasterPageName(name)
				setMasterPageObj(data)

			}

		}


		return () => mounted = false;
	}, [data])


	const getCleanData = (data) => {
		const cleanData = { ...data }
		if (!isAddMode) {
			delete cleanData.uri_slug
		}
		return cleanData
	}

	const crudErrorHandler = (error) => {
		showError(error)
	}

	const patchData = (data) => {
		patch(saveURL, data).then(function (response) {
			message.success(COMMON_SUCCESS_MESSAGE);
			history.push(`/course/master-page`)

		})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
			});

	}
	const postData = (data) => {
		post(saveURL, data)
			.then(function (response) {
				message.success(COMMON_SUCCESS_MESSAGE);
				history.push(`/course/master-page`)

			})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
			});

	}
	const showError = (errors) => {
		const error = getErrors(errors)
		if (error.allError.length > 0) {
			message.error(error.allError.join());
		}

		form.setFields(error.serverError)

	}
	const onFinish = (e) => {
		const data = form.getFieldsValue(true);
		const cleanData = getCleanData(data)
		setDisabledSave(true)
		if (isAddMode) {
			postData(cleanData)
		}
		else {
			patchData(cleanData)
		}
	}

	return (
		<>
			{error ? message.error(error) :
				<Row>

					<Col span={24}>
						<NavBreadCrumb />
						<div className="gx-pt-4">
							<div className="ant-card ant-card-bordered gx-card-widget">
								<div className="ant-card-body">
									<h2 className="gx-mb-0">{masterPageName}</h2>

								</div>
							</div>
						</div>
					</Col>


					<Col span={24}>
						<div className="ant-card ant-card-bordered gx-card">
							<div className="ant-card-body">
								<Form onFinish={onFinish} form={form}>
									<div className="gx-p-2">
										<MasterPageForm form={form} isAddMode={isAddMode} />
									</div>
									<div className="d-flex" style={{ justifyContent: 'left' }}>
										<Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
									</div>
								</Form>
							</div>
						</div>
					</Col>

				</Row>
			}
		</>
	);
};
export default MasterPageDetail;
