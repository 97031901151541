import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import AlumniDetailForm from './AlumniDetailForm'
import { getObject } from '../../../util/helpers';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import { isInt,getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patchImage,postImage } from '../../../services/';
import { Spin } from 'antd';


const InstituteAlumniDetail = (props) => {
	const[alumniImage, setAlumniImage]= useState(null);
  const locationData = props.location?.locationData
  const [form] = Form.useForm();
	const { instituteID } = useParams();
	const { alumniID } = useParams();
	const  [loader, setLoader] = useState(false);
	const history = useHistory()
	const isAddMode = !isInt(alumniID)
	console.log("isAddMode",isAddMode)
	let url = null;
	let saveURL = API_URL_LIST["INSTITUTE_ALUMNI_DETAIL_POST_URL"].replace("#id#",instituteID)

	if(!isAddMode)
	{
	  url=API_URL_LIST["INSTITUTE_ALUMNI_DETAIL_URL"].replace("#id#",instituteID)
	  url = url.replace("#id1#",alumniID)
	  saveURL = url
	  console.log(url)
	}
	const [data,loading,error] = useFetchData(url)
  const [instituteAlumni,setInstituteAlumni] = useState(null)
	const [disabledSave,setDisabledSave] = useState(false)
	const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
	const [breadCrumb] = useFetchData(dashboardTypeDetailURL)

	const getImage = (data) =>{
	  if(!data.image)
	  {
          return [];
	  }
    return [
		{
		  url: data.image,
		},
	]

  }

	const setInstituteAlumniObj = (data) => {
        const  instituteAlumniObj = {}
        instituteAlumniObj.add_alumni = [{
            name:data.name,
			batch:data.batch,
			designation:data.designation,
			email:data.email,
			linkedin_profile:data.linkedin_profile,
			institute:data.institute,
			object_status:data.object_status.id,
			company:getObject(data.company),
			course:getObject(data.course),
		}]
		instituteAlumniObj.add_alumni[0]['image'] = getImage(data)
		setInstituteAlumni(instituteAlumniObj);
		form.setFieldsValue({"add_alumni":instituteAlumniObj.add_alumni})

	}

	useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {
			  console.log("data",data)
			  setInstituteAlumniObj(data)
		  }
		  else {
		      const alumni_data = [{email:null}]
		      form.setFieldsValue({"add_alumni":alumni_data})
		  }
		}
		return () => mounted = false;
	}, [data])


	const getCleanData = (data) =>
	{
	  let allData = data['add_alumni']
		let temp = JSON.stringify(data['add_alumni'])
        let cleanData = JSON.parse(temp);
        for (const data_obj in cleanData)
		{

			for (let attr in cleanData[data_obj])
			{
			   if (cleanData[data_obj][attr] === "")
			   {
					cleanData[data_obj][attr] = null
			   }
			}
			if(!cleanData[data_obj].course)
			{
				cleanData[data_obj].course = null
			}
			else
			{
				cleanData[data_obj].course = cleanData[data_obj].course.value
			}
			if (cleanData[data_obj].company)
			{
				cleanData[data_obj].company = cleanData[data_obj].company.value
			}
      if (cleanData[data_obj].institute)
		  {
         cleanData[data_obj].institute = cleanData[data_obj].institute.id
      }
       if(alumniImage && alumniImage.length > 0)
		{
	// 		console.log("Image data is ", allData[data_obj].image);
			for (const element of alumniImage)
			{
				if(typeof element.originFileObj !== 'undefined')
			    {
					
		            cleanData[data_obj].image = element.originFileObj;
			    }
			    else{
			          delete cleanData[data_obj].image
			   }
			}
		}
		else{
			          cleanData[data_obj].image = null
			   }
	

    }

        if(!isAddMode) {
        cleanData = cleanData[0]
        }
        return cleanData
	}

	const crudErrorHandler = (error) =>
	{
		showError(error)
	}

	const patchData = (data) =>
	{
		setLoader(true);

	const formData= new FormData();
	   if( data.image instanceof File || data.image === null) {
		if(data.image){
	  formData.append('image', data.image); 
		}
	}
    formData.append('name',data.name)
		formData.append('batch',data.batch)

		if (data.course) {
		formData.append('course',data.course) }

		formData.append('company',data.company)

		if (data.designation) {
		formData.append('designation',data.designation) }

		if (data.email) {
		formData.append('email',data.email) }

		if (data.linkedin_profile) {
	  formData.append('linkedin_profile',data.linkedin_profile) }

		if (data.object_status!=null) {
		formData.append("object_status",data.object_status) }

	  patchImage(saveURL,formData)
	  .then(function (response)
      {
        console.log(response);
		    message.success(COMMON_SUCCESS_MESSAGE);
		    history.push(`/institute/${instituteID}/alumni/`)
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);

      });

	}
	const postData = (data) =>
	{
		setLoader(true);

	let formData= new FormData();
	let count = 1;
  let json_data = {}
	 for (const data_obj in data){
	 if (data[data_obj].image) {
	  formData.append('image'+count, data[data_obj].image); }
    let alumni_data = {}
	  alumni_data['name'] = data[data_obj].name
		alumni_data['batch'] = data[data_obj].batch

		if (data[data_obj].course) {
		alumni_data['course'] = data[data_obj].course}

		alumni_data['company'] = data[data_obj].company

		if (data[data_obj].designation) {
		alumni_data['designation'] = data[data_obj].designation }

		if (data[data_obj].email) {
		alumni_data['email']=  data[data_obj].email}

		if (data[data_obj].linkedin_profile) {
	  alumni_data['linkedin_profile']=data[data_obj].linkedin_profile}

		if (data[data_obj].object_status!=null) {
		alumni_data["object_status"] = data[data_obj].object_status }

    json_data['data'+count] = alumni_data
		count = count+1;
		}
		formData.append('json_data' ,JSON.stringify(json_data))
	  postImage(saveURL,formData)
	  .then(function (response)
      {
		message.success(COMMON_SUCCESS_MESSAGE);
		 history.push(`/institute/${instituteID}/alumni/`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);

      });

	}
  const showError = (errors) =>
  {
     const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
     console.log("server error",error.serverError)
    form.setFields(error.serverError)

  }
  const onFinish=(e)=>
  {
	  const data = form.getFieldsValue(true);
	  const cleanData = getCleanData(data)
	  setDisabledSave(true)
	  if(isAddMode)
	  {
		postData(cleanData)
	  }
	  else
	  {
        patchData(cleanData)
	  }
  }

  return (
  <>
	{error ? message.error(error) : loader === true ? <center><Spin /></center> :
    <Row>
			<Col span={24}>
				<NavBreadCrumb breadCrumb={breadCrumb}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      <div className="ant-card-body">
			      		<h2 className="gx-mb-0"> { instituteAlumni ? `${instituteAlumni.add_alumni[0].institute.name}, Alumni Template` : locationData ? `${locationData?.name}, Alumni Template` : `Alumni Template`} </h2>
						</div>
					</div>
				</div>
			</Col>


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
              <AlumniDetailForm form={form} instituteID={instituteID} isAddMode={isAddMode} cropImage={setAlumniImage}/>
            </div>
             <div className="d-flex" style={{ justifyContent: 'left' }}>
            <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
          </div>
        </div>
			</Col>

		</Row>
		}
		</>
  );
};
export default InstituteAlumniDetail;
