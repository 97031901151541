import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Row, Col, Form, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import SlugRedirectionDetailForm from './SlugRedirectionDetailForm'
import { COMMON_SUCCESS_MESSAGE, COMMON_API_LIST } from '../../../constants/CollegeDekho'
import { isInt, getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patch, post } from '../../../services/';
import { Spin } from 'antd';
import { CONTENT_TYPE_ID } from '../../../constants/Settings';


const SlugRedirectionDetail = (props) => {
  const [form] = Form.useForm();
  const { slugRedirectID } = useParams();
  const history = useHistory()
  const [loader, setLoader] = useState(false);
  const isAddMode = !isInt(slugRedirectID)

  const pathname = useSelector(({ common }) => common.pathname);
  const dashboardType = pathname.includes("institute") ? "institute" : "course";
  const contentTypeId = CONTENT_TYPE_ID[dashboardType]
  let url = null;
  let saveURL = COMMON_API_LIST["SLUG_REDIRECTION_POST_URL"].replace("#id#", contentTypeId)

  if (!isAddMode) {
    url = COMMON_API_LIST["SLUG_REDIRECTION_DETAIL_URL"].replace("#id#", contentTypeId).replace("#id1#", slugRedirectID)
    saveURL = url
  }
  const [data, loading, error] = useFetchData(url)
  const [disabledSave, setDisabledSave] = useState(false)



  const getCleanData = (data) => {
    const cleanData = { ...data }
    cleanData.content_type_id = contentTypeId
    return cleanData
  }

  const crudErrorHandler = (error) => {
    showError(error)
  }

  const patchData = (data) => {
    setLoader(true);
    patch(saveURL, data).then(function (response) {
      console.log(response);
      message.success(COMMON_SUCCESS_MESSAGE);
      history.push(`/${dashboardType}/slug-redirection/`)
    })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false)
        setLoader(false);
      });

  }
  const postData = (data) => {
    setLoader(true);
    post(saveURL, data)
      .then(function (response) {
        message.success(COMMON_SUCCESS_MESSAGE);
        history.push(`/${dashboardType}/slug-redirection/`)
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false)
        setLoader(false);
      });

  }

  const showError = (errors) => {
    const error = getErrors(errors)
    if (error.allError.length > 0) {
      message.error(error.allError.join());
    }
    console.log("server error", error.serverError)
    form.setFields(error.serverError)
  }
  const onFinish = (e) => {
    const data = form.getFieldsValue(true);
    const cleanData = getCleanData(data)
    setDisabledSave(true)

    if (isAddMode) {
      postData(cleanData)
    }
    else {
      patchData(cleanData)
    }
  }

  return (
    <>
      {error ? message.error(error) : loader === true ? <center><Spin /></center> :
        <Row>
          <Col span={24}>
            <NavBreadCrumb />
            <div className="gx-pt-4">
              <div className="ant-card ant-card-bordered gx-card-widget">
                <div className="ant-card-body">
                  <h2 className="gx-mb-0"> {`Slug Redirection`} </h2>
                </div>
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className="ant-card ant-card-bordered gx-card">
              <div className="ant-card-body">
                <Form onFinish={onFinish} form={form}>
                  <div className="gx-p-2">
                    <SlugRedirectionDetailForm form={form} />
                  </div>
                  <div className="d-flex" style={{ justifyContent: 'left' }}>
                    <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      }
    </>
  );
};
export default SlugRedirectionDetail;
