import { Button, Col, Collapse, Form, Row, message } from "antd"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"

import { useEffect, useState } from "react"
import { CustomEditor } from "../../../components/CustomEditor"
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb"
import { patch } from "../../../services"
import { COMMON_SUCCESS_MESSAGE } from "../../../constants/CollegeDekho"
import { getErrors, isInt } from "../../../util/helpers"
import { useFetchData } from "../../../hooks"
import ScholarshipLandingPageForm from "./ScholarshipLandingPageForm"



const ScholarshipLandingDetail = () => {

    const [content, setContent] = useState("")
    
    const { scholarshipLandingPageID } = useParams()
    
    const saveURL = `/api/cms/scholarship/landing/${scholarshipLandingPageID}/`
        
    const history = useHistory()
    const [data, loading, err] = useFetchData(saveURL)
    const Panel = Collapse.Panel

    const handleCollapse = (key) => { }


    const [form] = Form.useForm()
    const getImage = (image) => {
        if (!image) {
            return [];
        }
        return [
            {
                url: image,
            },
        ]

    }
    const setScholarshipLandingPageObj = (data) => {
        
        const scholarshipLandingPageData = {
            slug: data.slug,
            h1: data?.h1 || "",
            title: data?.title,
            description: data?.description,
            keyword: data?.keyword || "",
            snippet: data?.snippet || "",
            image: getImage(data?.image)

        }
       
        form.setFieldsValue(scholarshipLandingPageData)
        setContent(data?.content || "")
        


    }

    useEffect(() => {

        if (data) { setScholarshipLandingPageObj(data); }

    }, [data])


    const getCleanData = (data) => {

        const formData = new FormData();

        data?.title && formData.append("title", data?.title)
        data?.description && formData.append("description", data?.description)
        data?.h1 && formData.append("h1",  data?.h1)
        data?.keyword  && formData.append("keyword", data?.keyword)
        data?.snippet && formData.append("snippet", data?.snippet)
        formData.append("content", content)
        if (data?.image && data.image.length > 0) {
            for (const element of data?.image) {
                if (typeof element.originFileObj !== 'undefined') {
                    formData.append("image", element.originFileObj)
                }
            }

        }
        else {
            formData.append("image", '')
        }

        return formData

    }

    const showError = (errors) => {

        const error = getErrors(errors)
        if (error.allError.length > 0) {
            message.error(error.allError.join());
        }

        form.setFields(error.serverError)

    }

    const crudErrorHandler = (error) => {
        showError(error)


    }
   

    const patchData = (data) => {
    
        patch(saveURL, data).then(function (response) {
            message.success(COMMON_SUCCESS_MESSAGE);

        })
            .catch(crudErrorHandler)
            .finally(() => {

            });

    }

    const submitHandler = () => {
        const data = form.getFieldsValue(true);
        const formData = getCleanData(data)
        patchData(formData)
      
    }


    return (
        <Row>
            <Col span={24}>
                <NavBreadCrumb />
                <div className="gx-pt-4">
                    <div className="ant-card ant-card-bordered gx-card-widget">
                        <div className="ant-card-body">
                            <h2 className="gx-mb-0">
                               { "Scholarship Landing Page"}
                            </h2>

                        </div>
                    </div>
                </div>
            </Col>
            <Col span={24}>

                <Form form={form} onFinish={submitHandler}>
                    <Collapse defaultActiveKey={['1', '2', '3', '4']} onChange={handleCollapse} className="collegeEditCollapse">
                        <Panel header="Basic Details" key={1}>
                            <div className="gx-p-2">
                                <ScholarshipLandingPageForm />
                            </div>
                        </Panel>
                        <Panel header="Content" key={3}>
                            <div className="gx-p-2">
                                <CustomEditor content={content} setContent={setContent}/>
                            </div>
                        </Panel>

                    </Collapse>
                    <div className="d-flex">
                        <Button type="primary" htmlType="submit" > Save</Button>
                    </div>
                </Form>
            </Col>

        </Row>
    )

}
export default ScholarshipLandingDetail