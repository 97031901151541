import { Button, Col, Divider, Input, Pagination, Row, Table, message } from "antd"
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { FormOutlined, SearchOutlined } from "@ant-design/icons"
import { get } from "../../../services"
import { getErrors } from "../../../util/helpers"
import { PAGE_SIZE } from "../../../constants/CollegeDekho"
import { CONTENT_TYPE_ID } from "../../../constants/Settings"


const ScholarshipListing = () => {

  // const [data, loading, error] = useFetchData(BASE_URL+"/api/cms/institute")
  const [scholarships, setScholarships] = useState([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [text, setText] = useState()
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE)


  const getScholarshipList = (data) => {
    const url = "/api/cms/scholarship/"
    return get(url, data)
  }

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);

  }

  function onChange(page, PageSize) {
    setPage(page);
  }

  const columns = [
    {
      title: "name",
      dataIndex: "name",
    },
    {
      title: "slug",
      dataIndex: "slug",
    },
    {
      title: "amount",
      dataIndex: "scholarship_min_amount",
    },
    {
      title: 'Status',
      key: 'object_status',
      render: (text, record) => (

        <span>{record?.object_status?.name}</span>

      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <span className="gx-link"><Link to={'/scholarship/' + record.id + '/update/'}> <FormOutlined /></Link>
          </span>

          <Divider type="vertical" />
          <span className="gx-link">
            <Link to={{ pathname: `/scholarship/${record.id}/component` }}>
              Components
            </Link>
          </span>
        </span>

      ),
    },
    {
      title: 'FAQ',
      key: 'faq',
      render: (text, record) => (
        <span className="gx-link">
          <Link to={`/scholarship/${record.id}/faqs/${CONTENT_TYPE_ID.scholarship}/`}>
            <FormOutlined />
          </Link>
        </span>
      ),
    },
  ];



  useEffect(() => {
    setLoading(true)

    getScholarshipList({ page, pageSize, text }).then((res) => {
      setScholarships(res.data.results)
      setLoading(false)
    }
    ).catch((res) => {
      const err = getErrors(res)
      setError(err.allError)

    })

  }, [page, pageSize, text])

  const onPressEnterSearchInput = (e) => {

    if (e.target.value.length > 0) {
      setText(e.target.value);
    }
  }


  return (
    <Row>
      {error && error.length > 0 ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered searchBox gx-card">
            <div className="ant-card-body">
              <Input placeholder="Search by Scholarship Name or Scholarship ID	" prefix={<SearchOutlined />} allowClear onPressEnter={onPressEnterSearchInput} />
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Link to="/scholarship/create/">
          <Button type="primary">
            <span>Add New scholarship</span>
          </Button>
        </Link>

      </Col>


      <Col span={24}>
        {scholarships.length > 0 &&
          <Table
            className="gx-table-responsive"
            rowKey={"id"}
            dataSource={scholarships}
            columns={columns}
            loading={loading}
            pagination={false}
          />}
      </Col>

      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          pageSize={pageSize}
          // total={total}
          current={page}
        />
      </Col>
    </Row>
  )
}
export default ScholarshipListing