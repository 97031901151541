import React from 'react';
import { Row, Col, Form,Input, Select, DatePicker} from "antd";
import DebounceSelect from '../../../components/DebounceSelect'
import { getCityList } from "../../../services/City/City";
import { getStateList } from "../../../services/State/State";
import { getUniversityList } from "../../../services/University/University";
import { getInstituteType,getObjectStatus, getInstitutionType, getService, getPartnerStatus, getAdmissionStatus } from '../../../util/helpers';
import { getInstituteAutoSuggestion } from "../../../services/Institute/Institute";
import moment from 'moment';
const instituteType = getInstituteType()
const institutionType = getInstitutionType()
export const objectStatus = getObjectStatus()
export const service = getService()
const partnerStatusOptions = getPartnerStatus()
const admissionStatus = getAdmissionStatus()
const {YearPicker} = DatePicker;


const BasicDetails=({partnerStatus, setPartnerStatus, form})=>{
	const {getFieldDecorator} = form
	function disabledDate(current) {
		// Can not select days after today and today
		return current && current > moment().endOf('day');
	  }
	const handleClearPartnerStatus = () => {
		setPartnerStatus(null)
		form.setFieldsValue({admission_status: null})
	}
	const handlePartnerStatusChange = (e) => {
		if(e){
			setPartnerStatus(e);
			form.setFieldsValue({admission_status: 1})
		}
		else{
			setPartnerStatus(null);
			form.setFieldsValue({admission_status: null})
		}
	}
	
	return(
		  <> 
		  {console.log("Partner status", partnerStatus)}
		  <Row>
				<Col span={18}>
				<Form.Item label="College Name" name="name" className="labelInput" 
					 	 rules={[
			              {
			                required: true,
			                message: 'College name required',

			              },
						  { type: 'string', max: 255 }

			       ]}>
					   <Input className="gx-mb-3" name="name"/>
				</Form.Item> 
				</Col>
				<Col span={6}> 
					 <Form.Item label="Short Name" name="short_name" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'College short name Required',
			              },
						  { type: 'string', max: 255 }
			       ]}>
					<Input className="gx-mb-3"/>
					 </Form.Item>  
				</Col>
				<Col span={8}> 
					<Form.Item label="University" name="university" className="labelInput">
					
					<DebounceSelect
					 allowClear={true}
                     mode="single"
					 name="university"
                     placeholder="Select University"
                     fetchOptions={getUniversityList}
					 showSearch = {true}
                  />

					</Form.Item>
				</Col>
				<Col span={8}> 
					<Form.Item label="State" name="state" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'State Required',
			              },
			       ]}>
					
					<DebounceSelect
                     mode="single"
					 name="state"
                     placeholder="Select State"
                     fetchOptions={getStateList}
					 showSearch = {true}
					 allowClear={true}
                  />

					

					</Form.Item>
				</Col>
				<Col span={8}> 
				<Form.Item label="City" name="city" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'City Required',
			              },
			       ]}>
					
					<DebounceSelect
					 allowClear={true}
                     mode="single"
                     placeholder="Select City"
                     fetchOptions={getCityList}
					 showSearch = {true}
                  />

					</Form.Item>
				</Col>
				<Col span={8}> 
					<Form.Item label="College Type" name="institute_type" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'Institute type required',
			              },
			       ]}>
					<Select placeholder="Select Type" name="institute_type" style={{width: '100%'}} className="gx-mb-3" options={instituteType}>
					
					</Select>
					</Form.Item>
				</Col>

				<Col span={8}> 
				<Form.Item label="Established Year" name="estb_date" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'Established year required',
			              },

			       ]}>
					<YearPicker className="gx-mb-3 gx-w-100"  placeholder="Select Year" disabledDate={disabledDate}/>
					</Form.Item>
					</Col>
				<Col span={8}> 
					<Form.Item label="Year of accreditation" name="year_of_accredition" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'Year of accreditation required',
			              },
			       ]}>
					<YearPicker className="gx-mb-3 gx-w-100"  placeholder="Year of accreditation" disabledDate={disabledDate}/>
					</Form.Item>
					</Col>

					<Col span={12}> 
					<Form.Item label="Institution Type" name="type" className="labelInput"
					 	 rules={[
			              {
			                required: true,
			                message: 'Institution type required',
			              },
			       ]}>
					<Select placeholder="Select Type" name="i_type" style={{width: '100%'}} className="gx-mb-3" options={institutionType}>
					
					</Select>
					</Form.Item>
				</Col>
				<Col span={12}>

				<Form.Item label="Partner Status" name="partner_status" className="labelInput">
					<Select 
					placeholder="Select Type" 
					name="partner_status" 
					style={{width: '100%'}} 
					className="gx-mb-3" 
					options={partnerStatusOptions}
					allowClear={true}
					onChange={(e)=>handlePartnerStatusChange(e)}
					onClear={handleClearPartnerStatus}>
					
					</Select>
					</Form.Item>

				</Col>


				{ partnerStatus!==null &&
					<Col span={12}>

					<Form.Item label="Admission Status" name="admission_status" className="labelInput">
					<Select 
						placeholder="Select Type" 
						style={{ width: '100%' }} 
						className="gx-mb-3" 
						options={admissionStatus}
						allowClear={true}
						/>
					
					</Form.Item>

					</Col>
				}
				
				<Col span={24}>
					<Form.Item label="Other Campus" name="other_campuses" className="labelInput">
					
					<DebounceSelect
                     mode="multiple"
					 name="other_campuses"
                     
                     placeholder="Select Othercampus"
                     fetchOptions={getInstituteAutoSuggestion}
					 showSearch = {true}
                  />
					</Form.Item>
					
				</Col>

				<Col span={8}>
					<Form.Item label="Object Status" name="object_status" className="labelInput" 
					 	 rules={[
			              {
			                required: true,
			                message: 'Object status',
			              },
			       ]}>
					
					<Select placeholder="Object Status" name="object_status" style={{width: '100%'}} className="gx-mb-3" options={objectStatus}>
					
					</Select>

					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item label="Service" name="service" className="labelInput" >
					<Select placeholder="Service" name="service" style={{width: '100%'}} className="gx-mb-3" options={service} disabled	>
					
					</Select>

					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item 
					label="Institute Seat Filled" 
					name="number_of_seats_filled" 
					className="labelInput" 
					>
					<Input className="gx-mb-3" type='number' name='number_of_seats_filled' />

					</Form.Item>
				</Col>

				<Col span={24}>	

					<Form.Item label="Popular Name" name="synonym" className="labelInput"
					 	 rules={[
			              {
			                required: false,
			                message: 'College popular name Required',
			              },
						  { type: 'string', max: 1000 }
			       ]}>
					<Input className="gx-mb-3"/>
					 </Form.Item>  


				</Col>


			</Row>
			</>
		)
}

export default BasicDetails