import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getInstitutePlacementList } from "../../../services/InstitutePlacement/InstitutePlacement";

import { Divider, Row, Col, Table, Pagination, Button, message } from "antd";
import { getErrors } from "../../../util/helpers";
import { FormOutlined } from "@ant-design/icons";
import { API_URL_LIST, OBJECT_STATUS, PAGE_SIZE } from "../../../constants/CollegeDekho";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import { useFetchData } from "../../../hooks";

const InstitutePlacementListing = () => {
  const [institutePlacementList, setInstitutePlacementList] = useState([]);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const text= "";
  const [institute, setInstitute] = useState({});
  const { instituteID } = useParams();
  const instituteDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
  const [instituteData] = useFetchData(instituteDetailURL)

  function findValueInObjectStatus(valueToFind) {
    for (const status of OBJECT_STATUS) {
      if (status[0] === valueToFind) {
        return status[1]; // Return the 1st index value when a match is found.
      }
    }
    return null; // Return null if the value is not found.
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Placement Year",
      dataIndex: "year",
    },
    {
      title: "Content Type",
      // dataIndex: "content_type",
      render: (text, record) => (
        <span>
          {/* <Divider type="vertical" /> */}
          <span>{record?.content_type?.name}</span>
        </span>
      ),
    },
    {
      title: "Object",
      render: (text, record) => (
        <span>
          {/* <Divider type="vertical" /> */}
          <span>{record?.object_id?.name}</span>
        </span>
      ),
    },
    {
      title: "Object Status",
      // dataIndex: "object_status",
      render: (text, record) => (
        <span>
          {/* <Divider type="vertical" /> */}
          <span>{findValueInObjectStatus(record?.object_status)}</span>
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            <Divider type="vertical" />
            <span className="gx-link">
              <Link
                to={`/institute/${instituteID}/placement/${record.id}/update/`}
              >
                <FormOutlined />
              </Link>
            </span>
          </span>
        );
      },
    },
    {
      title: "Student",
      key: "action",
      render: (text, record) => {
        console.log("recoirdd", record);
        return (
          <span>
            <Divider type="vertical" />
            <span className="gx-link">
              <Link
                to={{
                  pathname: `/institute/${instituteID}/placement/${record?.id}/student-placement`,
                  // state: record,
                }}
              >
                <FormOutlined />
              </Link>
            </span>
          </span>
        );
      },
    },
    {
      title: "Sector",
      key: "action",
      render: (text, record) => {
        console.log("recoirdd", record);
        return (
          <span>
            <Divider type="vertical" />
            <span className="gx-link">
              <Link
                to={{
                  pathname: `/institute/${instituteID}/placement/${record?.id}/sector-placement`,
                }}
              >
                <FormOutlined />
              </Link>
            </span>
          </span>
        );
      },
    },
    {
      title: "Company",
      key: "action",
      render: (text, record) => {
        console.log("recoirdd", record);
        return (
          <span>
            <Divider type="vertical" />
            <span className="gx-link">
              <Link
                to={{
                  pathname: `/institute/${instituteID}/placement/${record?.id}/company-placement`,
                }}
              >
                {" "}
                <FormOutlined />
              </Link>
            </span>
          </span>
        );
      },
    },
  ];
  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getInstitutePlacementList({ instituteID, page, pageSize, text })
      .then((data) => {
        if (mounted) {
          console.log("daatd", data.data);
          setInstitutePlacementList(data.data.results);
          setTotal(data.data.count);
          setInstitute(data.data.extra_details.institute);
          setLoading(false);
          setError(null);
        }
      })
      .catch(function (response) {
        console.log("response", response);
        const err = getErrors(response);
        setError(err.allError);
        console.log(err);
      });

    return () => (mounted = false);
  }, [page, pageSize, text, instituteID]);

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);
    console.log(current, PageSize);
  }
  function onChange(page, PageSize) {
    setPage(page);
    console.log(page, PageSize);
  }

  return (
    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb breadCrumb={instituteData}/>
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered gx-card-widget">
            <div className="ant-card-body">
              <h2 className="gx-mb-0">{institute?.name}, Placements</h2>
            </div>
          </div>
        </div>
        <Row>
          <Col span={18}></Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Link
              to={{
                pathname: `/institute/${instituteID}/placement/create/`,
                locationData: { name: institute?.name },
              }}
            >
              <Button type="primary">
                <span>Add Placement Data</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Table
          className="gx-table-responsive"
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={institutePlacementList}
          pagination={false}
        />
      </Col>
      <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          pageSize={pageSize}
          total={total}
          current={page}
        />
      </Col>
    </Row>
  );
};

export default InstitutePlacementListing;
