import { getAutoSuggestionList } from "..";

export async function getCategory(text){
    console.log('fetching categories', text);
    const url =   `/api/cms/autosuggestion/scholarship-category/?text=${text}&page_size=1000&page=1`;
    return await getAutoSuggestionList(url)
   
}

export async function getSubCategory(categoryIds){
    console.log('fetching sub categories for', categoryIds);
    const url =   `/api/cms/autosuggestion/scholarship-subcategory/?category_ids=${categoryIds}`;
    return await getAutoSuggestionList(url)
}