import React from 'react';
import { Link } from 'react-router-dom';

const style = {

  textAlign: 'center'
};

const NotFound = () => (

  <div style={style}>
    <h1>404 - Page Not Found!</h1>
    <Link to="/institute">Go Home</Link>
  </div>
);

export default NotFound;