import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import LatestUpdateDetailForm from './LatestUpdateDetailForm'
import { getObject } from '../../../util/helpers';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST,DATE_TIME_FORMAT} from '../../../constants/CollegeDekho'
import { isInt,getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks";
import { patch,post } from '../../../services';
import { CustomEditor } from '../../../components/CustomEditor/';
import moment from 'moment';
import { Spin } from 'antd';


const InstituteLatestUpdateDetail = (props) => {
    const [form] = Form.useForm();
	const { instituteID } = useParams();
	const { instituteLatestUpdateID } = useParams();
	const history = useHistory()
	const [disabledSave,setDisabledSave] = useState(false)
	const [content, setContent] = useState("");
	const  [loader, setLoader] = useState(false);
	const locationData = props.location?.locationData
	const [extraDetails, setExtraDetails] = useState(locationData !== 'undefined' ? locationData : null);
	const  isAddMode = !isInt(instituteLatestUpdateID)
	console.log("isAddMode",isAddMode)
	let url = null;
	let saveURL = API_URL_LIST["INSTITUTE_LATEST_UPDATE_DETAIL_POST_URL"].replace("#id#",instituteID)

	if(!isAddMode)
	{
	  url = API_URL_LIST["INSTITUTE_LATEST_UPDATE_DETAIL_URL"].replace("#id#",instituteID)
	  url = url.replace("#id1#",instituteLatestUpdateID)
	  saveURL = url
	  console.log(url)
	}
	const [data,loading,error] = useFetchData(url)

	const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
	const [breadCrumb] = useFetchData(dashboardTypeDetailURL)
 
	const setInstituteLatestUpdateObj = (data) => {
        const  instituteLatestUpdateObj = {
	            heading:data.heading,
                date: moment(data.date, DATE_TIME_FORMAT),
				end_date: data.end_date ? moment(data.end_date, DATE_TIME_FORMAT) : null,
				schedule_date: data.schedule_date ? moment(data.schedule_date, DATE_TIME_FORMAT) : null,
                object_status:data.object_status === true ? 1 : 0,
				object_id:getObject(data.content_object),
		}
		form.setFieldsValue(instituteLatestUpdateObj)
		setContent(data.overview)
		setExtraDetails(data.content_object)

	}


	useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {

			  setInstituteLatestUpdateObj(data)


		  }

		}


		return () => mounted = false;
	}, [data])

	const getCleanData = (data) =>
	{
        const cleanData = {...data}
        console.log(cleanData)
        cleanData.date = cleanData["date"].format(DATE_TIME_FORMAT);
		if(cleanData["end_date"])
		{
          cleanData.end_date = cleanData["end_date"].format(DATE_TIME_FORMAT);
		}
		if(cleanData["schedule_date"])
		{
          cleanData.schedule_date = cleanData["schedule_date"].format(DATE_TIME_FORMAT);
		}

		cleanData.overview = content
		cleanData.object_id = data.object_id.value
		cleanData.content_type = "institutemasterpage"
        console.log("data..",cleanData)

        return cleanData
	}

	const crudErrorHandler = (error) =>
	{
		showError(error)
	}

	const patchData = (data) =>
	{
		setLoader(true);
	  patch(saveURL,data).then(function (response)
      {

		message.success(COMMON_SUCCESS_MESSAGE);
		history.push(`/institute/${instituteID}/latest-update/${response.data.id}/update/`)
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);
      });
	}

  const postData = (data) =>
	{
		setLoader(true);
	  post(saveURL,data)
	  .then(function (response)
      {
		 message.success(COMMON_SUCCESS_MESSAGE);
		 history.push(`/institute/${instituteID}/latest-update/${response.data.id}/update/`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);
      });

	}

  const showError = (errors) => {

	const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
    form.setFields(error.serverError)

  }


  const onFinish=(e)=>
    {
      const data = form.getFieldsValue(true);
      const cleanData = getCleanData(data)
      setDisabledSave(true)
      if(isAddMode)
      {
           postData(cleanData)
      }
      else
      {
          patchData(cleanData)
      }
    }



  return (
	  <>
	{error ? message.error(error) : loader === true ? <center><Spin /></center> :
    <Row>

			<Col span={24}>
				<NavBreadCrumb breadCrumb={breadCrumb}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">{extraDetails?.parent?.name} Latest Updates </h2>

						</div>
					</div>
				</div>
			</Col>


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
              <LatestUpdateDetailForm form={form}  />
            </div>

			<div className="gx-p-2">
			    <CustomEditor content={content} setContent={setContent} />
            </div>


              <div className="d-flex" style={{ justifyContent: 'left' }}>
                <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
              </div>
          </Form>
          </div>
        </div>
			</Col>

		</Row>
   }
	</>

  );
};
export default InstituteLatestUpdateDetail;
