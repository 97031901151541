import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {Row, Col, Form, Checkbox, Input, Select } from "antd";
import DebounceSelect from '../../../components/DebounceSelect'
import { getInstituteDegreeList } from '../../../services/Degree/Degree';
import { getInstituteStreamList } from '../../../services/Stream/Stream';
import { getInstituteSpecializationList } from '../../../services/Specialization/Specialization';
import { getObjectStatus } from "../../../util/helpers";
import { useFetchData } from "../../../hooks/";
import {API_URL_LIST} from '../../../constants/CollegeDekho'

const objectStatusOptions =  getObjectStatus()





const InstitutePageComponentForm=({form,isAddMode})=>{

	const {instituteID} =  useParams();
	const component = [{value: 41, label: "Static"}]



	return(
		   <Row>
				<Col span={20}>
				    <Form.Item label="Component" name="component" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'Select component',
			              }

			       ]}>
                    <Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select Component"
                        options={component}
						labelInValue = {true}
						showSearch = {true}
						
					    >
                    </Select>

					</Form.Item>
				</Col>
				<Col span={8}>
				    <Form.Item label="Degree" name="degree" className="labelInput" >
					<DebounceSelect
                     mode="multiple"
					 name="degree"
					 cldID={instituteID}
                     placeholder="Select degree"
                     fetchOptions={getInstituteDegreeList}
					 showSearch = {true}

                  />
					</Form.Item>
				</Col>
				<Col span={8}>
				    <Form.Item label="Stream" name="stream" className="labelInput" >

					<DebounceSelect
                     mode="multiple"
					 name="stream"
					 cldID={instituteID}
                     placeholder="Select Stream"
                     fetchOptions={getInstituteStreamList}
					 showSearch = {true}

                  />

					</Form.Item>
				</Col>
				<Col span={8}>
				    <Form.Item label="Specialization" name="specialization" className="labelInput" >
                    <DebounceSelect
                     mode="multiple"
					 name="specialization"
                     placeholder="Select specialization"
					 cldID={instituteID}
                     fetchOptions={getInstituteSpecializationList}
					 showSearch = {true}


                  />
					</Form.Item>
				</Col>

				<Col span={20}>
				    <Form.Item label="H2" name="h2" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'H2',
			              }

			       ]}>
                       <Input className="gx-mb-3" />

                       </Form.Item>

				</Col>


                <Col span={8}>
					<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
			              {
			                required: true,
			                message: 'Object Status Required',
			              },
			       ]}>
						<Select
						allowClear={true}
						style={{width: '100%'}}
						placeholder="Select object status"
                        options={objectStatusOptions}
					    >
                        </Select>

					</Form.Item>
				</Col>

				<Col span={8}>
				<Form.Item label=" " colon={false} name="has_read_more" className="labelInput" valuePropName="checked">

					<Checkbox className="gx-mb-3" name="has_read_more" >Has Read More</Checkbox>
				</Form.Item>
				</Col>

			</Row>
		)
}

export default InstitutePageComponentForm
