import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { API_URL_LIST, COMMON_SUCCESS_MESSAGE } from "../../../constants/CollegeDekho";
import { useFetchData } from "../../../hooks/";
import { getMasterPageList } from "../../../services/MasterPage/MasterPage";
import { Divider, Row, Col, Alert, Modal, Table, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import DebounceSelect from '../../../components/DebounceSelect'
import { post, get } from '../../../services/';
import { getErrors } from "../../../util/helpers";
import { FormOutlined } from "@ant-design/icons";
import { CONTENT_TYPE_ID } from "../../../constants/Settings";
const columns = [{
  title: 'ID',
  dataIndex: 'id',

},
{
  title: 'Name',
  key: 'name',
  render: (text, record) => (
    <span>
      {record.master_page?.name}
    </span>
  ),
},
{
  title: 'H1',
  dataIndex: 'h1',

},
{
  title: 'Title',
  dataIndex: 'title',

},

{
  title: 'Order',
  key: 'order',
  render: (text, record) => (
    <span>
      {record.master_page?.order}
    </span>
  ),

},
{
  title: 'Status',
  key: 'status',
  render: (text, record) => (
    <span>
      {record.object_status.name}
    </span>
  ),
},
{
  title: 'FAQ',
  key: 'faq',
  render: (text, record) => (
    <span>
      {console.log({ record })}
      <Link to={{
        pathname: `/institute/${record.institute.id}/faqs/${CONTENT_TYPE_ID.institute}`,
        search: `?page_content_type_id=${CONTENT_TYPE_ID.instituteMasterPage}&page_object_id=${record.id}`
      }}>
        <FormOutlined />
      </Link>
    </span>
  ),
},
{
  title: 'Action',
  key: 'action',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span className="gx-link">
        <Link to={`/institute/${record.institute.id}/page/${record.id}/update/`}> Edit/SEO</Link>
      </span>
      <Divider type="vertical" />
      <span className="gx-link">
        <Link to={{ pathname: `/institute/${record.institute.id}/page/${record.id}/component`, locationData: { "institute": { "name": record.institute.name }, "master_page": { "name": record.master_page?.name } } }}>
          Components
        </Link>
      </span>



    </span>
  ),
}
];



const InstitutePageListing = () => {

  const { instituteID } = useParams();

  const url = API_URL_LIST["INSTITUTE_PAGE_LISTING"].replace("#id#", instituteID)
  const instituteDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)

  //const [data,loading,error] = useFetchData(url)
  const [institute] = useFetchData(instituteDetailURL)
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const [newTemplateVisible, setNewTemplateVisible] = useState(false)

  const [masterPage, setMasterPage] = useState(null)
  const [isError, setIsError] = useState(false)
  const [messageText, setMessageText] = useState("")
  const [refresh, setRefresh] = useState(false)


  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      setLoading(true);
      await get(url)
        .then(function (data) {
          if (mounted) {
            setData(data.data);
            setLoading(false);
            setError(null);

          }

        })
        .catch(function (errors) {
          const err = getErrors(errors)
          setError(err.allError);
        });
    }

    if (url) {
      getData();
    }

    return () => mounted = false;
  }, [url, refresh])


  const newTemplateModel = (e) => {
    setNewTemplateVisible(true);
    setIsError(false)
  }
  const crudErrorHandler = (error) => {
    showError(error);
  }
  const postData = () => {
    post(url, { master_page: masterPage.value, object_status: 1 })
      .then(function (response) {
        setNewTemplateVisible(false);
        message.success(COMMON_SUCCESS_MESSAGE);
        setRefresh(true)
      })
      .catch(crudErrorHandler)
      .finally(() => {
        //setDisabledSave(false)
      });

  }
  const handleOk = (e) => {
    //setNewTemplateVisible(false);
    if (!masterPage) {
      setIsError(true)
      setMessageText("Please choose template")
      return;
    }
    postData()
  }

  const handleCancel = (e) => {
    setNewTemplateVisible(false);
  }

  const showError = (err) => {
    const errors = getErrors(err)
    setIsError(true)
    setMessageText(errors.allError.join())
  }


  return (
    <>
      {error ? message.error(error) :
        <Row>
          <Col span={24}>
            <NavBreadCrumb breadCrumb={institute}/>
            <div className="gx-pt-4">
              <div className="ant-card ant-card-bordered gx-card-widget">
                <div className="ant-card-body">
                  <h2 className="gx-mb-0">{institute && institute.name}, Templates </h2>



                  <Modal
                    title="New Templete"
                    visible={newTemplateVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    {isError &&
                      <Alert type="error" message={messageText} banner />
                    }

                    <DebounceSelect

                      mode="single"
                      name="master-page"
                      placeholder="Select Master Page"
                      fetchOptions={getMasterPageList}
                      showSearch={true}
                      style={{ width: '100%' }}
                      onChange={(newValue) => {
                        setMasterPage(newValue);
                        console.log(newValue);
                      }}
                    />
                  </Modal>
                </div>
              </div>
            </div>
            <Row>
              <Col span={18}>
                {/* <div className="ant-card ant-card-bordered searchBox gx-card">
            <div className="ant-card-body">
              <Input placeholder="default size" prefix={<SearchOutlined/>}  />
            </div>
            </div> */}
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>

                <Button type="primary" onClick={newTemplateModel}>Add New Page</Button>
              </Col>
            </Row>
          </Col>

          <Col span={24}>


          </Col>

          <Col span={24}>
            <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={data?.results} pagination={true} />
          </Col>

        </Row>
      }
    </>
  );
};

export default InstitutePageListing;
