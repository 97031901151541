import React,{useState,useEffect} from "react";
import {  Link,useParams } from 'react-router-dom';
import {Row, Col,Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import DragNDrop from './DragnDrop';
import { API_URL_LIST } from '../../../constants/CollegeDekho'
import { useFetchData } from "../../../hooks/";

const InstitutePageComponentListing = (props) =>
{

  const { instituteID } = useParams();
  const { instituteMasterPageID } = useParams();
  let url = API_URL_LIST["INSTITUTE_PAGE_COMPONENT_LISTING"].replace("#id#",instituteID)
  url = url.replace("#id1#",instituteMasterPageID)
  const [data,loading,error] = useFetchData(url,{"page_size":2000})
  const [dataSource, setDataSource] = useState([]);
  const [extraDetails, setExtraDetails] = useState(null);
  const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
  const [breadCrumb] = useFetchData(dashboardTypeDetailURL)



  useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {
			  console.log("data",data.results)
        setDataSource(data.results)
        setExtraDetails(data.extra_details)

		  }
		}


		return () => mounted = false;
	}, [data])



  return (

 <Row>
    {error ? message.error(error) : null}
     <Col span={24}>
         <NavBreadCrumb page={extraDetails?.master_page?.name} breadCrumb={breadCrumb}/>
         <div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">
                  {extraDetails?.institute.name}, ({extraDetails?.master_page?.name})
                </h2>
						</div>
					</div>
				</div>
        <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
            <Link to={{pathname: `/institute/${instituteID}/page/${instituteMasterPageID}/component/create/` }}>

                  <Button>
                     <span>Add New Component</span>
                  </Button>
              </Link>


            </Col>
        </Row>
     </Col>

     <Col span={24}>
        <DragNDrop dataSource={dataSource} setDataSource={setDataSource} />
     </Col>
    </Row>
  );
};

export default InstitutePageComponentListing;
