import { getLog } from '../../../services';
import { Col, Row, Button, Form, Select, message } from "antd";
import React, { useState, useEffect } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { useParams, useHistory } from "react-router-dom";
import DebounceSelect from "../../../components/DebounceSelect";
import { getLogListing } from "../../../services/Logs/AutoSuggestion";
import { LOG_BASE_URL } from "constants/Settings.js";
import { CONTENT_LOG_API_LIST, LOG_CONTENT_TYPE } from "../../../constants/CollegeDekho";
import { getErrors } from "../../../util/helpers";
import { getPageWidgetList } from "../../../services/Logs/logsList";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";



const CompareLogs = () => {
  const { templateId, objectId, version1, version2 } = useParams();
  const [logContent, setLogContent] = useState([]);
  const [form] = Form.useForm();
  const [versionOne, setVersionOne] = useState(version1);
  const [versionTwo, setVersionTwo] = useState(version2);
  const [error, setError] = useState(null);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [widgetList, setWidgetList] = useState([]);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const history = useHistory();
  const newsContentTypeIDs = ["1", "5", "6", "7"]

  const getContentLogData = async (version1, version2) => {
    let url =
      CONTENT_LOG_API_LIST["DEFAULT_LOG_COMPARE_URL"]
        .replace("#id#", templateId)
        .replace("#id1#", objectId);
    if (version1 && version2) {
      url =
        CONTENT_LOG_API_LIST["LOG_VERSION_COMPARE_URL"]
          .replace("#id#", templateId)
          .replace("#id1#", objectId)
          .replace("#id2#", versionOne?.value || version1)
          .replace("#id3#", versionTwo?.value || version2);
    }

    let respData = await getLog(url).then(response => response.data).catch(err => console.log(err))
    setLogContent(respData);
    if (respData?.old_id)
      setVersionOne({
        value: respData?.old_id?.id,
        label: respData?.old_id?.label,
      });
    if (respData?.new_id)
      setVersionTwo({
        value: respData?.new_id?.id,
        label: respData?.new_id?.label,
      });
  };

  useEffect(() => {
    async function fetchData() {
      await getContentLogData(version1, version2);

      if (!newsContentTypeIDs.includes(templateId)) {
        let mounted = true;
        await getPageWidgetList(templateId, objectId)
          .then((data) => {
            if (mounted) {
              setPageList(data.data.results);

              setSelectedPage({
                value: data.data.current_page.id,
                label: data.data.current_page.name,
              });
              const currentPageData = data.data.results.find(
                (page) => page.id === data.data.current_page.id
              );
              if (currentPageData) {
                setWidgetList(currentPageData.widgets);
              }

              setError(null);
            }
          })
          .catch(function (response) {
            const err = getErrors(response);
            setError(err.allError);
            console.log(err);
          });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    widgetList &&
      setSelectedWidget(
        widgetList.find((widget) => widget.id === parseInt(objectId))
      );

    // Set the defaultValue in the form field when it becomes available
    if (selectedPage !== null) {
      form.setFieldsValue({ page: selectedPage?.value });
    }
    if (selectedWidget !== null) {
      form.setFieldsValue({ widget: selectedWidget?.id });
    }
    if (versionOne !== null) {
      form.setFieldsValue({
        version1: { value: versionOne?.id, label: versionOne?.label },
      });
    }
    if (versionTwo !== null) {
      form.setFieldsValue({
        version2: { value: versionTwo?.id, label: versionTwo?.label },
      });
    }
  }, [
    selectedPage,
    widgetList,
    selectedWidget,
    versionOne,
    versionTwo,
    form,
    objectId,
  ]);

  const handlePageChange = (value) => {
    const selectedPageData = pageList.find((page) => page.id === value);
    setSelectedPage({
      value: selectedPageData.id,
      label: selectedPageData.name,
    });
    setWidgetList(selectedPageData.widgets);
  };

  const handleWidgetChange = (value) => {
    window.open(`/logs/${templateId}/${value.toString()}/compare`, "_self");
  };

  const pageOptions = pageList.map((page) => ({
    value: page.id,
    label: page.name,
  }));

  const widgetOptions = widgetList.map((widget) => ({
    value: widget.id,
    label: widget.name,
  }));

  const generateDiff = () => {
    getContentLogData(versionOne, versionTwo);
    history.push(
      `/logs/${templateId}/${objectId}/compare/${versionOne?.value}/${versionTwo?.value}`
    );
  };

  return (
    <>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb
          page={LOG_CONTENT_TYPE[templateId]}
          page2={logContent?.content_name}
        />
      </Col>
      <div className="gx-pt-4">
        <div className="ant-card ant-card-bordered gx-card">
          <div className="ant-card-body">
            <Form form={form}>
              <Row className="logs-autosuggetion-id">
                {!newsContentTypeIDs.includes(templateId) ? (
                  <>
                    <Col span={8}>
                      <Form.Item
                        label="Page"
                        name="page"
                        className="labelInput"
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.page !== currentValues.page
                        }
                      >
                        <Select
                          name="page"
                          allowClear={true}
                          style={{ width: "100%" }}
                          placeholder="Select Page"
                          options={pageOptions}
                          onChange={handlePageChange}
                        ></Select>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Widget"
                        name="widget"
                        className="labelInput"
                      >
                        <Select
                          allowClear={true}
                          style={{ width: "100%" }}
                          placeholder="Select Widget"
                          options={widgetOptions}
                          onChange={handleWidgetChange}
                        ></Select>
                      </Form.Item>
                    </Col>

                    <Col span={8}></Col>
                  </>
                ) : null}

                <Col span={8}>
                  <Form.Item
                    label="Version 1"
                    name="version1"
                    className="labelInput"
                  >
                    <DebounceSelect
                      mode="single"
                      fetchOnClick
                      placeholder="Select the Version"
                      fetchOptions={getLogListing}
                      cldID={{ templateId, objectId }}
                      onChange={(version) => {
                        return setVersionOne({
                          value: version.value,
                          label: version.label,
                        });
                      }}
                      showSearch={true}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Version 2"
                    name="version2"
                    className="labelInput"
                  >
                    <DebounceSelect
                      mode="single"
                      fetchOnClick
                      cldID={{ templateId, objectId }}
                      placeholder="Select the Version"
                      fetchOptions={getLogListing}
                      onChange={(version) => {
                        return setVersionTwo({
                          value: version.value,
                          label: version.label,
                        });
                      }}
                      showSearch={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "right", marginTop: 35 }}
                  >
                    <Button type="primary" onClick={generateDiff}>
                      Apply
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <Row>
        <div className="overflow-table">
          <Col span={24}>
            <ReactDiffViewer
              oldValue={logContent?.old_data}
              newValue={logContent?.new_data}
              compareMethod={DiffMethod.WORDS}
              splitView={true}
              style={{ width: "100%" }}
            />
          </Col>
        </div>
      </Row>
    </>
  );
};

export default CompareLogs;
