import {
  INSTITUTE_TYPE, GENDER, LEVEL, COURSE_MODE, DURATION_TYPE,
  COURSE_TYPE, FEE_TYPE, COURSE_ATTRIBUTE, CURRENCY, PROGRAM, SHIFT, OBJECT_STATUS,
  COMPONENT, COMMON_ERROR_MESSAGE, PAGE_TYPE, IMAGE_TYPE_CHOICES, VIDEO_TYPE_CHOICES,SERVICES,
  FEE_FREQUENCY_TYPE, COURSE_COMPONENT_LIST, COURSE_MASTER_PAGE_LIST, YES_NO_TYPE, COURSE_DURATION_TYPE,
  INSTITUTION_TYPE, SCHOLARSHIP_COMPONENT_LIST, PARTNER_STATUS, ADMISSION_STATUS, SIZE_TYPE, LANGUAGES, CONTENT_SOURCE
} from "../constants/CollegeDekho"

export const getOptions = (options, removeSoftDelete = false) => {
  let data = []
  data = options.map((item) => { return { value: item[0], label: item[1] } })
  if (removeSoftDelete) {
    data = data.filter((item) => { return item.value !== -1 })
  }
  return data
}
export const getInstituteType = () => {
  return getOptions(INSTITUTE_TYPE)
}
export const getSizeType = () => {
  return getOptions(SIZE_TYPE)
}
export const getInstitutionType = () => {
  return getOptions(INSTITUTION_TYPE)
}
export const getPartnerStatus = () => {
  return getOptions(PARTNER_STATUS)
}
export const getAdmissionStatus = () => {
  return getOptions(ADMISSION_STATUS)
}
export const getFeeFrequencyType = () => {
  return getOptions(FEE_FREQUENCY_TYPE)
}
export const getYesNoType = () => {
  return getOptions(YES_NO_TYPE)
}
export const getGender = () => {
  return getOptions(GENDER)
}
export const getLevel = () => {
  return getOptions(LEVEL)
}
export const getCourseMode = () => {
  return getOptions(COURSE_MODE)
}
export const getCourseComponent = () => {
  return getOptions(COURSE_COMPONENT_LIST)
}
export const getDurationType = () => {
  return getOptions(DURATION_TYPE)
}
export const getCourseDurationType = () => {
  return getOptions(COURSE_DURATION_TYPE)
}

export const getFeeType = () => {
  return getOptions(FEE_TYPE)
}

export const getScholarshipComponent = () => {
  return getOptions(SCHOLARSHIP_COMPONENT_LIST)
}


export const getCourseType = () => {
  return getOptions(COURSE_TYPE)
}

export const getCourseMasterPages = () => {
  return getOptions(COURSE_MASTER_PAGE_LIST)
}

export const getCourseAttribute = () => {
  return getOptions(COURSE_ATTRIBUTE)
}
export const getCurrency = () => {
  return getOptions(CURRENCY)
}
export const getProgram = () => {
  return getOptions(PROGRAM)
}
export const getShift = () => {
  return getOptions(SHIFT)
}

export const getObjectStatus = (removeSoftDelete = false) => {
  return getOptions(OBJECT_STATUS, removeSoftDelete)
}


export const getComponent = () => {
  return getOptions(COMPONENT)
}

export const getPageType = () => {
  return getOptions(PAGE_TYPE)
}

export const getService = () => {
  return getOptions(SERVICES)
}

export const getLanguage = () => {
  return getOptions(LANGUAGES)
}

export const getContentSource = () => {
  return getOptions(CONTENT_SOURCE)
}


export const isInt = (value) => {
  if (isNaN(value)) {
    return false;
  }
  var x = parseFloat(value);
  return (x | 0) === x;
}



export const getObject = (obj) => {
  if (!obj) {
    return null;
  }

  return { value: obj.id, label: `${obj.name}-(${obj.id})` }
}
export const getMultiObject = (obj) => {
  //return [{"id":30,"label":"himmat",key:30}]
  if (!obj) {
    return [];
  }
  let data = []

  for (var temp of obj) {
    data.push({ "value": temp.id, "label": `${temp.name}-(${temp.id})` })
  }

  //return obj.map((temp) => {"id":temp.id,"label":temp.name,"key":temp.id})
  return data


}

export const getSalaryInLacs = (salary) => {
  if (salary) {
    salary = salary / 100000
    return salary + " Lacs"
  }
  salary = "-"
  return salary
}

export const getCollegeorDegree = (degree) => {
  if (degree) {
    return degree
  }
  degree = "College";
  return degree
}

export const getYearChoices = (_year = 2010) => {
  let YEAR_CHOICES = []
  const current_year = (new Date()).getFullYear();
  for (let i = _year; i < current_year + 10; i++) {
    YEAR_CHOICES.push([i, i.toString()]);
  }
  return getOptions(YEAR_CHOICES)
}


export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"))
}

export const getImageType = () => {
  return getOptions(IMAGE_TYPE_CHOICES)
}

export const getVideoType = () => {
  return getOptions(VIDEO_TYPE_CHOICES)
}

export const sentenceCase = (str) => {
  //return str.charAt(0).toUpperCase() + str.slice(1);
  return str.split('-').map(function capitalize(part) {
    return part.charAt(0).toUpperCase() + part.slice(1);
  }).join('-');
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] == value);
}

export function getDashboardType(name) {
  if(name.includes('institute')) return 'institute';
  return 'course'
}
export function getTemplateContentTypeName(num) {
  const obj = {
    22:'institute',
    513:'course',
    1019:'scholarship',
    1016:'scholarship/category',
    1017:'scholarship/subcategory',
    1018:'scholarship/landing',
    1:'logs'
  }
  return obj[num]
}

export function getTemplateContentTypeID(name) {
  const obj = {
    'institute': 22,
    'course':513,
    'scholarship':1019,
    'logs':1
  }
  return obj[name]
}

export const getErrors = (error) => {

  if (typeof error?.response === 'undefined' || error?.response?.status === 500) {
    return { "serverError": [], "allError": [COMMON_ERROR_MESSAGE] }
  }

  let errors = error?.response?.data

  


  //error.response.data.errors
  if (typeof errors === 'undefined') {

    errors = error.response?.data?.errors
    if (typeof errors === 'undefined') {
      errors = error
    }
  }
  if (typeof errors?.errors !== 'undefined') {
    errors = errors?.errors
  }
  const serverError = []
  const allError = []
  if (Array.isArray(errors)) {
    errors.map((item) => {
      for (const property in item) {
        allError.push(item[property])
        serverError.push({ name: property, errors: [item[property]] })
      }
    }
    )
  }
  else if (error?.response?.status === 403) {
    allError.push(error?.response?.data?.detail?`${error?.response?.data?.detail}`:allError.push("You are not authorised !")  );

  }
  else if(error?.response?.status === 404 ){
    const temp = {detailNotFound: error}
    allError.push(temp)
    serverError.push(temp)
    console.log(temp)
  }
  else {
    
    console.log(errors, "In Else", error)
  
    for(const property in errors) {
    try {
      let temp = `${property}: ${errors[property]}`
      console.log("TEMP", temp)
      serverError.push({ name: property, errors: [errors[property]] })
      allError.push(temp)
      console.log(allError, "All Errors")
      console.log(serverError, "Server Errors")
  
    } catch (e) { console.log(e) }
    
  
  }
  }
  if (allError.length === 0) {
    allError.push(COMMON_ERROR_MESSAGE)
  }
  return { "serverError": serverError, "allError": allError }
}
