import React from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";

export default function DebounceSelect({
  fetchOptions,
  cldID = null,
  contentTypeId = null,
  debounceTimeout = 800,
  fetchOnClick,
  defaultValue,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      setIsOpen(true)
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value, cldID, contentTypeId).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout, contentTypeId,cldID]);
  const onChangeFocus=()=>{
setIsOpen(false)
  }
  return (
    <Select
      labelInValue
      filterOption={false}
      defaultValue={defaultValue ? defaultValue : []}
      onSearch={debounceFetcher}
      onClick={fetchOnClick ? () => debounceFetcher() : null}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      open={isOpen}
      autoClearSearchValue={false}
      onBlur={onChangeFocus}
    />
  );
} // Usage of DebounceSelect

// async function fetchUserList(username) {
//   console.log('fetching user', username);
//   return fetch('https://randomuser.me/api/?results=5')
//     .then((response) => response.json())
//     .then((body) =>
//       body.results.map((user) => ({
//         label: `${user.name.first} ${user.name.last}`,
//         value: user.login.username,
//       })),
//     );
// }

// const Demo = () => {
//   const [value, setValue] = React.useState([]);
//   return (
//     <DebounceSelect
//       mode="multiple"
//       value={value}
//       placeholder="Select users"
//       fetchOptions={fetchUserList}
//       onChange={(newValue) => {
//         setValue(newValue);
//       }}
//       style={{
//         width: '100%',
//       }}
//     />
//   );
// };

// export default Demo;
