import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Button, message } from "antd";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import {
  getObject,
  getMultiObject,
  getErrors,
  isInt,
} from "../../../util/helpers";
import {
  COMMON_SUCCESS_MESSAGE,
  API_URL_LIST,
} from "../../../constants/CollegeDekho";
import { useFetchData } from "../../../hooks/";
import { patch, post } from "../../../services/";
import { Spin } from "antd";
import StudentPlacementDetailForm from "./StudentPlacementDetailForm";

const StudentPlacementDetail = (props) => {
  const [form] = Form.useForm();
  const { instituteID } = useParams();
  const { placementID, studentPlacementID } = useParams();
  const history = useHistory();
  const isAddMode = !isInt(studentPlacementID);
  const [loader, setLoader] = useState(false);
  const contentTypeId= 0
  
  console.log("isAddMode", isAddMode);
  let url = null;
  let instituteData = "";
  let saveURL = API_URL_LIST["STUDENT_PLACEMENT_DETAIL_POST_URL"]
    .replace("#instituteID#", instituteID)
    .replace("#placementID#", placementID);

  if (!isAddMode) {
    url = API_URL_LIST["STUDENT_PLACEMENT_DETAIL"]
      .replace("#instituteID#", instituteID)
      .replace("#placementID#", placementID)
      .replace("#studentPlacementID#", studentPlacementID);
    // url = url.replace("#id1#", placementID);
    saveURL = url;
  }
  instituteData = API_URL_LIST["INSTITUTE_PLACEMENT_DETAIL_URL"]
    .replace("#id#", instituteID)
    .replace("#id1#", placementID);
console.log("instituteID",instituteID);

  const [data, loading, error] = useFetchData(url);
  const [instituteDetailData] = useFetchData(instituteData);
  const [disabledSave, setDisabledSave] = useState(false);
  const instituteName =
    instituteDetailData?.placement_type_data?.object_id?.name;

  const setInstitutePlacementObj = () => {
    console.log("dadad", data);
    // const { placement_type_data } = data;
    const studentPlacementObj = {
      course: data?.course,
      package_given: data?.package_given,
      degree: data?.degree,
      alumni: data?.alumni,
      company: data?.company,
    };
    console.log("dsdsds", data);
    if (data?.alumni) {
      studentPlacementObj["alumni"] = getObject(data?.alumni);
    }
    if (data?.company) {
      studentPlacementObj["company"] = getMultiObject(data?.company);
    }
    form.setFieldsValue(studentPlacementObj);
  };
console.log("instituteDetailData",instituteDetailData);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setInstitutePlacementObj(data);
    }
    return () => {
      mounted = false;
    };
  }, [data]);


  const crudErrorHandler = (error) => {
    showError(error);
  };

  const patchData = (data) => {
    setLoader(true);
    patch(saveURL, data)
      .then(function (response) {
        console.log(response);
        message.success(COMMON_SUCCESS_MESSAGE);
        history.push({
          pathname: `/institute/${instituteID}/placement/${placementID}/student-placement`,
          state: {
            object_id: {
              name: instituteName,
            },
          },
        });
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false);
        setLoader(false);
      });
  };
  const postData = (data) => {
    console.log("daadta", data);
    console.log("rururur", saveURL);
    setLoader(true);
    post(saveURL, data)
      .then(function (response) {
        message.success(COMMON_SUCCESS_MESSAGE);
        // history.push(
        //   `/institute/${instituteID}/placement/${placementID}/student-placement`
        // );
        history.push({
          pathname: `/institute/${instituteID}/placement/${placementID}/student-placement`,
          state: {
            object_id: {
              name: instituteName,
            },
          },
        });
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false);
        setLoader(false);
      });
  };
  const showError = (errors) => {
    const error = getErrors(errors);
    if (error.allError.length > 0) {
      message.error(error.allError.join());
    }
    console.log("server error", error.serverError);
    form.setFields(error.serverError);
  };
  // const onChangeContentType = (value) => {
  //   console.log("API CALL FOR DEGREE USING VALUE", value);
  // };
  console.log("contentTypeIdcontentTypeId", contentTypeId);
  const onValuesChange = (changedValues, allValues) => {
    console.log("sssfs", allValues);
  };

  const onFinish = (e) => {
    const data = form.getFieldsValue(true);
    const company = data?.company.map((e) => e.value);
    const payload = {
      course: data?.course,
      package_given: data?.package_given,

      placement: placementID,
      alumni: data?.alumni.value,
      company: company,
    };
    setDisabledSave(true);
    if (isAddMode) {
      postData(payload);
    } else {
      patchData(payload);
    }
  };

  return (
    <>
      {error ? (
        message.error(error)
      ) : loader === true ? (
        <center>
          <Spin />
        </center>
      ) : (
        <Row>
          <Col span={24}>
            <NavBreadCrumb page={instituteName} breadCrumb={instituteDetailData?.extra_details?.institute}/>
            <div className="gx-pt-4">
              <div className="ant-card ant-card-bordered gx-card-widget">
                <div className="ant-card-body">
                  <h2 className="gx-mb-0">
                    Student Placement
                    {/* {institutePlacement
                      ? `${institutePlacement.placement_year} ${
                          institutePlacement.object_id
                            ? `-${institutePlacement.object_id?.label}`
                            : ``
                        }- Placements`
                      : locationData
                      ? `${locationData?.name}, Placement Template`
                      : `Placement Template`}{" "} */}
                  </h2>
                </div>
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className="ant-card ant-card-bordered gx-card">
              <div className="ant-card-body">
                <Form
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                  form={form}
                >
                  <div className="gx-p-2">
                    <StudentPlacementDetailForm
                      form={form}
                      instituteID={instituteID}
                      contentTypeId={contentTypeId}
                    />
                  </div>
                  <div className="d-flex" style={{ justifyContent: "left" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disabledSave}
                    >
                      {" "}
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
export default StudentPlacementDetail;
