import React from "react";
import { Row, Col, Form, Input } from "antd";

import DebounceSelect from "../../../components/DebounceSelect";
import { getContentTypeObjectList } from "../../../services/ContentType/ObjectList";
import { CONTENT_TYPE_ID } from "../../../constants/Settings";

const CompanyPlacementDetailForm = ({ form, instituteID }) => {
  const regExp = /[a-zA-Z]/g;
  const percentValidator = (rule, value, callback) => {
    // Always return a callback, otherwise validateFields cannot respond
    if (value > 100 || regExp.test(value)) {
      return Promise.reject(
        "Enter Valid Percent"
        //When i delete that row its fix warning, but i need have that error message.
      );
    }

    //callback();
    return Promise.resolve();
  };
  return (
    <Row>
      <div></div>
      <Col span={12}>
        <Form.Item
          label="Number of offers"
          name="number_of_offers_given"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Package" name="package_given" className="labelInput">
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Hiring Percent"
          name="hiring_percent"
          className="labelInput"
          rules={[
            {
              validator: percentValidator,
            },
          ]}
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Company"
          name="company"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Company Required",
            },
          ]}
        >
          <DebounceSelect
            mode="single"
            name="company"
            placeholder="Select Company"
            cldID={instituteID}
            contentTypeId={CONTENT_TYPE_ID.company}
            fetchOptions={getContentTypeObjectList}
            showSearch={true}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CompanyPlacementDetailForm;
