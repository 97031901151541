import React,{useState} from "react";
import DebounceSelect from '../../../components/DebounceSelect'
import { getCityList } from "../../../services/City/City";
import { getStateList } from "../../../services/State/State";
import {Button,Form,Select} from "antd";
const Option = Select.Option
const InstituteListingFilter = ({onApplyFilter,onClearFilter}) => {
    const  [city, setCity] = useState([]);
    const  [state, setState] = useState([]);
    const  [partnerStatus, setPartnerStatus] = useState("");
  
    const onPartnerStatusChange = (value) => 
    {
      console.log(value); 
      setPartnerStatus(value)
         
    }
    const applyFilter = (e) => 
    {
        onApplyFilter({city,state,partnerStatus}) 
       console.log("apply filter");
    }
    const clearFilter = (e) => 
    {
       setCity([])
       setState([])
       setPartnerStatus("")
       onClearFilter();
    }
  
  return (

<div className="ant-card ant-card-bordered gx-card">
<div className="ant-card-body">
<Form name="register" layout="ant-form-horizontal">
  <ul className="collgeForm">
      <li className="gx-mb-3">
    <DebounceSelect
    mode="multiple"
    value={city}
    placeholder="Select City"
    fetchOptions={getCityList}
    onChange={(newValue) => {
      setCity(newValue);
      console.log(newValue);
    }}
    style={{
      width: '100%',
    }}
  />
       </li>
      <li className="gx-mb-3">
<DebounceSelect
    mode="multiple"
    value={state}
    placeholder="Select State"
    fetchOptions={getStateList}
    onChange={(newValue) => {
      setState(newValue);
      console.log(newValue);
    }}
    style={{
      width: '100%',
    }}
  />

      </li>
    
    <li className="gx-mb-3">    
    <Select
          allowClear={true}
          style={{width: '100%'}}
          placeholder="Please select partner status"
          onChange = {onPartnerStatusChange}
    >
         <Option value="">Select Status</Option>
         <Option value="1">Partner</Option>
         <Option value="2">CAF Partner</Option>
         <Option value="3">Branding Partner</Option>
         <Option value="4">Not a Partner</Option>
         
    </Select>

     </li>   
     <li> 
       <Button  type="primary" htmlType="submit" onClick={applyFilter}>Apply Filter</Button>
       <Button  type="danger" htmlType="submit" ghost onClick={clearFilter}>Clear</Button></li>
   </ul>
</Form>
</div>
</div>
  );
};
export default InstituteListingFilter;
