import React, { useState } from "react";
import DebounceSelect from '../../../components/DebounceSelect'
import {
  getCourseList,
  getStreamList,
  getAuthorList,
  getAllCoursesList,
} from "../../../services/CourseDetailApi/AutoSuggestion";
import { Button, Form, Select, DatePicker } from "antd";
import { getYesNoType } from '../../../util/helpers';

const yesNoType = getYesNoType()

const CourseListingFilter = ({ onApplyFilter, onClearFilter }) => {
  const [stream, setStream] = useState([]);
  const [courseContentId, setCourseContentId] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [course, setCourse] = useState([]);
  const [addedBy, setAddedBy] = useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);
  const [isActive, setIsActive] = useState(null);



  const applyFilter = (e) => {
    onApplyFilter({ stream, startDate, endDate, course, addedBy, modifiedBy, isActive, courseContentId })
  }
  const clearFilter = (e) => {
    setStream([])
    setCourseContentId([])
    setStartDate("")
    setEndDate("")
    setCourse([])
    setAddedBy([])
    setModifiedBy([])
    setIsActive(null)
    onClearFilter();
  }

  return (

    <div className="ant-card ant-card-bordered gx-card">
      <div className="ant-card-body">
        <Form name="register" layout="ant-form-horizontal">
          <li className="gx-mb-3">
            <DebounceSelect
              mode="multiple"
              value={courseContentId}
              placeholder="Search by Course Name or Course Short Name"
              fetchOptions={getAllCoursesList}
              onChange={(newValue) => {
                setCourseContentId(newValue);
              }}
              style={{
                width: '100%',
              }}
            />
          </li>
          <ul className="collgeForm">

            <li className="gx-mb-3">
              <DebounceSelect
                mode="multiple"
                value={stream}
                placeholder="Select Stream"
                fetchOptions={getStreamList}
                onChange={(newValue) => {
                  setStream(newValue);
                }}
                style={{
                  width: '100%',
                }}
              />
            </li>
            <li className="gx-mb-3">
              <DebounceSelect
                mode="multiple"
                value={course}
                placeholder="Select Course"
                fetchOptions={getCourseList}
                onChange={(newValue) => {
                  setCourse(newValue);
                }}
                style={{
                  width: '100%',
                }}
              />
            </li>
            <li className="gx-mb-3">
              <DebounceSelect
                mode="multiple"
                value={addedBy}
                placeholder="Course Added By"
                fetchOptions={getAuthorList}
                onChange={(newValue) => {
                  setAddedBy(newValue);
                }}
                style={{
                  width: '100%',
                }}
              />
            </li>
            <li className="gx-mb-3">
              <DebounceSelect
                mode="multiple"
                value={modifiedBy}
                placeholder="Course Modified By"
                fetchOptions={getAuthorList}
                onChange={(newValue) => {
                  setModifiedBy(newValue);
                }}
                style={{
                  width: '100%',
                }}
              />
            </li>
            <li className="gx-mb-3">
              <Form.Item label="" name="startDate" className="labelInput">
                <DatePicker className="gx-mb-3 gx-w-100" placeholder="Start Date" format="MMMM DD, YYYY"
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }} />
              </Form.Item>
            </li>
            <li className="gx-mb-3">
              <Form.Item label="" name="endDate" className="labelInput">
                <DatePicker className="gx-mb-3 gx-w-100" placeholder="End Date" format="MMMM DD, YYYY"
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }} />
              </Form.Item>
            </li>
            <li >
              <Form.Item label="" name="isActive" className="labelInput" >
                <Select placeholder="Is Active" name="isActive" style={{ width: '100%' }} onChange={(newValue) => {
                  setIsActive(newValue);
                }} options={yesNoType}>
                </Select>
              </Form.Item>
            </li>
            <li>
              <Button type="primary" htmlType="submit" onClick={applyFilter}>Apply Filter</Button>
              <Button type="danger" htmlType="submit" ghost onClick={clearFilter}>Clear</Button>
            </li>
          </ul>
        </Form>
      </div>
    </div>
  );
};
export default CourseListingFilter;
