import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Checkbox, Input, Select } from "antd";
import { getObjectStatus } from "../../../util/helpers";
import { useFetchData } from "../../../hooks/";
import { COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho'

const objectStatusOptions = getObjectStatus()





const CoursePageComponentForm = ({ form, isAddMode }) => {

	
	const component = [{value: 79, label: "Static"}]



	return (
		<Row>
			<Col span={20}>
				<Form.Item label="Component" name="component" className="labelInput" rules={[
					{
						required: true,
						message: 'Select component',
					}

				]}>
					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Select Component"
						options={component}
						labelInValue={true}
						showSearch={true}
				
					>
					</Select>

				</Form.Item>
			</Col>

			<Col span={20}>
				<Form.Item label="H2" name="h2" className="labelInput" rules={[
					{
						required: true,
						message: 'H2',
					}

				]}>
					<Input className="gx-mb-3" />

				</Form.Item>

			</Col>


			<Col span={8}>
				<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
					{
						required: true,
						message: 'Object Status Required',
					},
				]}>
					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Select object status"
						options={objectStatusOptions}
					>
					</Select>

				</Form.Item>
			</Col>

			<Col span={8}>
				<Form.Item label=" " colon={false} name="has_read_more" className="labelInput" valuePropName="checked">

					<Checkbox className="gx-mb-3" name="has_read_more" >Has Read More</Checkbox>
				</Form.Item>
			</Col>

		</Row>
	)
}

export default CoursePageComponentForm
