import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Button, message, Spin } from "antd";
import {
  API_URL_LIST,
  COMMON_SUCCESS_MESSAGE,
} from "../../../constants/CollegeDekho";
import { useFetchData } from "../../../hooks";
import { getErrors, getObject, isInt } from "../../../util/helpers";
import { patch, post } from "../../../services";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import CompanyPlacementDetailForm from "./CompanyPlacementDetailForm";

const CompanyPlacementDetail = (props) => {
  const [form] = Form.useForm();
  const { instituteID } = useParams();
  const { placementID, companyPlacementID } = useParams();
  const history = useHistory();
  const isAddMode = !isInt(companyPlacementID);
  const [loader, setLoader] = useState(false);

  console.log("isAddMode", isAddMode);
  let url = null;
  let saveURL = API_URL_LIST["COMPANY_PLACEMENT_DETAIL_POST_URL"]
    .replace("#instituteID#", instituteID)
    .replace("#placementID#", placementID);

  if (!isAddMode) {
    url = API_URL_LIST["COMPANY_PLACEMENT_DETAIL"]
      .replace("#instituteID#", instituteID)
      .replace("#placementID#", placementID)
      .replace("#studentPlacementID#", companyPlacementID);
    // url = url.replace("#id1#", placementID);
    saveURL = url;
  }
  console.log("urlurldddddd", url);
  let instituteData = API_URL_LIST["INSTITUTE_PLACEMENT_DETAIL_URL"]
    .replace("#id#", instituteID)
    .replace("#id1#", placementID);

  const [instituteDetailData] = useFetchData(instituteData);

  const instituteName =
    instituteDetailData?.placement_type_data?.object_id?.name;

  const [data, loading, error] = useFetchData(url);
  const [institutePlacement, setInstitutePlacement] = useState(null);
  const [disabledSave, setDisabledSave] = useState(false);
  const [contentType, setContentType] = useState([]);

  const setInstitutePlacementObj = () => {
    console.log("dadad", data);
    // const { placement_type_data } = data;

    const studentPlacementObj = {
      number_of_offers_given: data?.number_of_offers_given,
      package_given: data?.package_given,
      hiring_percent: data?.hiring_percent,
      placement: placementID,
      company: data?.company,
    };
    console.log("dsdsds", data);
    if (data?.company) {
      studentPlacementObj["company"] = getObject(data?.company);
    }
    // if (data?.company) {
    //   studentPlacementObj["company"] = getMultiObject(data?.company);
    // }
    setInstitutePlacement(studentPlacementObj);
    form.setFieldsValue(studentPlacementObj);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      console.log("dddsd", data);
      setInstitutePlacementObj(data);
    }
    return () => {
      mounted = false;
    };
  }, [data]);

  const getCleanData = (data) => {
    const cleanData = { ...data };
    delete cleanData.degree;
    delete cleanData.company;
    for (let attr in cleanData) {
      if (cleanData[attr] === "") {
        console.log(attr);
        cleanData[attr] = null;
      }
    }
    if (!data.degree) {
      cleanData.degree = null;
    } else {
      cleanData.degree = data.degree.value;
    }

    if (cleanData.max_salary === null) {
      console.log("YES");
      cleanData.max_salary_currency = null;
    }
    if (cleanData.average_salary === null) {
      cleanData.average_salary_currency = null;
    }
    if (cleanData.median_package === null) {
      cleanData.med_package_currency = null;
    }
    if (cleanData.avg_pkg_top_20_students === null) {
      cleanData.top_20_currency = null;
    }
    if (cleanData.avg_pkg_top_100_students === null) {
      cleanData.top_100_currency = null;
    }
    if (cleanData.institute) {
      cleanData.institute = cleanData.institute.id;
    }

    if (data.company) {
      cleanData.company = [];
      cleanData.company = data.company.map((item) => {
        return item.value;
      });
    }
    console.log("cleanDatacleanData", cleanData);
    return cleanData;
  };

  const crudErrorHandler = (error) => {
    showError(error);
  };

  const patchData = (data) => {
    setLoader(true);
    patch(saveURL, data)
      .then(function (response) {
        console.log(response);
        message.success(COMMON_SUCCESS_MESSAGE);
        history.push({
          pathname: `/institute/${instituteID}/placement/${placementID}/company-placement`,
          state: {
            object_id: {
              name: instituteName,
            },
          },
        });
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false);
        setLoader(false);
      });
  };
  const postData = (data) => {
    setLoader(true);
    post(saveURL, data)
      .then(function (response) {
        message.success(COMMON_SUCCESS_MESSAGE);
        // history.push(
        //   `/institute/${instituteID}/placement/${placementID}/student-placement`
        // );
        history.push({
          pathname: `/institute/${instituteID}/placement/${placementID}/company-placement`,
          state: {
            object_id: {
              name: instituteName,
            },
          },
        });
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false);
        setLoader(false);
      });
  };
  const showError = (errors) => {
    const error = getErrors(errors);
    if (error.allError.length > 0) {
      message.error(error.allError.join());
    }
    console.log("server error", error.serverError);
    form.setFields(error.serverError);
  };
  // const onChangeContentType = (value) => {
  //   console.log("API CALL FOR DEGREE USING VALUE", value);
  // };
  const onValuesChange = (changedValues, allValues) => {
    console.log("sssfs", allValues);
  };

  const onFinish = (e) => {
    const data = form.getFieldsValue(true);

    const payload = {
      number_of_offers_given: data?.number_of_offers_given,
      package_given: data?.package_given,
      hiring_percent: data?.hiring_percent,
      placement: placementID,
      company: data?.company?.key,
    };
    console.log("payloadpayload", payload);
    setDisabledSave(true);
    if (isAddMode) {
      postData(payload);
    } else {
      console.log("updateMOE");

      patchData(payload);
    }
  };

  return (
    <>
      {error ? (
        message.error(error)
      ) : loader === true ? (
        <center>
          <Spin />
        </center>
      ) : (
        <Row>
          <Col span={24}>
            <NavBreadCrumb page={instituteName} breadCrumb={instituteDetailData?.extra_details?.institute}/>
            <div className="gx-pt-4">
              <div className="ant-card ant-card-bordered gx-card-widget">
                <div className="ant-card-body">
                  <h2 className="gx-mb-0">
                    Company Placement
                    {/* {institutePlacement
                      ? `${institutePlacement.placement_year} ${
                          institutePlacement.object_id
                            ? `-${institutePlacement.object_id?.label}`
                            : ``
                        }- Placements`
                      : locationData
                      ? `${locationData?.name}, Placement Template`
                      : `Placement Template`}{" "} */}
                  </h2>
                </div>
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className="ant-card ant-card-bordered gx-card">
              <div className="ant-card-body">
                <Form
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                  form={form}
                >
                  <div className="gx-p-2">
                    <CompanyPlacementDetailForm
                      form={form}
                      instituteID={instituteID}
                    />
                  </div>
                  <div className="d-flex" style={{ justifyContent: "left" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disabledSave}
                    >
                      {" "}
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
export default CompanyPlacementDetail;
