import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getLogVersionsList } from "../../../services/Logs/logsList";
import { Row, Col, Table, Button, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import {
  LOG_CONTENT_TYPE,
  DATE_TIME_FORMAT_LISTING,
} from "../../../constants/CollegeDekho";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import { getErrors } from "../../../util/helpers";
import moment from "moment";

const LogVersionListing = () => {
  const [logsList, setLogsList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [extraDetails, setExtraDetails] = useState(null);
  const { templateId, objectId } = useParams();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "added_on",
      dataIndex: "added_on",
      render: (text, record) => (
        <span>{moment(record.added_on).format(DATE_TIME_FORMAT_LISTING)}</span>
      ),
    },
    {
      title: "View",
      key: "view",
      render: (text, record) => (
        <span>
          <span className="gx-link">
            <Link to={`/logs/${templateId}/${objectId}/${record.id}`}>
              {" "}
              <EyeOutlined />
            </Link>
          </span>
        </span>
      ),
    },
  ];

  console.log("template id and object id :::::::: ", templateId, objectId);
  // const templateId = 1;
  // const newsId = 37596
  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getLogVersionsList({ templateId, objectId })
      .then((data) => {
        if (mounted) {
          console.log({ data });
          setLogsList(data.data.result);
          setExtraDetails(data.data.extra_details);
          setLoading(false);
          setError(null);
        }
      })
      .catch(function (response) {
        const err = getErrors(response);
        setError(err.allError);
        console.log(err);
      });

    return () => (mounted = false);
  }, []);

  return (
    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb
          page={LOG_CONTENT_TYPE[templateId]}
          page2={extraDetails?.content_name}
        />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered gx-card-widget">
            <div className="ant-card-body">
              <h2 className="gx-mb-0">
                {LOG_CONTENT_TYPE[templateId]} - {extraDetails?.content_name}{" "}
                Logs
              </h2>
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} style={{ textAlign: "right" }}>
        <Link to={`/logs/${templateId}/${objectId}/compare`}>
          <Button type="primary">
            <span>Compare Logs</span>
          </Button>
        </Link>
      </Col>

      <Col span={24}>
        <Table
          className="gx-table-responsive"
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={logsList}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default LogVersionListing;
