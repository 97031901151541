import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Button, message, Spin } from "antd";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import PlacementDetailForm from "./PlacementDetailForm";
import { getObject, isInt, getErrors } from "../../../util/helpers";
import {
  COMMON_SUCCESS_MESSAGE,
  API_URL_LIST,
} from "../../../constants/CollegeDekho";
import { useFetchData } from "../../../hooks/";
import { patch, post } from "../../../services/";
import { CONTENT_TYPE_ID } from "../../../constants/Settings";

const InstitutePlacementDetail = (props) => {
  const locationData = props.location?.locationData;
  const [form] = Form.useForm();
  const { instituteID, instituteMasterPageID } = useParams();
  const { placementID } = useParams();
  const history = useHistory();
  const isAddMode = !isInt(placementID);
  const [loader, setLoader] = useState(false);
  const [contentTypeId, setContentTypeId] = useState(0);
  const [maxSalCurrRequired, setMaxSalCurrRequired] = useState(false);
  const [avgSalCurrRequired, setAvgSalCurrRequired] = useState(false);
  const [medPkgCurrRequired, setMedPkgCurrRequired] = useState(false);
  const [pckgCurrencyRequired, setPckgCurrencyRequired] = useState(false);
  const [top20CurrRequired, setTop20CurrRequired] = useState(false);
  const [top100CurrRequired, setTop100CurrRequired] = useState(false);
  const [instituteData, setInstituteData] = useState(false);

  let url = null;
  let saveURL = API_URL_LIST["INSTITUTE_PLACEMENT_DETAIL_POST_URL"].replace(
    "#id#",
    instituteID
  );

  if (!isAddMode) {
    url = API_URL_LIST["INSTITUTE_PLACEMENT_DETAIL_URL"].replace(
      "#id#",
      instituteID
    );
    url = url.replace("#id1#", placementID);
    saveURL = url;
  }

  var dashboardTypeDetailURL = null;
  var dashboardTypeID = null;
  var dashboardTypeMasterPageID = null;
  if (instituteID) {
    dashboardTypeID = instituteID;
    dashboardTypeMasterPageID = instituteMasterPageID;
    dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace(
      "#id#",
      dashboardTypeID
    );
  }
  console.log("dashboardTypeDetailURL", dashboardTypeDetailURL);
  const [data, loading, error] = useFetchData(url);
  const [instituteDetailData] = useFetchData(dashboardTypeDetailURL);
  const [institutePlacement, setInstitutePlacement] = useState(null);
  const [disabledSave, setDisabledSave] = useState(false);

  const setInstitutePlacementObj = (value) => {
    const { placement_type_data, placement_detail_data } = value;
    console.log("AFASDFA", value);
    const institutePlacementObj = {
      placement_year: placement_type_data?.year,
      object_status: placement_type_data?.object_status,
      placement_type_data_id: placement_type_data?.id,
      placement_detail_data_id: placement_detail_data?.id,
      no_of_students_placed: placement_detail_data?.no_of_students_placed,
      percent_of_students_placed:
        placement_detail_data?.percent_of_students_placed,
      no_of_companies_visited: placement_detail_data?.no_of_companies_visited,
      total_offers: placement_detail_data?.total_offers,
      course_duration: placement_detail_data?.course_duration,
      students_opted_for_higher_education:
        placement_detail_data?.students_opted_for_higher_education,
      highest_package: placement_detail_data?.highest_package,
      average_package: placement_detail_data?.average_package,
      median_package: placement_detail_data?.median_package,
      placement: placement_detail_data?.placement,
      total_pre_placement_offers:
        placement_detail_data?.total_pre_placement_offers,
      overseas_offers: placement_detail_data?.overseas_offers,
      old_row_id: placement_detail_data?.old_row_id,
      package_currency: placement_detail_data?.package_currency,
    };
    if (placement_type_data?.object_id) {
      if (placement_type_data?.content_type?.id == CONTENT_TYPE_ID?.level) {
        institutePlacementObj["object_id"] = placement_type_data?.object_id?.id;
      } else {
        institutePlacementObj["object_id"] = getObject(
          placement_type_data?.object_id
        );
      }
    }
    if (placement_type_data?.content_type) {
      institutePlacementObj["content_type"] =
        placement_type_data?.content_type?.id;

      setContentTypeId(placement_type_data?.content_type?.id);
    }
    setInstitutePlacement(institutePlacementObj);
    form.setFieldsValue(institutePlacementObj);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setInstitutePlacementObj(data);
    }
    setInstituteData(instituteDetailData);
    return () => {
      mounted = false;
    };
  }, [data, instituteDetailData]);
  const crudErrorHandler = (error) => {
    showError(error);
  };

  const patchData = (data) => {
    setLoader(true);
    patch(saveURL, data)
      .then(function (response) {
        message.success(COMMON_SUCCESS_MESSAGE);
        history.push(`/institute/${instituteID}/placement/`);
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false);
        setLoader(false);
      });
  };
  const postData = (data) => {
    setLoader(true);
    post(saveURL, data)
      .then(function (response) {
        message.success(COMMON_SUCCESS_MESSAGE);
        history.push(`/institute/${instituteID}/placement/`);
      })
      .catch(crudErrorHandler)
      .finally(() => {
        setDisabledSave(false);
        setLoader(false);
      });
  };
  const showError = (errors) => {
    const error = getErrors(errors);
    if (error.allError.length > 0) {
      message.error(error.allError.join());
    }
    form.setFields(error.serverError);
  };
  const onValuesChange = (changedValues, allValues) => {
    const instituteName = {
      id: instituteData?.id,
      name: instituteData?.name,
    };
    if (changedValues.content_type) {
      form.setFieldsValue({ object_id: null });
    }
    if (changedValues.content_type == CONTENT_TYPE_ID.institute) {
      form.setFieldsValue({ object_id: getObject(instituteName) });
    }

    if (allValues?.content_type) {
      setContentTypeId(allValues?.content_type);
    }
    const max_salary_currency_required = allValues.max_salary
      ? Object.values(allValues.max_salary).filter(Boolean).length > 0
      : false;
    setMaxSalCurrRequired(max_salary_currency_required);

    const avg_salary_currency_required = allValues.average_salary
      ? Object.values(allValues.average_salary).filter(Boolean).length > 0
      : false;
    setAvgSalCurrRequired(avg_salary_currency_required);

    const med_package_currency_required = allValues.median_package
      ? Object.values(allValues.median_package).filter(Boolean).length > 0
      : false;
    setMedPkgCurrRequired(med_package_currency_required);

    const currency_package_required = allValues.package_currency
      ? Object.values(allValues.package_currency).filter(Boolean).length > 0
      : false;
    setPckgCurrencyRequired(currency_package_required);

    const top_20_currency_required = allValues.avg_pkg_top_20_students
      ? Object.values(allValues.avg_pkg_top_20_students).filter(Boolean)
          .length > 0
      : false;
    setTop20CurrRequired(top_20_currency_required);

    const top_100_currency_required = allValues.avg_pkg_top_100_students
      ? Object.values(allValues.avg_pkg_top_100_students).filter(Boolean)
          .length > 0
      : false;
    setTop100CurrRequired(top_100_currency_required);
  };
  const CurrReqValidations = {
    max: maxSalCurrRequired,
    avg: avgSalCurrRequired,
    med: medPkgCurrRequired,
    pckcurr: pckgCurrencyRequired,
  };

  const onFinish = (e) => {
    const data = form.getFieldsValue(true);
    const payload = {
      placement_type_data: {
        year: data?.placement_year,
        object_status: data?.object_status,
        content_type: data?.content_type,
        object_id: data?.object_id?.value
          ? data?.object_id?.value
          : data?.object_id,
        institute: instituteID,
      },
      placement_detail_data: {
        no_of_students_placed: data?.no_of_students_placed ?? null,
        percent_of_students_placed: data?.percent_of_students_placed ?? null,
        total_offers: data?.total_offers ?? null,
        highest_package: data?.highest_package ?? null,
        average_package: data?.average_package ?? null,
        median_package: data?.median_package ?? null,
        no_of_companies_visited: data?.no_of_companies_visited ?? null,
        total_pre_placement_offers: data?.total_pre_placement_offers ?? null,
        overseas_offers: data?.overseas_offers ?? null,
        course_duration: data?.course_duration ?? null,
        students_opted_for_higher_education:
          data?.students_opted_for_higher_education ?? null,
        package_currency: data?.package_currency ?? null,
      },
    };
    setDisabledSave(true);
    if (isAddMode) {
      postData(payload);
    } else {
      let update_payload = { ...payload };
      update_payload["placement_detail_data"] = {
        ...payload["placement_detail_data"],
        id: institutePlacement?.placement_detail_data_id,
        placement: institutePlacement?.placement,
      };
      update_payload["placement_type_data"] = {
        ...payload["placement_type_data"],
        content_type: payload?.placement_type_data?.content_type?.value
          ? payload?.placement_type_data?.content_type.value
          : payload?.placement_type_data?.content_type,

        id: institutePlacement?.placement_type_data_id,
      };

      patchData(update_payload);
    }
  };

  return (
    <>
      {error ? (
        message.error(error)
      ) : loader === true ? (
        <center>
          <Spin />
        </center>
      ) : (
        <Row>
          <Col span={24}>
            <NavBreadCrumb
            breadCrumb={instituteDetailData}
              page={
                institutePlacement
                  ? `${institutePlacement.placement_year} ${
                      institutePlacement.degree
                        ? `-${institutePlacement.degree.label}`
                        : ``
                    }- Placements`
                  : null
              }
            />
            <div className="gx-pt-4">
              <div className="ant-card ant-card-bordered gx-card-widget">
                <div className="ant-card-body">
                  <h2 className="gx-mb-0">
                    {" "}
                    {institutePlacement
                      ? `${institutePlacement.placement_year} ${
                          institutePlacement.object_id?.label
                            ? `-${institutePlacement.object_id?.label}`
                            : ``
                        }- Placements`
                      : locationData
                      ? `${locationData?.name}, Placement Template`
                      : `Placement Template`}{" "}
                  </h2>
                </div>
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className="ant-card ant-card-bordered gx-card">
              <div className="ant-card-body">
                <Form
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                  form={form}
                >
                  <div className="gx-p-2">
                    <PlacementDetailForm
                      form={form}
                      CurrReqValidations={CurrReqValidations}
                      instituteID={instituteID}
                      contentTypeID={contentTypeId}
                    />
                  </div>
                  <div className="d-flex" style={{ justifyContent: "left" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disabledSave}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
export default InstitutePlacementDetail;
