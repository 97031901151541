import React from 'react';
import { Row, Col, Form, Input, Select } from "antd";
import { getObjectStatus, getCourseComponent } from "../../../util/helpers";
import DynamicForm from "./DynamicForm";
const objectStatusOptions = getObjectStatus()
const componentOptions = getCourseComponent()






const ComponentDetailForm = ({ form, isAddMode }) => {

	return (
		<Row>
			<Col span={12}>
				<Form.Item label="Name" name="name" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Enter name',
						},
						{ type: 'string', max: 254 },
					]}>
					<Input className="gx-mb-3" />
				</Form.Item>
			</Col>
			<Col span={12}>
				<Form.Item label="Component" name="component" className="labelInput" rules={[
					{
						required: true,
						message: 'Select component',
					}

				]}>
					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Select Component"
						options={componentOptions}
					>
					</Select>

				</Form.Item>
			</Col>


			<Col span={8}>
				<Form.Item label="H2" name="h2" className="labelInput">
					<Input className="gx-mb-3" />

				</Form.Item>
			</Col>


			<Col span={8}>
				<Form.Item label="Brief" name="brief" className="labelInput">
					<Input className="gx-mb-3" />

				</Form.Item>
			</Col>

			<Col span={24}>
				<DynamicForm />
			</Col>

			<Col span={8}>
				<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
					{
						required: true,
						message: 'Object Status Required',
					},
				]}>
					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Select object status"
						options={objectStatusOptions}
					>
					</Select>

				</Form.Item>
			</Col>


		</Row>
	)
}

export default ComponentDetailForm
