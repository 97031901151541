import { Row, Col, Form, Input, Select } from "antd";
import React from 'react';
import { getObjectStatus } from "../../../util/helpers";

let objectStatusOptions = getObjectStatus(false)

const FaqsDetailForm = () => {
	return (
		<>
			<Row gutter={36}>
				<Col span={6}>
					<Form.Item label="Object Status" name="object_status" className="labelInput"
						rules={[
							{
								required: true,
								message: 'Select Object Status',
							},
						]}>

						<Select
							allowClear={true}
							style={{ width: '100%' }}
							placeholder="Object status"
							options={objectStatusOptions}
						>
						</Select>
					</Form.Item>
				</Col>
				<Col span={24} className="gx-mt-4 gx-mb-4">
					<Form.Item label="Question" name="question" className="labelInput" rules={[{ required: false }]}>
						<Input placeholder="Question" />
					</Form.Item>
				</Col>
			</Row>
		</>
	)
}

export default FaqsDetailForm
