import axios from "axios";
import { BASE_URL, LOG_BASE_URL } from "../constants/Settings";
import { getUser } from "../util/helpers";
import {
  IMAGE_UPLOAD_URL
} from "../constants/CollegeDekho"

const getHeaders = () => {
  const user = getUser()
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${user.token}`
  }
  console.log("headers", headers)
  return headers
}
export async function get(url, data) {
  // console.log('fetching', url, data);
  const baseURL = BASE_URL + url;
  if (data) {
    return await axios.get(baseURL, { params: data, headers: getHeaders() })
  }

  return await axios.get(baseURL, { headers: getHeaders() });
}

export async function patch(url, data) {
  //console.log("url:",url,"data",data)
  const baseURL = BASE_URL + url;
  return await axios.patch(baseURL, data, { headers: getHeaders() })

}

export async function post(url, data) {
  //console.log("url",url,"data",data)
  const baseURL = BASE_URL + url;
  return await axios.post(baseURL, data, { headers: getHeaders() })

}

export async function postImage(url, data) {
  const user = getUser()
  console.log("url", url, "data", data)
  const baseURL = BASE_URL + url;
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Token ${user.token}`
  }

  return await axios.post(baseURL, data, { headers: headers })

}

export async function patchImage(url, data) {
  const user = getUser()
  console.log("url", url, "data", data)
  const baseURL = BASE_URL + url;
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Token ${user.token}`
  }
  return await axios.patch(baseURL, data, { headers: headers })

}

export async function login(email, password) {
  //console.log("url",url,"data",data)
  const baseURL = BASE_URL + "/api/cms/authentication/login/";
  return await axios.post(baseURL, { email: email, password: password })

}
export async function logout() {
  //console.log("url",url,"data",data)
  const baseURL = BASE_URL + "/api/cms/authentication/logout/";
  return await axios.post(baseURL,{},{ headers: getHeaders() })
}

export async function getAutoSuggestionList(url) {
  url = BASE_URL + url
  return axios.get(url, { headers: getHeaders() })
    .then((response) =>
      response.data.results.map((data) => ({
        label: `${data.name}(${data.id})`,
        value: data.id,
      })),
    ).catch(errorHandler);
}

export async function getNewsSuggestionList(url) {
  url = BASE_URL + url
  return axios.get(url, { headers: getHeaders() })
    .then((response) =>
      response.data.results.map((data) => ({
        label: `${data.title}(${data.id})`,
        value: data.id,
      })),
    ).catch(errorHandler);
}

export async function getExamSuggestionList(url) {
  url = BASE_URL + url
  return axios.get(url, { headers: getHeaders() })
    .then((response) =>
      response.data.results.map((data) => ({
        label: `${data.short_name}(${data.id})`,
        value: data.id,
      })),
    ).catch(errorHandler);
}

export const errorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    //console.log(error.response.data);
    //console.log(error.response.status);
    //console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    //throw new Error();
    return Promise.reject('Error')
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log("con..", error.config);
  //   if (error.response.status === 404) {
  //     throw new Error(`${error.config.url} not found`);
  //   }
  //   throw error;
}

export const getImageUploadURL = () => {
  return `${BASE_URL}${IMAGE_UPLOAD_URL}`
}


export async function deleteDescription(url) {
  const user = getUser()
  console.log("url", url)
  const baseURL = BASE_URL + url;
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Token ${user.token}`
  }
  return await axios.delete(baseURL, { headers: headers })

}

export async function saveDescription(url, data) {
  const user = getUser()
  console.log("url", url, "data", data)
  const baseURL = BASE_URL + url;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${user.token}`
  }
  return await axios.post(baseURL, data, { headers: headers })
}

export async function getDescription(url) {
  const baseURL = BASE_URL + url
  return await axios.get(baseURL, { headers: getHeaders() })

}

// Making a GET request to LOG service
export async function getLog(url, data) {
  //console.log('fetching', url, data);
  const baseURL = LOG_BASE_URL + url;
  if (data) {
    return await axios.get(baseURL, { params: data, headers: getHeaders() })
  }

  return await axios.get(baseURL, { headers: getHeaders() });
}
