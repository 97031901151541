import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, Button, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import LatestUpdateDetailForm from './latestUpdateDetailForm';
import { getObject } from '../../../util/helpers';
import { COMMON_SUCCESS_MESSAGE, COMMON_API_LIST, DATE_TIME_FORMAT, COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho'
import { isInt, getErrors, getMultiObject } from '../../../util/helpers';
import { useFetchData } from "../../../hooks";
import { patch, post } from '../../../services';
import { CustomEditor } from '../../../components/CustomEditor/';
import moment from 'moment';
import { Spin } from 'antd';
import { CONTENT_TYPE_ID } from '../../../constants/Settings';


const LatestUpdateDetail = (props) => {

	const pathname = useSelector(({ common }) => common.pathname);
	const dashboardType = pathname.includes("institute") ? "institute" : "course";
	const contentTypeId = CONTENT_TYPE_ID[dashboardType]

	const [form] = Form.useForm();
	const { ID, vernacularID } = useParams();
	const { latestUpdateID } = useParams();
	const history = useHistory()
	const [disabledSave, setDisabledSave] = useState(false)
	const [content, setContent] = useState("");
	const [loader, setLoader] = useState(false);
	const locationData = props.location?.locationData
	const [extraDetails, setExtraDetails] = useState(locationData !== 'undefined' ? locationData : null);
	const [vernacularCourse, setVernacularCourse] = useState();
	const isAddMode = !isInt(latestUpdateID)
	const dashboardTypeDetailURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", ID)
    const [breadCrumb, loadingDashboardTypeDetail, errorDashboardTypeDetail] = useFetchData(dashboardTypeDetailURL)

	let url = null;
	let saveURL = COMMON_API_LIST["LATEST_UPDATE_DETAIL_POST_URL"].replace("#id#", contentTypeId)
	saveURL = saveURL.replace("#id1#", vernacularID)

	if (!isAddMode) {
		url = COMMON_API_LIST["LATEST_UPDATE_DETAIL_URL"].replace("#id#", contentTypeId)
		url = url.replace("#id1#", vernacularID)
		url = url.replace("#id2#", latestUpdateID)
		saveURL = url
	}

	const [data, loading, error] = useFetchData(url)


	const setLatestUpdateObj = (data) => {
		const latestUpdateObj = {
			heading: data.heading,
			date: moment(data.date, DATE_TIME_FORMAT),
			end_date: data.end_date ? moment(data.end_date, DATE_TIME_FORMAT) : null,
			schedule_date: data.schedule_date ? moment(data.schedule_date, DATE_TIME_FORMAT) : null,
			object_status: data.object_status === true ? 1 : 0,
		}

		if (data.content_object.length > 1) {
			latestUpdateObj["object_id"] = getMultiObject(data.content_object)
		}
		else {
			latestUpdateObj["object_id"] = getObject(data.content_object)
		}

		form.setFieldsValue(latestUpdateObj)
		setContent(data.overview)
		setExtraDetails(data.content_object)

	}


	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (data) {

				setLatestUpdateObj(data)


			}

		}


		return () => mounted = false;
	}, [data])

	const getCleanData = (data) => {
		const cleanData = { ...data }
		cleanData.date = cleanData["date"].format(DATE_TIME_FORMAT);
		if (cleanData["end_date"]) {
			cleanData.end_date = cleanData["end_date"].format(DATE_TIME_FORMAT);
		}
		if (cleanData["schedule_date"]) {
			cleanData.schedule_date = cleanData["schedule_date"].format(DATE_TIME_FORMAT);
		}

		if (cleanData["heading"]) {
			cleanData.heading = cleanData["heading"].toString()
		}

		cleanData.overview = content

		return cleanData
	}

	const crudErrorHandler = (error) => {
		showError(error)
	}

	const patchData = (data) => {
		setLoader(true);
		patch(saveURL, data).then(function (response) {

			message.success(COMMON_SUCCESS_MESSAGE);
			history.push(`/${dashboardType}/${ID}/vernacular/${vernacularID}/latest-update/`)
		})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
				setLoader(false);
			});
	}

	const postData = (data) => {
		setLoader(true);
		post(saveURL, data)
			.then(function (response) {
				message.success(COMMON_SUCCESS_MESSAGE);
				history.push(`/${dashboardType}/${ID}/vernacular/${vernacularID}/latest-update/`)

			})
			.catch(crudErrorHandler)
			.finally(() => {
				setDisabledSave(false)
				setLoader(false);
			});

	}

	const showError = (errors) => {

		const error = getErrors(errors)
		if (error.allError.length > 0) {
			message.error(error.allError.join());
		}
		form.setFields(error.serverError)

	}


	const onFinish = (e) => {
		const data = form.getFieldsValue(true);
		const cleanData = getCleanData(data)
		setDisabledSave(true)
		if (isAddMode) {
			let objectIds = []
			if (cleanData.object_id) {
				objectIds = cleanData.object_id.map((item) => { return item.value })
			}
			cleanData.pages = objectIds
			delete cleanData.object_id
			postData(cleanData)
		}
		else {
			cleanData.object_id = cleanData.object_id.value
			patchData(cleanData)
		}
	}



	return (
		<>
			{error ? message.error(error) : loader === true ? <center><Spin /></center> :
				<Row>

					<Col span={24}>
						<NavBreadCrumb breadCrumb={breadCrumb} 
						courseVernacularID={vernacularCourse?.course_content_vernacular}/>
						<div className="gx-pt-4">
							<div className="ant-card ant-card-bordered gx-card-widget">
								<div className="ant-card-body">
									<h2 className="gx-mb-0">{extraDetails?.parent?.name} Latest Updates </h2>

								</div>
							</div>
						</div>
					</Col>


					<Col span={24}>
						<div className="ant-card ant-card-bordered gx-card">
							<div className="ant-card-body">
								<Form onFinish={onFinish} form={form}>
									<div className="gx-p-2">
										<LatestUpdateDetailForm isAddMode={!isAddMode} setExtraDetails={setVernacularCourse}/>
									</div>

									<div className="gx-p-2">
										<CustomEditor content={content} setContent={setContent} />
									</div>


									<div className="d-flex" style={{ justifyContent: 'left' }}>
										<Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
									</div>
								</Form>
							</div>
						</div>
					</Col>

				</Row>
			}
		</>

	);
};
export default LatestUpdateDetail;
