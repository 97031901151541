import { getAutoSuggestionList } from "..";
import { CONTENT_TYPE_ID } from "../../constants/Settings";

export async function getContentTypeObjectList(
  text,
  instituteID,
  contentTypeId
) {
  console.log("getAutoSuggestionList", contentTypeId);
  let url;
  if (
    contentTypeId == CONTENT_TYPE_ID.instituteCourse ||
    contentTypeId == CONTENT_TYPE_ID.degree
  ) {
    url = `/api/cms/common/object-list/?page_content_type_id=${contentTypeId}&text=${text}&institute_id=${instituteID}`;
  } else {
    url = `/api/cms/common/object-list/?page_content_type_id=${contentTypeId}&text=${text}`;
  }

  return await getAutoSuggestionList(url);
}
