import React, { useEffect, useState } from 'react'
import { Row, Col, Button, message, Upload, Modal, Form, Select } from "antd";
import { Link, useParams } from 'react-router-dom';
import NavBreadCrumb from '../../CollegeDekho/NavBreadCrumb';
import { getAutoSuggestionList, post } from '../../../services';
import { UploadOutlined } from '@ant-design/icons';
import { getErrors, getTemplateContentTypeName } from '../../../util/helpers';
import FaqListingComponent from './faqListingComponent';
import { API_URL_LIST, COURSE_CONTENT_API_LIST, SCHOLARSHIP_API_LIST } from '../../../constants/CollegeDekho';
import { useFetchData } from '../../../hooks';

const FaqsListing = () => {
	const { instituteID,
		courseID,
		courseVernacularID,
		scholarshipID,
		templateContentTypeID,
		scholarshipLandingPageID,
		scholarshipCategoryID,
		scholarshipSubCategoryID,ID

	} = useParams();

	const templateObjectID = instituteID
		|| courseVernacularID || scholarshipID
		|| scholarshipLandingPageID
		|| scholarshipCategoryID || scholarshipSubCategoryID
		var detailUrl=null
	if(instituteID){
 		detailUrl = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)

	}else if(courseID || ID){
		detailUrl = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", courseID ?courseID: ID)

	}else if(scholarshipID || ID){
		detailUrl = SCHOLARSHIP_API_LIST["SCHOLARSHIP_DETAIL_PAGE_URL"].replace("#id#", scholarshipID?scholarshipID: ID)

	}

	const [instituteData] = useFetchData(detailUrl)
	const templateName = getTemplateContentTypeName(templateContentTypeID)
	const addFAQURL = courseVernacularID ? `/${templateName}/${courseID}/vernacular/${templateObjectID}/faqs/${templateContentTypeID}/create` : `/${templateName}/${templateObjectID}/faqs/${templateContentTypeID}/create`

	const [form] = Form.useForm();

	const searchParams = new URLSearchParams(document.location.search)
	const pageObject = searchParams.get('page_object_id')

	pageObject && form.setFieldsValue({ page: +pageObject })
	const [fileError, setFileError] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [file, setFile] = useState(null)
	const [isUploading, setIsUploading] = useState(false)
	const [pageTypeList, setPageTypeList] = useState([])
	const [pageObjectList, setPageObjectList] = useState([])
	const [pageContentTypeID, setPageContentTypeID] = useState(searchParams.get('page_content_type_id'))
	const [pageObjectID, setPageObjectID] = useState(null)
	const [isLoadingSuggestions, setIsLoadingSuggestions] = useState({
		pageType: false,
		pageObject: false
	})
	const [error, setError] = useState('')
	const [extraDetails, setExtraDetails] = useState(null)
	const [page, setPage] = useState(1);
	let url=extraDetails?.breadcrumb[1]?.url
	let newData={
		id:url?.includes(courseVernacularID)&&courseVernacularID,
		name:extraDetails?.breadcrumb[1]?.title,
		language:{name:extraDetails?.breadcrumb[1]?.language?.name}
	}		
	useEffect(() => {
		const getPageTypeList = async () => {
			setIsLoadingSuggestions(prev => ({ ...prev, pageType: true }))
			const url = API_URL_LIST
				.FAQ_CONTENT_TYPES
				.replace('#templateContentTypeID#', templateContentTypeID)
			const response = await getAutoSuggestionList(url).finally(() => setIsLoadingSuggestions(prev => ({ ...prev, pageType: false })))
			if (response) {
				setIsLoadingSuggestions(prev => ({ ...prev, pageType: false }))
				setPageTypeList(response)
			}
		}

		getPageTypeList()
	}, [templateContentTypeID]);

	useEffect(() => {
		const getPageObjectList = async () => {
			setIsLoadingSuggestions(prev => ({ ...prev, pageObject: true }))
			const url = API_URL_LIST
				.FAQ_OBJECT_LIST
				.replace('#pageContentTypeID#', pageContentTypeID).replace('#templateObjectID#', templateObjectID)

			const response = await getAutoSuggestionList(url)
			if (response) {
				if (!response.length) message.error("No page found")
				setIsLoadingSuggestions(prev => ({ ...prev, pageObject: false }))
				setPageObjectList(response)

			}
		}
		pageContentTypeID && getPageObjectList()

	}, [pageContentTypeID, templateObjectID]);

	const crudErrorHandler = (error) => showError(error)
	const showError = (errors) => {
		const error = getErrors(errors)
		if (error.allError.length > 0) {
			message.error(error.allError.join());
		}
	}


	const uploadFile = () => {
		const url = API_URL_LIST.FAQ_UPLOAD
		if (!file) return setFileError("Please select a file.")
		setIsUploading(true)
		const formData = new FormData();
		formData.append("csv_file", file);
		post(url, formData)
			.then((response) => {
				setIsUploading(false)
				setFile(null)
				message.success(`FAQs uploaded successfully ${response?.data?.faq_uploaded} `,)
			})
			.catch(crudErrorHandler)
			.finally(() => setIsUploading(false));
	};

	const beforeUpload = (file) => {
		// Add validation or custom logic here if needed
		setFile(null)
		setFile(file);
		setFileError(null)
		return false;
	};

	const handleFileUpload = (file) => {
		// You can perform further processing with the uploaded file
		console.log("File uploaded:", file);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Modal
				title="Upload Faqs"
				visible={isModalOpen}
				okText="Upload"
				onOk={uploadFile}
				confirmLoading={isUploading}
				onCancel={handleCancel}>
				<Row gutter={[16]} >
					<Upload
						onRemove={() => setFile(null)}
						beforeUpload={beforeUpload}
						onChange={handleFileUpload}
						maxCount={1}
					>
						<Button>
							<UploadOutlined /> Click to Upload
						</Button>
						{fileError && <p style={{ color: 'red' }}>{fileError}</p>}
					</Upload>
				</Row>
			</Modal>

			<Row>
				{error ? message.error(error) : null}
				<Col span={24}>
					<NavBreadCrumb 
					breadCrumb={instituteData}
					courseVernacularID={newData}
					/>
					<div className="ant-card ant-card-bordered gx-card-widget gx-mt-4">
						<div className="ant-card-body">
							<h2 className="gx-mb-0">{extraDetails?.breadcrumb[1]?.title} FAQs </h2>
						</div>
					</div>
					<Row>
						<Col span={18} style={{ textAlign: 'left' }}>
							<Button
								onClick={() => {
									form.resetFields()
									setPageContentTypeID(null)
									setPageObjectID(null)
								}}
							>
								Clear Filters
							</Button>
						</Col>
						<Col span={6} style={{ textAlign: 'right' }}>
							<Button
								onClick={() => setIsModalOpen(true)}
							>
								Bulk Upload
							</Button>
							
							{
								pageContentTypeID && pageObjectID &&
								<Link to={{
									pathname: `${addFAQURL}`,
									search: `?page_content_type_id=${pageContentTypeID}&page_object_id=${pageObjectID}`,
									locationData: { parent: { "name": extraDetails?.institute?.name } }
								}}
								>
									<Button type="primary">
										<span>Add New FAQs</span>
									</Button>
								</Link>
							}

						</Col>
					</Row>
				</Col >
				<div className="gx-pt-4 gx-m-3" style={{ width: "100%" }}>
					<div className="ant-card ant-card-bordered gx-card">
						<div className="ant-card-body">
							<Form form={form}>
								<Row gutter={[40]}>
									<Col span={6} >
										<Form.Item label="Page Type" name="category" className="labelInput"
											rules={[
												{
													required: true,
													message: 'Select Category',
												},
											]}>
											<Select
												loading={isLoadingSuggestions?.pageType}
												allowClear={false}
												style={{ width: '100%' }}
												placeholder="Select Page"
												options={pageTypeList}
												value={+pageObjectID}
												defaultValue={+pageContentTypeID || null}
												onChange={(opt) => {
													setPageContentTypeID(opt)

												}}
											>
											</Select>
										</Form.Item>
									</Col>

									<Col span={6}>
										<Form.Item label="Page" name="page" className="labelInput"
											rules={[
												{
													required: true,
													message: 'Select page',
												},
											]}>
											<Select
												loading={isLoadingSuggestions?.pageObject}
												allowClear={false}
												style={{ width: '100%' }}
												placeholder="Select Page"
												options={pageObjectList}
												onChange={opt => setPageObjectID(opt)}
											>
											</Select>
										</Form.Item>
									</Col>

								</Row>
							</Form>
						</div>
					</div>
				</div>
				<Col span={24}>
					<FaqListingComponent
						courseID={courseID}
						page={page}
						setPage={setPage}
						templateContentTypeID={templateContentTypeID}
						templateObjectID={templateObjectID}
						pageContentTypeID={pageContentTypeID}
						pageObjectID={pageObjectID}
						setExtraDetails={setExtraDetails}
						setError={setError}
					/>
				</Col>
			</Row >
		</>
	)
};
export default FaqsListing;
