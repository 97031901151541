import { get } from "../index";

export async function getInstituteCourseList(data) 
{
    console.log("data",data);
    const url =  `/api/cms/institute/${data.instituteID}/institutecourse/`;
    let degree = []
    if(data.degree)
    {
      degree = data.degree.map((item) => { return item.value })
    }
    let stream = []
    if(data.stream)
    {
      stream = data.stream.map((item) => { return item.value })
    }
    let specialization = []
    if(data.specialization)
    {
      specialization = data.specialization.map((item) => { return item.value })
    }
    let mode = ""
    console.log("mode",typeof mode)
    if(typeof data.mode === "string")
    {
      mode = data.mode
    }

    
    const params = {
      page_size: data.pageSize,
      page:data.page,
      text:data.text,
      degree: degree.join(","),
      stream: stream.join(","),
      specialization: specialization.join(","),
      level: data.level.join(","),
      mode: data.mode

    } 
    console.log(params)
    return await get(url,params)
    // return await axios.get(url,{
    //     params: params 
    //   } );    

}