import { Checkbox, message, Modal, Row, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { get, patch } from "../../../../services";
import { API_URL_LIST } from "../../../../constants/CollegeDekho";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getErrors } from "../../../../util/helpers";
import ReplicateComponent from "./ReplicateComponent";

function ReplicateModal({
  isReplicateModalOpen,
  setIsReplicateModalOpen,
  replicate,
}) {
  const { instituteID } = useParams();
  const [isLoading, setIsLoading] = useState({
    getLoader: true,
    updateLoader: false,
  });
  const [error, setError] = useState(null);
  const [replicateList, setReplicateList] = useState([]);
  let url = API_URL_LIST.REPLICATE_COURSE_API.replace(
    "#instititeID#",
    instituteID
  );
  url = url.replace("#courseID#", replicate?.id);

  useEffect(() => {
    let mounted = true;
    if (mounted && replicate?.id) {
      get(url)
        .then((response) => {
          let responseData = response?.data.map((item) => ({
            ...item,
            mode: false,
            fee: false,
            exams: false,
            program_type: false,
          }));
          setReplicateList(responseData);

          setIsLoading((prev) => ({ ...prev, getLoader: false }));
        })
        .catch((error) => {
          const err = getErrors(error);
          setError(err.allError);
          setIsLoading((prev) => ({ ...prev, getLoader: false }));
        });
    }
    return () => {
      mounted = false;
    };
  }, [url, replicate?.id]);
  const onUpdateReplicate = () => {
    setIsLoading((prev) => ({ ...prev, updateLoader: true }));

    patch(url, replicateList)
      .then((res) => {
        message.success(res?.data?.message);
        setIsReplicateModalOpen(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.errors[0])        
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, updateLoader: false }));
      });
  };
  const onHandleReplicate = (item, index, value, type) => {
    const newArray = [...replicateList];
    let newElement = {};

    newElement = {
      ...item,
      [type]: !value,
    };

    newArray[index] = newElement;
    setReplicateList(newArray);
  };
  return (
    <div>
      <Modal
        width={1000}
        title={`Replicate from ${replicate?.name}`}
        visible={isReplicateModalOpen}
        okText="Update"
        onOk={onUpdateReplicate}
        confirmLoading={isLoading.updateLoader}
        onCancel={() => setIsReplicateModalOpen(false)}
        className="replicateModal"
      >
        {isLoading.getLoader ? (
          <center>
            <Spin />
          </center>
        ) : (
          <>
            {replicateList?.map((item, index) => {
              return (
                <ReplicateComponent
                  item={item}
                  index={index}
                  key={item?.id}
                  onHandleReplicate={onHandleReplicate}
                  style={{ height: "500px", overflowY: "auto" }}
                />
              );
            })}
          </>
        )}
      </Modal>
    </div>
  );
}

export default ReplicateModal;
