import { Row, Col } from "antd";
import { CustomEditor } from '../../../components/CustomEditor/';


const CollegeListing = ({content, setContent, isAddMode, instituteID}) => {


  return (
    <Row>
      <Col span={24}>
        <div className="gx-p-2">
          <CustomEditor content={content} setContent={setContent} />
        </div>
      </Col>
    </Row>
  )
}
export default CollegeListing
