import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import ScholarshipListing from "./ScholarshipListing";
import ScholarshipDetail from "./ScholarshipDetail";
import ScholarshiPageComponentListing from "./ScholarshipPageComponentListing";
import ScholarshipPageComponentDetail from "./ScholarshipPageComponentListing/ScholarshipPageComponentDetail";
import ScholarshipCategoryListing from "./ScholarshipCategory/ScholarshipCategoryListing";
import ScholarshipCategoryDetail from "./ScholarshipCategory/ScholarshipCategoryDetail";
import FaqsListing from "../../components/Faqs/listing";
import ScholarshipSubCategoryListing from "./ScholarshipSubCategory/ScholarshipSubCategoryListing";
import ScholarshipLanding from "./ScholarshipLanding/ScholarshipLanding";
import ScholarshipSubCategoryDetail from "./ScholarshipSubCategory/ScholarshipSubCategoryDetail";
import ScholarshipLandingDetail from "./ScholarshipLanding/ScholarshipLandingDetail";
import FaqsDetail from "../../components/Faqs/details";

const Scholarship = ({match}) => {

    return (

    <Switch>
        
        
        <Route exact path={`${match.url}`} component={ScholarshipListing}/>
        
        <Route exact path={`${match.url}/create`} component={ScholarshipDetail} />
        <Route exact path={`${match.url}/:scholarshipID(\\d+)/update`} component={ScholarshipDetail}/>
        <Route exact path={`${match.url}/:scholarshipID(\\d+)/component`} component={ScholarshiPageComponentListing}/>
        <Route exact path={`${match.url}/:scholarshipID(\\d+)/component/create`} component={ScholarshipPageComponentDetail}/>
        <Route exact path={`${match.url}/:scholarshipID(\\d+)/component/:componentID(\\d+)/update`} component={ScholarshipPageComponentDetail}/>
        <Route exact path={`${match.url}/:scholarshipID(\\d+)/faqs/:templateContentTypeID`} component={FaqsListing}/>
        <Route exact path={`${match.url}/:scholarshipID(\\d+)/faqs/:templateContentTypeID/create`} component={FaqsDetail}/>
        <Route exact path={`${match.url}/:scholarshipID(\\d+)/faqs/:templateContentTypeID/:faqObjectID/update`} component={FaqsDetail}/>

        <Route exact path={`${match.url}/landing/`} component={ScholarshipLanding} />
        <Route exact path={`${match.url}/landing/:scholarshipLandingPageID(\\d+)/faqs/:templateContentTypeID`} component={FaqsListing} />
        <Route exact path={`${match.url}/landing/:scholarshipLandingPageID(\\d+)/faqs/:templateContentTypeID/create`} component={FaqsDetail} />
        <Route exact path={`${match.url}/landing/:scholarshipLandingPageID(\\d+)/faqs/:templateContentTypeID/:faqObjectID/update`} component={FaqsDetail} />
        <Route exact path={`${match.url}/landing/:scholarshipLandingPageID(\\d+)/update`} component={ScholarshipLandingDetail} />
        <Route exact path={`${match.url}/landing/:scholarshipLandingPageID(\\d+)/faqs/:templateContentTypeID(\\d+)/:scholarshipLandingPageID(\\d+)`} component={FaqsListing}/>

        <Route exact path={`${match.url}/category`} component={ScholarshipCategoryListing} />
        <Route exact path={`${match.url}/category/create`} component={ScholarshipCategoryDetail} />
        <Route exact path={`${match.url}/category/:scholarshipCategoryID(\\d+)/update`} component={ScholarshipCategoryDetail} />
        <Route exact path={`${match.url}/category/:scholarshipCategoryID(\\d+)/faqs/:templateContentTypeID`} component={FaqsListing} />
        <Route exact path={`${match.url}/category/:scholarshipCategoryID(\\d+)/faqs/:templateContentTypeID/create`} component={FaqsDetail} />
        <Route exact path={`${match.url}/category/:scholarshipCategoryID(\\d+)/faqs/:templateContentTypeID/:faqObjectID/update`} component={FaqsDetail} />

        <Route exact path={`${match.url}/subcategory`} component={ScholarshipSubCategoryListing} />
        <Route exact path={`${match.url}/subcategory/create/`} component={ScholarshipSubCategoryDetail} />
        <Route exact path={`${match.url}/subcategory/:scholarshipSubCategoryID(\\d+)/update`} component={ScholarshipSubCategoryDetail} />
        <Route exact path={`${match.url}/subcategory/:scholarshipSubCategoryID(\\d+)/faq/:contentTypeID(\\d+)/:scholarshipSubCategoryID(\\d+)`} component={FaqsListing} />
        <Route exact path={`${match.url}/subcategory/:scholarshipSubCategoryID(\\d+)/faqs/:templateContentTypeID`} component={FaqsListing} />
        <Route exact path={`${match.url}/subcategory/:scholarshipSubCategoryID(\\d+)/faqs/:templateContentTypeID/create`} component={FaqsDetail} />
        <Route exact path={`${match.url}/subcategory/:scholarshipSubCategoryID(\\d+)/faqs/:templateContentTypeID/:faqObjectID/update`} component={FaqsDetail} />

        
    </Switch>
    )
}

export default Scholarship;