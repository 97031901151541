import { get } from "../index";

export async function getInstituteVideoList(data)
{
    console.log("data",data);
    const url = `/api/cms/institute/${data.instituteID}/video/`;

    const params = {
      page_size: data.pageSize,
      page:data.page,
      text:data.text

    }
    console.log(params)
    return await get(url,params)
}

export async function validYoutubeId(id)
{
  const url = "https://img.youtube.com/vi/" + id + "/mqdefault.jpg";
  const { status } = await fetch(url, {mode:'no-cors'});
  if (status === 404) return false;
  return true;
}
