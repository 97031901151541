import React from 'react';
import {Divider, Table,message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { post } from '../../../../services';
import { useParams } from 'react-router-dom';
import { API_URL_LIST,COMMON_ERROR_MESSAGE,DATE_TIME_FORMAT_LISTING } from '../../../../constants/CollegeDekho';
import {FormOutlined } from "@ant-design/icons";
import moment from 'moment';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState,useEffect } from "react";

const DragnDrop =({dataSource,setDataSource}) =>{

  const { instituteID, degreeID } = useParams();
//   const { instituteMasterPageID } = useParams();
  let saveURL = API_URL_LIST["INSTITUTE_DEGREE_COMPONENT_UPDATE_ORDER"]
  saveURL = saveURL.replace("#instituteID#",instituteID).replace("#degreeID#", degreeID)

  const columns = [
    {
      key: "dragHandle",
      dataIndex: "dragHandle",
      title: "Drag",
      width: 30,
      render: () => <MenuOutlined />,
    },
    {
      key: "key",
      dataIndex: "key",
      title: "ID",
    },
    {
      key: "h2",
      dataIndex: "h2",
      title: "H2",
    },

    {
      title: 'Component',
      key: 'component',
      render: (text, record) => (
        <span>
         {record.component.name}
      </span>
      ),
    },
    {
      key: "object_status",
      dataIndex: "object_status",
      title: "Object Status",
      render: (text, record) => (
        <span>
         {record.object_status.name}
      </span>
      ),
    },
    {
      key: "modified_on",
      dataIndex: "modified_on",
      title: "Modified on",
      render: (text, record) => (
        <span>
         {moment(record.modified_on).format(DATE_TIME_FORMAT_LISTING)}
      </span>
      ),
    },

  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
      <Divider type="vertical"/>
      <span className="gx-link">
        <Link to={`/institute/${instituteID}/degree/${degreeID}/page/component/${record.id}`}>Edit/SEO  </Link></span>
      
      <Divider type="vertical"/>
    </span>
    ),
  }
  ];


  // const dataSourceRaw = new Array(1).fill({}).map((item, index) => ({
  //   // This will be transformed into `data-row-key` of props.
  //   // Shall be truthy to be draggable. I don't know why.
  //   // To this end, index of number type is converted into string.
  //   key: index.toString(),
  // }));

  // ID to render overlay.
  const [activeId, setActiveId] = useState(null);


  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setDataSource((items) => {
        // In this example, find an item, where `item.key` === `useSortable.id`.
        const oldIndex = items.findIndex((item) => item.key === active.id);
        const newIndex = items.findIndex((item) => item.key === over.id);
        console.log(active.id,over.id)
        updateOrder(active.id,over.id)
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    // Stop overlay.
    setActiveId(null);
  }

  const crudErrorHandler = (error) =>
	{
			message.error(COMMON_ERROR_MESSAGE);
	}

  function updateOrder(from,after)
  {

    post(saveURL,{"from":from,"after":after})
	  .then(function (response)
      {

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		        //setDisabledSave(false)
      });

  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        components={{
          body: {
            wrapper: DraggableWrapper,
            row: DraggableRow,
          },
        }}
        pagination={false}
      />
      {/* Render overlay component. */}
      <DragOverlay>{activeId ? activeId : null}</DragOverlay>
    </DndContext>
  );

  function DraggableWrapper(props) {
    const { children, ...restProps } = props;
    /**
     * 'children[1]` is `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    return (
      <SortableContext
        items={children[1] instanceof Array ? children[1].map((child) => child.key) : []}
        strategy={verticalListSortingStrategy}
        {...restProps}
      >
        <tbody {...restProps}>
          {
            // This invokes `Table.components.body.row` for each element of `children`.
            children
          }
        </tbody>
      </SortableContext>
    );
  }

  function DraggableRow(props) {
    const { attributes, listeners, setNodeRef, isDragging, overIndex, index } = useSortable({
      id: props["data-row-key"],
    });
    const isOver = overIndex === index
    const { children, ...restProps } = props;
    const isData = children instanceof Array;
    const style = {
      ...restProps?.style,
      ...(isData && isDragging ? { background: "#80808038" } : {}),
      ...(isData && isOver ? { borderTop: "5px solid #ec161638" } : {})
    }
    /**
     * 'children[1]` is a row of `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    return (
      <tr
        ref={setNodeRef}
        {...attributes}
        {...restProps}
        style={style}
      >
        {
          children instanceof Array ? (
            children.map((child) => {
              const { children, key, ...restProps } = child;
              return key === "dragHandle" ? (
                <td {...listeners} {...restProps}>
                  {child}
                </td>
              ) : (
                <td {...restProps}>{child}</td>
              );
            })
          ) : (
            children
          )
        }
      </tr>
    );
  }
}

export default DragnDrop;
