import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { getInstituteCourseList } from "../../../services/InstituteCourse/InstituteCourse";
import { Divider, Row, Col, Table, Pagination, Button, Input, message, Modal, Upload } from "antd";
import { API_URL_LIST, PAGE_SIZE } from "../../../constants/CollegeDekho";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import InstituteCourseListingFilter from './InstituteCourseListingFilter';
import {  CopyTwoTone, SearchOutlined, UpCircleOutlined } from '@ant-design/icons';
import { getErrors } from '../../../util/helpers';
import { FormOutlined } from "@ant-design/icons";
import { post } from "../../../services";
import SampleFile from "../../../assets/institute_course_status_sample_bulk_upload.csv"
import { CONTENT_TYPE_ID } from "../../../constants/Settings";
import { useFetchData } from "../../../hooks";
import ReplicateModal from "./replicateModal/ReplicateModal";

const InstituteCourseListing = () => {
  const [instituteCourseList, setInstituteCourseList] = useState([])
  const [error, setError] = useState(null)
  const [degree, setDegree] = useState([])
  const [stream, setStream] = useState([])
  const [level, setLevel] = useState([])
  const [specialization, setSpecialization] = useState([])
  const [mode, setMode] = useState("")
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [institute, setInstitute] = useState({});
  const [replicate, setReplicate] = useState();
  const [isModalOpen, setIsModalOpen] = useState()
  const [isReplicateModalOpen, setIsReplicateModalOpen] = useState()
  const { instituteID } = useParams();
  const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
  const [breadCrumb] = useFetchData(dashboardTypeDetailURL)
  const onHandleReplicate=(record)=>{
    setIsReplicateModalOpen(true)
    setReplicate(record)
  }
  const columns = [{
    title: 'ID',
    dataIndex: 'id',
  
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Stream',
    key: 'stream',
    render: (text, record) => (
      <span>
        {record.course?.stream?.name}
      </span>
    ),
  },
  {
    title: 'Degree',
    key: 'degree',
    render: (text, record) => (
      <span>
        {record.course?.degree?.name}
      </span>
    ),
  },
  
  {
    title: 'Specialization',
    key: 'specialization',
    render: (text, record) => (
      <span>
        {record.course?.specialization?.name}
      </span>
    ),
  },
  {
    title: 'Mode',
    key: 'mode',
    render: (text, record) => (
      <span>
        {record.mode?.name}
      </span>
    ),
  },
  {
    title: 'Status',
    key: 'object_status',
    render: (text, record) => (
      <span>
        {record.object_status?.name}
      </span>
    ),
  },
  {
    title: 'Fee',
    dataIndex: 'fee'
  
  },
  {
    title: 'FAQ',
    key: 'faq',
    render: (text, record) => (
      <span className="gx-link">
        {
          record?.page ?
            <Link to={{
              pathname: `/institute/${record.institute.id}/faqs/${CONTENT_TYPE_ID.institute}/`,
              search: `?page_content_type_id=${CONTENT_TYPE_ID.instituteCoursePage}&page_object_id=${record.page.id}`
            }}>
              <FormOutlined />
            </Link>
  
            :
            <FormOutlined onClick={() => message.error("Institute Course page doesn't exist")} />
        }
      </span>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
        <Divider type="vertical" />
        <span className="gx-link"><Link to={`/institute/${record.institute.id}/course/${record.id}/update/`}>  <FormOutlined /> </Link></span>
        <Divider type="vertical" />
        <Divider type="vertical" />
        <span className="gx-link">
          <Link to={{ pathname: `/institute/${record.institute.id}/course/${record.id}/page`, locationData: { "institute": { "name": record.institute?.name }, "master_page": { "name": record.master_page?.name } } }}>
            Edit/SEO
          </Link>
        </span>
        <Divider type="vertical" />
        <span className="gx-link">
          <Link to={{ pathname: `/institute/${record.institute.id}/course/${record.id}/page/components`, locationData: { "institute": { "name": record.institute?.name }, "master_page": { "name": record.master_page?.name } } }}>
            Components
          </Link>
        </span>
      </span>
  
  
  
    ),
  },
  {
    title: 'Replicate',
    key: 'action',
    render: (text, record) => (
      <span className="gx-link" onClick={()=>onHandleReplicate(record)}>
        <CopyTwoTone/> 
     </span>
    ),
  },
  ];
  useEffect(() => {
    let mounted = true;

    setLoading(true);
    getInstituteCourseList({ instituteID, page, pageSize, text, degree, stream, level, specialization, mode })
      .then(data => {
        if (mounted) {
          setInstituteCourseList(data.data.results);
          setTotal(data.data.count);
          setInstitute(data.data.extra_details.institute)
          setLoading(false);
          setError(null);
        }
      })
      .catch(function (response) {
        const err = getErrors(response)
        setError(err.allError);

      });

    return () => mounted = false;
  }, [page, pageSize, text, degree, stream, level, specialization, mode])

  function onShowSizeChange(current, PageSize) {
    setPageSize(PageSize);
  }
  function onChange(page, PageSize) {
    setPage(page);
  }

  const onPressEnterSearchInput = (e) => {
    if (e.target.value.length > 2) {
      setText(e.target.value);
    }
  }
  const onApplyFilter = (data) => {

    setDegree(data.degree)
    setLevel(data.level)
    setSpecialization(data.specialization)
    setStream(data.stream)
    setMode(data.mode)
    setPage(1)
  }
  const onClearFilter = () => {
    setDegree([])
    setLevel([])
    setSpecialization([])
    setStream([])
    setMode([])
    setText("")
  }

  const [isUploading, setIsUploading] = useState(false)
  const [fileError, setFileError] = useState(null)
  const [file, setFile] = useState(null)

  const uploadFile = () => {
    const url = '/api/cms/common/institute-course-status/upload/';
    if (!file) return setFileError("Please select a file.")
    setIsUploading(true)
    const formData = new FormData();
    formData.append("institute_course_status_csv", file);
    post(url, formData)
      .then((response) => {
        setIsUploading(false)
        setFile(null)
        message.success(`Institute Course Status updated successfully ${response?.data?.institutecourse_admission_status_upoaded} `,)
      })
      .catch()
      .finally(() => setIsUploading(false));
  };

  const beforeUpload = (file) => {
    // Add validation or custom logic here if needed
    setFile(null)
    setFile(file);
    setFileError(null)
    return false;
  };
  const handleFileUpload = (file) => {
    // You can perform further processing with the uploaded file
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false)
  }

  return (
    <>
     { isReplicateModalOpen&&<ReplicateModal isReplicateModalOpen={isReplicateModalOpen} setIsReplicateModalOpen={setIsReplicateModalOpen} replicate={replicate}/>}
      <Modal
        title="Upload CSV file"
        visible={isModalOpen}
        okText="Upload"
        onOk={uploadFile}
        confirmLoading={isUploading}
        onCancel={handleModalClose}>
        <Row gutter={[16]} >
          <Upload
            onRemove={() => setFile(null)}
            beforeUpload={beforeUpload}
            onChange={handleFileUpload}
            maxCount={1}
          >
            <Button>
              <UpCircleOutlined /> Click to Upload
            </Button>
            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
          </Upload>

        </Row>
        <Row>

          <Link
            to={SampleFile}
            download="institute-course-status-bulk-upload.csv"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download Sample File</Button>
          </Link>

        </Row>
      </Modal>

      <Row>
        {error ? message.error(error) : null}
        <Col span={24}>
          <NavBreadCrumb breadCrumb={breadCrumb}/>
          <div className="gx-pt-4">
            <div className="ant-card ant-card-bordered gx-card-widget">
              <div className="ant-card-body">
                <h2 className="gx-mb-0">{institute?.name}, Courses</h2>
              </div>
            </div>
          </div>
          <Row>
            <Col span={18}>
              <div className="ant-card ant-card-bordered searchBox gx-card">
                <div className="ant-card-body">
                  <Input placeholder="Search by Course Name" prefix={<SearchOutlined />} onPressEnter={onPressEnterSearchInput} />
                </div>
              </div>
            </Col>



            <Col span={3} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => setIsModalOpen(true)}> Bulk Upload</Button>
            </Col>
            <Col span={3}>
              <Link to={{ pathname: `/institute/${instituteID}/course/create/`, locationData: { name: institute?.name } }} >
                <Button type="primary" renderAs="button">
                  <span>Add a Course</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <InstituteCourseListingFilter instituteID={instituteID} onApplyFilter={onApplyFilter} onClearFilter={onClearFilter} />
        </Col>

        <Col span={24}>
          <Table instituteID={instituteID} className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={instituteCourseList} pagination={false} />
        </Col>
        <Col span={24} className="gx-mt-3 gx-text-right">
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={onChange}
            pageSize={pageSize}
            total={total}
            current={page}
          />
        </Col>
      </Row>
    </>
  );
};

export default InstituteCourseListing;
