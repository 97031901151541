import { Row, Col, Form, Input, Select, DatePicker } from "antd";
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useFetchData } from "../../../hooks/";
import { API_URL_LIST, COURSE_CONTENT_API_LIST } from '../../../constants/CollegeDekho'
import { getObjectStatus } from "../../../util/helpers";


let objectStatusOptions = getObjectStatus(true)




const LatestUpdateDetailForm = ({ isAddMode ,setExtraDetails}) => {

	const pathname = useSelector(({ common }) => common.pathname);
	const dashboardType = pathname.includes("institute") ? "institute" : "course";

	const { ID, vernacularID } = useParams();

	let url = "";
	if (dashboardType == "institute") {
		url = API_URL_LIST["INSTITUTE_PAGE_LISTING"]
		url = url.replace("#id#", ID)
	}
	else {
		url = COURSE_CONTENT_API_LIST["COURSE_PAGE_LISTING"]
		url = url.replace("#id#", vernacularID)
	}

	const [data, loading, error] = useFetchData(url)
	const [page, setPage] = useState([])
	console.log("type of", typeof setContent)

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (data) {
				let items = []
				setExtraDetails(data?.extra_details)
				data.results.forEach((item) => {
					if (item.master_page.name == "Overview") {
						// for overview page only
						items.push({ value: item.id, label: item.master_page.name })
					}
				})
				setPage(items)
			}

		}

		return () => mounted = false;
	}, [data])





	return (
		<Row>

			<Col span={6}>
				<Form.Item label="Page" name="object_id" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Select page',
						},
					]}>
					<Select
						mode="multiple"
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Select Page"
						options={page}
						labelInValue={true}
						disabled={isAddMode}
					>
					</Select>


				</Form.Item>
			</Col>
			<Col span={6}>
				<Form.Item label="Start Date" name="date" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Enter Date',
						},
					]}>
					<DatePicker showTime />
				</Form.Item>
			</Col>
			<Col span={6}>
				<Form.Item label="End Date" name="end_date" className="labelInput"
					rules={[
						{
							required: false,
							message: 'Enter End Date',
						},
					]}>

					<DatePicker showTime />
				</Form.Item>
			</Col>
			<Col span={6}>
				<Form.Item label="Schedule Date" name="schedule_date" className="labelInput"
					rules={[
						{
							required: false,
							message: 'Enter Schedule Date',
						},
					]}>

					<DatePicker showTime />
				</Form.Item>
			</Col>
			<Col span={6}>
				<Form.Item label="Object Status" name="object_status" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Select Object Status',
						},
					]}>

					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Object status"
						options={objectStatusOptions}
					>
					</Select>
				</Form.Item>
			</Col>
			<Col span={24} className="gx-mt-4 gx-mb-4">
				<Form.Item label="Heading" name="heading" className="labelInput" rules={[{ required: false }]}>
					<Input placeholder="Heading" />
				</Form.Item>
			</Col>


		</Row>
	)
}

export default LatestUpdateDetailForm
