import React from 'react';
import {Card, Row, Col, Form, Button, Input, Select, Space} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DebounceSelect from '../../../components/DebounceSelect';
import { getRankingAuthorityList } from "../../../services/RankingAuthority/RankingAuthority";
import { getInstituteStreamList } from "../../../services/Stream/Stream";
import { getYearChoices,getObjectStatus } from "../../../util/helpers";


const YearOptions =  getYearChoices((new Date()).getFullYear()-9)
const objectStatusOptions = getObjectStatus()

const RankingDetailForm=({form,instituteID,isAddMode})=>{
 const current_year = new Date().getFullYear()
	return (
  <Row>
    <Col span={24}>
      <div>
        <Form.List name="add_ranking">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
              <Card>
              <Space key={field.key} style={{ display: "flex", marginBottom: 8 }}  align="baseline">
              <Row>

                <Col span={12}>
				            <Form.Item {...field}
				               className="labelInput"
				               label={[field.label, "Ranking Body"]}
				               name={[field.name, "ranking_authority"]}
				               fieldKey={[field.fieldKey, "ranking_Authority"]}
				               rules={[{
			                    required: true,
			                    message: 'Enter Ranking Body',
			                 },
			              ]}>
					            <DebounceSelect
                        mode="single"
					              name="ranking_authority"
                        placeholder="Select Ranking Body"
                        cldID={instituteID}
                        fetchOptions={getRankingAuthorityList}
					              showSearch = {true}
                      />
					          </Form.Item>
				        </Col>
                <Col span={12}>
				          <Form.Item {...field}
				              className="labelInput"
				              label={[field.label, "Stream"]}
				              name={[field.name, "stream"]}
				              fieldKey={[field.fieldKey, "stream"]}>
					            <DebounceSelect
                         mode="single"
					               name="stream"
                         placeholder="Select Stream"
                         cldID={instituteID}
                         fetchOptions={getInstituteStreamList}
					               showSearch = {true}
					               allowClear = {true}
                      />
					          </Form.Item>
				        </Col>
                <Col span={6}>
				          <Form.Item {...field}
				            className="labelInput"
				            name={[field.name, "year"]}
				            label={[field.label, "Year"]}
				            fieldKey={[field.fieldKey, "year"]}
				            initialValue={current_year}
				            rules = {[{ required: true,
				                        message: 'Enter Year'}]}>
					            <Select
						            allowClear={true}
						            style={{width: '100%'}}
						            placeholder="Select Year"
						            options={YearOptions}>
                      </Select>
					          </Form.Item>
					        </Col>
					        <Col span={6}>
                   <Form.Item
                    {...field}
                    className="labelInput"
                    name={[field.name, "rank"]}
                    label = {[field.label, "Min Rank"]}
                    fieldKey={[field.fieldKey, "rank"]}
                    rules = {[{ required: true,
                        message: 'Enter Rank',}]}>
                    <Input className="gx-mb-3"/>
                  </Form.Item>
                </Col>
				          <Col span={6}>
					          <Form.Item {...field}
				               className="labelInput"
				               label={[field.label, "Max Rank"]}
				               name={[field.name, "rank_max"]}
				               fieldKey={[field.fieldKey, "rank_max"]}>
						         <Input className="gx-mb-3" />
					          </Form.Item>
				          </Col>
				          <Col span={6}>
					           <Form.Item {...field}
				                className="labelInput"
				                label={[field.label, "Object Status"]}
				                name={[field.name, "object_status"]}
					              fieldKey={[field.fieldKey, "object_status"]}
					              rules={[{
			                     required: true,
			                     message: 'Object Status is required',
			              }]}>
						           <Select
						              allowClear={true}
						              style={{width: '100%'}}
						              placeholder="Select Object Status"
						              options={objectStatusOptions}
					             >
                       </Select>
					           </Form.Item>
				          </Col>

				          {isAddMode &&
				          <Col span={6}>
				             <Form.Item className="labelInput">
                       <Button type="primary" onClick={() => remove(field.name)} block icon={<MinusCircleOutlined/>}> Remove</Button>
                     </Form.Item>
                  </Col>
                  }
                </Row>
                </Space>
                </Card>
                ))}
              {isAddMode &&
              <Col span={8}>
                <Form.Item>
                   <Button type="primary" onClick={() => add()} block icon={<PlusOutlined/>}> Add More</Button>
                </Form.Item>
              </Col>
              }
            </>
          )}
        </Form.List>
      </div>
    </Col>
  </Row>
  )
}

export default RankingDetailForm
