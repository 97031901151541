import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import CourseDetail from './CourseDetail'
import { getMultiObject, getObject } from '../../../util/helpers';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'
import { isInt,getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patch,post } from '../../../services/';
import { Spin } from 'antd';


const InstituteCourseDetail = (props) => {
	const  [loader, setLoader] = useState(false);
	const locationData = props.location?.locationData
    const [form] = Form.useForm();
	const { instituteID } = useParams();
	const { instituteCourseID } = useParams();
	const history = useHistory()
	const  isAddMode = !isInt(instituteCourseID)
	console.log("isAddMode",isAddMode)
	let url = null;
	let saveURL = API_URL_LIST["INSTITUTE_COURSE_DETAIL_POST_URL"].replace("#id#",instituteID)

	if(!isAddMode)
	{
	  url=API_URL_LIST["INSTITUTE_COURSE_DETAIL_URL"].replace("#id#",instituteID)
	  url = url.replace("#id1#",instituteCourseID)
	  saveURL = url
	  console.log(url)
	}
	const [data,loading,error] = useFetchData(url)
	//const [data,loading,error,isAddMode] = useFetchInstituteCourseDetail({instituteID,instituteCourseID})
    const [instituteCourse,setInstituteCourse] = useState(null)
	const [disabledSave,setDisabledSave] = useState(false)
	const [feeCurrRequired, setFeeCurrRequired] = useState(false);
	const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
   const [breadCrumb] = useFetchData(dashboardTypeDetailURL)

	const institutePartnerStatus = data?.institute?.partner_status

	const setInstituteCourseObj = (data) => {
        const  instituteCourseObj = {
			name:data.name,
			mode:data.mode?.id,
			duration:data.duration,
			duration_type:data.duration_type?.id,
			fee:data.fee,
			commission_amount:data?.commission_amount,
			fee_currency:data.fee_currency?.id,
			fee_type:data.fee_type?.id,
			total_course_fee:data.total_course_fee,
			total_course_fee_currency:data.total_course_fee_currency,
			program_type:data.program_type,
			tuition_fees_on_campus:data.tuition_fees_on_campus,
			tuition_fee_currency:data.tuition_fee_currency,
			tuition_fee_type:data.tuition_fee_type,
			total_student:data.total_student,
			actual_intake:data.actual_intake,
			gender_ratio:data.gender_ratio,
			course_attribute:data.course_attribute?.id,
            course_type:data.course_type?.id,
			shift:data.shift?.id,
			institute: data.institute,
			object_status: data.object_status?.id,
			seats_filled: data.seats_filled

		}
		instituteCourseObj["course"] = getObject(data.course)

		if(data.exchange_program)
		{
			instituteCourseObj["exchange_program"] = "checked"
		}
		if(data.admission_status){
			instituteCourseObj["admission_status"] = data.admission_status.id
		}
		instituteCourseObj["exam_accepted"] = getMultiObject(data.exam_accepted)

		setInstituteCourse(instituteCourseObj)
		form.setFieldsValue(instituteCourseObj)
	}
	useEffect(() => {
		let mounted = true;

		if(mounted)
		{
		  if(data)
		  {
			  console.log("data",data)
			  setInstituteCourseObj(data)

		  }

		}


		return () => mounted = false;
	}, [data])


	const getCleanData = (data) =>
	{

		const temp = JSON.stringify(data)
        const cleanData = JSON.parse(temp);
		delete cleanData.institute
	    cleanData.course = data.course.value
		cleanData.object_status = data.object_status
		cleanData.admission_status = data.admission_status
		cleanData.partner_status = institutePartnerStatus
		if([null, undefined, ""].includes(data.seats_filled)){
			delete cleanData.seats_filled
		}
		if(cleanData?.exchange_program === 'checked')
		{
			cleanData.exchange_program = true
		}
		if(cleanData.fee === "")
		{
			cleanData.fee = null;
		}
		if(cleanData?.commission_amount === "")
			{
				cleanData.commission_amount = null;
			}
		if(cleanData.total_course_fee === "")
		{
			cleanData.total_course_fee = null;
		}
		if(cleanData.tuition_fees_on_campus === "")
		{
			cleanData.tuition_fees_on_campus = null;
		}
		if(cleanData.total_student === "")
		{
			cleanData.total_student = null;
		}
		if(cleanData.actual_intake === "")
		{
			cleanData.actual_intake = null;
		}
		let examAccepted = []
		if (typeof data.exam_accepted !== 'undefined') {
			examAccepted = data.exam_accepted.map((item) => { return item.value })
		}
		cleanData.exam_accepted = examAccepted


        return cleanData
	}

	const crudErrorHandler = (error) =>
	{
		showError(error)
	}

	const patchData = (data) =>
	{
		setLoader(true);
	  patch(saveURL,data).then(function (response)
      {

		message.success(COMMON_SUCCESS_MESSAGE);
		history.push(`/institute/${instituteID}/course`)
      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);
      });

	}
	const postData = (data) =>
	{
		setLoader(true);
	  post(saveURL,data)
	  .then(function (response)
      {
		 message.success(COMMON_SUCCESS_MESSAGE);
		 history.push(`/institute/${instituteID}/course`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
		setLoader(false);
      });

	}
  const showError = (errors) =>
  {
	const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
    console.log("server error",error.serverError)
    form.setFields(error.serverError)

  }

  const onValuesChange = (changedValues, allValues) => {
    const fee_required = allValues.fee
      ? Object.values(allValues.fee).filter(Boolean).length > 0
      : false;
    setFeeCurrRequired(fee_required);
  };

  const onFinish=(e)=>
  {
	  const data = form.getFieldsValue(true);
	  const cleanData = getCleanData(data)
	  setDisabledSave(true)
	  if(isAddMode)
	  {
		postData(cleanData)
	  }
	  else
	  {
        patchData(cleanData)
	  }
  }



  return (
    <>
	{error ? message.error(error) : loader === true ? <center><Spin /></center> :
	<Row>

			<Col span={24}>
				<NavBreadCrumb page={instituteCourse ? instituteCourse.name : null} breadCrumb={breadCrumb}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">
							  {instituteCourse ? `${instituteCourse.name} at ${instituteCourse?.institute?.name}`
							  :  locationData ? `${locationData?.name} Course Template` : `Course Template`
							  }
						</h2>

						</div>
					</div>
				</div>
			</Col>


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} onValuesChange={onValuesChange} form={form}>
            <div className="gx-p-2">
              <CourseDetail institutePartnerStatus={institutePartnerStatus} form={form} feeCurrRequired={feeCurrRequired}/>
            </div>
            <div className="d-flex" style={{ justifyContent: 'left' }}>
            <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
          </div>
        </div>
			</Col>

		</Row>
    }
	</>
  );
};
export default InstituteCourseDetail;
