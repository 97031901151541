import { get, getAutoSuggestionList } from "../index";

export async function getFaqList(data) {

  const url = `/api/cms/common/faq/${data.templateContentTypeID}/${data.templateObjectID}/`
  // const url = `/api/cms/common/faq/${data.contentTypeID}/${data.faqObjectID}/`;
  const params = {
    page_size: data.pageSize,
    page: data.page,
    text: data.text,
  }
  if (data.pageContentTypeID && data.pageObjectID)
  {
    params['page_content_type_id'] = data.pageContentTypeID
    params['page_object_id'] = data.pageObjectID
  }
  return await get(url, params)
}

// export async function getFaqPageType(templateContentTypeID) {
//   console.log({ templateContentTypeID })
//   // const url = `/api/cms/common/faq/${data.contentTypeID}/${data.faqObjectID}/`;
//   const url = `/api/cms/common/faq-content-types/?template_content_type_id=${22}`;
//   return await getAutoSuggestionList(url)
// }

export async function getFaqPage(data) {
  console.log({ data })
  const url = `/api/cms/common/faq/${data.contentTypeID}/${data.faqObjectID}/`;
  const params = {
    page_size: data.pageSize,
    page: data.page,
    text: data.text,
  }
  return await get(url, params)
}