import React,{useState,useEffect} from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {Row, Col, Form, Button,message} from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import MasterPageForm from './MasterPageForm'
import { useFetchData } from "../../../hooks/";
import { patch,post } from '../../../services/';
import { isInt,getErrors } from '../../../util/helpers';
import {COMMON_SUCCESS_MESSAGE,API_URL_LIST} from '../../../constants/CollegeDekho'

const MasterPageDetail = () => {
    const [form] = Form.useForm();
	const { masterPageID } = useParams();
	const  isAddMode = !isInt(masterPageID)
	console.log("isAddMode",isAddMode)
	let url = null;
	let saveURL = API_URL_LIST["MASTER_PAGE_API_URL"] 
	 
	if(!isAddMode)
	{
	  url=API_URL_LIST["MASTER_PAGE_DETAIL_URL"].replace("#id#",masterPageID)
	  saveURL = url
	  console.log(url)
	}  
	const [data,loading,error] = useFetchData(url)
	const history = useHistory()
	const [disabledSave,setDisabledSave] = useState(false)
	const setMasterPageObj = (data) => {
        const obj = {...data}
		obj.object_status = obj.object_status.id
		obj.page_type = obj.page_type.id
		form.setFieldsValue(obj)
	}

	useEffect(() => {
		let mounted = true;
        		
		if(mounted) 
		{
		  if(data)
		  {
			  console.log("data",data)
			  setMasterPageObj(data)
			
		  }  
		  
		}
		
		
		return () => mounted = false;
	}, [data])
  

	const getCleanData = (data) => 
	{
        const cleanData = {...data} 
		if(!isAddMode)
		{
			delete cleanData.uri_slug
		}
        return cleanData 
	}
	
	const crudErrorHandler = (error) => 
	{
         showError(error)
	}

	const patchData = (data) => 
	{
	  patch(saveURL,data).then(function (response) 
      {
		message.success(COMMON_SUCCESS_MESSAGE);
		history.push(`/institute/master-page`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
      });

	}
	const postData = (data) => 
	{
	  post(saveURL,data)
	  .then(function (response) 
      {
		message.success(COMMON_SUCCESS_MESSAGE);
		history.push(`/institute/master-page`)

      })
      .catch(crudErrorHandler)
	  .finally(() => {
		setDisabledSave(false)
      });

	}	
  const showError = (errors) => {
	const error = getErrors(errors)
	if(error.allError.length>0)
    {
          message.error(error.allError.join());
    }
    
    console.log("server error",error.serverError)
    form.setFields(error.serverError)

  } 	
  const onFinish=(e)=>
  {
	  const data = form.getFieldsValue(true);
	  const cleanData = getCleanData(data)
	  setDisabledSave(true)
	  if(isAddMode)
	  {
		postData(cleanData)			
	  }
	  else
	  {
        patchData(cleanData)
	  }
  }
 
	
    
  return (
	  <>
	  {error ? message.error(error) :
    <Row>
		
			<Col span={24}>
				<NavBreadCrumb/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">Master Page</h2>
						
						</div>
					</div>
				</div>
			</Col>	


			<Col span={24}>
        <div className="ant-card ant-card-bordered gx-card">
        <div className="ant-card-body">
          <Form onFinish={onFinish} form={form}>
            <div className="gx-p-2">
              <MasterPageForm form={form} isAddMode={isAddMode}/> 
            </div>
            <div className="d-flex" style={{ justifyContent: 'left' }}>
            <Button type="primary" htmlType="submit" disabled={disabledSave} > Save</Button>
            </div>
          </Form>
          </div>
        </div>
			</Col>
        			
		</Row>
       }
		</>
  );
};
export default MasterPageDetail;
