import React, { useState, useEffect } from "react";
import { Table, Row, Col, Divider } from "antd";
import { getInstituteLatestUpdate } from "../../../services/InstituteLatestUpdate/InstituteLatestUpdate";
import { Link, useParams } from 'react-router-dom';
import {FormOutlined } from "@ant-design/icons";
import { getErrors } from '../../../util/helpers';
import {DATE_TIME_FORMAT_LISTING} from '../../../constants/CollegeDekho'

import moment from 'moment';
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const columns = [{
  title: 'ID',
  dataIndex: 'id',

},
{
  title: 'Heading',
  dataIndex: 'heading',
},

{
  title: 'Date',
  dataIndex: 'date',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span> {record.date ? moment(record.date).format(DATE_TIME_FORMAT_LISTING): null} </span>
    </span>
  ),
},

{
  title: 'End Date',
  dataIndex: 'end_date',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span> {record.end_date ? moment(record.end_date).format(DATE_TIME_FORMAT_LISTING): null} </span>
    </span>
  ),

},
{
  title: 'Status',
  dataIndex: 'object_status',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span>{record.object_status ? 'Active' : 'InActive'}</span>


    </span>
  ),
},

{
  title: 'Action',
  key: 'action',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span className="gx-link"><Link to={`/institute/${record?.content_object?.parent?.id}/latest-update/${record.id}/update/`}> <FormOutlined/></Link></span>


    </span>
  ),
}
];



const InstituteLatestUpdateListComponent = ({setExtraDetails,setError}) => {

  const [instituteLatestUpdate, setInstituteLatestUpdate] = useState([])
  const [loading, setLoading] = useState(true);
  const { instituteID } = useParams();





  useEffect(() => {
    let mounted = true;
    //setLoading(true);
    console.log("API CALL - LatestUpdate ")

    getInstituteLatestUpdate(instituteID)
      .then(data => {
        if (mounted) {
          setInstituteLatestUpdate(data.data.results);
          //setTotal(data.data.count);
          setLoading(false);
          setExtraDetails(data.data.extra_details)

        }
      }
      )
      .catch(function (response)
      {
              const err =  getErrors(response)
              setError(err.allError);
              console.log(err)

       });

  }, [instituteID])



  return (
    <Row>
      <Col span={24}>
        <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={instituteLatestUpdate} pagination={false} />
      </Col>
    </Row>

  );
};

export default InstituteLatestUpdateListComponent;
