import React,{useState,useEffect} from "react";
import {  Link,useParams } from 'react-router-dom';
import { getInstituteFacultyList } from "../../../services/InstituteFaculty/InstituteFaculty";

import {Divider,Row, Col, Table, Pagination, Button,} from "antd";
import {FormOutlined} from "@ant-design/icons";
import {API_URL_LIST, PAGE_SIZE} from "../../../constants/CollegeDekho";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { useFetchData } from "../../../hooks";

const columns = [{
  title: 'ID',
  dataIndex: 'id',
},
{
  title: 'Name',
  dataIndex: 'name',
},
{
  title: 'Department',
  dataIndex: 'department',
},
{
  title: 'Specialization',
  key: 'specialization',
  render: (text, record) => (
    <span>
     {record.specialization?.name}
  </span>
  ),
},
{
    title: 'Designation',
    dataIndex: 'designation',
},

{
  title: 'Edu qualification',
  dataIndex: 'education_qualification',

},

{
    title: 'Action',
    key: 'action',
    render: (text, record) => (
        <span>
            <Divider type="vertical"/>
            <span className="gx-link"><Link to={`/institute/${record.institute.id}/faculty/${record.id}/update/`}><FormOutlined /></Link></span>
            <Divider type="vertical"/>
        </span>
    ),
}

];



const InstituteFacultyListing = () => {
  const  [instituteFacultyList,setInstituteFacultyList] = useState([])
  const  [pageSize,setPageSize] = useState(PAGE_SIZE)
  const  [page,setPage] = useState(1);
  const  [total,setTotal] = useState(0);
  const  [loading,setLoading] = useState(true);
  const  [text, setText] = useState("");
  const  [institute, setInstitute] = useState({});
  const { instituteID } = useParams();
  const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
  const [breadCrumb] = useFetchData(dashboardTypeDetailURL)


  useEffect(() => {
    let mounted = true;

    setLoading(true);

    getInstituteFacultyList({instituteID,page,pageSize,text})
    .then(data => {
    if(mounted)
    {
      setInstituteFacultyList(data.data.results);
      setTotal(data.data.count);
      setInstitute(data.data.extra_details.institute)
      setLoading(false);
    }
    })

    return () => mounted = false;
   }, [page,pageSize,text])

   function onShowSizeChange(current, PageSize)
   {
       setPageSize(PageSize);
   }
   function onChange(page, PageSize)
   {
        setPage(page);
   }

  return (
    <Row>
     <Col span={24}>
         <NavBreadCrumb breadCrumb={breadCrumb}/>
         <div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">{institute?.name}, Faculty</h2>
						</div>
					</div>
				</div>
        <Row>
           <Col span={18}>
           </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
            <Link to={{pathname: `/institute/${instituteID}/faculty/create/`, locationData: {name: institute?.name } }}>
                  <Button type="primary">
                   <span>Add a Faculty</span>
               </Button>
              </Link>
            </Col>
          </Row>
        </Col>


      <Col span={24}>
         <Table className="gx-table-responsive" loading={loading} rowKey="id"  columns={columns}  dataSource={instituteFacultyList} pagination={false} />
      </Col>
       <Col span={24} className="gx-mt-3 gx-text-right">
        <Pagination
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        onChange={onChange}
        pageSize = {pageSize}
        total = {total}
        current = {page}
        />
      </Col>
    </Row>
  );
};

export default InstituteFacultyListing;
