import { getAutoSuggestionList, get } from "../index";
import moment from "moment";
export function getCourseContent(data) {

  const url = `/api/cms/course-details/course-content/`;
  let stream = []
  let courseContentId = []
  let course = []
  let addedBy = []
  let modifiedBy = []

  if (data.stream) {
    stream = data.stream.map((item) => { return item.value })
  }
  if (data.courseContentId) {
    courseContentId = data.courseContentId.map((item) => { return item.value })
  }
  if (data.course) {
    course = data.course.map((item) => { return item.value })
  }
  if (data.addedBy) {
    addedBy = data.addedBy.map((item) => { return item.value })
  }
  if (data.modifiedBy) {
    modifiedBy = data.modifiedBy.map((item) => { return item.value })
  }

  data.page_size = data.pageSize
  delete data.pageSize

  delete data.stream;
  data.stream_id = stream.join();

  delete data.courseContentId
  data.course_content_id = courseContentId.join()

  delete data.course;
  data.course_id = course.join();

  delete data.addedBy
  data.added_by_id = addedBy.join()

  delete data.modifiedBy
  data.modified_by_id = modifiedBy.join()

  try {
    if (data.startDate) {
      data.start_date = moment(data.startDate).format('YYYY-MM-DD')
      delete data.startDate
    }

    if (data.start_date == "Invalid date") {
      throw "Invalid Date"
    }
  }
  catch (err) {

    data.start_date = data.startDate
    delete data.startDate

  }

  try {
    if (data.endDate) {
      data.end_date = moment(data.endDate).format('YYYY-MM-DD')
      delete data.endDate
    }
    if (data.end_date == "Invalid date") {
      throw "Invalid Date"
    }
  }
  catch (err) {

    data.end_date = data.endDate
    delete data.endDate

  }


  data.object_status = data.isActive
  delete data.isActive


  console.log("---------------->", data)
  return get(url, data)

}

export async function getCourseList(text) {
    const url =  `/api/cms/autosuggestion/course/?q=${text}`;
    return await getAutoSuggestionList(url)
  }

export async function getCourseAutoSuggestion(text) {
  console.log('fetching user', text);
  const url = `/api/cms/autosuggestion/course/?q=${text}`;
  return await getAutoSuggestionList(url)
}

export async function getInstituteCourseList(text, instituteID) {
  const url = `/api/cms/institute/${instituteID}/institutecourse/?text=${text}&page_size=1000&page=1`;
  return await getAutoSuggestionList(url)
}
