import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Collapse, Row, Col, Form, Button, message, Table } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import CourseDetailForm from './CourseDetailForm'
import { getMultiObject } from '../../../util/helpers';

import { isInt, getErrors } from '../../../util/helpers';
import { useFetchData } from "../../../hooks/";
import { patchImage, postImage } from '../../../services/';

import {
    COMMON_SUCCESS_MESSAGE,
    COMMON_ERROR_MESSAGE_WRONG_DATA, COURSE_CONTENT_API_LIST
}
    from '../../../constants/CollegeDekho'

const Panel = Collapse.Panel;


const CourseDetails = () => {
    const callback = (key) => { };

    const [form] = Form.useForm();
    const history = useHistory()
    const [loader, setLoader] = useState(false);
    const { courseID } = useParams();
    const isAddMode = !isInt(courseID)
    let url = null;
    let saveURL = COURSE_CONTENT_API_LIST["COURSE_DETAIL_POST_URL"]
    if (!isAddMode) {
        url = COURSE_CONTENT_API_LIST["COURSE_DETAIL_URL"].replace("#id#", courseID)
        saveURL = url
    }
    const [data, loading, error] = useFetchData(url)
    const [disabledSave, setDisabledSave] = useState(false)

    const getIcon = (data) => {
        if (!data.icon) {
            return [];
        }
        return [
            {
                //uid: '1',
                //name: 'xxx.png',
                //status: 'done',

                url: data.logo,
            },
        ]

    }

    const setCourseObj = (data) => {
        const courseObj = {
            name: data.name,
            short_name: data.short_name,
            fee_min: data.fee_min,
            fee_max: data.fee_max,
            college_listing_url: data.college_listing_url,
            object_status: data.object_status
        }

        if (data.slug) {
            courseObj["slug"] = data.slug.toString()
        }

        if (data.min_duration) {
            courseObj["min_duration"] = data.min_duration.toString()
        }

        if (data.max_duration) {
            courseObj["max_duration"] = data.max_duration.toString()
        }

        if (data.course_popularity_score) {
            courseObj["course_popularity_score"] = data.course_popularity_score.toString()
        }
        if (data.exam_listing_url) {
            courseObj["exam_listing_url"] = data.exam_listing_url.toString()
        }

        if (data.average_fee_frequency) {
            courseObj["average_fee_frequency"] = data.average_fee_frequency.id
        }

        if (data.average_fee_currency) {
            courseObj["average_fee_currency"] = data.average_fee_currency.id
        }

        if (data.max_duration_type) {
            courseObj["max_duration_type"] = data.max_duration_type.id
        }

        if (data.min_duration_type) {
            courseObj["min_duration_type"] = data.min_duration_type.id
        }

        courseObj["study_mode"] = getMultiObject(data.study_mode)
        courseObj["courses"] = getMultiObject(data.courses)
        courseObj["specializations"] = getMultiObject(data.specializations)
        courseObj["degrees"] = getMultiObject(data.degrees)
        courseObj["tagged_news"] = getMultiObject(data.tagged_news)
        courseObj["tagged_exams"] = getMultiObject(data.tagged_exams)
        courseObj["tagged_careers"] = getMultiObject(data.tagged_careers)
        courseObj["degrees"] = getMultiObject(data.degrees)
        courseObj.icon = getIcon(data)
        form.setFieldsValue(courseObj)

    }
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (data) {
                setCourseObj(data)
            }

        }

        return () => mounted = false;
    }, [data])

    const getCleanData = (data) => {
        const formData = new FormData();
        const icon = data.icon
        formData.append("name", data.name)
        formData.append("short_name", data.short_name)
        formData.append("fee_min", data.fee_min)
        formData.append("fee_max", data.fee_max)
        formData.append("min_duration", data.min_duration)
        formData.append("min_duration_type", data.min_duration_type)
        formData.append("max_duration", data.max_duration)
        formData.append("max_duration_type", data.max_duration_type)
        formData.append("college_listing_url", data.college_listing_url)
        formData.append("object_status", data.object_status)

        if (data.slug) {
            formData.append("slug", data.slug)
        }

        if (data.average_fee_currency) {
            formData.append("average_fee_currency", data.average_fee_currency)
        }

        if (data.average_fee_frequency) {
            formData.append("average_fee_frequency", data.average_fee_frequency)
        }
        if (data.course_popularity_score) {
            formData.append("course_popularity_score", data.course_popularity_score)
        }
        if (data.exam_listing_url) {
            formData.append("exam_listing_url", data.exam_listing_url)
        }

        let study_mode = []
        if (data.study_mode) {
            study_mode = data.study_mode.map((item) => { return item.value })
        }
        formData.append("study_mode", JSON.stringify(study_mode))

        let courses = []
        if (data.courses) {
            courses = data.courses.map((item) => { return item.value })
        }
        formData.append("courses", JSON.stringify(courses))

        let specializations = []
        if (data.specializations) {
            specializations = data.specializations.map((item) => { return item.value })
        }
        formData.append("specializations", JSON.stringify(specializations))

        let degrees = []
        if (data.degrees) {
            degrees = data.degrees.map((item) => { return item.value })
        }
        formData.append("degrees", JSON.stringify(degrees))

        let tagged_news = []
        if (data.tagged_news) {
            tagged_news = data.tagged_news.map((item) => { return item.value })
        }
        formData.append("tagged_news", JSON.stringify(tagged_news))

        let tagged_exams = []
        if (data.tagged_exams) {
            tagged_exams = data.tagged_exams.map((item) => { return item.value })
        }
        formData.append("tagged_exams", JSON.stringify(tagged_exams))

        let tagged_careers = []
        if (data.tagged_careers) {
            tagged_careers = data.tagged_careers.map((item) => { return item.value })
        }
        formData.append("tagged_careers", JSON.stringify(tagged_careers))

        if (icon && icon.length > 0) {
            for (const element of icon) {
                if (typeof element.originFileObj !== 'undefined') {
                    formData.append("icon", element.originFileObj)
                }
            }

        }
        else {
            formData.append("icon", '')
        }

        return formData
    }

    const crudErrorHandler = (error) => {
        showError(error)


    }

    const patchData = (data) => {
        setLoader(true);
        patchImage(saveURL, data).then(function (response) {
            message.success(COMMON_SUCCESS_MESSAGE);

        })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const postData = (data) => {
        setLoader(true);
        postImage(saveURL, data)
            .then(function (response) {

                message.success(COMMON_SUCCESS_MESSAGE);
                history.push(`/course/${response.data.id}/update/`)

            })
            .catch(crudErrorHandler)
            .finally(() => {
                setDisabledSave(false)
                setLoader(false);
            });

    }
    const showError = (errors) => {

        const error = getErrors(errors)
        if (error.allError.length > 0) {
            message.error(error.allError.join(" "));
        }
        else {
            message.error(COMMON_ERROR_MESSAGE_WRONG_DATA);
        }
        form.setFields(error.serverError)


    }
    const onFinish = (e) => {
        const data = form.getFieldsValue(true);
        const formData = getCleanData(data)
        setDisabledSave(true)
        if (isAddMode) {
            postData(formData)
        }
        else {
            patchData(formData)
        }
    }

    return (
        <>
            {error ? message.error(error) : loader === true ? <center><Spin /></center> :
                <Row>
                    <Col span={24}>

                        <NavBreadCrumb breadCrumb={data} />
                        <div className="gx-pt-4">
                            <div className="ant-card ant-card-bordered gx-card-widget">
                                <div className="ant-card-body">
                                    <h2 className="gx-mb-0"> {data?.name ? `${data.name} Overview` : `New Course`} </h2>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {
                        !isAddMode &&
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Link to={`/course/${courseID}/vernacular/`}>
                                <Button type="primary">
                                    <span>Go To Vernacular Courses</span>
                                </Button>
                            </Link>

                        </Col>
                    }


                    <Col span={24}>
                        <Form onFinish={onFinish} form={form} >
                            <Collapse defaultActiveKey={['1']} onChange={callback} className="collegeEditCollapse">
                                <Panel header="Course Details" key="1">
                                    <div className="gx-p-2">
                                        <CourseDetailForm courseID={courseID} isAddMode={isAddMode}/>
                                    </div>
                                </Panel>
                            </Collapse>
                            <div className="d-flex">
                                <Button type="primary" htmlType="submit" disabled={disabledSave}> Save</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            }
        </>
    )
}

export default CourseDetails
