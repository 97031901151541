import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

const UserInfo = () => {

  const dispatch = useDispatch();
  const {authUser, initURL} = useSelector(({auth}) => auth);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    // <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
    //   <Avatar src={"https://via.placeholder.com/150"} className="gx-avatar gx-pointer" alt=""/>
    // </Popover>
    <Popover placement="bottomRight" content={userMenuOptions} trigger="click"> 
        <Avatar src={"https://via.placeholder.com/150"} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name" style={{fontSize: 14}}>{authUser ? authUser.user.name : null}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
    </Popover>
  );
};

export default UserInfo;
