import { Row, Col, Table, Divider, Button } from "antd";
import { API_URL_LIST } from "../../../constants/CollegeDekho";
import {  Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import NavBreadCrumb from "../../../components/CollegeDekho/NavBreadCrumb";
import { useFetchData } from "../../../hooks";
import DragnDrop from "./DragnDrop";



const InstituteCoursePageComponentListing = () => {
    const {instituteID , instituteCourseID} = useParams()
    const [data,loading,error] = useFetchData(API_URL_LIST['INSITUTE_COURSE_COMPONENTS_LISING'].replace("#instituteID#", instituteID).replace("#courseID#", instituteCourseID))
    const [dataSource, setDataSource] = useState()
    const [extraDetails, setExtraDetails] = useState()

    useEffect(()=>{
      let mounted = true;
      if (mounted){
        if(data)
        {

          setDataSource(data.results)
          setExtraDetails(data.extra_details)
  
        }
      }
      return () => mounted = false
     
    }, [data])
    return (
       <Row>
      <Col span={24}>
         <NavBreadCrumb page={extraDetails?.institute_course?.name} breadCrumb={extraDetails?.institute}/>
         <div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">
                  {data?.extra_details?.institute.name}
                </h2>
						</div>
					</div>
				</div>
        <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
            <Link to={{pathname: `/institute/${instituteID}/course/${instituteCourseID}/page/component/create`, locationData: extraDetails }}>

                  <Button>
                     <span>Add New Component</span>
                  </Button>
              </Link>


            </Col>
        </Row>
     </Col>

     <Col span={24}>
        <DragnDrop dataSource={dataSource} setDataSource={setDataSource} />
     </Col>
     </Row>



      );
}

export default InstituteCoursePageComponentListing