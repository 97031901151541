import React from "react";
import { Row, Col, Form, Input } from "antd";

import DebounceSelect from "../../../components/DebounceSelect";
import { getContentTypeObjectList } from "../../../services/ContentType/ObjectList";
import { CONTENT_TYPE_ID } from "../../../constants/Settings";

const SectorPlacementDetailForm = ({ form, instituteID }) => {
  const regExp = /[a-zA-Z]/g;
  const percentValidator = (rule, value, callback) => {
    // Always return a callback, otherwise validateFields cannot respond
    if (value > 100 || regExp.test(value)) {
      return Promise.reject(
        "Enter Valid Percent"
        //When i delete that row its fix warning, but i need have that error message.
      );
    }

    //callback();
    return Promise.resolve();
  };
  return (
    <Row>
      <div></div>
      <Col span={12}>
        <Form.Item
          label="Sector Percent"
          name="sector_percent"
          className="labelInput"
          rules={[
            {
              required: true,
              validator: percentValidator,
            },
          ]}
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Number of Offer"
          name="number_of_offers"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Highest Package"
          name="highest_package"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Average Package"
          name="average_package"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Median Package"
          name="median_package"
          className="labelInput"
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Sector"
          name="sector"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Sector Required",
            },
          ]}
        >
          <DebounceSelect
            mode="single"
            name="sector"
            placeholder="Select Sector"
            cldID={instituteID}
            contentTypeId={CONTENT_TYPE_ID.sector}
            fetchOptions={getContentTypeObjectList}
            showSearch={true}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SectorPlacementDetailForm;
