import { Checkbox, Row } from "antd";
import React from "react";

function ReplicateComponent({ item, index, onHandleReplicate }) {
  return (
    <div>
      <Row
        style={{
          marginLeft: "0",
          marginRight: "0",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div style={{ width: "55%" }}>
          {item.name}-{item.id}
        </div>
        <Row style={{ gap: "15px", marginRight: "0" }}>
          Mode
          <Checkbox
            checked={item.mode}
            value={item.mode}
            onClick={() => onHandleReplicate(item, index, item.mode, "mode")}
          />
          Fees
          <Checkbox
            checked={item.fee}
            value={item.fee}
            onClick={() => onHandleReplicate(item, index, item.fee, "fee")}
          />
          Exams
          <Checkbox
            checked={item.exams}
            value={item.exams}
            onClick={() => onHandleReplicate(item, index, item.exams, "exams")}
          />
          Program Type
          <Checkbox
            checked={item.program_type}
            value={item.program_type}
            onClick={() =>
              onHandleReplicate(item, index, item.program_type, "program_type")
            }
          />
        </Row>
      </Row>
      <hr />
    </div>
  );
}

export default ReplicateComponent;
