import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./../notFound/index";
import SlugRedirectionListing from "../common/slugRedirectionListing/SlugRedirectionListing";
import SlugRedirectionDetail from "../common/slugRedirectionDetail/SlugRedirectionDetail";
import InstituteListing from "./instituteListing/InstituteListing";
import InstituteCourseListing from "./instituteCourseListing/InstituteCourseListing";
import InstituteCourseDetail from "./instituteCourseDetail/InstituteCourseDetail";
import InstituteDetail from "./instituteDetail/InstituteDetail";
import MasterPageListing from "./masterPageListing/MasterPageListing";
import MasterPageDetail from "./masterPageDetail/MasterPageDetail";
import InstituteLatestUpdateDetail from "./instituteLatestUpdateDetail/InstituteLatestUpdateDetail";
import InstituteLatestUpdateListing from "./instituteLatestUpdateListing/InstituteLatestUpdateListing";
import InstitutePageListing from "./institutePageListing/InstitutePageListing";
import InstitutePageDetail from "./institutePageDetail/InstitutePageDetail";
import InstituteFacultyListing from "./instituteFacultyListing/instituteFacultyListing";
import InstituteFacultyDetail from "./InstituteFacultyDetail/InstituteFacultyDetail";
import InstitutePlacementListing from "./InstitutePlacementListing/InstitutePlacementListing";
import InstitutePlacementDetail from "./InstitutePlacementDetail/InstitutePlacementDetail";
import ComponentListing from "./componentListing/ComponentListing";
import ComponentDetail from "./componentDetail/ComponentDetail";
import InstituteDegreeListing from "./instituteDegreeListing/InstituteDegreeListing";
import InstituteDegreePage from "./instituteDegreeListing/instituteDegreePage/InstituteDegreePage";
import InstituteDegreePageComponentListing from "./instituteDegreeListing/instituteDegreePageComponentListing/InstituteDegreePageComponentListing";
import InstituteDegreeDetail from "./instituteDegreeDetail/InstituteDegreeDetail";
import InstitutePageComponentListing from "./institutePageComponentListing/InstitutePageComponentListing";
import InstitutePageComponent from "./institutePageComponent/InstitutePageComponent";
import InstituteGallery from "./instituteGallery/InstituteGallery";
import InstituteAlumniListing from "./instituteAlumniListing/InstituteAlumniListing";
import InstituteAlumniDetail from "./instituteAlumniDetail/InstituteAlumniDetail";
import InstituteRankingDetail from "./instituteRankingDetail/InstituteRankingDetail";
import InstituteRankingListing from "./instituteRankingListing/InstituteRankingListing";
import InstituteFacilityDetail from "./instituteFacility/InstituteFacilityDetail";
import InstituteDegreePageComponent from "./instituteDegreeListing/instituteDegreePageComponentListing/instituteDegreePageComponent/instituteDegreePageComponent";
import InstituteCoursePageComponentListing from "./instituteCourseListing/InstituteCoursePageComponentListing";
import InstituteCoursePageComponent from "./instituteCourseListing/instituteCoursePageComponent/InstituteCoursePageComponent";
import InstituteCoursePage from "./instituteCourseListing/InstituteCoursePage";
import FaqsListing from "../../components/Faqs/listing";
import FaqsDetail from "../../components/Faqs/details";
import FaqLogs from "../common/faqLogs";
import StudentPlacementListing from "./studentPlacementListing/StudentPlacementListing";
import StudentPlacementDetail from "./studentPlacementDetail/StudentPlacementDetail";
import SectorPlacementListing from "./sectorPlacementListing/SectorPlacementListing";
import SectorPlacementDetail from "./sectorPlacementDetail/SectorPlacementDetail";
import CompanyPlacementListing from "./companyPlacementListing/CompanyPlacementListing";
import CompanyPlacementDetail from "./companyPlacementDetail/CompanyPlacementDetail";

const Institute = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/faq-logs`} component={FaqLogs} />
    <Route
      path={`${match.url}/slug-redirection/create/`}
      component={SlugRedirectionDetail}
    />
    <Route
      path={`${match.url}/slug-redirection`}
      component={SlugRedirectionListing}
    />
    <Route exact path={`${match.url}/`} component={InstituteListing} />
    <Route exact path={`${match.url}/component`} component={ComponentListing} />
    <Route
      exact
      path={`${match.url}/component/create/`}
      component={ComponentDetail}
    />
    <Route
      exact
      path={`${match.url}/component/:componentID(\\d+)/update/`}
      component={ComponentDetail}
    />
    <Route
      exact
      path={`${match.url}/master-page`}
      component={MasterPageListing}
    />
    <Route
      exact
      path={`${match.url}/master-page/create/`}
      component={MasterPageDetail}
    />
    <Route
      exact
      path={`${match.url}/master-page/:masterPageID(\\d+)/update/`}
      component={MasterPageDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/course`}
      component={InstituteCourseListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/course/:instituteCourseID(\\d+)/update/`}
      component={InstituteCourseDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/course/create/`}
      component={InstituteCourseDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/page`}
      component={InstitutePageListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/page/:instituteMasterPageID(\\d+)/update/`}
      component={InstitutePageDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/placement`}
      component={InstitutePlacementListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/placement/:placementID(\\d+)/update/`}
      component={InstitutePlacementDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/placement/create`}
      component={InstitutePlacementDetail}
    />
    {/* student placement */}
    <Route
      exact
      path={`${match.url}/:instituteID/placement/:placementID/student-placement/`}
      component={StudentPlacementListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/placement/:placementID/student-placement/:studentPlacementID/update`}
      component={StudentPlacementDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/placement/:placementID/student-placement/create/`}
      component={StudentPlacementDetail}
    />
    {/* sector placement */}
    <Route
      exact
      // path={`${match.url}/:placementID/sector_placement`}
      path={`${match.url}/:instituteID/placement/:placementID/sector-placement/`}
      component={SectorPlacementListing}
    />
    <Route
      exact
      // path={`${match.url}/:placementID/sector_placement`}
      path={`${match.url}/:instituteID/placement/:placementID/sector-placement/create/`}
      component={SectorPlacementDetail}
    />
    <Route
      exact
      // path={`${match.url}/:placementID/sector_placement`}
      path={`${match.url}/:instituteID/placement/:placementID/sector-placement/:studentPlacementID/update`}
      component={SectorPlacementDetail}
    />
    {/* company placement */}
    <Route
      exact
      path={`${match.url}/:instituteID/placement/:placementID/company-placement/`}
      component={CompanyPlacementListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/placement/:placementID/company-placement/create/`}
      component={CompanyPlacementDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/placement/:placementID/company-placement/:companyPlacementID/update`}
      component={CompanyPlacementDetail}
    />
    <Route exact path={`${match.url}/create/`} component={InstituteDetail} />
    <Route
      exact
      path={`${match.url}/:instituteID(\\d+)/update/`}
      component={InstituteDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/latest-update`}
      component={InstituteLatestUpdateListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/latest-update/:instituteLatestUpdateID(\\d+)/update/`}
      component={InstituteLatestUpdateDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/latest-update/create/`}
      component={InstituteLatestUpdateDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/gallery`}
      component={InstituteGallery}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/alumni`}
      component={InstituteAlumniListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/alumni/:alumniID(\\d+)/update/`}
      component={InstituteAlumniDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/alumni/create/`}
      component={InstituteAlumniDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/faculty`}
      component={InstituteFacultyListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/faculty/:facultyID(\\d+)/update/`}
      component={InstituteFacultyDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/faculty/create/`}
      component={InstituteFacultyDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/page/:instituteMasterPageID(\\d+)/component`}
      component={InstitutePageComponentListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/page/:instituteMasterPageID(\\d+)/component/create/`}
      component={InstitutePageComponent}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/page/:instituteMasterPageID/component/:componentInstituteMasterPageID(\\d+)/update/`}
      component={InstitutePageComponent}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/ranking`}
      component={InstituteRankingListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/faqs/:templateContentTypeID/`}
      component={FaqsListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/faqs/:templateContentTypeID/create`}
      component={FaqsDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/faqs/:templateContentTypeID/:faqObjectID/update`}
      component={FaqsDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/ranking/:rankingID(\\d+)/update/`}
      component={InstituteRankingDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/ranking/create/`}
      component={InstituteRankingDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/degree`}
      component={InstituteDegreeListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/degree/:popularDegreeID(\\d+)/update/`}
      component={InstituteDegreeDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/facility/`}
      component={InstituteFacilityDetail}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/degree/:degreeID/page`}
      component={InstituteDegreePage}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/degree/:degreeID/page/components`}
      component={InstituteDegreePageComponentListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/degree/:degreeID/page/component/create`}
      component={InstituteDegreePageComponent}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/degree/:degreeID/page/component/:componentID`}
      component={InstituteDegreePageComponent}
    />

    <Route
      exact
      path={`${match.url}/:instituteID/course/:instituteCourseID/page`}
      component={InstituteCoursePage}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/course/:instituteCourseID/page/components`}
      component={InstituteCoursePageComponentListing}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/course/:instituteCourseID/page/component/create`}
      component={InstituteCoursePageComponent}
    />
    <Route
      exact
      path={`${match.url}/:instituteID/course/:instituteCourseID/page/component/:componentID`}
      component={InstituteCoursePageComponent}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Institute;