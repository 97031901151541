import React,{useState} from "react";
import DebounceSelect from '../../../components/DebounceSelect/index'
import { getInstituteDegreeList } from "../../../services/Degree/Degree";
import { getInstituteSpecializationList } from "../../../services/Specialization/Specialization";
import { getInstituteStreamList } from "../../../services/Stream/Stream";
import { getLevel,getCourseMode } from "../../../util/helpers";
import {Button,Form,Select} from "antd";

const levelOptions = getLevel()
const courseModeOptions =  getCourseMode()


const InstituteCourseListingFilter = ({instituteID,onApplyFilter,onClearFilter}) => {
  const  [degree,setDegree] = useState([])
  const  [stream,setStream] = useState([])
  const  [level,setLevel] = useState([])
  const  [specialization,setSpecialization] = useState([])
  const  [mode,setMode] = useState([])
  

    const applyFilter = (e) => 
    {
       onApplyFilter({degree,stream,level,specialization,mode}) 
       console.log("apply filter");
    }
    const clearFilter = (e) => 
    {
       setDegree([])
       setStream([])
       setLevel([])
       setSpecialization([])
       setMode([])
       onClearFilter();
    }
  
  return (

<div className="ant-card ant-card-bordered gx-card">
<div className="ant-card-body">
<Form name="register" layout="ant-form-horizontal">
  <ul className="collgeForm">
      <li className="gx-mb-3">
    <DebounceSelect
    mode="multiple"
    value={degree}
    cldID={instituteID}
    placeholder="Degree"
    fetchOptions={getInstituteDegreeList}
    onChange={(newValue) => {
      setDegree(newValue);
      
    }}
    style={{
      width: '100%',
    }}
  />
       </li>
      <li className="gx-mb-3">
      
      <Select
          mode="multiple"
          allowClear={true}
          style={{width: '100%'}}
          placeholder="Level"
          options = {levelOptions}
          onChange={(newValue) => {
            setLevel(newValue);
            
          }}
    >
         
    </Select>
 
      </li>
    
    <li className="gx-mb-3">    
    <DebounceSelect
    mode="multiple"
    value={specialization}
    cldID={instituteID}
    placeholder="Specialization"
    fetchOptions={getInstituteSpecializationList}
    onChange={(newValue) => {
      setSpecialization(newValue);
      
    }}
    style={{
      width: '100%',
    }}
  />

     </li>   
     <li className="gx-mb-3">    
     <DebounceSelect
    mode="multiple"
    value={stream}
    cldID={instituteID}
    placeholder="Stream"
    fetchOptions={getInstituteStreamList}
    onChange={(newValue) => {
      setStream(newValue);
      
    }}
    style={{
      width: '100%',
    }}
  />

     </li>  
     <li className="gx-mb-3">    
    <Select
          allowClear={true}
          style={{width: '100%'}}
          value={mode}
          placeholder="Select Mode"
          options={courseModeOptions}
          onChange={(newValue) => {
            setMode(newValue);
            console.log(newValue)
          }}

    >
         
         
    </Select>
 
     </li>  
     <li> 
       <Button  type="primary" htmlType="submit" onClick={applyFilter}>Apply Filter</Button>
       <Button  type="danger" htmlType="submit" ghost onClick={clearFilter}>Clear</Button>
    </li>
   </ul>
</Form>
</div>
</div>
  );
};
export default InstituteCourseListingFilter;
