import React,{useState} from 'react'
import { Row, Col, Button, message } from "antd";
import { Link, useParams } from 'react-router-dom';
import InstituteLatestUpdateListComponent from "./InstituteLatestUpdateListComponent";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { API_URL_LIST } from '../../../constants/CollegeDekho';
import { useFetchData } from '../../../hooks';
const InstituteLatestUpdateListing = () => {
	const { instituteID } = useParams();
	const [extraDetails, setExtraDetails] = useState(null);
	const [error,setError] = useState(null)
	const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
	const [breadCrumb] = useFetchData(dashboardTypeDetailURL)
 
	return (

			<Row>
				{error ? message.error(error) : null}
				<Col span={24}>
					<NavBreadCrumb breadCrumb={breadCrumb}/>
					<div className="gx-pt-4">
						<div className="ant-card ant-card-bordered gx-card-widget">
							<div className="ant-card-body">
								<h2 className="gx-mb-0">{extraDetails?.institute?.name}, Latest Updates </h2>
							</div>
						</div>
					</div>

					<Row>
						<Col span={18}>
						</Col>
						<Col span={6} style={{ textAlign: 'right' }}>
						<Link to={{pathname: `/institute/${instituteID}/latest-update/create/`, locationData: {parent: {"name":extraDetails?.institute?.name} } }}>
							<Button type="primary">
								<span>Add New Latest Update</span>
							</Button>
						</Link>
						</Col>
					</Row>

				</Col>
				<Col span={24}>
					<InstituteLatestUpdateListComponent setExtraDetails={setExtraDetails} setError={setError}/>

				</Col>
			</Row>
		)
};
export default InstituteLatestUpdateListing;
