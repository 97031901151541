import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import { Row,Col } from "antd";
import UploadPicture from "./UploadPicture";
import UploadVideos from "./UploadVideos";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { API_URL_LIST } from '../../../constants/CollegeDekho';
import { useFetchData } from '../../../hooks';

const InstituteGallery = () => {
  const { instituteID } = useParams();
  const  [institute, setInstitute] = useState({});
  const dashboardTypeDetailURL = API_URL_LIST["INSTITUTE_DETAIL_URL"].replace("#id#", instituteID)
  const [breadCrumb] = useFetchData(dashboardTypeDetailURL)

  return (
    <Row>
			<Col span={24}>
				<NavBreadCrumb breadCrumb={breadCrumb}/>
				<div className="gx-pt-4">
					<div className="ant-card ant-card-bordered gx-card-widget">
			      		<div className="ant-card-body">
			      		<h2 className="gx-mb-0">{institute?.name}  Gallery Template </h2>
						</div>
					</div>
				</div>
			</Col>


			<Col span={24}>
                <UploadPicture instituteID={instituteID} institute={institute} setInstitute={setInstitute} />
                <UploadVideos instituteID={instituteID}/>
			</Col>

		</Row>
  );
};
export default InstituteGallery;
