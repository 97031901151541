import React from 'react';
import { Row, Col, Form, Input, Select } from "antd";
import { getObjectStatus, getCourseMasterPages } from "../../../util/helpers";
const objectStatusOptions = getObjectStatus()
const pageTypeOptions = getCourseMasterPages()

const { TextArea } = Input;




const MasterPageForm = ({ form, isAddMode }) => {


	return (
		<Row>
			<Col span={12}>
				<Form.Item label="Name" name="name" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Enter name',
						},
						{ type: 'string', max: 254 },
					]}>
					<Input className="gx-mb-3" />
				</Form.Item>
			</Col>

			<Col span={8}>
				<Form.Item label="Slug" name="uri_slug" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Slug Required',
						},
						{ type: 'string', max: 254 },
					]}>

					<Input className="gx-mb-3" disabled={!isAddMode} />

				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item label="Page Type" name="page_type" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Page Type Required',
						}

					]}>
					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Page Type"
						options={pageTypeOptions}
					>
					</Select>
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item label="Brief" name="brief" className="labelInput" rules={[

					{ type: 'string', max: 254 },
				]}>
					<Input className="gx-mb-3" />
				</Form.Item>
			</Col>

			<Col span={8}>
				<Form.Item label="Order" name="order" className="labelInput"
					rules={[
						{
							required: true,
							message: 'Order required',
						},


					]}>
					<Input className="gx-mb-3" />
				</Form.Item>
			</Col>

			<Col span={20}>
				<Form.Item label="H1" name="h1" className="labelInput"
					rules={[
						{
							required: true,
							message: 'H1 Required',
						},

						{ type: 'string', max: 254 },
					]}>
					<Input className="gx-mb-3" />
				</Form.Item>
			</Col>

			<Col span={20}>
				<Form.Item label="Title" name="title" className="labelInput" rules={[
					{
						required: true,
						message: 'Title Required',
					},
					{ type: 'string', max: 254 },
				]}>
					<Input className="gx-mb-3" />
				</Form.Item>
			</Col>

			<Col span={20}>
				<Form.Item label="Description" name="meta_description" className="labelInput" rules={[
					{
						required: true,
						message: 'Description Required',
					},

					{ type: 'string', max: 1000 },
				]}>
					<TextArea className="gx-mb-3" rows={4} />
				</Form.Item>
			</Col>

			<Col span={20}>
				<Form.Item label="Keywords" name="meta_keywords" className="labelInput" rules={[
					{
						required: true,
						message: 'Keywords Required',
					},

					{ type: 'string', max: 254 },
				]}>
					<TextArea className="gx-mb-3" rows={4} />
				</Form.Item>
			</Col>

			<Col span={8}>
				<Form.Item label="Object Status" name="object_status" className="labelInput" rules={[
					{
						required: true,
						message: 'Object Status Required',
					},
				]}>
					<Select
						allowClear={true}
						style={{ width: '100%' }}
						placeholder="Select object status"
						options={objectStatusOptions}
					>
					</Select>

				</Form.Item>
			</Col>

		</Row>
	)
}

export default MasterPageForm