import React from "react";
import { Link } from 'react-router-dom';
import { COURSE_CONTENT_API_LIST } from "../../../constants/CollegeDekho";
import { useFetchData } from "../../../hooks/";
import { Divider, Row, Col, Table, message } from "antd";
import NavBreadCrumb from '../../../components/CollegeDekho/NavBreadCrumb';
import { FormOutlined } from "@ant-design/icons";

const columns = [{
  title: 'ID',
  dataIndex: 'id',

},
{
  title: 'Name',
  dataIndex: 'name',
},
{
  title: 'H1',
  dataIndex: 'h1',

},
{
  title: 'Title',
  dataIndex: 'title',

},

{
  title: 'Order',
  dataIndex: 'order',

},
{
  title: 'Action',
  key: 'action',
  render: (text, record) => (
    <span>
      <Divider type="vertical" />
      <span className="gx-link"><Link to={`/course/master-page/${record.id}/update/`}> <FormOutlined /></Link></span>
      <Divider type="vertical" />

    </span>
  ),
}
];



const MasterPageListing = () => {


  const [data, loading, error] = useFetchData(COURSE_CONTENT_API_LIST['MASTER_PAGE_API_URL'])


  return (
    <Row>
      {error ? message.error(error) : null}
      <Col span={24}>
        <NavBreadCrumb />
        <div className="gx-pt-4">
          <div className="ant-card ant-card-bordered gx-card-widget">
            <div className="ant-card-body">
              <h2 className="gx-mb-0">Master Pages</h2>
            </div>
          </div>
        </div>
        <Row>
        </Row>
      </Col>

      <Col span={24}>


      </Col>

      <Col span={24}>
        <Table className="gx-table-responsive" loading={loading} rowKey="id" columns={columns} dataSource={data?.results} pagination={true} />
      </Col>

    </Row>
  );
};

export default MasterPageListing;
