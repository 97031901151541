import React from "react";
import { Row, Col, Form, Input } from "antd";
import DebounceSelect from "../../../components/DebounceSelect";
import { getContentTypeObjectList } from "../../../services/ContentType/ObjectList";
import { getCompanyList } from "../../../services/Company/Company";
import { CONTENT_TYPE_ID } from "../../../constants/Settings";

const StudentPlacementDetailForm = ({ form, instituteID }) => {

  return (
    <Row>
      <div></div>
      <Col span={12}>
        <Form.Item
          label="Course"
          name="course"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Enter Course",
            },
          ]}
        >
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Package" name="package_given" className="labelInput">
          <Input className="gx-mb-3" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label="Alumni"
          name="alumni"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Alumni Required",
            },
          ]}
        >
          <DebounceSelect
            mode="single"
            name="degree"
            placeholder="Select Alumni"
            cldID={instituteID}
            contentTypeId={CONTENT_TYPE_ID.alumni}
            fetchOptions={getContentTypeObjectList}
            showSearch={true}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Company"
          name="company"
          className="labelInput"
          rules={[
            {
              required: true,
              message: "Company Required",
            },
          ]}
        >
          <DebounceSelect
            cldID={instituteID}
            fetchOptions={getCompanyList}
            mode="multiple"
            placeholder="Select Company"
            showSearch={true}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default StudentPlacementDetailForm;
